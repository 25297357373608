<template>
  <div class="svg-container">
    <svg
      version="1.1"
      id="svgpoint"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 900 725"
      enable-background="new0 0 900 725"
      xml:space="preserve"
    >
      <g id="asia">
        <path
          id="ID"
          title="Indonésie"
          capital="Jakarta"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M748.498,608.098c-0.73,0.28-1.37,0.09-1.47-0.64c-0.09-0.73,1.65-1.01,1.65-1.01C750.048,606.178,749.228,607.818,748.498,608.098
		z M743.638,605.168c0,0-1.19-0.37-1.01,0.55C742.808,606.628,745.378,606.358,743.638,605.168z M745.748,595.638
		c0,0-0.64,1.65-0.09,2.02s0.83,0.09,1.19-0.92C747.208,595.728,746.108,594.438,745.748,595.638z M747.578,588.298
		c0,0-3.3-0.37-2.02,0.55C746.848,589.768,748.588,590.498,747.578,588.298z M564.058,667.548c0.33-1.19,1.41-1.3,0.98-3.69
		s-0.54-4.99-0.54-6.3s-0.43-3.8,0.22-4.99c0.65-1.19,0.98-1.96,1.09-2.82c0.11-0.87,0-0.76-1.3-2.17s-2.28-3.37-3.37-3.47
		c-1.09-0.11-3.15-0.54-3.47,0.76c-0.33,1.3-2.82,2.39-2.17,1.09s1.09-1.85,1.74-2.61s0.22-1.84-1.52-1.84s-0.76-0.54-0.98-2.06
		s0.76-2.28-0.76-3.26s-1.63-1.19-3.04-1.19s-2.39-0.54-2.61-1.3s-0.98-2.06-0.22-3.69s1.41-2.17,1.41-3.04s-0.22-1.63-1.85-2.17
		s-1.84-0.54-3.15,0.43c-1.3,0.98-4.02,3.26-3.58,1.74s3.04-1.85,3.26-2.61c0.22-0.76-0.65-2.06,0.43-2.28
		c1.09-0.22,0.11-1.52-0.22-1.63c-0.33-0.11-1.3,0.43-2.06,1.19s-2.39,0.65-2.61-0.33c-0.22-0.98-0.76-2.17-1.74-2.93
		s-1.19-1.3-1.96-1.96c-0.76-0.65,0.33-1.96-0.54-2.61c-0.87-0.65-1.84,0-2.06,0.76s-0.65-0.33-1.52-1.41s-2.06-1.41-2.17-0.76
		c-0.11,0.65,1.74,2.5,0.65,2.61c-1.09,0.11,0,0.43-1.52-1.09s-2.5-3.58-3.26-3.47s-1.3,0-1.63-1.52s-1.52-2.06-2.71-3.04
		s-3.8-2.82-5.32-3.26c-1.52-0.43-2.28-1.19-3.15-3.15s-2.06-3.47-2.82-4.67s-2.71-3.15-3.8-3.15s-0.98,1.52-2.39,0.54
		s-4.23-0.11-5.1-0.33c-0.87-0.22-1.52-0.43-2.17-1.19c-0.65-0.76-6.41-4.02-5.54,0.54c0,0,0.65,3.26,2.17,4.67
		c1.52,1.41,3.58,2.93,4.23,3.91c0.65,0.98,2.28,2.06,3.15,2.17s2.17,0.54,2.71,1.52c0.54,0.98,1.41,3.8,2.61,4.13
		c1.2,0.33,2.17,1.41,2.17,2.28c0,0.87,0.43,2.28,2.17,2.5c1.74,0.22,3.04,1.96,4.02,2.93s1.52,2.17,1.52,3.26s1.52,0.43,1.3,2.71
		c-0.22,2.28,0,4.02,0.98,4.45s2.28,0.22,3.58,2.06c1.3,1.85,2.5,3.91,3.58,5.21c1.09,1.3,1.52,2.06,1.96,3.58s0.43,2.61,1.41,3.37
		s1.19,1.41,1.41,2.5s0.22,2.61,1.19,3.15s2.71,1.63,2.93,3.04s1.41,2.17,2.5,2.61c1.09,0.43,2.5,2.06,2.93,3.58s0,1.41,2.28,2.5
		s3.47,2.82,5.21,3.58s4.23,3.15,5.21,4.23s3.26,4.34,3.47,3.47c0.22-0.87,0.11-2.06,1.63-1.3s1.52,0.43,2.71,0.11
		c1.19-0.33,1.41-0.87,2.5,0.11C563.618,668.528,563.728,668.748,564.058,667.548z M507.698,619.008c0,0-1.74-1.96-2.93-1.3
		c-1.2,0.65-0.54,1.41,0.33,2.61c0.87,1.19,1.63,1.84,2.61,2.71s1.74,0.65,2.06-0.43
		C510.088,621.508,510.308,621.078,507.698,619.008z M497.168,608.918c0,0-1.41-1.09-2.17-0.87c-0.76,0.22-1.52,1.74-0.22,1.85
		s1.63-0.43,2.71,0.98c1.09,1.41,2.39,1.3,2.06,0.33C499.238,610.218,498.688,609.778,497.168,608.918z M505.098,612.498
		c0,0-0.76-0.43-1.3-0.54c-0.54-0.11-0.87,0.87,0.33,1.3C505.318,613.688,507.048,613.688,505.098,612.498z M512.698,627.808
		c0,0-0.76,0.87-0.22,1.19c0.54,0.33,0.54,0.76,0.22,1.09s-0.98,1.3-0.87,1.63c0.11,0.33,1.19,0.54,1.52-0.22
		c0.33-0.76,0.76-1.19,0.76-2.06C514.108,628.568,513.458,627.588,512.698,627.808z M516.928,635.298c0,0-0.87-1.09-1.63-0.54
		c-0.76,0.54-1.52,1.3-0.65,2.39s1.41,2.61,2.17,2.82s2.17,0.11,2.17-0.65s-0.98-1.52-1.41-2.39
		C517.148,636.058,517.588,636.168,516.928,635.298z M524.858,645.288c-0.76-0.65-2.17-2.17-3.58-3.37c0,0-1.3-0.22-0.87,0.54
		c0.43,0.76,1.52,1.09,2.17,1.85c0.65,0.76,1.09,1.3,1.19,2.28c0.11,0.98,0.98,1.19,0.98,0.43
		C524.748,646.268,525.618,645.938,524.858,645.288z M526.378,647.568c0,0-0.76,0.76-0.65,1.09s0.65,1.09,0.65,1.63
		s0.87,1.19,0.87-0.11C527.248,648.878,527.248,647.568,526.378,647.568z M539.298,665.158c0,0.87,2.17,0.98,0.65-0.54
		C539.948,664.618,539.298,664.288,539.298,665.158z M560.908,640.618c-0.76,0.22-1.84,1.3-0.22,1.63c1.63,0.33,2.93,0.76,3.26,1.3
		s-0.22,2.61,1.85,3.69c2.07,1.08,3.8,3.47,4.02,1.63c0.22-1.85,1.74-1.85-0.43-3.15s-2.82-1.96-2.82-3.04s0.43-2.06-1.3-3.37
		c0,0-0.33-0.98-0.76-0.11s-0.65,0.54-1.09,0.11c-0.43-0.43-1.19-0.22-1.85-0.22S561.668,640.398,560.908,640.618z M553.418,631.498
		c0.54,0.33,1.19,0.76,1.19-0.43s0.65-1.96,1.09-1.63s2.06-0.22,0.65-1.52c0,0-2.28-0.33-2.28,0.54s0.43,0.98,0,1.41
		C553.638,630.308,552.878,631.178,553.418,631.498z M624.108,681.558c-1.41,0.22-0.22-1.09-2.17-0.98
		c-1.96,0.11-5.54,1.41-7.82,0.98s-1.96-2.06-2.06-2.71s-0.22-2.17-2.06-2.82s-2.61-0.33-4.67-1.3c-2.06-0.98-4.78-0.11-4.89-1.09
		c-0.11-0.98-1.84-1.85-2.5-0.43s0.22,2.61-2.71,2.71c-2.93,0.11-6.3,0.33-7.17-0.22c-0.87-0.54-0.54-0.87-1.96-0.87
		s-2.39-0.33-2.82-0.98s-0.76-1.63-1.96-2.39s-1.19,0.87-2.71-0.22s-2.39,0.22-2.61-0.43c-0.22-0.65-0.76-1.3-1.74-1.74
		s-1.74-0.65-1.96,0.33c-0.22,0.98-0.33,2.28-1.41,1.3c-1.09-0.98-0.87-1.19-2.17-1.19s-4.13-1.3-4.13,1.19c0,0,0.33,0.87-0.98,1.96
		c-1.3,1.09-2.93,1.52-1.74,2.5s1.74,1.3,2.17,0.76s0.98-1.09,1.85-0.22c0.87,0.87,2.71-0.43,2.71,1.3s-0.98,2.61,0.54,2.61
		s5.32-0.43,6.84,0.65c1.52,1.09,4.56,2.93,5.86,1.96s2.71-1.3,4.02-1.09c1.3,0.22,5.1,0,6.3,1.19s3.58,1.74,5.1,2.28
		c1.52,0.54,3.37,0.22,5.21,0.43c1.85,0.22,5.1,1.3,6.3,1.19c1.19-0.11,1.19,0.87,2.28,0.87s1.63,0.54,1.96-0.11
		c0.33-0.65-0.11-1.09,1.41-1.09c1.52,0,2.39,0.43,3.47,1.09s1.63,0.33,2.71,0.87c1.09,0.54,1.41,0.11,2.61,0.65
		c1.19,0.54,0.76,0.22,0.65-0.65s0.98-0.43,0.87-1.74s-1.3-1.3-0.76-2.06s1.3-1.09,1.3-1.63
		C625.298,681.878,625.518,681.338,624.108,681.558z M576.548,649.418c0.98,0.76,3.69,1.41,3.91,0.43s1.3-0.33,1.3-1.96
		s-0.11-1.96-1.52-2.39c-1.41-0.43-1.96-1.41-2.93-0.11c0,0-0.87,0.98-0.76,1.63C576.658,647.678,575.568,648.658,576.548,649.418z
		 M642.238,635.188c0.54-1.19,2.61-1.74,2.61-2.93s-1.09-5.32-0.11-6.62c0.98-1.3,1.74-1.74,2.5-2.71c0.76-0.98-0.11-2.06,1.41-1.09
		s1.85,0.65,3.47,0.65s2.06-0.22,2.71-0.98s0.11-1.19-1.41-1.85c-1.52-0.65-3.47-1.19-4.34-2.82c-0.87-1.63-2.06-1.19-1.74-2.61
		c0.33-1.41,1.63-1.85,0.76-3.15c-0.87-1.3-1.96-2.39-2.61-3.37c-0.65-0.98-1.96-1.74-2.17-2.82c-0.21-1.08-0.33-1.63,0.76-1.63
		s1.52-0.54,2.06,0.11s1.63,0.33,1.19-0.33c-0.43-0.65-1.3-1.41-1.84-2.06c-0.3-0.36-0.27-0.82,0.04-1.16
		c-0.8-0.56-1.79-1.48-2.87-1.83c-1.52-0.49-3.53,0.22-5.16,0s-3.26-0.22-4.13-0.22s-0.49,1.3-1.36,2.5
		c-0.87,1.19-0.87,2.17-0.92,4.18s-0.87,2.55-1.9,2.93s-0.76,1.25-0.87,2.23s0.22,1.57-1.03,2.12s-0.92,2.5-1.41,3.2
		s-1.03,1.09-1.09,2.01s-2.77,0.87-4.18,0.76s-1.79,1.96-2.93,1.74c-1.14-0.22-2.71-0.76-3.09-1.47s-2.23-0.71-3.64-0.6
		c-1.41,0.11-2.01,0.16-2.5,0.87s-1.19,0.87-2.01,2.12c-0.81,1.25-2.82,1.19-3.8,0.71s-1.57-0.11-2.34-0.11
		c-0.76,0-2.06,0.27-2.12,0.81s-0.27,0.6-1.36,0.43c-1.09-0.17-1.52-0.71-1.96-1.52s-2.23-1.25-2.77-2.01
		c-0.54-0.76-1.19-1.79-2.06-2.66c-0.56-0.56-0.6-1.87-0.56-2.72c-0.38,0.29-0.59,0.93-1.12,1.69c-0.76,1.09-1.52,0.65-1.3,1.74
		c0,0,0.22,2.5-0.98,3.15s-1.63,1.74-1.3,3.04s-0.11,2.82,0.65,3.26c0.76,0.43,1.19,0,1.19,1.52s0.11,3.8,0.76,4.34
		s1.52,1.52,1.63,2.61s1.3,2.39,1.52,1.74c0.22-0.65,2.28-1.74,2.28,0s0.33,2.17,0.33,3.8s-0.43,1.85-0.11,2.61
		c0.33,0.76,1.52,0.87,1.19,2.5s-0.33,3.15,0.76,3.15s3.04-0.65,3.69,0s1.63,1.52,2.06,0.43s1.09-0.98,1.96-0.98s2.28-0.54,2.5,0.54
		c0.22,1.09,0.65,1.19,0.54,2.39c-0.11,1.19,0.22,2.93,0.76,1.96s1.3-0.65,1.41-1.3s0.87-1.63,1.52-0.54s1.74,0.98,1.96,0.33
		c0.22-0.65,1.52,0.11,1.63-0.76s0.22-1.3,1.85-1.09c1.63,0.22,2.39,0.43,2.5,1.3s-0.11,1.19,1.41,1.19c1.52,0,1.85,0.22,1.85-0.65
		s-0.11-0.43,1.41-0.43c1.52,0,1.09,0.43,1.41,1.52c0.33,1.09,0.87,0.43,0.76,1.96s0.22,1.96,1.19,1.96s1.96,0.65,1.96-0.43
		s2.39-0.11,2.39-0.65s1.41-0.11,1.85-1.09c0.44-0.98,2.17-0.43,2.5-0.22c0.33,0.22,0.33,0.98,0.54,1.63
		c0.22,0.65,1.3,0.87,1.41-0.11s0.65-1.52,0.98-2.06c0.33-0.54,0.98-1.63,0.22-2.06s-2.28-0.87-2.28-1.52s-0.22-1.52,1.41-1.52
		c1.63,0,0.87-0.98,0.87-1.74s1.09-0.65,1.09-1.96s-1.3-1.96-1.19-2.82c0.11-0.87-1.74-1.3-0.54-2.28c1.19-0.98,2.61-1.19,3.26-2.39
		C640.498,635.838,641.698,636.388,642.238,635.188z M681.868,666.358c0.87-0.33,0.98-0.33,1.09-1.19c0.11-0.87,1.74-0.65,1.09-0.98
		s-0.65-0.54-0.65-1.63s-0.54-1.52,0.33-1.85s0.33-0.87,0.33-1.3s-0.87-1.19-1.3-0.43s-1.52,0.22-1.52-0.11
		c0-0.33-0.33-1.41,0.22-1.63c0.54-0.22,1.19-1.3,1.52-0.54s1.41,1.41,1.3,0.54s1.63-0.98,0.43-1.63s-1.84-0.65-1.84-0.22
		c0,0.43-0.43,0.87-1.85,0s-2.5-0.33-2.82-1.41c-0.33-1.09-1.09-0.76-0.11-1.74s1.09-1.19,0.54-2.17s-0.33-1.52-1.52-2.61
		c-1.19-1.09-2.82-2.82-3.37-4.02c-0.54-1.19-1.52-2.39-0.11-2.82c1.41-0.43,4.45-1.3,5.1-2.71c0.65-1.41,1.63-2.5,2.5-2.82
		c0.87-0.33,1.52-0.22,1.52,0.54c0,0.76-1.09,0.11-1.09,1.41s-0.43,2.39,0.43,2.17c0.87-0.22,0.87-1.3,1.3-0.54s0.65,0.98,1.09,0.43
		c0.43-0.54,0.87-1.09,1.19-0.33c0.33,0.76,1.3,1.19,1.3,0s-0.11-2.71-0.87-2.71s-1.3-0.11-1.09-0.87c0.22-0.76,0.98,0.11,0.98-1.3
		s0.11-2.17-1.41-2.28c-1.52-0.11-2.39-0.22-2.71,0.54c-0.33,0.76-1.3,0.65-2.82,0.65s-2.39,0.22-2.82,0.98s-1.63,1.3-2.17,0.43
		s-0.76-1.3-1.3-0.98c-0.54,0.33-0.87-0.33-1.3,1.19s-1.09,1.19-1.41,1.96c-0.33,0.76-0.43,0.76-1.74,0.76s-2.06,0-2.17-1.19
		s-0.11-2.28-1.63-2.71s-1.85-0.22-1.85-1.52c0-1.3-0.76-5.43,0.98-5.76s1.41-1.85,2.06-1.85s1.19,0.11,1.63,0.87
		c0.43,0.76,1.3-0.11,2.61-0.11s1.63,0.43,1.96-0.11s1.52-0.98,2.17-0.33c0.65,0.65,1.3,0.65,2.5,0.54
		c1.19-0.11,2.06-0.33,3.58-0.22c1.52,0.11,2.61-0.22,3.69,0.54c1.08,0.76,1.52,0.76,3.26,0.76s3.15,0.87,3.91,0
		s1.41,0.33,2.17-1.41s1.3-2.06,2.71-3.04c1.41-0.98,1.74-1.96,2.17-3.15s0-1.19-0.65-1.96s-1.85-0.87-3.58,1.19
		c-1.73,2.06-2.17,3.69-4.02,3.8c-1.84,0.11-2.93,0.33-3.37-0.33c-0.43-0.65-2.39-0.22-3.26-0.22s-0.65,0.98-1.63,0.65
		s-1.74-0.87-2.39-0.87s-0.54-1.09-2.5-0.87c-1.96,0.22-4.02,0.54-4.13-0.33s-0.87-0.33-1.74-0.43c-0.87-0.11-1.09-1.74-2.17-1.19
		c-1.08,0.55-1.3,2.06-1.96,2.93c-0.65,0.87-1.85,0.98-3.04,1.09s-1.63,1.52-2.28,3.04s-2.17,2.93-1.84,3.69
		c0.33,0.76,0.54,0.11,0.54,1.41s0.22,2.93-1.09,3.91c0,0-2.17,2.17-1.74,3.37c0.43,1.19,0.43,2.17-0.22,3.15
		c-0.65,0.98-0.98,1.84-0.98,2.82s-0.11,1.96-1.41,2.39s-1.84,1.41-0.98,1.96c0.87,0.54,0.98,1.52,0.33,2.39
		c-0.65,0.87-0.43,1.96,0.54,2.28c0.98,0.33,0.98-0.87,2.5-0.65s1.85,0.43,1.63,1.52s-1.3,2.06,0.22,2.82s0.87,1.41,0.76,2.82
		s-0.33,1.74-1.3,2.39c-0.98,0.65-0.76,2.61-0.76,3.69s1.09,1.52,2.28,2.06s2.5,1.09,2.93,0.11c0.43-0.98,1.19-1.3,1.74-1.3
		s1.09-0.87,0.43-1.3c-0.65-0.43-0.87-0.65-0.76-1.74s1.3-1.09,1.3-2.61c0-1.52,0.22-1.96-0.65-2.39s-0.76-0.54-0.65-1.52
		c0.11-0.98-0.22-1.52,0.54-1.52s0.76-0.76,0.76-2.17s0-1.74-0.54-2.06s-0.76-1.09-0.76-1.74c0-0.65-0.33-1.41,0.87-1.52
		c1.19-0.11,0.76-1.41,2.06-1.09c1.3,0.33,2.17,0,2.17,1.63s0.43,1.85-0.54,2.61c-0.98,0.76-1.3,1.74,0.22,3.04
		c1.52,1.3,1.84,2.39,3.04,2.5c1.19,0.11,1.09,0.87,1.09,2.06s-0.65,3.37,1.09,3.37s2.28,0,2.28-1.19s0.87-1.52,1.63-1.52
		s0.98,1.74,0.87,3.26s-2.28,4.13,0.87,5.1C682.958,667.978,680.998,666.678,681.868,666.358z M635.508,685.788
		c0,0-0.65,0.54-0.98,1.41c-0.33,0.87-0.65,1.52,0.43,2.06c1.09,0.54,2.5,0.65,2.82-0.33c0.33-0.98,0.43-1.41,0.87-1.63
		c0.43-0.22,0.65-0.76,0.33-1.41C638.658,685.248,636.588,683.838,635.508,685.788z M645.708,687.528c-0.65-0.33-0.54-1.09-2.5-0.98
		c0,0-2.17,0.11-2.61,1.09c-0.43,0.98-1.09,1.09-0.87,1.84c0.22,0.76,0.76,0.76,1.52,1.09s1.3,0.76,2.39,0.33s1.41-0.87,2.5-0.65
		s1.19,0.11,0.87-1.09C646.688,687.958,646.368,687.858,645.708,687.528z M655.268,688.828c0.11-0.76,0.43-0.65,0.33-1.63
		c-0.11-0.98-0.54-1.84-2.06-1.84s-2.17,0.33-3.47-0.54s-2.39-2.17-3.58-0.54c0,0-0.76,0.11,0,0.98s1.19,0.98,1.63,1.96
		c0.43,0.98,0.43,2.06,1.19,1.74c0.76-0.33,1.96,0,2.39,0.43s1.41,0,2.28,0C654.828,689.378,655.158,689.588,655.268,688.828z
		 M667.648,696.978c-1.19-0.54-1.09-1.3-2.61-2.28c-1.52-0.98-1.96-0.54-2.71-1.63c-0.76-1.09-1.52-0.76-3.04-0.33
		c0,0-2.28-0.76-3.04,0s-1.52,0.54-1.52,1.3s0.11,0.54,0.87,1.09c0.76,0.54,0.43,0.87,1.96,0.87s1.63,0.11,2.17,0.76
		s1.63,0,2.5,0.87s1.74,2.06,2.5,2.06s1.19-0.65,1.85-0.98C667.208,698.388,668.838,697.518,667.648,696.978z M677.098,686.878
		c-0.76,0.11-0.54-1.19-1.74-1.09s-0.87,1.52-2.17,1.41s-2.82-0.43-3.37-1.19c-0.54-0.76-0.98-0.98-2.5-0.98s-5.21-0.33-5.65,1.41
		c0,0-0.43,0.76-1.63,0.54c-1.19-0.22-3.37-0.76-1.96,0.54s1.41,0.98,3.37,1.41s1.84,0.87,3.47,1.09c1.63,0.22,1.52-0.43,3.91,0
		c2.39,0.43,3.8,0.11,5.65-0.22c1.85-0.33,0.22-0.11,1.52-0.65c1.3-0.54,2.17-0.11,2.06-0.76S677.848,686.768,677.098,686.878z
		 M688.818,686.008c0.65,0,1.3,1.09,1.84,1.19c0.54,0.11,0.54,0.22,0.65-0.87s-0.54-1.3-1.84-1.3c-1.3,0-1.74-0.65-2.82-0.65
		s-1.96,0.54-2.71,0.54c-0.76,0-0.98-2.82-2.93-1.09c0,0,0.11,1.19-0.87,2.06s-1.63,1.74-0.22,1.96c1.41,0.22,2.71,0,2.93-0.76
		s1.63-1.63,2.28-0.87s0.87,1.3,1.63,0.76C687.518,686.438,688.168,686.008,688.818,686.008z M703.588,683.188
		c0.65-0.33,3.15,0.87,3.26-0.11s1.74-0.11,1.84-1.19c0.1-1.08-0.76-1.52-1.96-1.3c-1.19,0.22-1.19-0.98-3.58,0.43
		c0,0-1.09,0.87-1.09,1.84C702.068,683.838,702.938,683.508,703.588,683.188z M683.498,700.778c0,0-0.54,0.98-1.96,1.41
		c-1.42,0.43-0.22,1.96,0.76,1.84c0.98-0.11,0.87-1.09,2.06-0.98c1.19,0.11,1.09-0.54,0.76-1.52
		C684.788,700.548,684.148,699.688,683.498,700.778z M675.568,699.368c0,0-1.19,0.43-1.74,1.52c-0.54,1.09,0.43,2.17,1.3,0.98
		C676.008,700.668,677.308,697.848,675.568,699.368z M711.728,684.708c-1.52-0.11-3.37-0.87-4.45,0.33
		c-1.09,1.19-1.41,0.76-3.69,0.65s-3.26-0.54-4.99,1.19s-2.28,2.82-3.8,3.8c-1.52,0.98-1.84,1.09-3.58,1.41c0,0-3.26,0.43-4.02,2.06
		s-0.43,2.17-0.43,3.58s-0.11,3.26,1.63,2.61s0.98-1.74,2.82-1.96c1.85-0.22,3.47-0.87,4.45-2.28c0.98-1.41,2.71-3.37,4.45-3.8
		s1.85-1.3,4.23-1.52c2.39-0.22,1.74-1.3,3.91-1.84s3.26-0.87,4.02-1.96C713.038,685.898,713.248,684.818,711.728,684.708z
		 M709.668,597.298c-1.09,0-0.98-0.87-1.09,0c0,0,0,0.54,0,1.3s-1.52,1.19-1.09,2.39c0.43,1.19,1.09,1.63,1.19,0.33
		s0.54-0.98,0.98-2.28C710.098,597.728,710.748,597.298,709.668,597.298z M713.468,618.688c-0.54,0.43-0.43,1.3,0,1.96
		c0.43,0.65,0.65,0.98,0.65,2.06s-0.43,1.63,0.22,2.28s0.98,0.65,0.76,2.17s-0.54,1.96,0.87,2.93c1.41,0.98,1.41,2.61,2.28,3.04
		c0.87,0.43,1.3,0.11,0.76-1.09s-2.28-2.5-1.96-4.45c0.33-1.96,0.43-2.82,2.06-2.61c1.63,0.22,1.41,0.22,2.39,0.87
		c0.98,0.65,2.5,0.11,1.84-0.98c-0.65-1.09-1.09-1.52-2.06-1.41c-0.98,0.11-1.52,0-1.3-0.87c0.22-0.87,0.33-0.87,1.19-1.19
		c0.87-0.33,1.19-0.33,1.41-1.74s0.11-2.06-1.09-2.82s-1.52-0.22-1.96,0.87s-0.87,2.06-1.74,1.96c-0.87-0.11-0.54-0.43-0.54-2.82
		s0-6.84-1.74-2.82c0,0-1.3,1.3-1.3,2.5C714.228,617.708,714.008,618.248,713.468,618.688z M720.528,609.458
		c0,0-1.63,1.41-1.63,2.82s0.87,2.17,1.63,1.3s1.41-1.41,1.52-2.61C722.158,609.778,721.608,607.288,720.528,609.458z
		 M704.348,641.378c1.19-0.54,0.43-1.96-0.76-1.96s-2.82,0.87-4.13,0.65c-1.3-0.22-0.87-1.74-4.78-1.19c0,0-3.26-0.22-3.37,0.65
		s-0.11,2.17,1.96,2.28c2.06,0.11,0.87-0.76,2.71-0.76s5.32-0.65,5.65,0.54s0.54,2.17,1.09,2.28c0.54,0.11,0,1.63,0.54,1.74
		s0.98,0.11,0.98-1.3C704.238,642.898,703.148,641.918,704.348,641.378z M706.738,648.658c0,0-3.47,0.11-3.04,1.52
		c0.43,1.41,0.54,1.09,1.52,2.39c0.98,1.3,1.74,2.39,2.61,2.39s0.98-1.09,2.06-1.41s1.63-0.43,1.74-1.63
		c0.11-1.19-0.22-1.3-1.63-2.28C708.578,648.658,709.778,648.548,706.738,648.658z M718.028,638.778c0-0.98-0.76-1.09-1.3-1.09
		s-0.76-1.63-2.28-0.76c0,0-2.06,0.54-2.06,1.19s1.19,0.43,1.96,1.3s1.41,0.54,1.96-0.11
		C716.838,638.668,718.028,639.748,718.028,638.778z M711.408,631.178c1.74,1.41,1.09,1.52,2.61,1.84c1.52,0.33,2.06,0.33,1.63-0.87
		c-0.43-1.19-1.3-2.39-3.15-2.61C712.488,629.548,709.668,629.758,711.408,631.178z M736.808,651.368
		c-0.98-0.76-0.54-2.93-2.71-2.93c-2.17,0-2.61,0.54-4.13-0.76s-2.61-1.41-3.26-1.09s-1.63,0.76-3.47,0.76c-1.85,0-0.33,0-2.17,0
		c0,0-2.28-0.11-3.15,0.54c-0.87,0.65-2.06,1.63-1.41,3.8s0.43,1.3,1.09,1.84c0.65,0.54,1.52,1.09,1.52-0.33s-1.19-0.11-1.19-1.41
		s0.22-1.74,0.87-1.63s0.43,1.3,1.09,1.41c0.65,0.11,2.06,0.87,2.39,0.33s0.22-0.65,0.87-0.65s1.09-0.11,1.41,0.43
		c0.33,0.54,1.19,0.54,2.06,0.54s1.09,0.43,1.19-0.43c0.11-0.87,0.54-0.76,1.74-0.54c1.2,0.22,0.33,1.09,1.63,1.09
		s0.87,0.87,1.96,0.87s0.98-0.43,1.52,0.54c0.54,0.98,0.87,1.09,1.84,1.09c0.98,0,0.76-0.54,0.65-1.63
		C737.028,652.128,737.788,652.128,736.808,651.368z M729.968,641.918c1.19,0.54,1.3,0.33,2.39,0.11s1.52,0,2.17-0.76
		c0.65-0.76-0.22-1.09-1.3-1.41c-1.09-0.33-0.98-0.98-1.96-0.11C731.278,639.748,728.778,641.378,729.968,641.918z M740.288,629.218
		c0-0.65,0.11-1.41-1.41-1.19c-1.52,0.22-1.19,0-3.47-0.22c0,0-2.28-0.65-2.17,0.76c0.11,1.41,0.11,0.87,1.09,0.98
		c0.98,0.11,1.09,0.22,1.63,0.76s0.87,1.3,1.19,0.33c0.33-0.98,0.98-0.54,1.85-0.33
		C739.858,630.518,740.288,629.868,740.288,629.218z M778.078,639.538c-1.63-0.33-2.28,0.33-3.15-0.33c-0.87-0.65,0-0.76-2.39-0.76
		c0,0-1.74,0.43-2.82-0.33c-1.09-0.76-1.84-0.76-2.93-0.65c-1.09,0.11-1.41,1.19-0.11,1.19s0.98-0.43,2.5,0.43
		c1.52,0.87,1.74,0.87,2.61,1.3s1.52,0.65,2.93,0.76s3.15,0.98,3.26,0.11C778.078,640.398,779.698,639.858,778.078,639.538z
		 M771.128,635.188c0.54,1.09,0.98,0.98,1.74,1.19s1.84,0.22,1.52-0.87c-0.33-1.09-0.43-2.06-4.02-3.04c0,0-2.93-0.11-1.41,0.98
		S770.578,634.108,771.128,635.188z M764.718,633.888c0,0-0.54,0,0.11,0.87s1.09,0.11,0.98-0.43
		C765.698,633.778,765.698,633.888,764.718,633.888z M763.738,666.568c-0.87-0.43-0.65-3.69-2.17-1.19c0,0-1.84,0.87-1.41,1.96
		c0.43,1.09,0.87,1.52,0.43,2.06c-0.43,0.54-0.98,0.76-0.98,2.17s-0.33,4.78,0.33,4.78s1.09,0.11,1.74-1.19
		c0.65-1.3,1.09-0.33,0.98-2.06c-0.11-1.74,0.87,0.43,1.19-1.52C764.178,669.608,764.608,667.008,763.738,666.568z M745.498,676.888
		c-0.76,0-2.93-0.54-3.58,0.54c0,0-1.52,1.52-2.06,2.28s-2.93,2.17-1.41,2.71c1.52,0.54,2.06,1.3,2.61,0.22
		c0.54-1.09,2.28-1.19,2.28-2.5c0-1.3-0.65-2.06,0.87-2.06C745.718,678.078,746.258,676.888,745.498,676.888z M807.418,680.478
		c0.19-2.59,0.74-4.08-0.19-4.82s-1.67-1.85-0.37-2.59c1.3-0.74,0.56-3.34,0.56-3.34l-0.17-23.36c-0.9,0.19-1.59,0.3-1.91-0.53
		c-0.54-1.41,0.33-1.96-2.17-1.96s-4.45,0.54-7.6-1.3c-3.15-1.84-3.58-2.17-5.32-2.82s-2.06-2.06-3.91-1.84
		c-1.84,0.22-3.58,0.11-4.34,1.63s-0.43,2.82-2.39,2.82s-3.04-0.33-3.91,1.19s-4.23,3.69-4.67,4.78c-0.43,1.09,0,3.26-2.61,3.15
		s-2.82-0.87-4.13-2.17c-1.3-1.3,0.54-2.17-1.09-3.26s-2.82,0-2.71-2.61s-0.87-3.37,0-4.99c0.87-1.63,1.09-1.74,0.11-2.82
		c-0.98-1.08,0.33-2.61-2.28-2.93c-2.61-0.32-3.37,0.33-4.99-0.76c-1.63-1.09-4.99-3.04-7.28-1.09c0,0-1.41,1.63-3.26,1.63
		c-1.85,0-2.93,0.33-2.5,1.19c0.43,0.87,0.43,2.71-0.65,1.41s-1.74-1.63-2.93-1.96s-1.52-0.76-2.82-0.11s-0.11,1.3,1.3,1.3
		s1.09,0.22,1.3,1.09c0.22,0.87,0.65,1.52,1.52,1.74s-0.76,1.09,0.98,1.3c1.74,0.22,5.32,0.54,5.76,1.84
		c0.43,1.3-0.22,2.61,1.41,3.04c1.63,0.43,1.74-0.11,3.47,0.43c1.74,0.54,2.28,0.54,2.82,0s0.76-0.54,2.17-0.87s3.15-0.43,3.37,0.33
		s0.43,1.41-0.98,1.63c-1.41,0.22-2.5,0.11-3.69,0.11s-1.74,0.43-2.17,1.41s-1.3,0.76-2.82,0.33s-3.15-0.98-2.93,0.22
		s0.33,1.41,2.06,1.63c1.73,0.22,0.98,1.52,2.39,2.17c1.41,0.65,1.19,1.19,1.19,2.82s-0.54,2.28,0.54,2.82
		c1.09,0.54,3.69,0.11,4.02-2.06s0.98-2.82,2.06-1.19s1.63,1.74,2.28,1.74s0.54,1.09,2.39,1.19c1.85,0.11,2.17,0.54,3.26,1.96
		c1.09,1.41,1.96,0.87,3.69,1.19c1.74,0.33,3.15-0.76,5.76,1.19c2.61,1.96,2.5,1.74,5.32,2.17s3.8,0.22,6.52,3.15
		c2.71,2.93,0.22,4.02,3.91,6.62c1.63,1.63,1.63,2.28,1.63,3.8c0,1.52,0.43,2.61-1.63,2.82c-2.06,0.22-4.34,0.65-4.67,2.17
		c-0.33,1.52-1.19,2.71-1.63,3.69c-0.43,0.98-0.43,1.96,2.17,1.74c2.61-0.22,5.86,0.54,5.97-0.54c0.11-1.09,1.41-1.96,2.39-1.85
		c0.98,0.11,1.19,0.87,1.96,0.76c0.76-0.11,2.93-1.63,3.91,0.11s3.58,2.71,4.89,4.67c0.76,1.15,1.16,1.77,1.77,2.06
		C807.428,688.338,807.288,682.308,807.418,680.478z M618.128,675.798c0,0-2.71-0.22-3.69-0.22s-1.3,0.54-1.3,1.09
		c0,0.54,0.11,1.19,1.19,1.19s2.06,0,2.06,0.65s1.3,0.76,1.41,0s0.65-0.76,1.52-0.98s1.85-0.76,1.85-1.19
		C621.168,675.908,620.298,675.798,618.128,675.798z M629.968,675.258c0,0-1.63,0.33-0.65,0.76S632.468,675.368,629.968,675.258z
		 M630.078,684.158c-0.56-0.64-1.3-0.33-2.06-0.22c-0.76,0.11-0.11,1.19-1.19,1.19s-1.74,0.11-1.19,1.52
		c0.54,1.41,1.52,0.65,1.74,1.3c0.22,0.65,0.33,1.74,0.87,1.74s0.98-0.43,0.98-1.09s0.43-0.76,1.19-0.87s1.3-0.54,1.41-1.09
		C631.918,686.118,631.598,685.898,630.078,684.158z M665.258,667.978c0,0-1.09,2.5-0.43,3.47s1.52,0,1.52-1.09
		C666.348,669.268,665.908,666.458,665.258,667.978z M695.878,684.488c0,0-3.26-1.3-3.26,0.33s0.65,2.61,2.17,1.96
		c1.52-0.65,1.19-0.98,2.28-1.09C698.158,685.578,698.268,684.158,695.878,684.488z M716.178,683.618c0,0-1.52,1.3-0.33,1.74
		C717.048,685.788,719.008,684.488,716.178,683.618z M729.208,681.558c0,0-2.28,0.54-1.19,1.41s1.63,0.98,1.74-0.11
		S730.518,681.558,729.208,681.558z M738.228,684.158c0,0-2.17-0.11-2.06,0.87s1.52,1.63,1.63,0.76
		C737.898,684.918,741.158,685.138,738.228,684.158z"
          pointer-events="auto"
        ></path>

        <path
          id="MY"
          title="Malaisie"
          capital="Kuala Lumpur"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M537.558,586.058c0.2-0.55,0.34-1.01,0.74-1.43c0.41,0.34,0.84,0.6,1.28,0.78c1.56,0.62,2.34,0.93,3.42,2.49
		c1.09,1.56,2.49,2.02,3.74,4.2s1.25,5.29,0.93,7.16c-0.31,1.87-0.47,7.94,0.93,9.49c1.4,1.56,2.03,2.34,2.65,3.42
		c0.62,1.09,1.71,2.8,1.56,4.82c-0.01,0.14-0.04,0.27-0.08,0.4c-0.37-0.14-0.63-0.34-0.98-0.46c-0.53-0.18-1.23,0.4-2.28,0.7
		c-0.62,0.18-0.77,0.96-0.78,1.58c-0.23-0.08-0.42-0.19-0.54-0.35c-0.78-1.09-4.05-4.2-6.54-5.14s-8.72-1.56-8.72-4.67
		s-3.42-7.31-4.36-8.71c-0.93-1.4-1.87-6.07-2.18-9.65c-0.3-3.42-0.46-6.41-2.09-8.02c0.2-0.15,0.39-0.35,0.48-0.59
		c0.27-0.69,1.17-0.79,1.33-0.21c0.16,0.58,0.32,1.01,1.27,1.17s1.96,0.48,2.81,1.27c0.85,0.8,1.8,1.33,1.27,1.8
		c-0.53,0.48-0.64,1.01-0.64,1.64s0.11,1.01,0.58,1.17c0.48,0.16,1.33,0.21,1.33-0.53c0-0.74,0.48-1.27,1.17-1.27
		s0.27,1.06,1.54,0.96s1.33-0.42,1.33-1.01C536.708,586.478,537.288,586.798,537.558,586.058z M590.068,616.028
		c0.87,0.87,1.52,1.9,2.06,2.66c0.54,0.76,2.34,1.19,2.77,2.01c0.43,0.81,0.87,1.36,1.96,1.52s1.3,0.11,1.36-0.43
		c0.05-0.54,1.36-0.81,2.12-0.81s1.36-0.38,2.34,0.11s2.99,0.54,3.8-0.71s1.52-1.41,2.01-2.12s1.09-0.76,2.5-0.87
		s3.26-0.11,3.64,0.6s1.96,1.25,3.09,1.47c1.14,0.22,1.52-1.85,2.93-1.74s4.13,0.16,4.18-0.76s0.6-1.3,1.09-2.01
		c0.49-0.71,0.16-2.66,1.41-3.2c1.25-0.54,0.92-1.14,1.03-2.12c0.11-0.98-0.16-1.84,0.87-2.23c1.03-0.38,1.85-0.92,1.9-2.93
		c0.05-2.01,0.05-2.99,0.92-4.18s0.49-2.5,1.36-2.5s2.5,0,4.13,0.22s3.64-0.49,5.16,0c1.08,0.35,2.07,1.26,2.87,1.83
		c0.25-0.27,0.68-0.47,1.26-0.47c1.3,0,1.96-0.33,1.96-0.98c0-0.65,0.22-0.65,1.3-0.65c1.09,0,1.63-0.54,1.41-1.63
		c-0.22-1.09-1.63,0-1.74-1.3s0.43-1.09,1.63-1.09c1.19,0,3.47,0.22,3.47-0.22c0-0.43,0.54-0.65,1.3-0.87s1.96-1.84,0.22-2.28
		c-1.74-0.43-3.91-0.87-4.56-1.85s-1.52-1.09-2.17-0.98c-0.65,0.11-3.15-0.22-3.15-1.52s0.11-2.5-1.09-3.47s-3.04-1.52-3.37-2.17
		c-0.33-0.65-2.06-1.63-3.15,0.33s-3.47,2.5-3.91,4.67c-0.43,2.17-0.11,1.85-1.3,2.5s-2.06,1.52-2.28,2.82
		c-0.22,1.3,0.65,2.82-1.3,2.82c-0.49,0-0.99-0.03-1.48-0.05c0.4,0.42,0.31,0.53,0.39,0.78c0.12,0.36-0.12,1.15-0.24,2.17
		c-0.12,1.03-0.3,0.6-1.33,0.6s-1.03,0.54-1.03,1.75s-0.48,1.09-1.39,1.09s-1.21-0.84-1.57-1.39s-0.36-1.03-1.75-1.45
		c-0.48-0.15-0.8-0.42-1.02-0.71c-0.28,0.35-0.48,0.83-0.68,1.43c-0.43,1.3-2.93,2.82-3.91,4.67s-2.06,4.02-5.21,4.23
		s-4.13,1.09-5.32,1.09s-3.47,0.87-3.47,1.96s-0.76,3.15-1.3,3.69s-0.43,1.96-0.43,2.61c0,0.65-0.65,1.74-1.84,1.63
		s-1.41-2.06-3.04-1.96s-3.37,0.22-3.69-0.43c-0.33-0.65-0.76-1.96-1.63-2.06c-0.26-0.03-0.45,0.03-0.61,0.16
		C589.458,614.158,589.498,615.468,590.068,616.028z"
          pointer-events="auto"
        ></path>

        <path
          id="PH"
          title="Philippines"
          capital="Manille"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M675.318,484.148c-0.07-0.43,0.43-1.23,0.43-1.23c0.36-0.14,0.36,0.72,0.36,1.23C676.118,484.658,675.388,484.588,675.318,484.148z
		 M671.188,491.698c-0.18,0.26-0.14,0.83,0.26,0.83s0.85,0.06,0.9-0.16c0.05-0.22,0.19-0.64-0.21-0.7
		C672.138,491.668,671.368,491.448,671.188,491.698z M674.488,496.028c0.22,0.21,0.69,0.26,0.71-0.29c0.02-0.55,0-0.93-0.22-1.22
		s-0.4-0.45-0.53-0.21c0,0-0.18,0.27-0.06,0.59S674.268,495.818,674.488,496.028z M660.528,513.368c-0.19,0.01-0.36,0.1-0.48,0.25
		l-0.01,0.02c-0.24,0.3-0.3,0.7-0.15,1.05l0.98,2.28l1.39,4.1l0.96,2.94c0.06,0.18,0.15,0.34,0.28,0.47l1.1,1.13
		c0.13,0.13,0.33,0.17,0.5,0.1s0.29-0.24,0.29-0.42v-0.76c0-0.37,0.22-0.7,0.56-0.84l0.78-0.32c0.25-0.1,0.53-0.08,0.76,0.07
		l0.95,0.61c0.05,0.03,0.1,0.07,0.15,0.12l0.22,0.23c0.21,0.22,0.3,0.52,0.25,0.82c-0.06,0.3-0.26,0.55-0.53,0.67l-1.08,0.69
		c-0.2,0.13-0.35,0.32-0.43,0.55l-0.08,0.23c-0.1,0.32-0.1,0.66,0,0.98l0.57,1.75c0.09,0.27,0.3,0.49,0.57,0.6l0.96,0.37l1.74,0.89
		l0.87-0.53l0.29-0.5c0.14-0.25,0.4-0.41,0.68-0.44l0.69-0.08c0.27-0.03,0.55,0.03,0.78,0.18l1.9,1.2l1.91,1.07l1.22,1.6l0.01,0.01
		c0.14,0.16,0.37,0.22,0.57,0.14s0.32-0.29,0.29-0.5l0,0l-0.87-1.78l-0.37-0.84c-0.08-0.18-0.07-0.38,0.03-0.55
		c0.1-0.17,0.27-0.28,0.46-0.3l0.21-0.02c0.3-0.03,0.59,0.15,0.68,0.45l0.28,0.97c0.06,0.19,0.2,0.35,0.39,0.42l1.09,0.42l0.87,1.07
		l2.09,1.96l1.64,1.23c0.17,0.13,0.3,0.31,0.37,0.52l0.28,0.86c0.09,0.27,0.28,0.49,0.53,0.62l0.57,0.29c0.26,0.13,0.56,0.13,0.82,0
		s0.43-0.38,0.47-0.67l0.17-1.78l0.52-0.89l-0.51-1.71c-0.11-0.36-0.44-0.61-0.82-0.61h-1.63l-0.7-0.89l-0.47-0.4
		c-0.12-0.1-0.17-0.26-0.14-0.41c0.03-0.15,0.14-0.28,0.28-0.33l0.32-0.11h1.04l0.03-0.06c0.29-0.49,0.21-1.12-0.19-1.53l-0.54-0.56
		l-2.26-0.36l-1.22,0.53l-0.21,0.27c-0.11,0.14-0.28,0.21-0.45,0.2s-0.33-0.11-0.42-0.26l-0.65-1.11v-0.37
		c0-0.5-0.37-0.92-0.86-0.99l-1.74-0.24h-0.97c-0.34,0-0.63,0.25-0.69,0.58l-0.08,0.49l-0.7,0.89l-1.57-0.18l-0.76-0.17
		c-0.48-0.11-0.83-0.52-0.86-1.01l-0.09-1.46c-0.02-0.36,0.11-0.71,0.36-0.97l0.46-0.47c0.23-0.24,0.55-0.37,0.89-0.37h0.18
		c0.25,0,0.48-0.11,0.64-0.31c0.15-0.2,0.21-0.45,0.15-0.69l-0.19-0.76c-0.06-0.24-0.21-0.45-0.42-0.58l-0.57-0.37
		c-0.12-0.08-0.27-0.1-0.41-0.05c-0.14,0.05-0.24,0.15-0.29,0.28l-0.15,0.4c-0.1,0.28-0.37,0.47-0.67,0.47h-0.2l-1.04-1.25
		l-0.17-1.25l0.35-1.78l0.52-2.14c0,0,0.87-1.25,1.39-1.42c0.52-0.18,0.87-0.71,0.87-0.71l1.57-1.31c0.23-0.19,0.41-0.44,0.51-0.72
		l0.36-1l0.46-1.87c0.04-0.18,0.06-0.36,0.06-0.54v-1.82c0-0.48-0.21-0.94-0.58-1.25l-0.46-0.39c-0.53-0.44-0.74-1.15-0.55-1.82
		l0.12-0.42c0.05-0.17,0.06-0.35,0.03-0.53c-0.07-0.47-0.17-1.44,0.22-1.7c0.13-0.09,0.22-0.25,0.28-0.43
		c0.08-0.26-0.01-0.53-0.22-0.7c-0.21-0.17-0.5-0.19-0.73-0.05l-0.84,0.49c-0.37,0.21-0.81,0.27-1.22,0.16l-0.73-0.2
		c-0.36-0.1-0.68-0.29-0.94-0.56l-0.53-0.55c-0.19-0.2-0.42-0.35-0.67-0.45l-1.39-0.55c-0.32-0.12-0.66-0.16-1-0.11l-1.33,0.21
		c-0.27,0.04-0.52,0.17-0.72,0.37l-0.92,0.94l-1.06,2.05c-0.21,0.4-0.27,0.87-0.16,1.31l0.28,1.17c0.04,0.18,0.05,0.37,0.03,0.56
		l-0.48,3.46v4.63l-0.09,1.51c-0.02,0.33-0.19,0.62-0.46,0.81s-0.6,0.24-0.92,0.14c-0.3-0.09-0.57-0.25-0.8-0.46l-1.21-1.13
		C660.908,513.418,660.718,513.358,660.528,513.368z M688.298,531.098c-0.28,0.09-0.5,0.32-0.58,0.6l-0.16,0.53l-0.05,0.33
		c-0.07,0.5,0.28,0.97,0.78,1.05l0.31,0.05l0.63-0.09c0.35-0.05,0.64-0.31,0.72-0.66l0.1-0.41c0.07-0.27-0.03-0.56-0.25-0.73
		l-0.69-0.53C688.888,531.058,688.578,531.008,688.298,531.098z M671.218,541.058l0.87-1.42l0.14-3c0.03-0.55-0.31-1.05-0.82-1.23
		l-1.28-0.47c-0.2-0.07-0.38-0.18-0.54-0.32l-1.27-1.1c-0.45-0.39-1.1-0.45-1.61-0.16l-0.7,0.4l-1.31-0.49
		c-0.14-0.05-0.29-0.03-0.41,0.05s-0.19,0.22-0.19,0.37v0.28c0,0.31,0.16,0.6,0.42,0.77l1.46,0.92c0.25,0.16,0.45,0.39,0.57,0.66
		l0.52,1.19v0.91c0,0.7,0.52,1.3,1.22,1.4v0.74c0,0.6,0.44,1.1,1.03,1.18l0.86,0.12c0.42,0.06,0.83-0.18,0.98-0.58L671.218,541.058z
		 M675.998,533.548c-0.43,0.01-1.9-0.27-1.58,1.35c0.29,1.5,1.32,0.35,1.65-0.05c0.06-0.08,0.09-0.18,0.07-0.27l-0.12-0.63
		c-0.01-0.04,0.03-0.18,0.05-0.2C676.098,533.718,676.148,533.538,675.998,533.548z M673.088,540.698l-0.14,2.04
		c0,0,0,0.36,1.39,0.36c0.9,0,1.14-1.33,1.21-2.27c0.02-0.23-0.07-0.45-0.24-0.61s-0.4-0.23-0.63-0.19l-1.1,0.16
		C673.308,540.218,673.108,540.438,673.088,540.698z M677.728,542.238c0.06,0.08,0.16,0.12,0.26,0.12c0.1,0,0.19-0.05,0.25-0.13
		l0.38-0.52c0.1-0.14,0.13-0.31,0.09-0.48c-0.05-0.16-0.16-0.3-0.32-0.36l-0.22-0.09l-0.45,0.2c-0.18,0.08-0.32,0.24-0.36,0.43
		s0,0.4,0.13,0.55L677.728,542.238z M688.088,544.878l-0.44-1.05c-0.05-0.13-0.15-0.24-0.27-0.31l-1.45-0.86
		c-0.27-0.16-0.61-0.11-0.82,0.11l-0.62,0.64c-0.23,0.23-0.25,0.6-0.06,0.86l0.19,0.26l1.7,0.29c0.23,0.04,0.41,0.22,0.46,0.45
		l0.1,0.51l0.24,1c0.06,0.26,0.27,0.46,0.53,0.51l1.32,0.27l0.43-0.53c0.25-0.3,0.21-0.74-0.07-1.01L688.088,544.878z
		 M675.748,545.368c-0.3,0.03-0.57,0.22-0.71,0.49s-0.14,0.59,0,0.86l0.36,0.69c0.15,0.29,0.23,0.6,0.23,0.92v2
		c0,0.35-0.14,0.68-0.38,0.93l-0.34,0.35c-0.21,0.21-0.3,0.51-0.25,0.8l0.23,1.41c0.05,0.3,0.24,0.55,0.52,0.67
		c0.27,0.12,0.59,0.1,0.84-0.06l0.26-0.16l1.01-0.28c0.53-0.15,1.09,0.09,1.34,0.57l0.01,0.02c0.09,0.16,0.25,0.26,0.43,0.27
		s0.35-0.08,0.45-0.23l0.28-0.43c0.16-0.25,0.13-0.57-0.07-0.78c-0.24-0.25-0.47-0.55-0.16-0.55c0.52,0,0.87-0.89,0.87-0.89
		l1.04-1.25l0.44-1.17c0.11-0.29,0.09-0.62-0.05-0.9c-0.15-0.28-0.4-0.48-0.71-0.56l-0.9-0.23l-0.87-0.18l-1.23-0.25
		c-0.32-0.07-0.59-0.28-0.74-0.57l-0.29-0.6l-1.39-0.89L675.748,545.368z M683.108,551.758l-0.76,0.78l-0.52,1.42l-0.65,0.93
		c-0.14,0.2-0.22,0.45-0.22,0.7v1.79c0,0.3-0.19,0.56-0.47,0.65l-1.62,0.55l-0.59,0.4c-0.27,0.19-0.41,0.52-0.34,0.85l0.14,0.64
		c0.06,0.27,0.25,0.48,0.51,0.57l0.11,0.04l1.22,0.89l1.22,1.43l0.35,0.84c0.1,0.24,0.36,0.36,0.6,0.3l1.66-0.42l0.35-0.45
		c0.21-0.26,0.22-0.63,0.04-0.9l-0.74-1.14l-0.3-1.99c-0.03-0.21-0.01-0.43,0.07-0.63l0.57-1.48l0.7-1.6l0.75-1.07
		c0.08-0.12,0.13-0.25,0.15-0.39l0.22-1.68c0.06-0.44-0.23-0.86-0.66-0.96l-1.11-0.26
		C683.538,551.508,683.288,551.578,683.108,551.758z M700.718,542.998l-0.52-1.2l-0.53-0.16c-0.24-0.07-0.44-0.24-0.55-0.47
		l-0.22-0.45c-0.08-0.17-0.22-0.3-0.4-0.37l-1.82-0.7h-1.44l-1.17,0.4c0,0-0.98-0.8-2-0.06c-0.3,0.22-0.42,0.61-0.28,0.96l0.7,1.8
		c0.09,0.23,0.3,0.4,0.54,0.45l0.61,0.11c0.27,0.05,0.55-0.04,0.74-0.23l0.28-0.29c0.07-0.07,0.17-0.1,0.26-0.07
		c0.09,0.02,0.17,0.09,0.19,0.19l0.26,0.89l0.26,0.67l-0.26,1.87l0.39,0.2c0.09,0.05,0.16,0.14,0.18,0.24
		c0.02,0.11-0.01,0.21-0.08,0.3l-0.5,0.6l-0.65,1.34l-0.78,0.13l-0.2-1.73c-0.03-0.31-0.29-0.54-0.6-0.54h-0.89
		c0,0-0.91,0.4-0.39,1.74c0.33,0.84,0.45,1.58,0.5,2.01c0.03,0.25,0.17,0.48,0.4,0.61l0.54,0.31c0.14,0.08,0.32,0.09,0.47,0.03
		c0.17-0.07,0.42-0.12,0.57,0.12c0.26,0.4,0.39,1.87,0.39,1.87l0.13,2c0,0-0.91,1.2,0.13,2s2.09,0,2.09,0l0.78-1.07v-1.47
		l-0.91-1.74l0.13-0.93l-0.13-2.14l0.26-1.2c0,0,0.91-0.27,0.91,0.67s1.04,1.07,1.04,1.07l1.43-0.8c0,0,0.26-1.07,0.26-1.6
		c0-0.54-0.91-1.47-0.91-1.47l-0.26-1.47l0.78-1.6L700.718,542.998z M689.798,549.968l-0.62,0.07c-0.11,0.01-0.21,0.1-0.23,0.21
		l-0.41,2.02c-0.04,0.22-0.13,0.43-0.25,0.61l-0.86,1.32l-1.32,2.08c-0.24,0.39-0.38,0.83-0.4,1.28l-0.06,1.82
		c0,0.14,0.09,0.26,0.22,0.29c0.13,0.03,0.27-0.03,0.33-0.15l0.45-0.92l0.91-0.8l0.13-0.94l0.42-0.97c0.06-0.15,0.2-0.25,0.35-0.28
		l0.53-0.09l0.65-1.07l0.26-1.6l0.13-1.34l0.1-1.24c0.01-0.09-0.03-0.18-0.09-0.24C689.978,549.988,689.888,549.958,689.798,549.968
		z M689.438,559.288l-0.27,0.75l-0.78,0.13c0,0-0.91,0.27-0.78,0.67c0.13,0.4,0.13,0.4,0.13,0.4l0.78,0.27l1.57,0.13l1.17-0.27
		l1.83-1.74l-0.13-1.34l-0.35-0.36c-0.18-0.19-0.47-0.23-0.7-0.11l-1.44,0.74l-0.6,0.26
		C689.668,558.918,689.508,559.088,689.438,559.288z M655.978,552.038l0.76-0.11c0.08-0.01,0.15-0.04,0.2-0.1l0.82-0.75
		c0.16-0.14,0.19-0.37,0.08-0.55l-0.4-0.67c-0.09-0.16-0.27-0.26-0.46-0.26s-0.36,0.1-0.45,0.26l-0.33,0.57l-0.63,0.94
		c-0.09,0.14-0.1,0.32-0.01,0.47C655.648,551.988,655.818,552.068,655.978,552.038z M654.078,556.008
		c-0.23,0.03-0.45,0.14-0.62,0.29l-0.91,0.83l-0.4,0.46c-0.13,0.15-0.16,0.36-0.08,0.55c0.09,0.18,0.27,0.29,0.47,0.28l1.63-0.07
		c0.2-0.01,0.4,0.02,0.58,0.1c0.38,0.15,1.05,0.33,1.05-0.24c0-0.8,0-1.87,0-1.87s0.91-0.94,1.43-0.94s1.83-0.13,2.22-0.27
		c0.39-0.13,0.65-1.07,0.65-1.07h-1.83l-0.39-0.8l-0.91-0.27l-0.65,0.53l-0.65,1.47l-1.04,0.94L654.078,556.008z M648.298,563.788
		l0.98-0.65l1.21-1.24c0.06-0.06,0.11-0.14,0.15-0.22l0.48-1.09c0.07-0.16,0.06-0.34-0.03-0.49l-0.28-0.46
		c-0.06-0.09-0.15-0.15-0.26-0.17c-0.1-0.02-0.21,0.02-0.29,0.09l-1.77,1.58l-0.91,1.6l-0.05,0.6c-0.02,0.19,0.08,0.37,0.24,0.46
		C647.938,563.898,648.138,563.888,648.298,563.788z M643.538,567.678l1.83-1.2l0.91-1.47l0.06-0.93c0.01-0.14-0.07-0.27-0.2-0.32
		s-0.27-0.02-0.36,0.08l-0.68,0.77c0,0-1.43,0.53-1.57,0.94c-0.08,0.26-0.38,0.89-0.58,1.31c-0.11,0.24-0.02,0.52,0.22,0.64
		L643.538,567.678z M700.788,555.498c-0.24-0.07-0.49,0.02-0.64,0.21l-0.29,0.69c-0.06,0.16-0.06,0.33,0.01,0.48l0.39,0.8
		c0.09,0.19,0.26,0.33,0.47,0.38l0.15,0.04c0.13,0.03,0.27,0,0.37-0.09s0.15-0.22,0.14-0.35l-0.17-1.66
		C701.188,555.768,701.018,555.568,700.788,555.498z M661.348,591.648c-1.09,0.54-1.41,1.52-0.65,1.84
		c0.76,0.33,0.65-1.19,1.84-1.41s1.63-0.87,0.98-1.41s-1.84-1.19-1.52-0.11C661.998,590.568,662.438,591.108,661.348,591.648z
		 M668.618,585.248c0,0-1.19,1.3-0.22,1.85c0.98,0.54,0.54-0.11,1.41,0.43s1.09,0.11,1.19-0.54
		C671.118,586.328,669.818,584.268,668.618,585.248z M702.938,559.618c-0.22,1.09-1.3,1.63-2.06,0.65s-2.28-0.87-2.17,0.44
		c0.11,1.3,0.98,1.63,1.09,2.61c0.11,0.98-0.33,1.3-1.41,1.19c-1.09-0.11-0.87,0-1.74,0.33s-2.39,0.11-2.39,1.52
		s1.63,1.85-0.87,1.63s-1.63,0.22-1.84,1.19c-0.22,0.98,0.65,1.3-0.98,1.52c-1.63,0.22-2.39,0.11-2.28-1.41
		c0.11-1.52-0.98-1.74-1.74-1.96s-2.17-0.33-2.61,0.76c-0.43,1.09-0.11,2.39-1.3,2.39s-2.71,0-4.23,1.52c0,0-1.85,0.54-1.85,1.74
		s0.11,1.85-0.54,2.61s-1.63,1.3-0.54,2.39s1.74,1.19,2.17-0.33s1.09-1.19,1.3-2.61c0.22-1.41,0.76-1.74,1.63-1.74
		s-0.65,1.41,0.87,1.63s0.43-1.52,2.39-0.43s2.5,0.98,2.5-0.22s-0.54-1.63,0.65-1.3s1.3,1.74,2.28,1.74s1.41,0.65,1.41,1.41
		c0,0.76,0,0.54-0.65,1.52c-0.65,0.98-1.3,1.96-0.76,2.5s0.54,1.19,0.54,1.85c0,0.65,0,1.09,1.09,1.63s1.96,2.28,2.93,2.28
		s1.19,0.98,2.28,0.98s1.41-0.33,1.96,0.98c0.54,1.3,0.76,2.61,1.96,2.39c1.19-0.22,0.33-0.11,0.33-1.41s-0.76-2.17,0.33-2.5
		s0.65-0.54,0.76-1.85s1.3-1.52-0.11-2.39s-3.04-1.63-2.17-2.39s1.52-0.11,1.52-1.19s0.11-0.98,0.54,0.22
		c0.43,1.19,1.09,1.3,1.09-0.11s0.54-1.52,1.09,0.22c0.54,1.74-0.43,1.63,0.54,2.82s-0.22,1.85,0.33,1.96
		c0.54,0.11,1.3,0.43,1.41-1.63s-0.98-2.93,0.33-3.47c1.3-0.54,1.19-1.19,1.19-2.61s0.65-1.63-0.33-2.71
		c-0.98-1.09,0.43-2.06-0.87-3.47s-2.17-1.96-1.41-3.15s1.52-1.3,0.11-3.04c-1.41-1.74-1.96-3.37-0.87-4.02s1.3-2.5,0.43-2.71
		C703.368,557.338,703.148,558.528,702.938,559.618z M674.698,582.858c0.87,0.76,1.63,1.09,2.06,0s0.98-1.3,0.33-1.74
		c-0.65-0.44-2.06-0.76-2.39,0.54C674.698,581.658,673.838,582.098,674.698,582.858z"
          pointer-events="auto"
        ></path>

        <path
          id="JP"
          title="Japon"
          capital="Tokio"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M806.148,289.748c-0.47-0.35,0-1.74,0-1.74C807.548,287.428,806.618,290.098,806.148,289.748z M836.828,307.058
		c-0.93-0.46,0.23-1.74-1.04-2.79c-1.28-1.05-0.47-1.98,0.12-3.37c0.58-1.4,1.05-2.67,0.12-2.79s0,0.58-1.05,1.74
		s-1.05,1.86-2.79,1.86s-1.97-0.12-2.56-0.81c-0.58-0.7-0.23-1.05-2.79-1.05s-3.49-0.81-4.76-2.09c-1.28-1.28-4.07-3.49-4.76-5
		c-0.7-1.51-7.55-6.97-6.74-2.56c0,0-1.05,1.86,0.35,2.09c1.4,0.23,1.05,0.81,1.05,2.21c0,1.39,0.81,3.02-0.12,3.83
		s-1.28,1.51-1.28,2.44s0.35,1.39-0.47,2.21c-0.81,0.81-1.28,1.63-1.05,2.21c0.23,0.58,0.81,2.56-0.81,2.79
		c-1.63,0.23-1.74,0.58-2.44-0.46c-0.7-1.05-1.28-0.58-1.74-1.16c-0.47-0.58-1.16,0-1.86,0.81s-0.12,1.16,0.12,1.86
		c0.23,0.7,0.93,1.28-0.35,1.74c-1.28,0.46-2.44,0.58-2.9,1.86c-0.47,1.28-0.93,2.09-0.58,2.9s-0.12,1.51,0.93,1.98
		c1.04,0.46,1.39,1.16,1.04,1.74c-0.35,0.58-0.7,1.86-0.35,2.67c0.35,0.81,1.16,1.39,1.51,0.58c0.35-0.81,1.86-0.12,1.74-1.51
		s-0.7-1.39,1.28-1.05s2.32,0.46,2.32-0.58s-0.58-1.51-1.86-1.74c-1.28-0.23-1.39-0.35-2.09-1.05c-0.7-0.7-1.4-1.28-0.47-2.09
		s2.09-1.05,2.56-0.23c0.47,0.82,1.74,0.93,2.44,0.35s2.09,0,2.56-1.16s2.67-1.05,3.25-0.12s0.93,1.98,2.44,2.32
		c1.51,0.35,3.83,1.16,4.07,1.51s3.25,2.79,3.25,0.35s0.7-2.79,1.86-4.3c1.16-1.51,1.97-2.9,3.83-2.9s4.53,0.58,5.34-0.46
		s2.32-0.58,2.9-1.28C837.638,307.878,837.748,307.528,836.828,307.058z M796.278,316.588c0-0.7-0.81-1.51-0.93-0.35
		c0,0-0.35,1.16,0.47,1.39C796.618,317.868,796.278,317.288,796.278,316.588z M810.798,330.528c-1.16-1.05-1.28-1.74-1.28-3.37
		s0.93-3.6-0.81-4.3s-4.88,0.23-2.79,1.05c2.09,0.81,2.56,0.58,1.74,1.39c-0.81,0.81-1.39,1.98-2.56,1.39
		c-1.16-0.58-0.7-2.32-2.09-2.21c-1.4,0.12-1.51,0.81-1.4,1.98c0.12,1.16,0,1.86-1.04,1.86s-2.09,0.23-1.97,1.28
		c0.12,1.05,1.28,1.28,1.28,2.67s-0.23,1.16-0.81,2.21s-0.93,0.81,1.05,3.49c0,0-0.12,3.37-0.93,4.3s-1.51,1.16-1.63,2.67
		s-1.04,3.49-1.86,4.65c-0.81,1.16-4.18,2.09-4.65,3.25c-0.47,1.16-1.39,5.81-2.79,6.04c-1.39,0.23-1.63-1.63-2.67-1.63
		s-2.79,0.81-3.6,2.09s-1.51,1.63-3.6,1.63s-2.09,1.28-2.44,2.21s-1.05,1.05-2.21,1.74s-1.39,0.93-1.39,1.74s0.93,3.14-0.47,3.49
		c-1.39,0.35-1.97-0.35-2.56,0.35c-0.58,0.7-1.74,0.7-1.74,0s-0.23-1.98-1.39-1.86c-1.16,0.12-3.14,0.7-4.65,0.93
		s-0.7,1.28-3.72,1.16s-7.9-0.58-8.83,1.05c-0.93,1.63-2.79,3.37-3.95,3.72c-1.16,0.35-2.67,1.51-3.02,2.21
		c-0.35,0.7-0.58,0.46-2.44,0.7c-1.86,0.23-2.56,1.05-2.56,1.86s-0.47,2.32,0.81,2.32s0.81-0.58,2.79-0.46
		c1.97,0.12,2.79,0.23,3.02,0.7c0.23,0.46,1.63,1.28,2.32,1.28c0.7,0,1.28-0.93,0.58-1.28s-0.93-1.39-0.12-1.86
		c0.81-0.46,1.28-0.93,1.51-0.35s1.63,1.63,1.86,0.7s0.93-1.05,1.39-1.05s1.74,0.23,2.09-0.58c0.35-0.81,1.51-0.58,3.25-0.81
		s2.9-0.23,3.49-1.05c0.58-0.81,1.86-0.93,2.67-0.93s3.84-0.35,4.3,0.7c0.47,1.05,1.04,2.32-0.47,2.67
		c-1.51,0.35-2.09,1.74-1.16,2.44c0.93,0.7,1.51,0.93,2.21,1.98c0.7,1.05,2.21,3.49,3.49,1.74c1.28-1.74,2.44-4.53,4.07-5.11
		s3.02-0.35,3.02-1.39s0.12-0.81-1.16-1.51c-1.28-0.7-1.16-2.32-0.23-2.79c0.93-0.46,1.86-0.23,2.56,0.58s3.14,1.51,4.53,1.39
		c1.4-0.12,3.37,0.12,4.53-1.39s3.14-0.58,4.18-0.81c1.05-0.23,0.12-1.98,1.74-1.98s2.44-1.86,3.02-0.93s2.32-1.05,1.51,0.93
		c-0.81,1.98-0.58,2.79,0.47,2.09c1.04-0.7,3.72-0.7,3.95-2.67c0.23-1.98-0.81-2.09,1.16-2.56s2.09-1.63,0.93-2.67
		c-1.16-1.05-0.93-1.39-0.93-3.37s-0.81-3.02,0.93-3.6c1.74-0.58,2.91-0.23,2.44-2.21s-1.28-4.18-1.28-6.39s-0.7-3.25,1.04-3.6
		s2.67,1.51,2.91-1.28s0.23-3.02,1.16-4.07s1.97-0.93,2.09-2.67c0.12-1.74,1.05-1.39,0.47-4.07
		C813.578,333.668,811.958,331.578,810.798,330.528z M788.248,348.768c0,0-0.81,2.21-0.35,3.25c0.47,1.05,1.86,0.58,1.97-0.23
		c0.12-0.81-0.7-1.51-0.23-2.44C790.108,348.428,789.418,346.098,788.248,348.768z M760.138,387.348c0.58-1.05,0.58-1.98,1.63-2.21
		s1.16-0.7,1.16-1.86s1.74-2.44-0.47-2.79c-2.21-0.35-1.63-1.74-3.37-0.93s-2.79-0.23-2.79,1.86c0,0-0.47,1.39-1.39,1.39
		s-1.16,1.86-1.97,0.93s-0.81-2.44-2.09-1.63s-0.7,1.74-1.97,2.79c-1.27,1.05-1.86,1.39-2.44,1.74c-0.58,0.35-0.12,1.05,0.81,1.05
		s1.05-0.23,1.05,1.28s0.58,2.67,1.04,2.67s0.81,1.98,1.97,0s0.7-1.98,2.09-3.14s1.16-1.86,2.44-1.86s1.63,0.12,1.97,0.58
		C758.148,387.678,759.548,388.388,760.138,387.348z M743.398,388.278c-1.04,0.12-0.7-0.58-1.04-1.74c-0.35-1.16,0.12-1.74-1.4-1.63
		c-1.51,0.12,0.23,1.05-1.86,0.58c-2.09-0.46-1.05-1.16-1.63-1.74s-3.25-1.05-4.07,0.58c0,0-0.58,1.28-2.09,1.74
		s-2.67,0.93-2.79,1.74s-0.81,1.51,0.35,1.74c1.16,0.23,0.93,1.51,1.51,0.81s1.97-0.7,1.86,0c-0.12,0.7-1.28,1.05-2.09,1.05
		s-1.63,0.35-1.39,0.93s1.04,1.51,1.63,0.81c0.58-0.7,0.58-1.16,1.51-1.16s1.28,0.12,1.16-0.7c-0.12-0.81,0.35-0.93,0.7-0.7
		c0.35,0.23,0.81,0.81,0.81,1.39c0,0.58,0.58,1.16-0.58,1.39c-1.16,0.23-2.09,0.47-2.67,0.7c-0.58,0.23-0.81,1.05-0.81,1.74
		c0,0.7,1.39,1.51,1.63,0.23c0.23-1.28,0.12-1.28,1.05-1.51s1.28,0.35,1.04,0.7c-0.24,0.35,0.12,0.81-0.93,1.51
		s-1.63,2.21-1.51,2.79s0.58,0.46,0.93,1.86s-1.74,2.32-0.7,2.32c1.05,0,1.97,0.93,2.44,0.23c0.47-0.7,1.51-1.63,1.86-1.05
		s-1.51,1.98-0.58,2.21s2.21,0.46,2.21-0.58c0-1.05,0.35-1.51,1.05-1.51s2.44,0.46,2.32-1.74c-0.12-2.2,0.93-2.09,0.35-3.49
		s1.16-0.58,1.16-2.32c0-1.74,0-2.09,1.05-2.79s0.47-1.16,0.58-2.67C744.558,388.508,744.448,388.158,743.398,388.278z
		 M722.608,391.878c-1.74,0.7-1.74,1.28-1.4,1.98c0.35,0.7-0.23-0.46,1.16-0.7c1.39-0.23,2.79-1.51,2.56-2.9s-0.7-1.39-1.04-0.46
		C723.878,389.788,724.348,391.178,722.608,391.878z M737.128,406.978c0,0-1.04,3.83,0.12,3.83s0.93-0.23,1.04-1.05
		c0.12-0.81,0.81-0.23,0.81-1.28S737.358,405.468,737.128,406.978z M733.638,410.238c0,0-0.12,1.39,0.35,1.51s1.28,0.12,1.28-0.7
		C735.268,410.238,733.868,409.188,733.638,410.238z M724.808,426.738c0.12,0.7,0.47,0.93,1.63,0.7c1.16-0.23,1.28-0.58,1.63-1.16
		c0.35-0.58,1.74-1.86,0.93-1.86s-0.47,0.46-2.09,1.16C726.898,425.568,724.698,426.038,724.808,426.738z M717.028,437.888
		c0,0-1.4,0.81-1.4,1.98c0,1.16-2.32,1.74-1.16,2.44s0.93-0.23,1.63-1.16s0.47-1.39,1.74-1.63c1.28-0.23,1.63-0.81,1.63-1.39
		C719.468,437.538,717.958,436.378,717.028,437.888z M691.578,454.738c-0.22-0.11-0.46-0.19-0.71-0.25c-0.23-0.06-0.47-0.1-0.7-0.07
		c-0.19,0.02-0.42,0.08-0.52,0.26c-0.13,0.26,0.1,0.58,0.23,0.79c0.09,0.14,0.33,0.25,0.48,0.29c0.19,0.04,0.39,0.01,0.57-0.04
		c0.31-0.09,0.62-0.25,0.83-0.49c0.05-0.05,0.14-0.17,0.12-0.25C691.858,454.868,691.648,454.778,691.578,454.738z"
          pointer-events="auto"
        ></path>

        <path
          id="TW"
          title="Taïwan"
          capital="Taipei"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M662.078,465.048c0.12-1.3,0.25-2.62,0.26-3.92c0-0.63-0.14-1.13,0.22-1.65c0.3-0.44,0.59-0.94,1.01-1.28
		c0.83-0.67,1.58-0.34,1.7-1.58c0.07-0.74-0.05-2.14,0.39-2.76c0.19-0.27,0.56-0.53,0.8-0.76c0.46-0.44,0.93-0.88,1.39-1.33
		l1.25-1.56c0.4-0.5,0.78-0.77,1.35-1.04c0.87-0.42,1.03-0.57,1.87-0.2c0.86,0.38,2.32,0.59,2.37,1.66
		c0.04,0.74-0.06,1.45-0.06,2.18c0,1.55-0.55,3.29-1.07,4.74l-0.72,2l-1.07,4.67l-2.14,4.23c-0.31,0.31-0.49,1.03-0.67,1.43
		c-0.22,0.51-0.43,1.02-0.65,1.52c-0.19,0.44-0.74,2.59-1.33,2.71c-0.03,0.01-0.05,0.01-0.08,0c-0.46-0.05-0.53-1.56-0.67-1.92
		c-0.21-0.54-0.29-1.2-0.69-1.62c-0.45-0.47-1.17-0.56-1.63-0.98c-0.42-0.39-0.93-1.08-1.27-1.55
		C662.038,467.208,661.988,466.038,662.078,465.048z"
          pointer-events="auto"
        ></path>

        <path
          id="KP"
          title="Corée-du-Sud"
          capital="Séoul"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M709.358,386.188c0.81,0.93-0.35,1.05-0.7,1.86c-0.35,0.81-0.81,0.81-2.79,0.7s-1.97-1.86-1.28-2.09c0.7-0.23,2.44-0.12,2.44-0.12
		C707.728,385.368,708.548,385.258,709.358,386.188z M727.138,379.208c0-1.16,0.81-3.95-0.93-1.86c0,0-1.05,0.58-0.58,1.98
		C726.088,380.728,727.138,380.378,727.138,379.208z M717.948,348.128c-0.83,1.38-2.91,0.28-4.98,0s-3.74,1.66-4.7,3.32
		c-0.42,0.73-0.77,1.43-1.02,1.98c0.39,0.93,0.39,2.33,0.88,4.27c0.78,3.11-0.16,1.71-2.18,2.02s-1.09,1.24,0,2.18
		c1.09,0.93,0.47,2.02,1.25,5.14c0.78,3.11-0.62,3.27-1.71,4.83s-0.47,1.56,0.31,3.42c0.78,1.86-0.16,1.71-1.25,3.11
		s0.47,1.56,2.96,0.15c2.49-1.4,3.11,1.4,4.98,0.16s2.18-2.18,3.89-2.8s2.34,0.78,4.98-0.47c2.65-1.24,1.09-2.02,4.2-2.65
		c3.11-0.62,1.09-3.11,1.87-6.23c0.78-3.12,0-5.6-0.47-7.63s-1.71-3.27-3.74-5.91c-2.02-2.65-2.34-3.74-3.11-6.23
		c-0.11-0.36-0.26-0.67-0.44-0.96C718.998,346.368,718.548,347.138,717.948,348.128z"
          pointer-events="auto"
        ></path>

        <path
          id="MM"
          title="Birmanie"
          capital="Naypyidaw"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M514.758,556.308c0-1.68,0.22-3.2,1.41-4.07c1.2-0.87,2.39-1.52,2.99-3.09s1.63-0.65,1.52-2.33s0.33-2.71-0.43-3.26
		s0.11-3.09-1.03-3.69s-1.57-0.6-1.25-2.33c0.33-1.74,0.54-4.72-0.11-5.65c-0.65-0.92-2.06-2.17-2.77-2.66s-2.28-2.5-2.61-3.26
		c-0.33-0.76-0.6-1.68-1.09-2.06s-0.92-2.12,0.38-2.33c1.3-0.22,2.12-0.92,2.06-2.5c-0.05-1.58-0.81-2.77,0.16-3.2
		c0.98-0.43,1.74-0.22,1.74-1.36s-0.38-0.98-1.14-1.68c-0.76-0.71-1.63-2.33-1.9-3.26c-0.27-0.92-0.6-1.68-2.01-2.82
		s-3.09-2.5-3.09-3.8s0-2.88-0.76-3.37s-1.52-1.47-0.43-1.68c1.09-0.22,1.9,0.22,1.9-1.63s0-5.81,1.41-6.52s1.74-0.76,3.26-0.33
		c1.52,0.43,2.44,0.33,3.31-0.87c0.87-1.19,1.63-1.63,2.55-1.74s0.92-1.68,2.71-1.79c0.13-0.01,0.25-0.01,0.37-0.02
		c0.07,0,0.13,0,0.19-0.01c0.04,0,0.09,0,0.13,0c0.53-0.01,1.01-0.02,1.4-0.09h0.01c0.09-0.02,0.17-0.04,0.25-0.06
		c0.01,0,0.02,0,0.02-0.01c0.08-0.03,0.16-0.06,0.23-0.1c0.04-0.02,0.07-0.04,0.11-0.06c0.02-0.01,0.03-0.02,0.05-0.03
		c0.03-0.02,0.06-0.05,0.09-0.07c0.02-0.02,0.04-0.04,0.06-0.05c0.03-0.02,0.05-0.05,0.07-0.08c0.04-0.05,0.07-0.09,0.1-0.14
		c0.01-0.02,0.03-0.05,0.04-0.07c0.02-0.04,0.04-0.08,0.06-0.12c0.01-0.03,0.02-0.06,0.03-0.09c0.02-0.05,0.03-0.1,0.05-0.15
		c0.01-0.03,0.02-0.05,0.02-0.08c0.02-0.08,0.04-0.17,0.06-0.27c0.27-1.74,0.27-1.47,1.3-2.01c1.03-0.54,1.84-0.76,2.17-1.9
		c0.33-1.14,0.43-1.36,1.19-2.01c0.62-0.53,1.13-1.78,1.5-3.1c-0.03,0-0.06,0-0.09,0.01c-0.49,0.16-0.81,0.49-1.36,1.09
		c-0.54,0.6-0.98,0.33-1.3,0.98s-1.74,0.38-3.04,0.38s-0.98-0.87-1.41-1.79s-0.71-1.3-1.09-2.12c-0.38-0.81-2.55-0.65-3.47-0.6
		s-0.76-0.49-1.41-0.81c-0.65-0.33,0.16-1.25,0.6-1.47s0.76-0.87,0.54-1.74c-0.22-0.87,0.87-0.81,1.19-2.17
		c0.33-1.36-1.68-0.92-2.71-0.98c-1.03-0.05-1.19-0.49-1.36-1.63c-0.16-1.14-0.38-0.98-0.76-2.01s-0.54-1.19-0.22-2.23
		c0.33-1.03,0.81-2.88,0.05-2.28s-2.23,0.65-2.77,0.27c-0.54-0.38-1.19,0.43-1.74,0.43s-1.36,0.27-1.74,0.81s-1.14,0.6-1.52,0.43
		c-0.38-0.16,0.22-1.95,0.05-2.93c-0.16-0.98-0.16-0.98-0.54-1.74c-0.38-0.76-0.54-0.98,0.11-1.47s0.6-1.3,1.03-2.23
		c0.43-0.92,1.41-1.36,2.23-2.44c0.81-1.09,0.98-1.3,2.12-1.85c1.14-0.55,1.19-1.57,1.74-2.23c0.54-0.65,0.22-2.61,0.22-3.8
		s0.49-1.19,0.65-2.01s-0.05-1.57-0.38-2.33c-0.33-0.76,0.05-2.06,0.05-2.99s-1.74-1.14-2.66-1.3c-0.92-0.16-1.25-1.3-1.25-2.17
		s-0.11-2.01-0.87-2.5s-1.09-0.49-1.63-1.63s-1.79-0.6-2.17-0.05c-0.3,0.43-0.64,1-1.1,1.07c0.03,0.54-0.04,1.04-0.04,1.59
		c0,0.98-0.27,1.47-1.52,1.79s-1.36,1.19-1.57,2.44s1.14,1.25,1.19,2.12s-0.81,1.47-1.52,0.33s-2.01-0.71-2.88-0.71
		s-2.93,0.27-3.64,1.63c-0.71,1.36-1.74,2.77-3.31,2.88c-1.57,0.11-1.95,1.57-2.23,2.93c-0.27,1.36-0.11,1.03,0.54,1.79
		s-0.54,1.09-0.6,2.06s-1.25,1.79-1.74,1.9s-1.19,0.16-1.25,1.09s-0.54,0.65-0.6,1.14c-0.05,0.49,0.49,0.54,1.09,1.03
		s-0.33,0.92-0.38,1.68c-0.05,0.76,0.38,1.9-0.98,2.06c-1.36,0.16,0.16,1.85-1.36,1.9s-0.98,1.85-0.92,2.82
		c0.05,0.98-0.81,1.47-0.92,0.98c-0.11-0.49-0.49-1.3-1.79-1.25c-1.3,0.05-1.19-0.76-2.33-0.76s-0.87,0.87-0.27,1.3
		s0.71,1.09,0.11,1.41s-0.43,2.33-0.43,3.48s-0.6,1.52-1.41,1.9s-0.65,1.74-0.65,2.66s0.16,0.92,0.65,0.92s0.71,0.81,0.71,1.52
		s-1.25,0.54-1.3,1.41s-0.92,0.6-1.9,0.22c-0.44-0.17-0.88-0.23-1.24-0.24l0,0c0.02,0.08,0.02,0.18,0.02,0.27c0,0.01,0,0.01,0,0.02
		c0,0.33-0.06,0.72-0.11,1.07c0,0.01,0,0.02,0,0.03c-0.01,0.08-0.02,0.15-0.03,0.22s-0.02,0.14-0.02,0.2c0,0.02,0,0.04-0.01,0.06
		c0,0.08,0,0.15,0,0.21c0,0.01,0,0.03,0,0.04c0.01,0.06,0.02,0.12,0.04,0.17c0.22,0.51-0.51,0.87,0.22,1.59
		c0.72,0.72,1.01,1.81,0.29,1.74s-2.24-0.58-2.46-0.14c-0.19,0.37,0.1,1.81-1.28,2.39c1.13,2.05,4.41,5.32,6.03,6.8
		c1.87,1.71,1.56,1.56,1.71,0.47c0.16-1.09,1.4,0.93,2.8,1.87c1.4,0.93,0.47,1.71-0.47,2.49c-0.93,0.78-0.47,0.78,0.47,1.71
		c0.93,0.93-1.25,1.25-0.62,2.33s1.87-1.24,2.96-0.93s1.09,2.49,1.56,4.67c0.47,2.18,1.56,2.02,1.56,3.27c0,1.24,0,2.65,0.16,4.67
		s-1.4,2.65-1.4,3.74c0,1.09-0.31,3.11-0.31,4.36s1.87,1.87,2.18,1.09s0.78-0.62,1.56,0.47c0.78,1.09,1.4,1.87,2.02,0.47
		s1.09,0.16,2.33,0.16s2.18-1.09,3.42-2.96c1.25-1.87,2.96-1.87,4.2-2.02c1.25-0.16,1.24-1.56,1.71-1.56s0.93-2.02,1.4-3.42
		c0.47-1.4,2.18,0.78,2.18,1.87s0.31,1.25,0.78,2.49c0.47,1.24,1.4,0.93,1.4,2.8s0.93,2.65,0.93,4.83s0,2.96,0.62,4.51
		c0.62,1.56,1.25,2.33,1.4,5.14s1.25,2.8,2.49,4.05c1.25,1.25,0.62,1.56,0.93,3.73c0.31,2.18,1.09,3.11,1.09,4.36
		c0,1.24-0.31,1.09-1.4,1.56c-1.09,0.47,0.16,1.09,0.16,2.02s-1.09,0.47-1.87,0.47s-0.31,1.87,0.62,1.87s0.62-0.31,2.18,0.31
		s0.78,2.33-0.47,3.27s-0.31,3.58-0.16,6.38c0.01,0.15,0.01,0.28,0.02,0.41C513.928,557.948,514.758,557.488,514.758,556.308z"
          pointer-events="auto"
        ></path>

        <path
          id="TH"
          title="Thaïlande"
          capital="Bangkok"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M541.328,539.818c-0.65-1.16-0.07-1.81-1.23-3.26s-0.43-2.24,0.43-2.75c0.87-0.51,3.11-3.62,3.47-4.63s1.16-1.3,1.81-1.3
		s2.1,0.14,2.68-0.65c0.58-0.8,1.38,0.87,2.24,0.58c0.87-0.29,2.75-0.14,3.04,0.07c0.29,0.22,1.81-0.14,2.1-0.72
		c0.29-0.58,1.23,0,1.96,0.72c0.06,0.06,0.13,0.12,0.21,0.17c0.02,0.01,0.03,0.02,0.05,0.03c0.07,0.05,0.15,0.09,0.23,0.13
		c0.01,0,0.02,0.01,0.03,0.01c0.08,0.04,0.16,0.07,0.24,0.09c0.02,0.01,0.03,0.01,0.05,0.02c0.09,0.03,0.18,0.05,0.27,0.07
		c0.01,0,0.01,0,0.02,0c0.09,0.02,0.18,0.03,0.27,0.04h0.01c0.28,0.02,0.55-0.01,0.74-0.09c0.04-0.02,0.07-0.03,0.1-0.05
		c0,0,0,0,0.01,0c0.03-0.02,0.05-0.03,0.07-0.05c0.01-0.01,0.02-0.02,0.02-0.02c0.01-0.02,0.03-0.03,0.04-0.05
		c0.02-0.03,0.03-0.05,0.04-0.08c0.15-0.51,1.09-1.23,1.45-1.45s0.22-1.38,0.22-1.88c0-0.51,0-1.16-0.8-1.95s0.58-0.87,0.65-1.38
		s0.58-0.87,1.09-1.38s-0.43-1.59-0.72-2.53s-1.81-1.45-2.46-1.81s-1.09-1.09-2.46-1.88c-1.38-0.8-0.65-1.59-0.65-2.39
		s0-1.45-0.07-2.24c-0.07-0.8,0.65-1.59,0.43-2.32c-0.22-0.73-1.01-1.67-2.03-2.24c-1.01-0.58-2.1-2.1-2.68-3.11
		c-0.58-1.01-1.45-1.3-2.1-1.95c-0.65-0.65-3.04-0.51-3.91-0.22s-0.51,2.32-1.88,2.53c-1.38,0.22-0.8,0.36-1.45,1.09
		s-1.3-0.8-1.88-1.01c-0.58-0.22-0.58,0.29-1.74-1.16s-1.81,0.36-3.11,0.8c-1.3,0.43-0.36,1.23-1.45,1.67
		c-1.09,0.43-1.59,1.38-1.96,2.1s-0.87,0.65-1.88,0.65s-0.15-0.36-0.8-0.72c-0.65-0.36-0.94-0.65-0.58-1.09s1.09-0.94,1.09-1.38
		c0-0.43,0.8-0.8,1.16-1.3c0.36-0.51,0.29-1.16-0.15-1.45c-0.43-0.29-0.36-1.3-0.43-1.88c-0.07-0.58,0.43-0.94,1.01-1.23
		s0.29-2.03,0.29-2.75s0.07-1.67-0.15-1.81c-0.22-0.14-0.72-0.8-0.72-1.45s-0.51-0.8-1.01-0.8c-0.51,0-1.01,0.14-1.01,0.72
		s-0.29,0.51-1.23,0.58s-0.87-0.51-1.81-1.23s0.15-0.72,0.15-1.23s0-0.51,0.87-1.38s-0.15-0.8-0.36-1.09
		c-0.21-0.29-0.29-1.38-1.81-1.23c-0.61,0.06-0.96-0.22-1.16-0.58c-0.07,0.04-0.15,0.07-0.23,0.1c-0.01,0-0.01,0-0.02,0.01
		c-0.08,0.03-0.16,0.05-0.25,0.06h-0.01c-0.4,0.08-0.87,0.08-1.4,0.09c-0.04,0-0.09,0-0.13,0c-0.06,0-0.13,0-0.19,0.01
		c-0.12,0-0.25,0.01-0.37,0.02c-1.79,0.11-1.79,1.68-2.71,1.79c-0.92,0.11-1.68,0.54-2.55,1.74c-0.87,1.19-1.79,1.3-3.31,0.87
		c-1.52-0.43-1.85-0.38-3.26,0.33s-1.41,4.67-1.41,6.52s-0.81,1.41-1.9,1.63s-0.33,1.19,0.43,1.68c0.76,0.49,0.76,2.06,0.76,3.37
		c0,1.3,1.68,2.66,3.09,3.8c1.41,1.14,1.74,1.9,2.01,2.82s1.14,2.55,1.9,3.26s1.14,0.54,1.14,1.68s-0.76,0.92-1.74,1.36
		c-0.98,0.43-0.22,1.63-0.16,3.2c0.05,1.57-0.76,2.28-2.06,2.5s-0.87,1.95-0.38,2.33s0.76,1.3,1.09,2.06s1.9,2.77,2.61,3.26
		c0.71,0.49,2.12,1.74,2.77,2.66s0.43,3.91,0.11,5.65c-0.33,1.74,0.11,1.74,1.25,2.33s0.27,3.15,1.03,3.69s0.33,1.57,0.43,3.26
		c0.11,1.68-0.92,0.76-1.52,2.33c-0.6,1.57-1.79,2.23-2.99,3.09c-1.19,0.87-1.41,2.39-1.41,4.07c0,1.18-0.83,1.64-1.32,1.81
		c0.09,2.39-0.34,2.49-0.49,4.26c-0.16,1.87,0,1.87-0.93,3.11c-0.93,1.25-0.78,1.09-0.78,2.49s0.16,4.83,0.78,5.45
		s1.09-1.4,1.4-2.49c0.11-0.37,0.09-0.51,0.05-0.55c-0.11,0.03-0.04-0.05,0,0c0.06-0.02,0.19-0.08,0.42-0.23
		c0.93-0.62,2.34,1.09,3.11,2.49c0.78,1.4,1.4,2.49,2.96,3.11s2.18,5.14,4.04,6.69c0.09,0.07,0.16,0.15,0.24,0.22
		c0.2-0.15,0.39-0.35,0.48-0.59c0.27-0.69,1.17-0.79,1.33-0.21c0.16,0.58,0.32,1.01,1.27,1.17s1.96,0.48,2.81,1.27
		c0.85,0.8,1.8,1.33,1.27,1.8c-0.53,0.48-0.64,1.01-0.64,1.64c0,0.64,0.11,1.01,0.58,1.17c0.48,0.16,1.33,0.21,1.33-0.53
		c0-0.74,0.48-1.27,1.17-1.27s0.27,1.06,1.54,0.96s1.33-0.42,1.33-1.01s0.58-0.27,0.85-1.01c0.2-0.55,0.34-1.01,0.74-1.43
		c-1.05-0.85-1.99-2.17-2.77-3.74c-1.09-2.18-3.42-0.62-4.98-0.78s-3.42-2.49-4.51-3.27s-0.31-1.71,0.16-2.18s-0.47-2.34-0.47-4.05
		s-1.25-1.87-1.25-1.87s-0.47-1.4-0.62-2.49c-0.16-1.09-0.62-1.4-0.93-2.34c-0.31-0.93-1.71-0.78-3.11-0.62
		c-1.4,0.15-0.78-0.47-1.24-1.56c-0.47-1.09-0.78-1.56-0.78-2.8s0.31-2.33,0.78-3.42c0.47-1.09,2.18-3.89,2.34-5.14
		c0.16-1.24,1.09-2.49,1.24-4.2c0.15-1.71,1.09-1.87,1.71-3.11c0.62-1.25,0.31-2.65-0.31-3.11c-0.62-0.47-0.16-1.4,0.47-1.87
		c0.63-0.47,0.78-1.09,0-2.33s1.25-1.71,1.56-2.33s2.96-0.47,4.51,0.16c1.56,0.62-0.16,2.96-0.16,4.98s2.8,1.4,4.67,1.4
		s2.49,0.16,4.2,1.4c1.08,0.78,2.95,2.67,4.43,4.35c0.02-1.16-0.19-2.45-0.36-3.07C542.628,541.848,541.978,540.978,541.328,539.818
		z"
          pointer-events="auto"
        ></path>

        <path
          id="KH"
          title="Cambodge"
          capital="Phnom Penh"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M555.738,554.878c2.32,0.22,2.75-0.14,2.75-1.09s-0.87-2.03,1.88-2.03s3.26-0.51,3.77,0.36s1.23,1.67,1.88,1.01
		c0.65-0.65,1.45-1.3,0.65-1.88s-2.03-0.14-2.1-1.3c-0.07-1.16-1.09-0.87-1.01-1.88c0.07-1.01,0.15-1.16,1.01-1.52
		c0.87-0.36,0.87-1.38,1.38-0.87s0.65,1.09,1.66,1.09s0.43-2.1,1.3-2.1s1.09-0.87,2.1-0.94c1.01-0.07,1.09,0,1.59-0.94
		s1.3-1.74,2.32-2.61c1.01-0.87,1.45-1.52,1.59-2.97s-0.07-4.34-0.58-4.49c-0.51-0.14-0.22-2.03-1.09-2.32
		c-0.87-0.29-0.72-0.94-0.43-2.32c0.17-0.83,0.42-1.5,0.35-2.13c-0.04,0.01-0.08,0.02-0.13,0.03c-1.16,0.22-1.52,0.51-1.74,1.16
		s-1.09,1.45-1.81,1.01c-0.72-0.43-1.23-0.8-1.88-1.45s-1.59-0.72-1.88-0.22c-0.29,0.51-1.74,1.45-1.52,2.24s0.72,1.45,0.29,1.88
		s-0.8,1.52-2.17-0.07c-1.38-1.59-1.66-1.01-2.24-1.01c-0.47,0-1.23-0.24-1.73-1.19c-0.19,0.08-0.45,0.11-0.74,0.09h-0.01
		c-0.09-0.01-0.18-0.02-0.27-0.04c-0.01,0-0.01,0-0.02,0c-0.09-0.02-0.18-0.04-0.27-0.07c-0.02-0.01-0.03-0.01-0.05-0.02
		c-0.08-0.03-0.16-0.06-0.24-0.09c-0.01,0-0.02-0.01-0.03-0.01c-0.08-0.04-0.16-0.08-0.23-0.13c-0.02-0.01-0.03-0.02-0.05-0.03
		c-0.07-0.05-0.14-0.11-0.21-0.17c-0.72-0.72-1.66-1.3-1.96-0.72c-0.29,0.58-1.81,0.94-2.1,0.72c-0.29-0.22-2.17-0.36-3.04-0.07
		s-1.66-1.38-2.24-0.58s-2.03,0.65-2.68,0.65s-1.45,0.29-1.81,1.3s-2.61,4.13-3.47,4.63c-0.87,0.51-1.59,1.3-0.43,2.75
		c1.16,1.45,0.58,2.1,1.23,3.26c0.65,1.16,1.3,2.03,1.52,2.82c0.17,0.62,0.38,1.91,0.36,3.07c0.87,1,1.61,1.92,1.96,2.5
		c0.93,1.56-0.78,2.02-0.78,3.11c0,1.09,1.71,1.24,2.18,0.47c0.47-0.78,1.4-2.18,2.34-1.25c0.93,0.93-0.16,1.56-0.62,3.11
		c-0.47,1.56,1.25,0.47,2.03,0.62s0.93,0.93,0.93,2.65c0,1.71,1.24-0.78,1.4-1.87c0.1-0.68,0.62-0.21,1.29,0.27
		C554.228,555.028,554.768,554.788,555.738,554.878z"
          pointer-events="auto"
        ></path>

        <path
          id="VN"
          title="Vietnam"
          capital="Hanoï"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M574.808,475.898c-1.25,0-2.39-0.76-2.77-1.14s-2.01-2.12-2.5-2.55s-0.54-1.79-0.54-2.5s0.38-0.87,0.92-1.19
		c0.54-0.33,0.05-1.52-0.49-2.17s-2.66-0.22-3.8-0.27s-1.63-0.43-2.44-0.65s-1.41-0.65-1.79-1.41s-0.92-1.25-1.68-1.25
		s-0.92,0.98-1.74,1.09s-0.27,0.71-0.98,2.39c-0.71,1.68-1.96,0.65-2.99,0.65s-1.09,0.27-2.01,0.43s-0.98,1.09-1.57,1.14
		c-0.59,0.05-0.81-0.38-1.03-0.76s-0.81-0.33-1.09,0.16c-0.27,0.49-1.14,0-1.57-0.27s-0.98,0.65-1.25,0.98s-0.54,0.98-1.14,0.98
		c-0.6,0-1.09-0.11-1.25-0.76s-1.19-0.98-1.74-1.19s-1.68,0.87-2.17,1.47c-0.05,0.06-0.1,0.12-0.15,0.17
		c-0.02,0.02-0.04,0.04-0.07,0.06s-0.05,0.05-0.08,0.07c-0.07,0.05-0.14,0.1-0.21,0.14c0,0.84,0.12,2.09,0.83,2.28
		c1.09,0.29,2.03,1.16,2.32,2.1s0.15,0.87,1.09,0.94s1.52,0.58,1.52,1.09s-0.43,1.88-1.09,2.03c-0.65,0.14,0.22,0.87,1.16,1.74
		s0.72,2.17,1.96,2.32c1.23,0.14,2.97,0.87,3.18,0.14c0.22-0.72,0.72-0.87,1.59-1.16c0.87-0.29,2.03-0.29,2.46,0.36
		s1.23,1.38,1.09,2.03c-0.14,0.65-0.58,1.01,0.22,1.38c0.8,0.36,0.8,1.23,1.88,1.16c1.09-0.07,1.3,0.87,1.09,1.45
		c-0.22,0.58-1.38,0.51-1.38,1.3s-0.8,1.74-1.52,1.74s-3.47-0.51-3.55,0.14s-2.32,1.59-0.29,2.39c2.03,0.8,2.1,1.52,3.33,2.24
		c1.23,0.72,1.81,1.45,2.75,1.52c0.94,0.07,2.17,0,2.17,0.8s-0.51,2.17,0.8,2.9s2.03,0.8,2.03,1.88c0,1.09,0.58,1.81,1.59,2.46
		s3.11,2.53,3.84,3.18c0.73,0.65,1.01,1.38,1.3,2.53c0.29,1.16,0.36,1.81,1.59,2.1c1.23,0.29,0.94,1.09,2.03,1.52
		s1.09,1.01,1.52,1.23s1.45,1.23,0.87,1.52c-0.58,0.29-1.74,0.22-1.74,0.8s-0.43,1.3,0.51,1.67s1.59,0.87,2.24,1.59
		c0.65,0.72,1.23,1.67,0.15,2.03s-0.87,0.94-0.72,1.3c0.01,0.02,0.02,0.05,0.03,0.08c0.01,0.02,0.01,0.04,0.02,0.06
		c0,0.01,0.01,0.02,0.01,0.03c0.01,0.03,0.01,0.06,0.02,0.09c0,0.01,0,0.01,0,0.02c0.01,0.04,0.01,0.07,0.02,0.11l0,0
		c0.05,0.48-0.06,1.18-0.83,1.39c0.08,0.64-0.17,1.31-0.35,2.13c-0.29,1.38-0.43,2.03,0.43,2.32s0.58,2.17,1.09,2.32
		c0.51,0.14,0.72,3.04,0.58,4.49c-0.15,1.45-0.58,2.1-1.59,2.97s-1.81,1.67-2.32,2.61s-0.58,0.87-1.59,0.94s-1.23,0.94-2.1,0.94
		s-0.29,2.1-1.3,2.1s-1.16-0.58-1.66-1.09c-0.51-0.51-0.51,0.51-1.38,0.87s-0.94,0.51-1.01,1.52s0.94,0.72,1.01,1.88
		s1.3,0.72,2.1,1.3s0,1.23-0.65,1.88s-1.38-0.14-1.88-1.01c-0.51-0.87-1.01-0.36-3.77-0.36c-2.75,0-1.88,1.09-1.88,2.03
		s-0.43,1.3-2.75,1.09c-0.97-0.09-1.5,0.15-1.8,0.46c0.41,0.3,0.88,0.6,1.35,0.66c1.24,0.16,2.34,0.62,2.96,1.56
		c0.62,0.93,1.4,1.09,0.47,1.56c-0.93,0.47-0.62,5.76-1.25,8.1s2.18,0.62,2.96-0.78s2.03-2.02,2.96-2.02s1.87-1.09,2.65-2.8
		s1.25,0,2.65,0s0-3.27,0.62-3.58s1.56-0.62,1.09-1.09s-0.31-1.87-0.31-2.65s1.87,0.15,3.42,0.62c1.56,0.47,3.27-0.31,4.51-0.93
		c1.25-0.62,1.87-1.87,4.2-2.96s2.49-2.33,3.89-2.96c1.4-0.62,0.93-3.73,0.93-5.29s0.31-2.49,0.93-2.49s1.09-0.93,1.09-2.18
		s-0.93-1.4-0.93-2.02s0.31-5.14,0.31-7.47s-2.34-4.52-2.8-6.38c-0.47-1.87-0.93-2.18-2.49-3.42c-1.56-1.25-1.87-1.4-2.02-2.8
		c-0.16-1.4-1.71-2.02-3.43-3.27c-1.71-1.25-3.73-1.87-4.82-4.2s-2.34-2.49-4.2-3.73c-1.87-1.25-0.78-1.87-0.78-3.11
		s-1.24-1.87-2.34-2.49c-1.09-0.62-1.71-2.33-2.34-2.8c-0.62-0.47-0.15-2.8,0.31-2.96c0.47-0.15,0.47-2.18,0.47-3.42
		c0-1.24,0.93-2.18,2.34-2.64c1.4-0.47,1.71-1.4,2.8-2.8c1.09-1.4,0.62-2.18,0.78-3.73c0.16-1.55,2.8-0.62,4.67-0.62
		s0.93-2.33,2.34-2.64c1.16-0.26,1.15-0.52,2.16-1.3c-0.54-0.22-0.98-0.22-1.5-0.52
		C576.818,475.298,576.058,475.898,574.808,475.898z"
          pointer-events="auto"
        ></path>

        <path
          id="LA"
          title="Laos"
          capital="Vientiane"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M529.578,477.588c-0.76,0.65-0.87,0.87-1.19,2.01c-0.33,1.14-1.14,1.36-2.17,1.9c-1.03,0.54-1.03,0.27-1.3,2.01
		c-0.02,0.1-0.03,0.19-0.06,0.27c-0.01,0.03-0.02,0.06-0.02,0.08c-0.02,0.05-0.03,0.11-0.05,0.15c-0.01,0.03-0.02,0.06-0.03,0.09
		c-0.02,0.04-0.04,0.08-0.06,0.12c-0.01,0.02-0.03,0.05-0.04,0.07c-0.03,0.05-0.07,0.1-0.1,0.14c-0.02,0.03-0.05,0.05-0.07,0.08
		c-0.02,0.02-0.04,0.04-0.06,0.05c-0.03,0.02-0.06,0.05-0.09,0.07c-0.02,0.01-0.03,0.02-0.05,0.03c-0.03,0.02-0.07,0.05-0.11,0.06
		c0.21,0.36,0.56,0.64,1.16,0.58c1.52-0.14,1.59,0.94,1.81,1.23s1.23,0.22,0.36,1.09s-0.87,0.87-0.87,1.38s-1.09,0.51-0.15,1.23
		s0.87,1.3,1.81,1.23c0.94-0.07,1.23,0,1.23-0.58s0.51-0.72,1.01-0.72s1.01,0.14,1.01,0.8s0.51,1.3,0.72,1.45
		c0.22,0.14,0.15,1.09,0.15,1.81s0.29,2.46-0.29,2.75c-0.58,0.29-1.09,0.65-1.01,1.23c0.07,0.58,0,1.59,0.43,1.88
		s0.51,0.94,0.15,1.45s-1.16,0.87-1.16,1.3s-0.72,0.94-1.09,1.38c-0.36,0.43-0.07,0.72,0.58,1.09s-0.22,0.72,0.8,0.72
		s1.52,0.07,1.88-0.65s0.87-1.67,1.96-2.1s0.15-1.23,1.45-1.67c1.3-0.43,1.95-2.24,3.11-0.8c1.16,1.45,1.16,0.94,1.74,1.16
		s1.23,1.74,1.88,1.01s0.07-0.87,1.45-1.09s1.01-2.24,1.88-2.53s3.26-0.43,3.91,0.22c0.65,0.65,1.52,0.94,2.1,1.95
		c0.58,1.01,1.66,2.53,2.68,3.11c1.01,0.58,1.81,1.52,2.03,2.24c0.22,0.72-0.51,1.52-0.43,2.32c0.07,0.8,0.07,1.45,0.07,2.24
		c0,0.8-0.72,1.59,0.65,2.39c1.38,0.8,1.81,1.52,2.46,1.88s2.17,0.87,2.46,1.81c0.29,0.94,1.23,2.03,0.72,2.53
		c-0.51,0.51-1.01,0.87-1.09,1.38c-0.07,0.51-1.45,0.58-0.65,1.38s0.8,1.45,0.8,1.95c0,0.51,0.15,1.67-0.22,1.88
		c-0.36,0.22-1.3,0.94-1.45,1.45c-0.01,0.03-0.02,0.06-0.04,0.08c-0.01,0.02-0.03,0.03-0.04,0.05c-0.01,0.01-0.01,0.02-0.02,0.02
		c-0.02,0.02-0.05,0.04-0.07,0.05c0,0,0,0-0.01,0c-0.03,0.02-0.06,0.03-0.1,0.05c0.5,0.95,1.26,1.19,1.73,1.19
		c0.58,0,0.87-0.58,2.24,1.01c1.38,1.59,1.74,0.51,2.17,0.07c0.43-0.43-0.07-1.09-0.29-1.88s1.23-1.74,1.52-2.24
		c0.29-0.51,1.23-0.43,1.88,0.22s1.16,1.01,1.88,1.45c0.72,0.43,1.59-0.36,1.81-1.01s0.58-0.94,1.74-1.16
		c0.05-0.01,0.09-0.02,0.13-0.03c0.76-0.21,0.87-0.91,0.83-1.39l0,0c0-0.04-0.01-0.08-0.02-0.11c0-0.01,0-0.01,0-0.02
		c-0.01-0.03-0.01-0.06-0.02-0.09c0-0.01-0.01-0.02-0.01-0.03c-0.01-0.02-0.01-0.04-0.02-0.06c-0.01-0.03-0.02-0.05-0.03-0.08
		c-0.15-0.36-0.36-0.94,0.72-1.3s0.51-1.3-0.15-2.03c-0.66-0.73-1.3-1.23-2.24-1.59s-0.51-1.09-0.51-1.67c0-0.58,1.16-0.51,1.74-0.8
		s-0.43-1.3-0.87-1.52c-0.43-0.22-0.43-0.8-1.52-1.23s-0.8-1.23-2.03-1.52s-1.3-0.94-1.59-2.1c-0.29-1.16-0.58-1.88-1.3-2.53
		s-2.82-2.53-3.84-3.18c-1.01-0.65-1.59-1.38-1.59-2.46s-0.72-1.16-2.03-1.88c-1.31-0.72-0.8-2.1-0.8-2.9s-1.23-0.72-2.17-0.8
		s-1.52-0.8-2.75-1.52s-1.3-1.45-3.33-2.24c-2.03-0.8,0.22-1.74,0.29-2.39s2.82-0.14,3.55-0.14s1.52-0.94,1.52-1.74
		s1.16-0.72,1.38-1.3c0.22-0.58,0-1.52-1.09-1.45c-1.09,0.07-1.09-0.8-1.88-1.16c-0.79-0.36-0.36-0.72-0.22-1.38
		c0.15-0.65-0.65-1.38-1.09-2.03c-0.43-0.65-1.59-0.65-2.46-0.36s-1.38,0.43-1.59,1.16s-1.96,0-3.18-0.14
		c-1.23-0.14-1.01-1.45-1.96-2.32c-0.94-0.87-1.81-1.59-1.16-1.74s1.09-1.52,1.09-2.03s-0.58-1.01-1.52-1.09
		c-0.94-0.07-0.8,0-1.09-0.94s-1.23-1.81-2.32-2.1c-0.72-0.19-0.83-1.44-0.83-2.28c-0.16,0.08-0.33,0.11-0.53,0.1
		c-0.02,0-0.04,0-0.06,0c-0.08-0.01-0.17-0.02-0.27-0.04c-0.03-0.01-0.05-0.01-0.08-0.02c-0.12-0.03-0.25-0.06-0.4-0.1
		c-0.98-0.27-0.98-0.27-1.9-0.27s-0.6,1.47-0.98,1.57c-0.38,0.11-0.49,0.92-0.49,1.52s0.92,0.6,1.03,1.19
		c0.11,0.6,0.33,0.54,0.87,0.98c0.54,0.43,0.33,1.57,0.05,2.06c-0.27,0.49-0.16,1.14-0.22,1.95c-0.05,0.81-1.74,0.22-2.71,0.27
		s-0.76-0.98-1.03-1.57c-0.27-0.6-0.22-0.76-0.22-1.36c0-0.07,0-0.15-0.01-0.22c0-0.04-0.01-0.08-0.02-0.13
		c-0.01-0.03-0.01-0.07-0.02-0.1c-0.01-0.06-0.03-0.12-0.04-0.18c0-0.01-0.01-0.02-0.01-0.04c-0.11-0.36-0.3-0.66-0.57-0.64l0,0
		C530.708,475.808,530.198,477.058,529.578,477.588z"
          pointer-events="auto"
        ></path>

        <path
          id="IN"
          title="Inde"
          capital="New Delhi"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M505.078,426.478c-0.05,0.01-0.11,0.01-0.17,0.01c-0.06-0.01-0.13-0.02-0.2-0.05c-0.65-0.27-1.3,0-1.85,0.16
		c-0.55,0.16-0.65-0.22-1.14-0.81c-0.49-0.6-1.85,0.16-3.2,0c-1.36-0.16-0.16-1.47,0.43-1.85c0.6-0.38,0.54-0.92,0.49-1.47
		s-0.43-1.03-1.19-1.41s-0.22-1.3-0.27-1.74s-0.65-0.92-1.19-1.03s-0.49-0.98-1.14-0.92c-0.65,0.05-0.81,1.19-1.52,1.19
		s-1.63,1.68-2.01,2.17s-1.03,0.27-1.57-0.16s-0.81-0.05-1.85-0.05s-1.14-1.03-1.9-1.85s-1.79,0.71-3.04,1.41s-1.47,1.41-2.33,2.61
		c-0.86,1.2-2.28,1.19-3.48,1.14c-1.19-0.05-1.3,0.54-1.68,1.47c-0.38,0.92-1.14,1.63-2.33,1.85c-1.19,0.22-1.03,1.52-1.36,2.61
		s-2.5,0.71-3.42,0.54c-0.92-0.16-1.63,0.43-2.39,0.6c-0.06,0.01-0.12,0.01-0.16,0.01c-0.37,0.22-0.83,0.05-1.06,0.18
		c-0.37,0.22-0.37,0.74-0.22,1.03c0.15,0.3,1.03,0.89,2.07,1.03c1.03,0.15,0.81,2.07,0.89,2.51c0.07,0.44,0.74,0.81,1.4,1.48
		c0.66,0.66-1.92,0.3-3.25,0.3s-2.36,0.07-3.39,0.07s-2.88,0-3.25,0.66s-1.7,0.37-1.77-0.22c-0.07-0.59-0.59-0.81-1.4-0.81
		s-0.96,0.07-1.25,0.96c-0.3,0.89-1.55,0.66-2.29,0.74c-0.74,0.07-2.07-0.22-2.29-0.59s-1.77-0.66-2.8-0.66s-0.74-1.4-1.48-1.7
		c-0.74-0.3-0.15-1.03-0.22-2.14c-0.03-0.45,0.11-0.89,0.28-1.26c-0.04-0.04-0.08-0.08-0.11-0.12c-0.01-0.01-0.02-0.02-0.03-0.03
		c-0.03-0.04-0.06-0.08-0.08-0.12c-0.27-0.49,0.11-2.17,0.11-2.77c0-0.6-0.16-1.14-0.98-1.19s-1.41,0.27-1.9,0.76
		c-0.03,0.03-0.07,0.06-0.12,0.09c-0.01,0.01-0.03,0.02-0.05,0.02c-0.03,0.02-0.06,0.03-0.1,0.05c-0.02,0.01-0.04,0.01-0.06,0.02
		c-0.04,0.01-0.07,0.02-0.11,0.03c-0.02,0.01-0.05,0.01-0.07,0.01c-0.04,0.01-0.08,0.01-0.13,0.02c-0.02,0-0.05,0.01-0.07,0.01
		c-0.05,0.01-0.1,0.01-0.16,0.01c-0.02,0-0.04,0-0.07,0c-0.07,0-0.14,0-0.21,0c-0.01,0-0.02,0-0.03,0c-0.17,0-0.36-0.01-0.54-0.02
		c0.03,0.55,0.06,1.13,0.06,1.4c0,0.59,0.15,1.4-0.44,1.48c-0.59,0.07-0.74,0.59-0.66,1.11s-0.15,2.95,0.37,3.17
		s0.59,0.66,0.59,1.25c0,0.45,0.42,0.73,0.63,1.32c-0.79-0.02-1.36,0.79-1.86,1.39c-0.65,0.8-1.74-0.07-2.9,0.07
		s-1.38,0.22-2.68-0.43s-1.45,0.22-2.61,0.22s-2.61-0.8-4.42-0.94c-1.81-0.14-1.95-0.29-2.39-1.01c-0.43-0.72-0.65-0.51-2.39-0.29
		c-1.74,0.22-1.45-0.58-2.61-0.8c-1.16-0.22-1.95-0.58-2.17-1.45c-0.22-0.87-0.87-0.94-1.45-2.03c-0.58-1.09-1.3-0.87-2.32-0.94
		c-1.01-0.07-1.09,0.36-1.81,0.87s-1.81-0.14-2.68-0.51c-0.87-0.36-1.52,0.22-2.53,0.51s-1.3-0.36-1.74-1.3
		c-0.43-0.94-1.38-1.01-2.39-0.94s-2.1-0.43-2.68-1.45c-0.58-1.01-1.09-0.36-1.52,0s-1.01,0.51-1.67-0.22
		c-0.65-0.72-1.81-1.01-2.39-2.24c-0.58-1.23-1.59-1.3-2.46-1.45s-1.59-0.29-2.03-1.01c-0.43-0.72-1.59-0.58-2.82-0.8
		s-1.09-1.09-1.45-2.53c-0.36-1.45,0.43-1.45,1.01-1.95c0.58-0.51,0.65-0.8,0.58-1.74s0.43-1.3,0.72-2.32
		c0.29-1.01,0.8-1.09,1.95-1.81c0.96-0.6,1.17-1.05,0.97-1.73c-0.02-0.06-0.04-0.13-0.07-0.19c0-0.01-0.01-0.02-0.01-0.03
		c-0.03-0.07-0.06-0.14-0.09-0.22c-0.43-0.94-0.87-0.51-1.59-1.09s-1.3-0.65-2.68-1.16s-1.23-1.09-2.03-2.39
		c-0.8-1.3-1.23-0.51-2.53-0.51s-1.52-0.87-2.17-2.32c-0.65-1.45-1.59-0.51-2.82-0.51s-0.87-4.05-1.23-5.36
		c-0.36-1.3-0.65-1.01-1.3-2.03s-0.29-1.88,0.36-1.88s1.38-0.58,1.74-0.36s0.51,1.45,0.87,2.1s1.67-0.43,2.17-1.16
		c0.51-0.72,1.45-0.65,2.1-1.3s0-1.38-0.58-1.95c-0.58-0.58-0.51-0.87-0.58-1.3s-0.8-1.23-1.74-1.59s-1.81-1.88-1.45-2.9
		s-0.07-1.16-0.94-2.1s-0.14-1.67,0.65-2.17c0.8-0.51,1.52,0.14,2.03,0.87c0.51,0.72,1.23,0,2.61-0.36s2.03-2.53,2.61-3.4
		s1.01-1.16,1.88-1.38s0.87-2.03,0.72-3.04c-0.14-1.01,0.51-1.16,0.87-2.17s-1.01-1.09-2.32-1.38c-1.3-0.29-1.95-1.16-2.9-2.39
		c-0.94-1.23-2.46-1.09-3.84-1.09s-1.52,0.36-2.68,0.72c-1.16,0.36-1.16,1.23-2.32,1.3c-1.16,0.07-1.09,0.72-2.03,1.67
		c-0.05,0.05-0.1,0.09-0.16,0.13c-0.04,0.03-0.09,0.06-0.14,0.09c0.83,1.46,1,3,0.91,3.73c-0.11,0.98-1.3,0.33-1.85-0.65
		s-2.39-0.87-3.69-0.87s-2.71,1.41-3.15,2.71s-1.63,1.3-3.8,1.41c-2.17,0.11-2.71,0.87-4.67,1.3s-3.48-0.43-3.91-0.87
		c-0.43-0.43-1.74-0.65-3.26-0.65s-2.5-0.65-3.91-0.65s-1.41,2.71-1.41,3.8s0.11,1.3,1.3,1.95s1.41,1.19,0.11,2.06
		c-1.3,0.87,0.54,2.5,0.33,3.91c-0.22,1.41,1.3,2.5,1.3,3.26s1.85,1.19,1.95,2.28c0.11,1.09,2.28,1.19,3.91,1.41s1.3,0.87,1.19,1.52
		s-2.82,1.19-4.23,1.52s-1.52,1.74-0.98,2.5c0.54,0.76-0.43,1.63-0.43,2.39s0.33,1.52,1.19,1.74c0.87,0.22,0,1.09-0.98,1.09
		s-1.41,1.09-2.61,1.85c-1.19,0.76-1.09,1.63-1.52,3.8s-1.41,1.41-2.71,1.52s-1.41,1.95-1.63,3.48c-0.22,1.52-1.09,1.41-2.28,3.26
		s-1.52,1.74-3.26,1.95c-1.74,0.22-1.74,3.37-2.71,3.91s-1.41,0.98-1.52,2.39s-3.26,1.3-4.13,1.3s-1.41-0.22-1.63,0.87
		s-1.3,0.76-1.63,0.76c-0.33,0-0.76-0.43-0.98-1.3s-0.87-1.41-1.95-1.41s-1.85,1.52-3.04,3.58s-1.19,2.17-2.61,2.71
		s-0.98,2.28-0.87,2.93c0.11,0.65,2.39,1.41,3.37,1.52c0.98,0.11,0.98,0,1.63,1.3s0.11,2.06-0.54,2.39s0,1.41,0.33,2.06
		s1.19,1.19,2.61,1.41c1.41,0.22,0.65,2.39,1.85,2.61c1.19,0.22,0.33,2.06,1.3,3.37c0.98,1.3-0.11,1.19,0.33,2.28
		c0.43,1.09-0.22,0.76-1.09,1.74s-1.09,0.65-1.3-0.11c-0.22-0.76-0.76-1.19-1.85-1.3s-0.65,1.3-1.74,1.3s-1.3,1.09-2.5,1.09
		c-1.19,0-1.63,0-2.06-0.87s-2.61-0.33-3.91-0.33s-1.63,1.09-1.63,1.95c0,0.86-1.09,0.76-2.17,0.76c-0.58,0-1.09,0.83-1.45,1.6
		c1.23,0.86,1.45,1.71,2.08,2.98c0.78,1.56,4.2,1.87,5.92,3.27s4.51-1.4,6.54,0c2.02,1.4-0.16,1.87-2.18,2.18s-1.87,1.25-3.42,1.09
		c-1.56-0.15-2.96-0.47-2.96,0.62s3.58,4.36,4.83,6.23c1.25,1.87,3.11,3.42,5.6,4.83c2.49,1.4,3.11,1.87,4.51,0.16
		c1.4-1.71,3.58-1.56,5.29-2.65c1.71-1.09,2.49-2.18,1.87-3.27s0.16-3.89,0.16-5.14s1.87,0.47,2.33,2.02
		c0.47,1.56,0.62,1.56,0.31,3.27s1.71,4.36,2.02,5.76c0.31,1.4,0.15,2.02-1.87,4.51c-2.02,2.49,0,5.14,0.16,7.16
		c0.15,2.02,3.58,11.52,4.05,15.1c0.47,3.58,1.56,7.94,3.27,10.89c1.71,2.96,3.74,6.85,5.29,10.58c1.56,3.73,2.02,5.6,2.33,8.09
		s2.18,4.98,4.36,8.25s3.89,6.07,4.98,9.34s1.09,5.14,1.56,7.78c0.47,2.65,3.73,5.14,4.51,7c0.78,1.87,4.36,1.71,5.29,0.47
		c0.93-1.25,3.42-2.96,3.58-4.67c0.16-1.71,1.25-1.4,3.58-2.02s1.56-2.18,1.4-3.11c-0.15-0.93,1.87-3.89,2.96-4.83
		c1.09-0.93,2.8-0.78,2.96-1.71s-0.47-3.89-0.78-5.14s0.47-2.8,0.93-4.36s1.25-3.27,2.18-4.51c0.93-1.25,0.93-2.65,0.78-4.05
		c-0.16-1.4-0.15-2.96-0.15-4.67c0-1.71-1.25-1.87-0.78-3.11s-0.16-2.96-0.62-4.05c-0.47-1.09,0.16-2.96,0.78-5.14
		s0.62-1.71,2.96-1.71s3.58-1.24,4.82-3.27c1.24-2.02,3.27-0.78,5.29-2.02s2.02-4.52,2.96-5.45c0.93-0.93,3.42-1.4,4.36-2.64
		c0.93-1.24,3.73-3.58,4.83-4.36c1.09-0.78,3.27-2.02,5.45-4.67s3.27-4.83,5.14-5.29c1.87-0.47,3.27-0.93,4.98-1.56
		c1.71-0.62,3.42-3.42,4.83-4.67c1.4-1.24,0.47-2.8,0.62-4.36c0.16-1.56,0.47-3.27,1.87-3.27s3.27-0.31,3.73-1.24s1.56-1.56,2.02,0
		s2.33,1.09,2.33,1.09s2.8-0.78,3.73,0c0.1,0.08,0.18,0.14,0.27,0.2c0.04-1.36-0.09-3.94-0.14-4.51c-0.05-0.65-0.54-0.76-0.76-1.57
		s0.11-2.99,0.11-3.8s0.05-1.57-0.54-2.06c-0.6-0.49-0.81-0.87-1.19-1.9s-0.38-3.42,0-4.51s-0.92-2.33-1.52-2.66
		c-0.6-0.33-0.92-0.92-1.52-1.52c-0.6-0.6-0.43-1.3-0.33-1.9c0.11-0.6,1.36-0.71,1.36-1.03s0.38-1.03,1.41-1.03s1.63,0.05,1.95-0.65
		c0.32-0.7,0.54-1.47,0.43-1.63c-0.11-0.16-2.12-0.38-2.71-0.49c-0.6-0.11-1.14-1.14-1.36-1.68c-0.22-0.54-0.65-0.65-1.03-0.81
		s-0.87-0.11-0.87-0.6c0-0.49,0.16-1.19,0.81-1.3s0.16-0.87,1.09-1.03c0.92-0.16,0.54-1.09,0.38-1.52c-0.07-0.19-0.05-0.48,0-0.75
		l0,0c0.45,0.38,0.69,0.72,0.98,0.96c0,0,0,0,0.01,0c0.03,0.03,0.07,0.06,0.11,0.08c0.01,0.01,0.02,0.01,0.03,0.02
		c0.03,0.02,0.06,0.04,0.09,0.05c0.02,0.01,0.03,0.02,0.05,0.02c0.03,0.02,0.06,0.03,0.09,0.04c0.02,0.01,0.04,0.02,0.06,0.02
		c0.03,0.01,0.06,0.02,0.1,0.03c0.02,0.01,0.04,0.01,0.07,0.02c0.03,0.01,0.07,0.02,0.11,0.02c0.02,0,0.05,0.01,0.07,0.01
		c0.04,0.01,0.09,0.01,0.14,0.02c0.02,0,0.04,0.01,0.07,0.01c0.07,0.01,0.15,0.01,0.23,0.01c1.38,0,1.67-0.14,2.1,0.8
		s0.8,1.59,2.03,1.81s1.74-0.94,2.68-1.16s0.94,0.29,0.94,1.16s-1.09,1.81-0.43,2.46s-0.8,2.1,0,2.53s1.45,0.43,2.03,0.94
		s1.95,0.65,2.97,0.72s3.98-0.36,5.43-0.22c1.45,0.14,0.65,1.01,2.68,0.43c2.03-0.58,2.68-0.43,3.55-0.29
		c0.87,0.14,0.87,0.87,1.16,1.23c0.29,0.36,0.87,1.23-0.14,1.52s-1.16,0.58-1.16,1.23s-1.09,1.09-1.3,1.88
		c-0.22,0.8-1.09,1.23-1.67,1.3c-0.58,0.07-1.45,0-1.52,0.43s-1.81,0.58-1.81,1.16c0,0.58-1.16,0.8-0.94,1.52s0.87,2.46,0.87,3.4
		s0.22,1.38,0.94,1.38s1.95,1.74,2.46,0.36s0.22-2.82,0.72-2.97c0.51-0.14-0.36-1.23,0.87-1.52c1.23-0.29,2.32-0.58,2.32,0.36
		c0,0.94-0.36,3.26,0,3.62s0.65,1.01,0.72,1.67s1.16,0.94,1.09,2.39s-0.43,2.61,0.07,3.11c0.04,0.04,0.07,0.09,0.1,0.15
		c0.01,0.01,0.01,0.02,0.02,0.04c0.02,0.06,0.04,0.12,0.05,0.18l0,0c0.36,0.02,0.8,0.08,1.24,0.24c0.98,0.38,1.85,0.65,1.9-0.22
		s1.3-0.71,1.3-1.41s-0.22-1.52-0.71-1.52s-0.65,0-0.65-0.92s-0.16-2.28,0.65-2.66s1.41-0.76,1.41-1.9s-0.16-3.15,0.43-3.48
		c0.6-0.33,0.49-0.98-0.11-1.41c-0.6-0.43-0.87-1.3,0.27-1.3s1.03,0.81,2.33,0.76s1.68,0.76,1.79,1.25c0.11,0.49,0.98,0,0.92-0.98
		c-0.05-0.98-0.6-2.77,0.92-2.82c1.52-0.05,0-1.74,1.36-1.9s0.92-1.3,0.98-2.06c0.05-0.76,0.98-1.19,0.38-1.68s-1.14-0.54-1.09-1.03
		s0.54-0.22,0.6-1.14s0.76-0.98,1.25-1.09s1.68-0.92,1.74-1.9c0.05-0.98,1.25-1.3,0.6-2.06s-0.81-0.43-0.54-1.79
		c0.27-1.36,0.65-2.82,2.23-2.93c1.57-0.11,2.61-1.52,3.31-2.88c0.71-1.36,2.77-1.63,3.64-1.63c0.87,0,2.17-0.43,2.88,0.71
		s1.57,0.54,1.52-0.33c-0.05-0.87-1.41-0.87-1.19-2.12s0.33-2.12,1.57-2.44c1.25-0.33,1.52-0.81,1.52-1.79
		C505.038,427.518,505.108,427.018,505.078,426.478z"
          pointer-events="auto"
        ></path>

        <path
          id="LK"
          title="Sri Lanka"
          capital="Colombo"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M400.638,572.978c-1.3-0.87-3.69-0.43-3.96-2.44s-4.18-8.14-5.32-8.96c-1.14-0.81-2.5-2.71-4.45-2.55c0,0-1.52,1.09-0.54,1.85
		c0.98,0.76,0.76,0.81,0.71,2.55c-0.05,1.74,0.43,1.68-0.54,2.12s-2.12,0.38-2.01,1.52s0.22,2.39-0.16,3.04
		c-0.38,0.65-0.11,2.44-0.27,3.2s0,3.58,0,4.72s-0.05,1.52,0.43,2.61c0.49,1.09,0.43,1.79,0.65,2.88s1.14,1.57,1.9,2.55
		s1.41,1.14,2.28,1.41s1.74,0.16,2.23-0.33c0.49-0.49,2.06-1.09,3.2-1.36c1.14-0.27,4.4-2.55,4.56-3.47s0-2.99,0.43-4.18
		C400.208,576.938,401.948,573.838,400.638,572.978z"
          pointer-events="auto"
        ></path>

        <path
          id="AF"
          title="Afghanistan"
          capital="Kaboul"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M347.178,355.948c-1.01-0.22-1.88,0.36-2.53,0.8s-1.88,0.29-2.32-0.22c-0.43-0.51-1.59-0.65-3.26-0.43s-3.69,2.17-4.56,3.04
		s-2.46,0.94-3.4,1.81s-2.97,0.94-4.27-0.14c-1.3-1.09,0.22-3.26,0.07-3.98c-0.14-0.72-0.14-1.88,0.22-2.61
		c0.36-0.72,0.65-1.45-0.8-2.1c-1.45-0.65-0.72-1.16-0.94-2.68s-1.38-2.24-2.46-2.24s-1.95,2.53-3.18,3.18
		c-1.23,0.65-1.59,1.16-1.74,3.33c-0.14,2.17-1.67,1.16-3.26,0.87s-2.17,0.43-2.1,1.59s0.36,2.39-0.43,2.39
		c-0.8,0-1.16-0.8-2.61-1.74s-1.09,1.09-3.18,1.09c-2.1,0-1.95,1.74-3.33,1.88s-1.23-0.58-1.45-1.67c-0.01-0.06-0.03-0.12-0.05-0.17
		c-0.01-0.02-0.02-0.03-0.03-0.05c-0.02-0.03-0.03-0.06-0.05-0.08c-0.01-0.02-0.03-0.03-0.04-0.04c-0.02-0.02-0.04-0.04-0.06-0.05
		c-0.02-0.01-0.03-0.02-0.05-0.03s-0.04-0.02-0.07-0.03c-0.02-0.01-0.04-0.02-0.06-0.02c-0.02-0.01-0.05-0.01-0.08-0.02
		c-0.02,0-0.04-0.01-0.07-0.01s-0.06-0.01-0.08-0.01c-0.07,0-0.15,0-0.23,0c-0.24,0.02-0.52,0.07-0.82,0.12
		c-0.03,0-0.06,0.01-0.09,0.01c-0.05,0.01-0.09,0.01-0.14,0.02c-0.04,0.01-0.09,0.01-0.13,0.02s-0.08,0.01-0.13,0.01
		c-0.05,0-0.1,0.01-0.15,0.01c-0.04,0-0.08,0-0.11,0.01c-0.06,0-0.11,0-0.17,0c-0.03,0-0.06,0-0.09,0
		c-0.09-0.01-0.18-0.01-0.26-0.03c-1.3-0.22-1.95-0.72-2.39-1.45c-0.02-0.04-0.06-0.08-0.1-0.11c-0.01-0.01-0.02-0.02-0.04-0.03
		c-0.03-0.02-0.07-0.05-0.11-0.07c-0.01-0.01-0.03-0.02-0.04-0.02c-0.05-0.02-0.12-0.05-0.18-0.07c-0.01,0-0.03-0.01-0.04-0.01
		c-0.06-0.02-0.12-0.03-0.18-0.04c-0.02,0-0.05-0.01-0.08-0.01c-0.06-0.01-0.12-0.02-0.19-0.02c-0.02,0-0.04-0.01-0.07-0.01
		c-0.09-0.01-0.17-0.01-0.27-0.02c-0.03,0-0.06,0-0.09,0c-0.07,0-0.14,0-0.21,0c-0.04,0-0.08,0-0.12,0c-0.07,0-0.14,0-0.22,0.01
		c-0.03,0-0.07,0-0.1,0c-0.1,0-0.2,0.01-0.3,0.02c-0.05,0-0.11,0.01-0.16,0.01c-0.05,0-0.11,0.01-0.16,0.01
		c-0.06,0.01-0.12,0.01-0.18,0.02c-0.04,0-0.09,0.01-0.13,0.01c-0.13,0.01-0.26,0.03-0.39,0.04c-0.02,0-0.04,0.01-0.07,0.01
		c-0.38,0.05-0.74,0.09-1.08,0.14c-0.13,0.02-0.26,0.04-0.38,0.05c-1.59,0.22-2.03-0.51-2.82-1.23c-0.8-0.72-1.88-0.43-2.24,0.8
		s-2.03,1.67-3.4,1.67s-1.95,0.94-2.32,2.17s-1.16,1.88-1.23,3.4s-0.87,2.75-2.24,2.75c-1.38,0-1.52,1.38-3.18,1.59
		s-2.46,0.72-3.55,0.94s-1.01,0.94-0.51,1.74c0.51,0.8-0.8,1.16-1.3,1.88s-1.16,1.16-2.39,0.72c-1.23-0.43-1.88,0.58-2.24,1.23
		s-1.01-0.14-1.23-1.01s-1.88-1.01-2.46-1.01s-1.16,0.22-1.88-0.87c-0.41-0.62-1.39-0.72-2.15-0.69c0.01,0.14,0.02,0.28,0.02,0.42
		c0,0.02,0,0.04,0,0.05c0,0.13,0,0.26-0.01,0.38c0,0.01,0,0.02,0,0.02c-0.01,0.12-0.03,0.24-0.05,0.36
		c-0.01,0.03-0.01,0.05-0.02,0.08c-0.03,0.11-0.06,0.22-0.1,0.33c-0.51,1.16-0.87,1.45-0.87,2.24c0,0.8,0.58,2.1-0.36,2.75
		s-1.3,1.45-1.3,2.1s0.65,1.67-0.43,1.74c-1.08,0.07-1.52,0.72-1.52,1.88s-0.51,3.26,0.51,3.48c1.01,0.22,3.04,0,1.45,1.38
		s-1.96,1.45-1.96,2.39s-0.29,1.81,0.36,2.03s0.15,1.88,0.8,2.17c0.65,0.29,0.94-0.29,0.94,2.03s-0.65,2.82-0.65,3.69
		s0.29,2.46,1.16,2.68c0.87,0.22,2.9,0.14,3.98,0.36s1.95,1.38,2.32,2.1c0.36,0.72,0.14,2.17-0.51,2.97s-1.81,1.38-2.32,2.17
		c-0.51,0.8-1.45,1.67-1.96,2.17c-0.02,0.02-0.04,0.04-0.07,0.06c-0.01,0.01-0.02,0.02-0.03,0.02c-0.02,0.02-0.04,0.03-0.06,0.05
		c-0.01,0.01-0.03,0.02-0.04,0.03l-0.01,0.01c-0.46,0.38-1.32,1.03-1.5,1.85v0.01c-0.01,0.06-0.02,0.12-0.03,0.19l0,0
		c1.09,0.62,2.76,1.49,3.76,1.68c1.59,0.29,2.24,0.36,3.33,1.01c1.09,0.65,2.32,0.8,3.55,0.8s3.77,0.07,6.23,0.07
		s4.05,0.51,5.72-0.29c1.67-0.8,2.97-0.94,4.63-0.94c1.67,0,3.69,0.07,4.85-0.29s1.95-0.22,3.62-0.65c1.67-0.43,1.88-0.8,1.74-2.9
		s-1.45-4.85,0.72-6.23s1.16-1.59,2.32-2.32c1.16-0.72,1.67-1.52,2.46-0.51c0.79,1.01,0.72,0.51,1.74,0.51s2.39-0.36,2.39-0.94
		s-1.81-1.16-1.59-1.88s1.45-0.58,2.32-0.36s1.38-0.43,2.03-0.8s3.04-0.94,3.84,0.14c0.8,1.09,2.03,1.45,2.32,0.65
		s0.65-1.09,1.3-1.52s0.72-0.87,0.72-1.88s-1.23-1.45-0.94-2.32s1.3-1.16,1.3-2.53s0.14-3.11,2.24-3.11c2.1,0,2.68-0.14,3.04-0.87
		s1.81-1.09,0.58-2.39s-1.95-1.95-2.1-2.61c-0.14-0.65-0.22-1.38,1.23-1.38s1.88,0.51,2.03,0.8c0.14,0.29,1.45,0,2.39,0
		s0.51-0.87,1.67-0.94s1.38-0.58,1.23-1.67c-0.14-1.09-0.36-1.3-0.94-1.81c-0.58-0.51-0.43-0.87,0.58-1.67
		c1.01-0.8,3.48-1.52,3.26-3.4s0.22-3.48-0.51-4.13s-0.65-1.95-1.59-2.17s-0.87-1.16,0.14-1.74s1.16-1.16,2.39-1.3
		c1.23-0.14,1.01-0.65,1.88-1.3s2.75-1.3,3.04-2.03c0.29-0.72,1.81-0.72,3.98-0.8s4.2-0.29,6.01-0.14c1.81,0.14,4.13,0.14,4.56-0.58
		c0.05-0.09,0.11-0.16,0.17-0.23c0.02-0.02,0.04-0.04,0.05-0.06c0.04-0.05,0.09-0.09,0.13-0.13c0.02-0.02,0.04-0.03,0.06-0.05
		c0.05-0.04,0.1-0.07,0.15-0.1c0.02-0.01,0.03-0.02,0.05-0.03c0.31-0.18,0.66-0.28,1.04-0.48c0.05-0.03,0.11-0.05,0.16-0.09
		c0.72-0.43,0.87-0.51,1.52-1.81c0.03-0.07,0.06-0.13,0.09-0.2C349.658,356.118,348.038,356.138,347.178,355.948z"
          pointer-events="auto"
        ></path>

        <path
          id="PK"
          title="Pakistan"
          capital="Islamabad"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M305.628,458.958c1.09,0,2.17,0.11,2.17-0.76s0.33-1.95,1.63-1.95s3.48-0.54,3.91,0.33s0.87,0.87,2.06,0.87s1.41-1.09,2.5-1.09
		s0.65-1.41,1.74-1.3s1.63,0.54,1.85,1.3c0.22,0.76,0.43,1.09,1.3,0.11s1.52-0.65,1.09-1.74s0.65-0.98-0.33-2.28
		c-0.98-1.3-0.11-3.15-1.3-3.37s-0.43-2.39-1.85-2.61c-1.41-0.22-2.28-0.76-2.61-1.41s-0.98-1.74-0.33-2.06s1.19-1.09,0.54-2.39
		c-0.65-1.3-0.65-1.19-1.63-1.3s-3.26-0.87-3.37-1.52s-0.54-2.39,0.87-2.93s1.41-0.65,2.61-2.71s1.95-3.58,3.04-3.58
		s1.74,0.54,1.95,1.41c0.22,0.87,0.65,1.3,0.98,1.3s1.41,0.33,1.63-0.76s0.76-0.87,1.63-0.87s4.02,0.11,4.13-1.3
		c0.11-1.41,0.54-1.85,1.52-2.39c0.98-0.54,0.98-3.69,2.71-3.91c1.73-0.22,2.06-0.11,3.26-1.95c1.19-1.85,2.06-1.74,2.28-3.26
		s0.33-3.37,1.63-3.48c1.3-0.11,2.28,0.65,2.71-1.52c0.43-2.17,0.33-3.04,1.52-3.8c1.19-0.76,1.63-1.85,2.61-1.85
		s1.85-0.87,0.98-1.09s-1.19-0.98-1.19-1.74c0-0.76,0.98-1.63,0.43-2.39c-0.54-0.76-0.43-2.17,0.98-2.5s4.13-0.87,4.23-1.52
		s0.43-1.3-1.19-1.52c-1.63-0.22-3.8-0.33-3.91-1.41c-0.11-1.09-1.95-1.52-1.95-2.28s-1.52-1.85-1.3-3.26s-1.63-3.04-0.33-3.91
		c1.3-0.87,1.09-1.41-0.11-2.06c-1.2-0.65-1.3-0.87-1.3-1.95s0-3.8,1.41-3.8s2.39,0.65,3.91,0.65s2.82,0.22,3.26,0.65
		c0.43,0.43,1.95,1.3,3.91,0.87s2.5-1.19,4.67-1.3s3.37-0.11,3.8-1.41s1.85-2.71,3.15-2.71s3.15-0.11,3.69,0.87s1.74,1.63,1.85,0.65
		c0.08-0.74-0.08-2.27-0.91-3.73c-1.05,0.59-3.25,0.28-4.52,0.17c-0.02,0-0.04,0-0.06-0.01c-0.09-0.01-0.18-0.01-0.26-0.02
		c-1.3-0.07-0.72-0.51-2.46-0.72c-1.74-0.22-1.3-0.43-2.1-1.52c-0.8-1.09-1.74-0.65-2.82-0.65s-1.23-0.87-2.03-1.52
		s0.43-1.74,0.43-2.68s-0.72-1.74-1.38-2.03s-0.43-0.87-1.67-1.16c-1.23-0.29-1.3-0.65-1.74-1.23s-0.94-0.43-2.46-0.43
		c-0.94,0-1.6-0.44-1.97-0.78c-0.38,0.2-0.73,0.31-1.04,0.48c-0.02,0.01-0.03,0.02-0.05,0.03c-0.05,0.03-0.1,0.07-0.15,0.1
		c-0.02,0.02-0.04,0.03-0.06,0.05c-0.05,0.04-0.09,0.08-0.13,0.13c-0.02,0.02-0.04,0.03-0.05,0.06c-0.06,0.07-0.11,0.14-0.17,0.23
		c-0.43,0.72-2.75,0.72-4.56,0.58s-3.84,0.07-6.01,0.14s-3.69,0.07-3.98,0.8s-2.17,1.38-3.04,2.03s-0.65,1.16-1.88,1.3
		c-1.23,0.14-1.38,0.72-2.39,1.3s-1.09,1.52-0.14,1.74c0.95,0.22,0.87,1.52,1.59,2.17s0.29,2.24,0.51,4.13
		c0.22,1.88-2.24,2.61-3.26,3.4c-1.01,0.8-1.16,1.16-0.58,1.67c0.58,0.51,0.8,0.72,0.94,1.81s-0.07,1.59-1.23,1.67
		s-0.72,0.94-1.67,0.94s-2.24,0.29-2.39,0c-0.14-0.29-0.58-0.8-2.03-0.8s-1.38,0.72-1.23,1.38c0.14,0.65,0.87,1.3,2.1,2.61
		c1.23,1.3-0.22,1.67-0.58,2.39s-0.94,0.87-3.04,0.87s-2.24,1.74-2.24,3.11s-1.01,1.67-1.3,2.53c-0.29,0.87,0.94,1.3,0.94,2.32
		s-0.07,1.45-0.72,1.88s-1.01,0.72-1.3,1.52c-0.29,0.8-1.52,0.43-2.32-0.65c-0.8-1.09-3.18-0.51-3.84-0.14
		c-0.65,0.36-1.16,1.01-2.03,0.8c-0.87-0.22-2.1-0.36-2.32,0.36s1.59,1.3,1.59,1.88c0,0.58-1.38,0.94-2.39,0.94
		s-0.94,0.51-1.74-0.51s-1.3-0.22-2.46,0.51c-1.16,0.72-0.14,0.94-2.32,2.32c-2.17,1.38-0.87,4.13-0.72,6.23s-0.07,2.46-1.74,2.9
		c-1.67,0.43-2.46,0.29-3.62,0.65s-3.18,0.29-4.85,0.29s-2.97,0.14-4.63,0.94c-1.67,0.8-3.26,0.29-5.72,0.29s-4.99-0.07-6.23-0.07
		c-1.23,0-2.46-0.14-3.55-0.8s-1.74-0.72-3.33-1.01c-1-0.18-2.67-1.06-3.76-1.68c-0.01,0.12,0,0.25,0.03,0.38
		c0,0.01,0,0.02,0.01,0.02c0.01,0.05,0.03,0.11,0.05,0.17c0,0.01,0.01,0.02,0.01,0.04c0.02,0.06,0.05,0.12,0.08,0.18
		c0,0.01,0.01,0.01,0.01,0.02c0.03,0.07,0.08,0.13,0.12,0.2c0.01,0.01,0.02,0.02,0.02,0.03c0.04,0.05,0.08,0.11,0.13,0.16
		c0.02,0.02,0.03,0.04,0.05,0.06c0.04,0.05,0.09,0.1,0.14,0.15c0.02,0.02,0.04,0.04,0.06,0.06c0.07,0.07,0.15,0.14,0.23,0.2
		c2.32,1.88,2.97,3.77,3.33,4.92c0.36,1.16,2.32,3.91,4.34,4.42c2.03,0.51,4.49,1.01,4.49,3.4c0,2.39-0.51,4.42,0.72,5.14
		s3.4,0.51,2.75,1.74s-0.36,2.68-1.38,2.97c-1.01,0.29-5.36-0.14-6.73,2.03c-1.37,2.17-2.75,2.61-2.9,3.62s-0.65,3.91-0.65,4.78
		c0,0.36-0.04,0.83-0.08,1.25c2.5,0.29,2.28,0.04,3.68-1.21c1.56-1.4,6.85,0.93,8.25,0.16c1.4-0.78,2.49-0.93,4.36-1.09
		s5.92-0.16,9.34,0.31c3.42,0.47,3.27-1.24,4.83-1.4s3.27,0.16,4.05,1.56s0.62,2.8,2.33,3.11s1.56,2.18,2.18,4.36
		s3.11,2.65,5.29,3.73c0.41,0.2,0.74,0.4,1.03,0.6C304.538,459.788,305.058,458.958,305.628,458.958z"
          pointer-events="auto"
        ></path>

        <path
          id="IL"
          title="Israël"
          capital="Jerusalem"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M70.438,402.278c-1.01,1.3-1.59,2.46-0.43,4.2c1.16,1.74,0.29,3.62,1.45,4.78c0.07,0.07,0.14,0.15,0.21,0.23
		c0.02,0.02,0.04,0.05,0.06,0.08c0.05,0.06,0.09,0.12,0.14,0.19c0.02,0.03,0.04,0.07,0.07,0.1c0.04,0.06,0.08,0.12,0.12,0.19
		c0.02,0.04,0.04,0.08,0.07,0.11c0.04,0.07,0.07,0.13,0.11,0.2c0.02,0.04,0.04,0.09,0.07,0.13c0.03,0.07,0.07,0.13,0.1,0.2
		c0.02,0.05,0.04,0.1,0.07,0.15c0.03,0.06,0.06,0.13,0.09,0.19c0.02,0.05,0.04,0.11,0.07,0.17s0.05,0.12,0.07,0.18
		s0.04,0.12,0.07,0.18c0.02,0.05,0.04,0.11,0.06,0.17c0.02,0.07,0.05,0.14,0.07,0.21c0.01,0.05,0.03,0.09,0.04,0.14
		c0.02,0.08,0.04,0.15,0.07,0.23c0.01,0.04,0.02,0.08,0.03,0.11c0.02,0.08,0.04,0.16,0.06,0.24c0.01,0.03,0.01,0.06,0.02,0.08
		c0.02,0.09,0.04,0.17,0.06,0.26c0,0.02,0.01,0.04,0.01,0.06c0.02,0.09,0.04,0.17,0.05,0.26c0,0.01,0,0.02,0.01,0.04
		c0.02,0.09,0.03,0.17,0.04,0.25c0,0.01,0,0.01,0,0.02c0.01,0.08,0.02,0.16,0.03,0.23v0.01c0.01,0.07,0.01,0.14,0.01,0.21v0.01
		c1.03-0.72,1.72-2.65,1.72-3.43c0-0.87,0.65-3.15,0.71-3.96s0.22-1.36,0.6-2.12s0.49-1.57,0.81-2.88c0.33-1.3,0.81-2.77,0.81-3.37
		s0-2.82-0.11-3.26c-0.11-0.43-0.11-1.19,0.06-1.85s0.71-3.04,0.6-3.96c-0.01-0.11-0.03-0.23-0.06-0.35
		c-0.01-0.06-0.03-0.11-0.04-0.17c-0.01-0.06-0.03-0.12-0.04-0.17c-0.02-0.08-0.05-0.16-0.07-0.24c-0.01-0.03-0.02-0.06-0.03-0.09
		c-0.03-0.09-0.06-0.19-0.1-0.27c-0.01-0.02-0.02-0.05-0.03-0.07c-0.02,0.04-0.05,0.08-0.07,0.11l0,0
		c-0.03,0.03-0.05,0.05-0.08,0.07l-0.01,0.01c-0.03,0.02-0.06,0.03-0.09,0.04c-0.01,0-0.02,0.01-0.03,0.01
		c-0.04,0.01-0.09,0.02-0.15,0.02c-0.64,0-2.14-0.12-3.61-0.69c-0.59,1.21-0.36,2.8-0.36,4.8c0,2.8-1.4,1.71-2.49,4.51
		c-0.13,0.35-0.27,0.64-0.4,0.89C70.988,400.498,70.998,401.568,70.438,402.278z"
          pointer-events="auto"
        ></path>

        <path
          id="LB"
          title="Liban"
          capital="Beyrouth"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M77.868,390.158c0.01,0,0.02-0.01,0.03-0.01c0.03-0.01,0.07-0.03,0.09-0.04l0.01-0.01c0.03-0.02,0.06-0.05,0.08-0.07l0,0
		c0.03-0.03,0.05-0.07,0.07-0.11c0.03-0.06,0.06-0.12,0.08-0.19c0-0.01,0.01-0.02,0.01-0.04c0.05-0.15,0.08-0.31,0.12-0.47
		c0.02-0.07,0.04-0.14,0.06-0.21c0-0.01,0-0.01,0-0.02c0.02-0.06,0.04-0.12,0.07-0.17c0.01-0.02,0.01-0.03,0.02-0.05
		c0.03-0.06,0.06-0.11,0.1-0.16l0,0c0.04-0.04,0.09-0.07,0.14-0.1c0.01-0.01,0.03-0.01,0.04-0.02c0.06-0.02,0.12-0.04,0.2-0.04
		c0.92,0,1.36-0.6,1.63-1.19c0.27-0.6,0.22-1.85,0.38-2.28c0.16-0.43,0.92-0.6,1.96-0.76c1.03-0.16,0.38-1.19,1.57-2.33
		c1.19-1.14,0.33-2.33-0.06-2.82c-0.38-0.49-1.3-0.76-2.01-1.52c-0.47-0.5-1.58-0.79-2.28-0.93c-0.2,0.44-0.47,0.96-0.71,1.82
		c-0.62,2.18-1.71,2.96-2.49,5.29c-0.78,2.33,0.31,3.27-1.71,4.51c-0.58,0.36-0.96,0.78-1.19,1.27c1.46,0.57,2.96,0.69,3.61,0.69
		C77.768,390.178,77.818,390.168,77.868,390.158z"
          pointer-events="auto"
        ></path>

        <path
          id="SY"
          title="Syrie"
          capital="Damas"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M84.498,379.048c0.38,0.49,1.25,1.68,0.06,2.82s-0.54,2.17-1.57,2.33s-1.79,0.33-1.96,0.76c-0.16,0.43-0.11,1.68-0.38,2.28
		s-0.71,1.19-1.63,1.19c-0.08,0-0.14,0.02-0.2,0.04c-0.01,0.01-0.03,0.01-0.04,0.02c-0.05,0.03-0.1,0.06-0.14,0.1l0,0
		c-0.04,0.04-0.07,0.1-0.1,0.16c-0.01,0.02-0.02,0.03-0.02,0.05c-0.03,0.05-0.05,0.11-0.07,0.17c0,0.01,0,0.01,0,0.02
		c-0.02,0.07-0.04,0.14-0.06,0.21c-0.04,0.16-0.07,0.33-0.12,0.47c0,0.01-0.01,0.03-0.01,0.04c-0.02,0.07-0.05,0.13-0.08,0.19
		c0.01,0.02,0.01,0.03,0.02,0.05c0.19,0.14,0.41,0.31,0.57,0.45c0.33,0.27,0.43,1.68,1.57,2.17c1.14,0.49,2.44,2.17,3.42,2.55
		c0.98,0.38,3.09,1.63,6.3-0.76c3.2-2.39,7.87-4.78,11.02-6.95c3.15-2.17,7.6-3.58,10.42-5.32c2.82-1.74,5.65-3.58,5.43-5.65
		c-0.22-2.06,0.22-2.82,0.76-4.34s0.98-1.74,0.54-2.71c-0.43-0.98-0.76-1.63-0.65-2.93s0.33-3.48,1.85-3.91s2.28-0.33,3.69-2.06
		c0.09-0.12,0.19-0.23,0.29-0.33c0.04-0.04,0.07-0.07,0.11-0.11c0.07-0.07,0.13-0.14,0.2-0.2c0.04-0.04,0.09-0.08,0.13-0.12
		c0.06-0.06,0.13-0.11,0.19-0.17c0.05-0.04,0.09-0.08,0.14-0.12c0.06-0.05,0.13-0.1,0.19-0.15c0.05-0.04,0.09-0.07,0.14-0.11
		c0.07-0.05,0.13-0.1,0.2-0.15c0.04-0.03,0.09-0.06,0.13-0.1c0.07-0.05,0.14-0.1,0.21-0.14c0.04-0.03,0.08-0.05,0.12-0.08
		c0.08-0.05,0.16-0.1,0.23-0.15c0.03-0.02,0.06-0.04,0.08-0.06c0.1-0.07,0.21-0.13,0.31-0.19c-1.37-1.77-1.89-1.79-2.28-1.4
		c-0.43,0.43-1.23,0.29-2.17,0.72c-0.94,0.43-2.03,1.01-2.9,1.01s-1.67-0.07-2.82-0.87c-1.16-0.8-2.61,0.65-3.76,1.3
		s-2.53,1.3-3.33,1.81s-4.05,0.51-5.5,0.65s-3.11,0.29-3.98-0.87s-2.9-0.58-3.98-0.51c-1.09,0.07-2.03,0.87-2.9,0.87
		s-0.72,0.94-2.24,1.09c-1.52,0.14-1.3-0.51-2.39-1.38s-2.24,0-2.53,0.87s0,1.59,0.51,2.32s-0.22,0.8-1.3,1.01
		c-1.09,0.22-1.01,0.94-1.16,2.1s-2.03,0.94-2.53,0.58c-0.12-0.08-0.3-0.19-0.51-0.3c-0.45,0.84-1.06,1.54-0.32,3.01
		c0.93,1.87,0,1.87,0.62,3.89c0.38,1.23,0.24,1.71-0.07,2.39c0.7,0.14,1.81,0.43,2.28,0.93
		C83.198,378.288,84.118,378.558,84.498,379.048z"
          pointer-events="auto"
        ></path>

        <path
          id="SA"
          title="Arabie-saoudite"
          capital="Riyad"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M123.938,511.928c-0.05,0.5-0.99,0.03-1.46-0.4c-0.48-0.43-1.11-0.71-1.67-0.91s-0.78-0.83-0.53-1.04s0.76-0.2,0.81-0.45
		c0.05-0.25-0.3-1.39,0.08-1.44s1.16,0.88,1.19,1.39c0.03,0.5,0.5,0.93,0.5,0.93C124.288,511.068,123.988,511.418,123.938,511.928z
		 M217.168,473.008c-0.22-0.94-2.24-2.68-2.46-3.48c-0.02-0.09-0.05-0.18-0.08-0.27c-0.02-0.08-0.05-0.17-0.07-0.26
		c0-0.02-0.01-0.03-0.01-0.05c-0.02-0.09-0.05-0.19-0.07-0.3c0-0.01,0-0.02-0.01-0.03c-0.02-0.11-0.04-0.22-0.05-0.35l0,0
		c-2.42-0.17-7.25-0.49-8.4-0.49c-1.45,0-2.53-1.01-3.98-1.38s-2.97,0.14-4.78,0.36s-2.53-1.45-3.04-2.1s-1.88-2.32-2.61-3.26
		c-0.36-0.47-0.52-1.55-0.6-2.51c-0.58-0.59-1-1.74-1.64-2.37c-1.09-1.09-1.71-1.87-1.4-2.96c0.02-0.06,0.03-0.12,0.04-0.18
		c-0.62,0.09-0.89,0.4-1.62,0.68c-0.87,0.33-1.14,0.05-1.63-0.11s-1.03-1.3-1.14-1.74c-0.11-0.43-0.6-0.65-0.92-0.92
		c-0.16-0.13-0.2-0.48-0.2-0.8c-0.3-0.05-0.59-0.36-0.75-0.97c-0.47-1.71-0.93-3.11-2.8-4.98c-1.87-1.87,0-3.74-0.47-6.38
		c-0.47-2.65-2.34-3.11-3.58-3.58s-2.18-1.71-3.89-2.65c-1.71-0.93-2.02-3.58-2.34-5.91c-0.13-0.96-0.44-1.48-0.77-1.86
		c-0.53,0.83-1.5,0.83-2.79,0.83c-1.74,0-3.33,0.14-3.76-2.03s-4.05-2.46-5.94-2.46c-0.1,0-0.21,0-0.32,0c-0.05,0-0.1,0-0.15,0
		c-0.04,0-0.09,0-0.14,0c-0.04,0-0.09,0-0.13,0c-0.09,0-0.18,0-0.28,0c-0.05,0-0.11,0-0.16,0c-0.02,0-0.04,0-0.06,0s-0.05,0-0.07,0
		c-0.13,0-0.27,0-0.42,0c-0.01,0-0.02,0-0.03,0c-0.16,0-0.33,0-0.51,0c-0.03,0-0.05,0-0.08,0c-0.18,0-0.36,0-0.54-0.01
		c-0.02,0-0.04,0-0.06,0c-0.38,0-0.79-0.01-1.21-0.02c-0.03,0-0.06,0-0.09,0c-0.2,0-0.41-0.01-0.62-0.01c-0.03,0-0.06,0-0.09,0
		c-0.21,0-0.43-0.01-0.64-0.02h-0.01c-0.21-0.01-0.43-0.01-0.64-0.02c-0.04,0-0.08,0-0.12,0c-0.21-0.01-0.42-0.01-0.63-0.02
		c-0.04,0-0.07,0-0.11,0c-0.21-0.01-0.42-0.02-0.63-0.03c-0.01,0-0.02,0-0.03,0c-0.21-0.01-0.42-0.02-0.63-0.03
		c-0.04,0-0.08,0-0.12-0.01c-0.2-0.01-0.4-0.02-0.6-0.03c-0.04,0-0.08-0.01-0.12-0.01c-0.2-0.01-0.4-0.03-0.59-0.04
		c-0.01,0-0.02,0-0.03,0c-0.2-0.01-0.39-0.03-0.58-0.05c-0.04,0-0.08-0.01-0.12-0.01c-0.18-0.02-0.36-0.03-0.54-0.05
		c-0.03,0-0.07-0.01-0.1-0.01c-0.18-0.02-0.36-0.04-0.53-0.06c-3.62-0.43-5.07-2.17-6.66-3.62c-1.59-1.45-7.38-6.23-9.12-7.38
		s-5.21-4.63-7.24-5.21s-3.62-0.87-6.51-3.62c-2.9-2.75-6.37-1.88-8.98-2.61c-2.61-0.72-3.62-0.29-4.63,0.58
		c-1.01,0.87-3.47,1.01-5.79,1.45c-2.32,0.43-3.33,1.74-3.76,3.76c-0.43,2.02,0.58,2.46,1.59,3.04c1.01,0.58,1.01,1.16,1.59,1.88
		s0.43,1.88-0.43,2.03c-0.87,0.14-1.3,0.87-1.45,2.32c-0.14,1.45-2.46,1.16-4.49,1.16s-4.05,4.05-5.65,5.36
		c-1.59,1.3-2.17,0.43-3.76-0.43c-1.17-0.64-3.58-0.65-4.78-0.61l0,0c-0.02,0.09-0.05,0.19-0.08,0.3c0,0.02-0.01,0.04-0.02,0.05
		c-0.03,0.09-0.06,0.19-0.09,0.3c-0.02,0.05-0.04,0.11-0.06,0.17c-0.03,0.08-0.06,0.16-0.09,0.24c-0.06,0.15-0.12,0.32-0.19,0.5
		c0.6,0.09,0.11,1.47,0.5,3.18c0.47,2.02-0.78,1.71-0.93,3.58s0.31,1.09,2.02,1.09s2.49,2.49,4.05,4.83
		c1.56,2.33,2.49,4.05,3.89,6.85c1.4,2.8,3.89,5.14,5.45,7.47s1.56,1.56,1.56,3.74s0.31,2.02,1.4,4.2c1.09,2.18,1.56,2.18,3.89,2.65
		s3.27,2.18,4.2,4.2c0.93,2.02,2.18,3.27,2.96,4.98s1.09,3.11,0.31,4.52c-0.78,1.4-0.31,2.02,0.31,3.73
		c0.62,1.71,0.16,2.64,0.47,3.74c0.31,1.09,1.71,3.42,2.65,4.82c0.93,1.4,1.56,1.4,3.89,1.87s3.58,2.65,4.82,4.36
		s2.49,4.36,2.96,6.69s3.42,6.23,5.45,7.31c2.02,1.09,1.87,1.71,2.18,3.27s0.62,2.18,2.18,3.89c0.49,0.54,0.75,1.22,0.86,2.03
		c1.11-0.23,2.65-0.68,2.61-1.38c-0.05-1.09-1.52-2.12-0.71-2.82s1.36-1.03,1.41-2.12s0.87-1.47,1.74-1.25
		c0.87,0.22,1.09,0.16,1.36,0.76s0.71,0.65,1.47,0.65s1.41,0.16,1.9-0.33c0.49-0.49,1.63-1.03,2.34-0.38
		c0.71,0.65,9.81,1.81,12.13,3.69s3.76,10.13,6.8,3.76c3.04-6.37,2.9-7.09,7.53-9.41s25-8.78,25-8.78s0.07-0.03,0.2-0.04l-0.01-0.02
		c0,0,9.7-3.76,11.29-4.13c1.59-0.36,9.27-2.53,9.85-4.63s0.94-3.98,1.59-4.7s0.51-4.05,1.52-4.78
		C217.168,476.118,217.388,473.948,217.168,473.008z"
          pointer-events="auto"
        ></path>

        <path
          id="OM"
          title="Oman"
          capital="Mascate"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M214.858,465.328c-0.05,0.16-0.1,0.31-0.14,0.46c-0.01,0.05-0.03,0.1-0.04,0.15c-0.03,0.09-0.05,0.19-0.07,0.28
		c-0.01,0.05-0.02,0.11-0.04,0.16c-0.02,0.08-0.03,0.16-0.05,0.24c-0.01,0.05-0.02,0.1-0.03,0.15c-0.01,0.07-0.02,0.15-0.03,0.22
		c-0.01,0.05-0.01,0.09-0.02,0.13c-0.01,0.07-0.01,0.14-0.02,0.21c0,0.04-0.01,0.08-0.01,0.11c0,0.07-0.01,0.15-0.01,0.22
		c0,0.03,0,0.05,0,0.08c0,0.09,0,0.18,0.01,0.26v0.01c0,0.09,0.01,0.18,0.02,0.27l0,0c0.01,0.12,0.03,0.24,0.05,0.35
		c0,0.01,0,0.02,0.01,0.03c0.02,0.1,0.04,0.2,0.07,0.3c0,0.02,0.01,0.03,0.01,0.05c0.02,0.09,0.05,0.18,0.07,0.26
		c0.03,0.09,0.05,0.18,0.08,0.27c0.22,0.8,2.24,2.53,2.46,3.48s0,3.11-1.01,3.84c-1.01,0.72-0.87,4.05-1.52,4.78
		c-0.65,0.72-1.01,2.61-1.59,4.7c-0.58,2.1-8.25,4.27-9.85,4.63c-1.59,0.36-11.29,4.13-11.29,4.13l7.75,16.44
		c0.71-0.17,1.43-0.37,2.18-0.82c1.56-0.93,3.11-0.31,4.51-1.87s3.27,0.31,5.14,0.62s3.11-1.87,3.27-4.05s1.87-2.8,4.36-2.8
		s3.89-1.4,4.05-3.11s2.18-1.87,2.96-3.58c0.78-1.71,3.58-0.78,5.91-0.93c2.34-0.15,0.93-3.11-0.31-4.51
		c-1.25-1.4,0.62-1.56,0.31-2.8c-0.31-1.25,0.93-2.02,3.42-2.02s2.49-0.93,2.65-2.96c0.16-2.02,1.25-1.71,2.8-3.27
		c1.56-1.56,2.65-3.27,3.42-5.29c0.78-2.02,3.11-3.89,1.87-4.67s-3.11-1.71-5.14-5.45c-2.03-3.74-4.67-3.11-6.69-3.11
		s-4.36-0.47-5.45-1.71c-1.09-1.25-2.96-1.56-5.14-2.65s0.47-2.96-0.78-4.82c-0.23-0.34-0.37-0.77-0.47-1.26
		c-0.79,0.53-1.31,1.05-1.31,1.05S215.648,462.938,214.858,465.328z"
          pointer-events="auto"
        ></path>

        <path
          id="YE"
          title="Yémen"
          capital="Sanaa"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M191.918,495.098l7.74,16.42c-0.76,0.18-1.53,0.33-2.33,0.74c-1.56,0.78-2.96,0.93-3.43,2.33c-0.47,1.4,0,4.36-2.65,4.82
		s-2.65,2.49-6.54,3.27s-4.52-0.31-7.16,1.4c-2.65,1.71-4.05,0.16-5.91,2.96c-1.87,2.8-4.2,3.42-6.38,2.8
		c-2.18-0.62-3.27,0.93-4.67,2.02s-3.11,1.56-5.14,1.87s-7.63,0.31-8.4,1.56s-1.87,3.42-3.89,3.58c-2.02,0.16-2.8-0.78-3.89,0.47
		s-3.58,1.09-4.82,0.31c-1.25-0.78-3.43-2.34-3.11-4.83c0.31-2.49-0.15-5.45-1.71-8.4c-1.56-2.96-2.49-3.11-2.03-7
		c0.32-2.66,0.78-4.95,0.55-6.69c1.12-0.23,2.65-0.68,2.61-1.38c-0.05-1.09-1.52-2.12-0.71-2.82s1.36-1.03,1.41-2.12
		s0.87-1.47,1.74-1.25c0.87,0.22,1.09,0.16,1.36,0.76s0.71,0.65,1.47,0.65s1.41,0.16,1.9-0.33c0.49-0.49,1.63-1.03,2.34-0.38
		c0.71,0.65,9.81,1.81,12.13,3.69s3.76,10.13,6.8,3.76c3.04-6.37,2.9-7.09,7.53-9.41s25-8.78,25-8.78
		S191.788,495.098,191.918,495.098z M209.248,540.038c-0.87-0.29-1.48-0.83-2.46-0.9c-0.98-0.07-1.49,0.07-2.75,0.04
		c0,0-1.38-0.25-1.77,0.33c-0.4,0.58-1.59,0.36-1.41,1.19s1.16,0.4,1.45,0.9c0.29,0.51,0.9,0.72,1.74,0.8
		c0.84,0.08,0.83-0.47,1.63-0.51c0.8-0.04,1.48,0.18,1.81-0.33s0.62-0.72,1.27-0.72
		C209.388,540.828,210.118,540.328,209.248,540.038z M192.598,542.028c0,0-1.74-0.22-1.88,0.11c-0.15,0.33,0.4,0.18,0.72,0.72
		c0.33,0.54,0.69,0.8,1.23,0.8s1.74,0.22,1.77-0.36C194.478,542.718,193.828,542.168,192.598,542.028z"
          pointer-events="auto"
        ></path>

        <path
          id="IR"
          title="Iran"
          capital="Téhéran"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M259.588,444.408c0.14-1.01,1.52-1.45,2.9-3.62c1.38-2.17,5.72-1.74,6.73-2.03s0.72-1.74,1.38-2.97c0.65-1.23-1.52-1.01-2.75-1.74
		c-1.23-0.72-0.72-2.75-0.72-5.14s-2.46-2.9-4.49-3.4s-3.98-3.26-4.34-4.42s-1.01-3.04-3.33-4.92c-0.09-0.07-0.16-0.14-0.23-0.2
		c-0.02-0.02-0.04-0.04-0.06-0.06c-0.05-0.05-0.1-0.1-0.14-0.15c-0.02-0.02-0.04-0.04-0.05-0.06c-0.05-0.05-0.09-0.11-0.13-0.16
		c-0.01-0.01-0.02-0.02-0.02-0.03c-0.05-0.07-0.09-0.14-0.12-0.2c0-0.01-0.01-0.01-0.01-0.02c-0.03-0.06-0.06-0.12-0.08-0.18
		c0-0.01-0.01-0.02-0.01-0.04c-0.02-0.06-0.04-0.11-0.05-0.17c0-0.01,0-0.02-0.01-0.02c-0.03-0.13-0.03-0.26-0.03-0.38l0,0l0,0
		c0.01-0.06,0.01-0.13,0.03-0.19v-0.01c0.18-0.82,1.04-1.47,1.5-1.85l0.01-0.01c0.01-0.01,0.03-0.02,0.04-0.03
		c0.02-0.02,0.04-0.03,0.06-0.05c0.01-0.01,0.02-0.02,0.03-0.02c0.03-0.02,0.05-0.04,0.07-0.06c0.51-0.51,1.45-1.38,1.95-2.17
		c0.51-0.8,1.67-1.38,2.32-2.17c0.65-0.8,0.87-2.24,0.51-2.97s-1.23-1.88-2.32-2.1s-3.11-0.14-3.98-0.36s-1.16-1.81-1.16-2.68
		s0.65-1.38,0.65-3.69s-0.29-1.74-0.94-2.03c-0.65-0.29-0.15-1.95-0.8-2.17c-0.65-0.22-0.36-1.09-0.36-2.03s0.36-1.01,1.96-2.39
		c1.59-1.38-0.43-1.16-1.45-1.38c-1.01-0.22-0.51-2.32-0.51-3.48s0.43-1.81,1.52-1.88s0.43-1.09,0.43-1.74s0.36-1.45,1.3-2.1
		s0.36-1.95,0.36-2.75s0.36-1.09,0.87-2.24c0.04-0.1,0.08-0.21,0.1-0.33c0.01-0.03,0.01-0.05,0.02-0.08
		c0.02-0.12,0.04-0.23,0.05-0.36c0-0.01,0-0.02,0-0.02c0.01-0.12,0.01-0.25,0.01-0.38c0-0.02,0-0.04,0-0.05
		c0-0.14-0.01-0.28-0.02-0.42c-0.01-0.11-0.02-0.23-0.04-0.34c0-0.01,0-0.02,0-0.03c-0.01-0.11-0.03-0.21-0.05-0.31
		c0-0.01,0-0.01,0-0.02c-0.09-0.55-0.21-1.02-0.27-1.26c-0.01-0.04-0.02-0.08-0.02-0.11c-0.07-0.36,0.22-1.52,0.43-2.17
		c0.22-0.65-0.07-1.09-0.58-1.74s-0.22-1.09-0.22-2.03s-3.55-0.22-4.78-0.22s-2.61-1.59-3.26-2.68s-0.72-1.45-2.61-1.45
		s-2.1-1.67-2.75-2.82s-3.84-1.23-5.43-1.23s-2.32-0.94-3.33-1.3s-2.97-0.51-3.33-1.01c-0.36-0.51-1.96-2.03-2.82-2.03
		c-0.87,0-1.09-0.43-2.1-0.8c-1.01-0.36-2.39,0.36-3.04,1.23c-0.65,0.87-2.32,0.72-4.13,0.43s-3.04,0-3.26,0.58
		s-1.01,0.58-2.17,1.23s-1.74,1.67-1.74,2.46s-0.51,1.16-1.81,1.09s-1.38,0.94-2.75,0.8c-0.47-0.05-0.9-0.11-1.26-0.16
		c0.03,1.03,0.6,0.99,0.86,1.62c0,0.01,0.01,0.01,0.01,0.02c0.02,0.04,0.03,0.09,0.04,0.14c0,0.02,0.01,0.03,0.01,0.05
		c0.01,0.05,0.02,0.1,0.03,0.15c0,0.02,0.01,0.05,0.01,0.07c0.01,0.05,0.01,0.12,0.01,0.18c0,0.03,0.01,0.06,0.01,0.09
		c0,0.1,0,0.2-0.01,0.31c-0.11,1.85-3.04,0.65-4.89,0.65s-1.85,0.76-3.58,1.19c-1.74,0.43-2.5,0.43-3.48,0.98
		c-0.98,0.54-3.15,0.76-4.56-0.22s-2.93-0.65-4.45-1.41s-4.34-2.82-4.89-4.02c-0.55-1.2-1.74-1.3-3.37-1.52
		c-1.63-0.22-2.39-0.22-3.69-0.87s-1.85-2.39-1.85-4.23c0-0.81-0.13-1.35-0.29-1.81c-1.25,0.15-2.44,0.15-2.82-0.33
		c-0.58-0.72-1.16-0.72-1.74-1.23s-1.38-0.58-1.74-1.16c-0.36-0.58-0.15-1.52,0.94-1.88c1.09-0.36,0.72-1.67,0.15-2.32
		s0.22-0.94,0.22-2.17s-0.87-1.3-2.32-1.16c-1.45,0.14-1.45,1.23-2.68,1.23s-1.52,0.72-2.82,1.81s-1.81,1.67-3.4,2.9
		c-0.08,0.06-0.16,0.11-0.23,0.17l0,0c-0.09,0.06-0.18,0.12-0.28,0.17l-0.01,0.01c-0.09,0.05-0.18,0.1-0.27,0.14
		c-0.01,0-0.01,0.01-0.02,0.01c-0.09,0.04-0.18,0.08-0.27,0.11c-0.01,0-0.01,0.01-0.02,0.01c-0.09,0.03-0.18,0.06-0.27,0.09
		c-0.01,0-0.01,0-0.02,0.01c-0.09,0.03-0.18,0.05-0.26,0.07c-0.01,0-0.01,0-0.02,0.01c-0.09,0.02-0.18,0.04-0.26,0.05
		c-0.01,0-0.01,0-0.02,0c-0.09,0.01-0.18,0.02-0.26,0.03c-0.01,0-0.01,0-0.02,0c-0.09,0.01-0.18,0.02-0.27,0.02h-0.01
		c-0.09,0-0.18,0.01-0.27,0.01l0,0c-0.54,0-1.07-0.05-1.56-0.09c-0.01,0-0.01,0-0.02,0c-0.09-0.01-0.17-0.01-0.26-0.02
		c-1.38-0.07-1.96-0.65-3.4-1.01c-1.45-0.36-0.58-1.3-1.59-1.45c-1.01-0.14-2.17-1.88-2.17-3.11c0-1.23-0.43-1.38-1.3-1.81
		c-0.1-0.05-0.21-0.09-0.31-0.12c-0.07-0.02-0.15-0.02-0.22-0.03c-0.02,0-0.05-0.01-0.07-0.01c-0.1,0-0.19,0.01-0.28,0.03l0,0
		c-0.09,0.02-0.18,0.05-0.26,0.09c-0.37,0.18-0.66,0.55-0.81,1.04c-0.29,0.94-0.22,1.45-1.96,1.67s-0.58,2.24,0.15,3.69
		s0.72,1.23,0.72,2.39s0.15,1.45,0.94,2.82c0.8,1.38-1.09,1.81-1.3,3.04s2.1,1.81,2.17,2.9c0.07,1.09,0.29,1.01,1.09,1.67
		c0.15,0.12,0.26,0.24,0.37,0.36c0.23-0.41,0.42-0.76,0.42-0.76s-0.2,0.35-0.42,0.76c0.49,0.55,0.64,1.14,0.64,2.03
		c0,1.09,0.87,3.04,1.45,4.49c0.58,1.45,1.45,1.59,1.52,2.68s0,1.74,1.45,1.74s1.81,1.01,3.76,1.01s1.88,1.23,1.09,1.45
		c-0.8,0.22-1.38-0.07-1.45,1.3s0.51,0.43,1.16,1.67c0.65,1.23,0.65,1.3-1.52,1.74s-1.16,2.46-2.32,3.33s-0.15,2.39-0.87,4.05
		c-0.72,1.67,0.22,1.38,0.65,2.32s0.72,1.38,2.75,2.61c2.03,1.23,0.94,1.74,0.72,3.11s1.38,1.09,2.9,1.45
		c1.52,0.36,2.17,1.95,4.34,2.75s2.24,2.97,3.62,3.69c1.38,0.72,1.45,1.45,1.52,2.61s-0.29,1.23-1.09,1.38
		c-0.8,0.14-0.51,3.48,1.01,3.98c1.52,0.51,1.3,1.3,1.09,2.1c-0.22,0.8-0.43,1.74,1.23,2.46c1.66,0.72,1.38,1.52,2.24,2.17
		c0.42,0.32,0.89,0.93,1.26,1.49l0,0c1.71,0.16,1.71-0.78,1.71-2.02s1.56-0.93,2.18,0.31s2.34,1.4,4.05,0.16s2.96,0.15,3.74,2.8
		s1.56,2.8,3.11,4.51c1.56,1.71,1.24,4.67,2.18,6.54c0.93,1.87,5.29,3.89,8.09,4.67s1.71,2.96,4.67,3.89c2.96,0.93,4.98,2.65,7,3.42
		s4.05,1.25,5.6,0.78c1.56-0.47,2.65-0.93,4.67-0.62s3.58-0.62,4.82-2.49c1.25-1.87,2.8-1.56,4.67-0.31s1.71,3.42,2.02,5.14
		s0.31,1.71,1.09,3.42c0.78,1.71,4.36,2.65,7.16,2.65s3.89,0.47,5.29,1.4s6.07,0,8.72,0.16s4.51,1.4,8.09,1.87
		c0.38,0.05,0.7,0.09,0.99,0.12c0.04-0.42,0.08-0.89,0.08-1.25C258.938,448.318,259.448,445.428,259.588,444.408z"
          pointer-events="auto"
        ></path>

        <path
          id="IQ"
          title="Irak"
          capital="Bagdad"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M167.728,412.558c-0.87-0.65-0.58-1.45-2.24-2.17c-1.66-0.73-1.45-1.67-1.23-2.46c0.22-0.8,0.43-1.59-1.09-2.1
		c-1.52-0.51-1.81-3.84-1.01-3.98s1.16-0.22,1.09-1.38s-0.15-1.88-1.52-2.61s-1.45-2.9-3.62-3.69s-2.82-2.39-4.34-2.75
		s-3.11-0.07-2.9-1.45c0.22-1.38,1.3-1.88-0.72-3.11c-2.03-1.23-2.32-1.67-2.75-2.61c-0.43-0.94-1.38-0.65-0.65-2.32
		c0.72-1.67-0.29-3.18,0.87-4.05c1.16-0.87,0.15-2.9,2.32-3.33c2.17-0.43,2.17-0.51,1.52-1.74s-1.23-0.29-1.16-1.67
		c0.07-1.38,0.65-1.09,1.45-1.3c0.8-0.22,0.87-1.45-1.09-1.45s-2.32-1.01-3.76-1.01c-1.45,0-1.38-0.65-1.45-1.74
		c-0.07-1.09-0.94-1.23-1.52-2.68c-0.58-1.45-1.45-3.4-1.45-4.49c0-0.89-0.15-1.48-0.64-2.03c-0.26,0.46-0.55,1.01-0.63,1.2
		c-0.15,0.36-1.23,0.47-1.92,1.05s-1.56-1.05-2.03-1.77s-1.77-0.11-2.64-0.07s-1.92-0.14-2.28-0.62s-1.63-0.65-3.08-0.61
		s-1.52,0.87-2.03,1.59c-0.22,0.32-0.78,0.65-1.45,1.08c-0.1,0.06-0.2,0.13-0.31,0.19c-0.03,0.02-0.06,0.04-0.08,0.06
		c-0.08,0.05-0.16,0.1-0.23,0.15c-0.04,0.03-0.08,0.05-0.12,0.08c-0.07,0.05-0.14,0.09-0.21,0.14c-0.04,0.03-0.09,0.06-0.13,0.1
		c-0.07,0.05-0.13,0.1-0.2,0.15c-0.05,0.04-0.09,0.07-0.14,0.11c-0.06,0.05-0.13,0.1-0.19,0.15c-0.05,0.04-0.09,0.08-0.14,0.12
		c-0.06,0.05-0.13,0.11-0.19,0.17c-0.04,0.04-0.09,0.08-0.13,0.12c-0.07,0.06-0.13,0.13-0.2,0.2c-0.04,0.04-0.07,0.07-0.11,0.11
		c-0.1,0.11-0.2,0.22-0.29,0.33c-1.41,1.74-2.17,1.63-3.69,2.06c-1.52,0.43-1.74,2.61-1.85,3.91c-0.11,1.3,0.22,1.95,0.65,2.93
		c0.43,0.98,0,1.19-0.54,2.71s-0.98,2.28-0.76,4.34s-2.61,3.91-5.43,5.65s-7.28,3.15-10.42,5.32c-0.51,0.35-1.06,0.71-1.63,1.07
		c2.49,2.72,4.46,5.57,5.62,9.15c0.05,0.01,0.09,0.02,0.14,0.03c2.61,0.72,6.08-0.14,8.98,2.61s4.49,3.04,6.51,3.62
		c2.03,0.58,5.5,4.05,7.24,5.21c1.74,1.16,7.53,5.94,9.12,7.38c1.59,1.45,3.04,3.18,6.66,3.62c0.17,0.02,0.35,0.04,0.53,0.06
		c0.03,0,0.07,0.01,0.1,0.01c0.18,0.02,0.35,0.03,0.54,0.05c0.04,0,0.08,0.01,0.12,0.01c0.19,0.02,0.38,0.03,0.58,0.05
		c0.01,0,0.02,0,0.03,0c0.19,0.01,0.39,0.03,0.59,0.04c0.04,0,0.08,0.01,0.12,0.01c0.2,0.01,0.4,0.02,0.6,0.03
		c0.04,0,0.08,0,0.12,0.01c0.21,0.01,0.42,0.02,0.63,0.03c0.01,0,0.02,0,0.03,0c0.21,0.01,0.42,0.02,0.63,0.03c0.04,0,0.07,0,0.11,0
		c0.21,0.01,0.42,0.02,0.63,0.02c0.04,0,0.08,0,0.12,0c0.22,0.01,0.43,0.01,0.64,0.02h0.01c0.21,0.01,0.43,0.01,0.64,0.02
		c0.03,0,0.06,0,0.09,0c0.21,0,0.41,0.01,0.62,0.01c0.03,0,0.06,0,0.09,0c0.42,0.01,0.82,0.01,1.21,0.02c0.02,0,0.04,0,0.06,0
		c0.19,0,0.37,0,0.54,0.01c0.03,0,0.05,0,0.08,0c0.18,0,0.35,0,0.51,0c0.01,0,0.02,0,0.03,0c0.15,0,0.28,0,0.42,0
		c0.02,0,0.05,0,0.07,0c0.34-0.91,0.89-2.15,1.47-2.46c0.94-0.51,1.59-1.67,2.24-3.26c0.65-1.59,1.74-2.24,3.18-2.03
		c1.45,0.22,2.39,0.14,3.04,0.65c0.3,0.23,0.75,0.5,1.15,0.71c0.69-0.42,2.4-0.21,3.73-0.09
		C168.618,413.498,168.148,412.878,167.728,412.558z"
          pointer-events="auto"
        ></path>

        <path
          id="JO"
          title="Jordanie"
          capital="Amman"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M90.038,394.358c-3.2,2.39-5.32,1.14-6.3,0.76s-2.28-2.06-3.42-2.55s-1.25-1.9-1.57-2.17c-0.16-0.13-0.38-0.3-0.57-0.45
		c0,0.01,0,0.02,0.01,0.02c0.03,0.09,0.07,0.18,0.1,0.27c0.01,0.03,0.02,0.06,0.03,0.09c0.03,0.08,0.05,0.16,0.07,0.24
		c0.02,0.06,0.03,0.12,0.04,0.17c0.01,0.06,0.03,0.11,0.04,0.17c0.03,0.12,0.04,0.23,0.06,0.35c0.11,0.92-0.43,3.31-0.6,3.96
		s-0.16,1.41-0.06,1.85c0.11,0.43,0.11,2.66,0.11,3.26s-0.49,2.06-0.81,3.37c-0.33,1.3-0.43,2.12-0.81,2.88s-0.54,1.3-0.6,2.12
		c-0.06,0.81-0.71,3.09-0.71,3.96c0,0.77-0.69,2.71-1.72,3.43c0,0.03,0,0.07,0,0.1c0,0.05,0,0.1,0.01,0.15c0,0.01,0,0.02,0.01,0.04
		c0,0.03,0.01,0.07,0.02,0.1c0,0.01,0,0.02,0.01,0.03c0.13,0.43,0.5,0.56,0.24,1.68l0,0c1.2-0.04,3.62-0.02,4.79,0.61
		c1.59,0.87,2.17,1.74,3.76,0.43c1.59-1.31,3.62-5.36,5.65-5.36s4.34,0.29,4.49-1.16c0.14-1.45,0.58-2.17,1.45-2.32
		c0.87-0.14,1.01-1.3,0.43-2.03c-0.58-0.72-0.58-1.3-1.59-1.88c-1.01-0.58-2.03-1.01-1.59-3.04s1.45-3.33,3.76-3.76
		c2.31-0.43,4.78-0.58,5.79-1.45c0.99-0.85,2-1.28,4.49-0.61c-1.16-3.57-3.12-6.43-5.62-9.15
		C96.438,390.348,92.728,392.348,90.038,394.358z"
          pointer-events="auto"
        ></path>

        <path
          id="AE"
          title="Émirats-arabes-unis"
          capital="Abou Dabi"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M194.228,464.678c0.51,0.65,1.23,2.32,3.04,2.1s3.33-0.72,4.78-0.36s2.53,1.38,3.98,1.38c1.15,0,5.98,0.32,8.4,0.49l0,0
		c-0.01-0.08-0.02-0.17-0.02-0.27v-0.01c0-0.08-0.01-0.17-0.01-0.26c0-0.03,0-0.05,0-0.08c0-0.07,0-0.14,0.01-0.22
		c0-0.04,0.01-0.08,0.01-0.11c0.01-0.07,0.01-0.14,0.02-0.21c0.01-0.04,0.01-0.09,0.02-0.13c0.01-0.07,0.02-0.14,0.03-0.22
		c0.01-0.05,0.02-0.1,0.03-0.15c0.01-0.08,0.03-0.16,0.05-0.24c0.01-0.05,0.02-0.11,0.04-0.16c0.02-0.09,0.05-0.18,0.07-0.28
		c0.01-0.05,0.02-0.1,0.04-0.15c0.04-0.15,0.09-0.3,0.14-0.46c0.8-2.39,6.37-13.83,6.37-13.83s0.51-0.52,1.31-1.05
		c-0.22-1.15-0.14-2.64-0.05-4.14c-1.22,1.01-0.95-0.22-2.05-1.39c-0.49-0.52-0.81-1.09-1.02-1.58c-0.53,1.67-1.53,1.71-2.79,3.24
		c-1.4,1.71-2.49,1.4-3.42,3.42c-0.93,2.02-3.43,3.27-4.98,5.45c-1.56,2.18-7.32,2.18-10.27,1.71c-2.96-0.47-3.58,0.31-5.29,1.71
		c-0.72,0.59-1.21,0.46-1.63,0.04c0.07,0.96,0.24,2.04,0.6,2.51C192.338,462.368,193.718,464.028,194.228,464.678z"
          pointer-events="auto"
        ></path>

        <path
          id="KZ"
          title="Kazakhstan"
          capital="Noursoultan"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M434.348,255.518c-0.01-0.04-0.01-0.08-0.02-0.12c-0.01-0.02-0.01-0.04-0.02-0.06c-0.01-0.04-0.03-0.07-0.05-0.11
		c-0.01-0.02-0.02-0.04-0.03-0.06c-0.02-0.03-0.05-0.07-0.07-0.1c-0.01-0.02-0.03-0.04-0.05-0.05c-0.03-0.03-0.07-0.07-0.11-0.1
		c-0.02-0.01-0.03-0.03-0.05-0.04c-0.06-0.04-0.12-0.09-0.2-0.13c-1.09-0.58-1.52-0.87-1.52-1.88c0-1.01-1.09-2.39-2.17-1.01
		s-3.91,2.1-5.65,1.38s-2.82-0.8-3.62-2.39s0.22-3.11-2.03-3.33c-2.24-0.22-3.69-0.07-4.42-2.03s-2.17-4.56-3.84-4.99
		c-1.67-0.43-3.4-0.36-4.85,0.58s-2.97,0.65-3.55,1.52c-0.58,0.87-1.38,0.65-2.39-0.15c-1.01-0.8-2.24-0.72-2.82-0.65
		s-1.09-0.14-1.59-1.01c-0.51-0.87-1.95,0.94-1.95-1.01s-1.38-2.53-2.24-2.75c-0.87-0.22-2.17,1.01-2.24,2.1s-2.03,4.78-3.91,2.17
		s-7.09-13.68-9.7-16.29c-2.61-2.61-5.57-7.53-8.54-9.34s-6.01-2.97-5.14-4.27s2.53-1.67,2.53-2.82c0-1.16-1.3-0.51-2.82-0.29
		c-1.52,0.22-2.97,0.8-3.55,1.52s-0.71,0.83-2.01,0.83c-1.3,0-2.23-0.16-2.23,0.65s-0.27,1.85-1.68,1.96s-2.44-0.22-2.71,0.27
		s-1.14,0.87-1.25,1.47s0.22,2.61-0.76,1.85s-1.74-0.98-2.39-1.3c-0.65-0.33-1.79-0.11-2.28,0.05s-1.36,2.71-2.5,1.74
		c-1.14-0.98-1.85-2.17-0.65-2.61c1.2-0.43,1.3-0.65,1.68-1.41c0.38-0.76,1.52-1.14,0.87-1.85s-1.3-0.49-1.95,0.43
		c-0.65,0.92-1.9,0.87-2.66,0.16c-0.76-0.71-1.41-2.17-1.85-1.19c-0.43,0.98-1.36,2.23-1.68,1.09c-0.33-1.14-0.92-1.79-1.41-2.17
		s-0.33-2.17-1.3-1.19c-0.98,0.98-1.74,1.03-2.28,1.03s-0.65,1.95-1.9,1.19s-4.34-1.3-3.53-1.74s1.25-1.14,1.19-2.12
		s-0.16-0.6-0.76-1.63s-0.76-1.52-1.03-3.04c-0.27-1.52-0.76-2.34-1.57-2.66s-1.79-0.49-2.01,0.22s-0.54,1.74-2.12,0.98
		c-1.57-0.76-1.57-1.3-3.91-2.06c-2.33-0.76-3.58-0.11-4.78,0.76c-1.19,0.87-3.2-0.49-3.96,1.68s-2.28,1.95-4.56,2.17
		s-9.88,2.06-11.62,2.28s-3.37,0.33-4.23,1.52s-2.71,1.3-4.78,1.3c-2.06,0-4.13-0.22-4.89,0.65s-3.04,0.22-4.45,1.19
		c-1.41,0.98-2.28,0.98-4.23,1.3s-3.26-1.09-4.23-0.11c-0.98,0.98-2.28,0.87-3.04,0.33s-1.92-0.43-2.39-0.14s-2.28,0.07-1.81,1.12
		c0.47,1.05,0.54,2.06,0.33,2.57c-0.22,0.51-0.11,0.98,0.58,1.27c0.69,0.29,1.7,0.18,1.66,1.16c-0.04,0.98-0.22,1.56,1.3,1.77
		c1.52,0.22,3,0.11,3.18,0.58s-0.07,2.21-1.59,1.88s-4.85-1.23-5.43-0.65c-0.58,0.58-0.43,1.63-1.19,1.92
		c-0.76,0.29-0.9,1.2,0.18,1.92c1.09,0.72,1.52,2.24,0.29,3.04c-1.23,0.8-1.77,1.05-2.75,1.34s-1.74,0.65-2.21,0.72
		s-0.8,1.09,0.54,1.59c1.34,0.51,1.45,2.24,2.53,2.28c1.09,0.04,1.99-0.11,2.5,0.33c0.51,0.43,0.51,0.9,1.74,1.16
		c1.23,0.25,2.46,1.56,2.9,1.92c0.43,0.36,0.51,1.27-0.65,2.1s-1.48,2.5-2.32,2.5s-1.34,1.01-2.43,1.01s-3.37,0.07-3.91-0.72
		c-0.54-0.8-1.3-1.77-2.21-0.29c-0.9,1.48-2.32,3.76-3.26,2.42c-0.94-1.34-1.45-1.74-2.71-1.74s-2.21-0.22-2.86-1.05
		s-1.7-2.9-2.68-2.86s-3.76-0.33-3.87,0.33c-0.11,0.65-1.16,2.35-2.13,1.81c-0.98-0.54-2.64-1.99-3.65-1.92s-1.96,1.01-3,0.76
		c-1.05-0.25-1.34,0.36-2.17,1.38c-0.83,1.01-1.63,2.28-2.82,2.39s-2.1-0.18-4.13-1.85c-2.03-1.66-3.11-1.81-3.11-0.94
		s0.36,3.47-0.94,3.33s0.8-1.95-1.45-3.26c-2.24-1.3-2.75-2.68-4.05-2.97c-1.3-0.29-1.16-2.75-3.11-2.68
		c-1.96,0.07-5.36,0.8-5.72-0.43s-1.09-1.74-2.24-1.74c-1.16,0-2.39-0.43-2.46,0.72c-0.07,1.16-1.16,2.17-2.82,1.38
		c-1.66-0.8-1.38-2.1-2.97-2.17s-2.03,0.72-2.68,2.03c-0.65,1.3-1.96,2.46-3.69,2.61c-1.74,0.14-3.11,0.36-3.55,1.45
		c-0.43,1.09-1.23,2.97-3.4,3.33s-2.46-0.07-2.46,1.3c0,1.38-0.94,2.82,0.22,3.48c1.16,0.65,1.59,1.16,0.43,2.03
		s-2.53,2.17-3.84,0.07c-1.3-2.1-2.46-2.61-3.11-3.4c-0.65-0.8-1.45-1.38-2.39-0.36c-0.94,1.01-0.07,2.46-1.45,3.18
		s-1.96,1.81-2.39,2.68s-0.8,2.32-0.15,3.11c0.65,0.8,2.03,1.88,0.8,2.9c-1.23,1.01-2.32,1.96-2.53,2.97
		c-0.22,1.01-1.66,1.23-0.36,2.39s3.4,0.65,3.48,2.24c0.07,1.59,0.36,4.05,1.52,4.05s1.74-0.8,2.75-0.51
		c1.01,0.29,3.33,0.29,4.13,1.59s1.81,3.26,2.61,3.84s1.96,2.17,1.88,2.9c-0.08,0.73-3.4,2.03-1.45,2.9c1.59,0.7,2.74,0.89,4.2,2.32
		l0,0c0.03-0.09,0.06-0.18,0.09-0.29c0-0.01,0-0.01,0-0.02c0.03-0.11,0.05-0.22,0.09-0.35c0.22-0.87,1.3-1.52,3.04-1.63
		c1.74-0.11,1.63-0.65,2.17-1.63s0.98-1.19,2.5-1.19s2.06-0.54,2.82-1.19c0.76-0.65,1.09-0.98,2.39-0.98s4.13,1.3,4.78,1.85
		c0.65,0.54,2.5,0.54,2.93-0.11s1.74-0.43,3.04-0.87c1.3-0.43,2.39,1.41,2.93,2.06s0.11,1.3-0.76,1.41
		c-0.87,0.11-0.54,1.19,0.43,1.63s0,1.74,0,3.37s-2.28,1.19-2.61,1.95s0.33,1.96,1.63,2.71c1.3,0.76-0.22,0.87-1.41,0.65
		s-0.87-0.76-1.52-0.76s-4.13,0.22-5.76,0.22s-2.71,0.33-2.93,1.41c-0.22,1.09-0.87,2.17-2.17,2.39s0,1.74,1.52,1.96
		s0.98,1.52,0,1.74s-1.85,0.11-3.04-0.54c-1.19-0.65-1.74-0.43-2.71-0.11c-0.98,0.33-1.3,1.85-1.09,2.61
		c0.22,0.76,0.98,0.54,2.71,1.19s2.71,3.58,3.37,4.67c0.65,1.09,0.76,1.63,0.76,2.82s0.65,1.41,2.17,1.41s1.3,0.87,1.96,1.95
		c0.65,1.09,3.15,0.65,4.34,0.76c1.19,0.11,1.74,1.95,1.63,3.37s-0.43,1.63-1.74,1.85c-1.31,0.22-0.33,2.06-0.98,3.04
		c-0.04,0.07-0.08,0.14-0.11,0.2c-0.01,0.02-0.01,0.04-0.02,0.06c-0.02,0.05-0.03,0.09-0.04,0.14c0,0.02-0.01,0.05-0.01,0.07
		c0,0.05-0.01,0.09-0.01,0.14c0,0.02,0,0.04,0,0.06c0.01,0.05,0.01,0.1,0.03,0.15c0,0.01,0.01,0.03,0.01,0.04
		c0.08,0.27,0.27,0.52,0.52,0.74c0.62-0.34,1.39-0.86,1.9-1.6c0.98-1.41,1.74-2.5,3.69-2.5s1.41-1.3,3.8-1.52s3.26-0.54,4.34,0.76
		c1.09,1.3,3.15,0.54,3.58,1.95c0.43,1.41,1.52,3.8,2.5,5.1c0.98,1.3,2.93,1.74,4.24,1.3c0.34-0.11,0.66-0.22,0.97-0.3l-0.1-30.97
		c0,0,3.58-0.65,5.43-1.41c1.85-0.76,3.15-1.52,5.32-1.95c1.99-0.4,5.87-2.06,7.85-1.43c0.17-0.6,0.35-1.18,0.53-1.59
		c0.5-1.1,1.2-2.29,2.19-2.29c1,0,1.39,0.5,1.79,1.3s0.5,0.9,1.69,0.5c1.2-0.4-0.583-3.408,0.9-3.88
		c1.394-0.444,1.756,0.553,1.756,0.553c1.59,1.29,3.624,0.347,4.714,0.347s1-1.1,0.7-1.49c-0.3-0.4-1.79-0.7-3.59-1.69
		c-1.79-1,1.29-1.69,2.59-1.3c1.29,0.4,2.504,0.091,3.152,1.662c0.652,1.582,0.58,1.31-0.42,2.1c-1,0.8,0.248,0.328-0.842,0.628
		c-1.1,0.3-2.07,0.854-2.86,2.054c-0.8,1.2-3.819,0.189-4.532,0.997c-1.739,1.974,0.075,1.794,0.68,3.036
		c0.696,1.43,0.374,1.458,0.19,1.981c-0.338,0.962-0.014,1.117,0.523,2.715c0.26,0.774,5.089,2.077,4.809,2.347
		c0.53,0.39,0.99,0.79,1.3,1.19c1.63,2.06,4.78,4.02,5.97,5.76s1.3,2.82,3.8,2.5s4.34-1.63,7.49-1.09s6.95,1.52,8.36,0.43
		c1.41-1.08,3.15-2.39,5.43,0.87s2.5,4.56,4.23,4.67c1.73,0.11,1.19,1.63,1.09,3.37s0.87,2.71,2.06,4.23
		c1.19,1.52,1.74,3.48,2.06,5.1s0.43,2.28,2.5,2.28s6.08-1.52,6.62,0.54s-0.11,2.93,1.09,3.26c1.19,0.33,2.93,1.74,2.82,0.33
		s-1.3-2.17,0.11-2.82c1.41-0.65,1.63-2.82,3.69-3.69s2.28-1.41,3.48-2.06c1.19-0.65,1.95-0.43,3.15-1.63s1.95-2.39,3.37-2.39
		s1.95-0.76,2.82-2.71c0.55-1.23,1.7-2.12,2.91-2.61c0.01,0,0.02-0.01,0.02-0.01c0.11-0.04,0.22-0.08,0.33-0.12
		c0.02-0.01,0.04-0.01,0.06-0.02c0.1-0.03,0.2-0.06,0.3-0.09c0.03-0.01,0.06-0.02,0.1-0.02c0.08-0.02,0.17-0.04,0.26-0.06
		c0.05-0.01,0.1-0.02,0.14-0.03c0.07-0.01,0.14-0.03,0.2-0.04c0.07-0.01,0.13-0.02,0.2-0.02c0.05-0.01,0.1-0.01,0.14-0.02
		c0.11-0.01,0.22-0.01,0.33-0.01c1.74,0,4.34,0.11,5.65,0.98c1.3,0.87,3.15,0.87,4.45,1.09c1.3,0.22,2.61,0.33,2.61-1.19
		s0.22-2.82,1.19-3.26c0.98-0.43,1.74-2.5,3.69-1.41s2.17,2.71,4.23,2.71s3.15,1.95,5.54,0.98c2.39-0.98,3.37-1.09,5.32-0.87
		s4.02-0.87,5.76-0.43c1.74,0.43,2.82,0.43,5.65,0.65c2.82,0.22,4.45,0.65,5.97,1.09c1.52,0.43,1.95,0.22,2.71,1.3
		c0.76,1.09,1.63,1.52,2.71,1.74c0.67,0.13,1.88,0.35,3.01,0.52c-0.01-0.14-0.02-0.27-0.04-0.41c0-0.02,0-0.03,0-0.05
		c-0.01-0.11-0.02-0.23-0.02-0.34c0-0.04,0-0.07-0.01-0.11c-0.01-0.1-0.01-0.19-0.01-0.29c0-0.04,0-0.09,0-0.13
		c0-0.09,0-0.17,0-0.25c0-0.05,0-0.09,0-0.14c0-0.08,0.01-0.15,0.01-0.23c0-0.04,0-0.09,0.01-0.13c0.01-0.07,0.02-0.14,0.02-0.21
		c0.01-0.04,0.01-0.08,0.02-0.12c0.01-0.07,0.03-0.13,0.05-0.2c0.01-0.03,0.01-0.06,0.02-0.09c0.03-0.09,0.06-0.17,0.1-0.24
		c0.58-1.09,2.61-1.01,2.97-2.1c0.36-1.09,1.45-0.87,0.87-2.82c-0.58-1.95-0.07-3.26-1.38-4.2c-1.3-0.94-1.16-1.38-1.23-3.4
		c-0.07-2.03,0.51-4.92-1.01-4.92s-1.95-0.58-1.74-1.74c0.22-1.16,2.9-0.36,3.04-1.01s3.98,0.36,4.56-0.65s2.24-1.59,3.62-1.3
		s1.09,1.45,3.18,1.59c2.1,0.15,3.55,0.15,3.91-0.8c0.36-0.94,0.43-1.74-0.72-1.74s-1.52-0.72-1.38-1.52c0.14-0.8,0.8-4.71,2.1-5.57
		c1.3-0.87,0.8-0.87,1.23-2.61c0.43-1.74,0.87-2.82,1.3-3.76s0.36-2.82,2.97-1.59c2.61,1.23,1.52,2.1,3.98,1.88
		c2.46-0.22,3.84-0.36,4.99,0.29c1.16,0.65,1.95,0.58,2.82-0.29s1.38-1.16,2.24-1.3c0.87-0.15,1.52-0.51,1.38-1.81
		c-0.14-1.3,0.22-1.81-0.36-2.68s-0.72-2.17-0.65-3.26s0.94-4.05,3.18-4.05s5.14,0,5.36-2.46c0.22-2.46-0.29-2.82,0.87-3.55
		c0.11-0.07,0.22-0.15,0.32-0.23c0.02-0.01,0.03-0.02,0.04-0.03c0.1-0.08,0.18-0.16,0.26-0.24c0.01-0.01,0.02-0.02,0.03-0.04
		c0.08-0.09,0.16-0.17,0.22-0.26v-0.01c0.07-0.09,0.12-0.19,0.17-0.29l0,0c0.06-0.13,0.1-0.26,0.11-0.38c0-0.01,0-0.01,0-0.02
		c0.01-0.05,0.01-0.1,0-0.16C434.358,255.548,434.348,255.528,434.348,255.518z M379.758,279.578c0,0.94-0.8,0.58-1.01,1.23
		c-0.22,0.65-1.88,0.43-3.11,0.36c-1.23-0.07-1.45,0.65-2.24,0.29c-0.8-0.36-1.59,0-2.75,0s-1.16-0.07-2.03-0.72s-2.39,0-3.91,0.22
		s-2.53-1.23-3.62-1.23s-2.1,0-3.04-0.51s-2.24,0.72-3.62,0.65s-1.52,0.65-3.04,0.87c-1.52,0.22-2.24,1.52-2.82,2.46
		c-0.58,0.94-1.38,1.01-2.53,1.3c-1.16,0.29-0.36,3.18-1.09,3.33c-0.72,0.14-0.51,0.65-0.58,1.66c-0.07,1.01,0.94,0.36,1.3,1.3
		s0.29,1.59-0.36,1.59s-1.3-1.01-1.67-1.74c-0.36-0.72-1.23-1.3-2.1-1.88s-0.8-3.18-0.14-3.26c0.65-0.07,1.23-0.8,1.16-1.52
		s-0.07-1.16,1.09-1.09s0.8-1.38,2.1-1.81c1.3-0.43,2.03-2.17,2.03-2.17c0.65-1.23,3.69-0.43,4.78-0.51c1.09-0.07,1.3,0.8,1.81,0.22
		s0.8-0.29,1.45-0.51c0.65-0.22,3.77-0.36,4.63,0.14c0.87,0.51,0.87,0.8,1.67,0.58c0.8-0.22,2.1,0.36,3.11,0.58
		c1.01,0.22,1.88,0.15,2.68-0.29c0.8-0.43,2.32-0.07,3.69-0.07c1.38,0,1.74,1.01,2.24,1.01s0.94-0.07,1.23-0.65s0.58-0.94,1.16-1.16
		c0.58-0.22,0.8,0.8,1.45,1.01c0.65,0.22,1.01-0.51,0.87-1.16s0.36-0.72,0.94-0.58C380.048,277.698,379.758,278.638,379.758,279.578
		z"
          pointer-events="auto"
        ></path>

        <path
          id="CN"
          title="Chine"
          capital="Pékin"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M597.018,493.018c0.36-0.23,0.56-0.31,0.9-0.58c0.49-0.39,0.42-0.25,0.52-0.94c0.12-0.81,1.91-3.32,1.49-3.91
		c-0.06-0.09-0.12-0.17-0.19-0.25c-0.67-0.88-2.65-0.54-3.77-0.54c-0.8,0.01-1.7-0.13-2.48,0.01c-0.68,0.12-2-0.56-2.65-0.36
		c-1.41,0.42-2.15,1.55-3.56,2.07c-0.67,0.25-0.7,0.49-0.95,1.1s-0.61,0.85-1.12,1.27c-0.74,0.61-1.78,1.79-2.04,2.72
		c-0.12,0.45,0.03,0.67,0.18,1.14c0.22,0.69,0.24,1.45,0.47,2.12c0.18,0.51,0.51,0.94,0.67,1.48c0.46,1.6,2.42,1.29,3.8,1.44
		l1.92-0.18l2.09-0.18c1.93-0.16,3.76-2.12,4.84-3.6c0.14-0.2-0.62-1-0.7-1.2C596.228,494.048,596.458,493.358,597.018,493.018z
		 M692.118,332.928c1.09-0.94,3.98-2.03,4.85-2.75s2.24-0.58,2.97-1.23s0.94-1.38,1.88-1.59c0.94-0.22,1.96-1.01,3.04-1.95
		c1.09-0.94,2.03-2.53,2.24-3.69c0.22-1.16,0.36-1.81,1.96-1.81c1.59,0,1.01,1.74,1.45,1.74s1.96,0.8,2.53,1.52s2.53,0.8,2.97,0.22
		c0.43-0.58,1.38-0.29,2.03-0.43c0.65-0.14,0.43-1.09-0.07-1.45c-0.51-0.36,0.15-1.45-0.65-2.17c-0.8-0.72,0.07-1.45,2.03-1.3
		c1.96,0.14,4.85-0.29,5.28-1.16c0.43-0.87,0.94-2.03,1.81-2.03s2.03-0.65,2.03-1.59s0.07-1.16,0.94-1.59s-0.36-1.52,0.65-1.81
		s2.24,0.72,2.32,1.3c0.07,0.58,0,1.45,0.65,1.81c0.08,0.04,0.16,0.08,0.25,0.09c0.06,0.01,0.13,0.02,0.19,0.02
		c0.02,0,0.04,0.01,0.06,0c0.17-0.01,0.34-0.06,0.49-0.15h0.01c0.16-0.09,0.31-0.23,0.44-0.4c0-0.01,0.01-0.01,0.02-0.02
		c0.05-0.07,0.1-0.16,0.14-0.24c0.01-0.02,0.02-0.03,0.03-0.05c0.05-0.11,0.09-0.22,0.11-0.34c0.22-0.94,0.29-0.51,1.45-0.58
		c1.16-0.07,1.52-0.51,2.1-1.59c0.58-1.08,1.01-0.72,1.16-1.74c0.15-1.02,0.36-2.17,0-3.04s-0.07-3.11,0-4.2s-0.07-2.17-0.65-2.68
		s0-1.16-0.58-1.66c-0.58-0.51-1.09-1.38-0.94-2.24c0.15-0.87,1.88-0.87,3.11-1.09s1.66-1.09,2.32-2.1s1.74-0.51,2.82,0.07
		c1.09,0.58,2.75,1.3,4.49,1.59s2.1-0.43,2.17-1.95s0.36-1.81,1.45-2.17c1.09-0.36,1.3-1.88,1.74-3.33
		c0.43-1.45,0.94-1.59,1.81-2.39c0.87-0.8,0.8-2.68,0.72-4.05c-0.07-1.38,1.59-2.61,1.59-4.13s0.72-2.61,1.59-2.68
		c0.87-0.07,1.52-0.29,2.03-1.45s0.22-2.1-0.8-2.82c-1.01-0.72-0.07-1.45,0.72-1.81c0.8-0.36,0.29-1.09,0.15-1.88
		c-0.15-0.8-2.24-0.72-3.55-0.72s-0.87,1.16-2.46,1.45s-1.52,0.51-2.46,1.09s-2.53,0.51-3.91,0.58c-1.38,0.07-2.32,1.81-3.4,2.9
		s-2.24,0.87-3.91,0.72c-1.66-0.14-1.81-1.09-2.9-0.51s-2.24,0.36-2.53,0.07c-0.29-0.29-0.29-1.09-1.74-1.81s-0.22-1.74-0.22-2.61
		s0.36-2.39-0.51-2.97s-0.65-1.23-0.65-2.24s-1.88-0.58-3.4-1.09s-1.88-2.32-2.97-3.33s-1.66-1.66-3.26-0.8
		c-1.59,0.87-2.9-1.09-3.69-1.74s-1.52-0.58-2.24,0.43s-1.38,0-2.32-0.15c-0.94-0.14-1.96-1.16-2.82-1.95
		c-0.87-0.8-0.94-2.17-0.51-3.69s-0.87-1.23-1.23-2.53s-0.22-1.74-0.87-2.9c-0.65-1.16-1.01-1.45-1.88-2.32
		c-0.87-0.87-0.72-1.16-0.8-2.53c-0.07-1.38-0.72-2.17-0.94-2.9c-0.22-0.72-0.36-1.09-1.45-2.32c-1.09-1.23-0.94-3.04-1.09-4.56
		s-1.81-1.66-2.1-2.68c-0.29-1.01-0.72-1.88-1.88-2.24s-1.66-1.3-2.32-2.39c-0.65-1.09-3.69-0.72-5.14-0.65s-1.96-0.22-2.39-0.72
		c-0.43-0.51-1.09-1.09-2.32-1.3s-2.46-1.16-3.47-1.45c-1.01-0.29-1.81,0.29-3.33,0.72c-1.52,0.43-2.1-0.15-4.27-0.15
		c-2.17,0-1.59,0.58-3.04,0.87c-1.45,0.29-2.46,0.14-3.91,0.22s-1.66,0.94-2.75,0.94s-0.94,0.29-2.68,1.16
		c-1.74,0.87-1.96,2.68-3.26,3.04s-1.16,0.65-1.16,1.45s1.38,0.8,2.68,0.72c1.3-0.07,1.38,1.23,1.38,1.88s0.29,1.23,0.8,1.66
		c0.51,0.43,0.51,0.72,0.07,1.67c-0.43,0.94-2.03,1.59-3.47,2.39c-1.45,0.8-2.03,2.53-2.46,4.2s-1.23,2.24-1.81,3.55
		s-1.09,2.1-2.17,2.9c-1.09,0.8-1.3,1.88-0.65,2.39c0.65,0.51,1.16,1.01,0.87,1.96c-0.29,0.94-1.59,1.45-2.9,1.45
		c-1.3,0-1.45,0.8-2.97,1.23c-1.52,0.43-1.74,1.74-3.33,2.75s-1.88,0.14-3.11-0.29s-1.45-0.65-3.55-0.94
		c-0.12-0.02-0.23-0.04-0.33-0.06c-0.03-0.01-0.06-0.01-0.09-0.02c-0.08-0.02-0.15-0.03-0.21-0.05c-0.03-0.01-0.06-0.02-0.08-0.02
		c-0.08-0.02-0.16-0.05-0.22-0.08c-0.01,0-0.02-0.01-0.02-0.01c-0.08-0.03-0.15-0.06-0.22-0.1c-0.02-0.01-0.03-0.02-0.05-0.03
		c-0.05-0.03-0.09-0.05-0.14-0.08c-0.02-0.01-0.03-0.02-0.05-0.03c-0.05-0.03-0.09-0.07-0.14-0.1c-0.01-0.01-0.02-0.01-0.02-0.02
		c-0.05-0.04-0.1-0.08-0.14-0.13c-0.01-0.01-0.02-0.02-0.03-0.03c-0.03-0.03-0.06-0.07-0.09-0.11c-0.01-0.01-0.02-0.03-0.03-0.04
		c-0.03-0.04-0.06-0.08-0.09-0.12c0-0.01-0.01-0.01-0.01-0.02c-0.03-0.05-0.07-0.1-0.1-0.15c0-0.01-0.01-0.01-0.01-0.01
		c-0.03-0.05-0.06-0.1-0.09-0.15c0,0,0,0,0-0.01c-0.04-0.06-0.07-0.13-0.11-0.19l0,0c-1.21,1.81-1.99,4.62-3.06,5.51
		c-1.3,1.09-0.54,2.82-1.84,4.02c-1.3,1.19-0.87,1.52-0.87,3.48s-0.33,1.74-1.96,2.06c-1.63,0.33-0.33,2.28,1.41,3.8
		s2.06-0.22,2.5-0.87s3.8,0.22,5.32-0.22s1.63,1.41,2.93,1.52s2.06-1.52,2.93-2.39s3.47-0.54,4.23-0.22
		c0.76,0.33,3.26,1.19,4.02,2.5c0.76,1.3,3.91,3.69,5.54,4.89c1.63,1.19,1.63,3.26,1.09,3.91s-1.85,0.22-3.58,0.87
		c-1.74,0.65-1.74-0.65-2.71-1.3c-0.97-0.65-2.61-0.22-4.23-0.22s-2.39,1.85-4.56,1.74s-2.93,1.41-5.86,1.74
		c-2.93,0.32-3.37,2.28-4.23,4.45c-0.87,2.17-1.96,2.5-4.02,3.91s-5.32-0.11-7.06-0.11s-2.5,3.48-3.8,3.8s-2.17,1.3-3.91,1.85
		s-3.15-0.33-4.78-0.54c-1.63-0.22-1.63-0.76-3.26-2.06s-2.06,0-3.8,0.33s-1.3,3.04-2.17,3.58c-0.87,0.54-0.87,1.19-1.09,1.85
		c-0.22,0.66,1.09,2.39,2.93,3.37c1.84,0.98,1.3,2.28,0.43,2.82s-1.09,1.52-4.23,2.39c-3.14,0.87-2.82,2.71-4.45,4.13
		c-1.63,1.41-2.71,2.06-4.45,2.06s-3.58,1.3-4.99,1.74c-1.41,0.43-2.71,0.43-5.76,0.11c-3.04-0.33-3.91,0-5.43,0
		s-4.56,0.87-6.19,1.19s-3.47,1.95-5.43,2.06s-1.52,1.63-2.93,1.63s-1.41,0.54-2.61,1.85c-1.19,1.3-2.93,0.43-4.34-1.09
		c-1.41-1.52-3.15-0.43-4.67-0.22c-1.52,0.22-1.96,0.11-3.15-0.65c-1.19-0.76-1.09-1.19-2.93-1.19s-2.06-0.76-3.58-0.76
		s-1.41-0.54-4.13-2.61c-2.72-2.07-5.97-0.76-7.49-1.41s-2.71-0.65-4.02-0.76c-1.3-0.11-2.28,0-4.23,0.87
		c-1.96,0.87-5.1-0.33-6.52-0.33s-3.91,0.43-6.08-0.76s-5.1-0.54-6.62-0.43c-1.52,0.11-2.17,0.65-3.04-1.19s-1.09-1.19-2.28-2.17
		s-1.41-1.85-1.52-3.04s-0.11-2.06-1.3-2.82s-0.43-1.09-0.98-2.17c-0.54-1.09-1.74-1.19-3.37-1.41s-3.04-1.52-3.69-2.28
		s-1.52-0.76-2.61-1.09s-1.63-0.65-2.5-2.06s-3.8-0.87-4.99-0.98c-1.19-0.11-2.06,0.11-3.48,0.11c-1.41,0-1.85-0.33-3.26-0.76
		s-3.91,0-5.86-0.22c-1.95-0.22-1.09-1.19-2.82-2.06c-1.74-0.87-0.76-1.3-0.65-2.28c0.11-0.98,0.76-2.06,2.17-3.04
		s0.43-1.19-0.33-1.74c-0.76-0.54-0.54-2.17,0.98-2.82c1.52-0.65,0.87-1.85-0.43-2.39s-0.98-1.63-2.39-2.71
		c-1.41-1.09-1.63-2.17-1.74-3.26s-0.76-1.63-1.09-2.17s-1.3-1.52-2.17-1.74c-0.87-0.22-1.52-0.11-1.95-0.87
		c-0.43-0.76-2.39,0-3.8-0.11c-1.41-0.11-3.26-1.96-3.48-2.61s-0.54-0.87-2.06-1.41s-1.74-1.09-1.95-1.85
		c-0.22-0.76-0.65-0.76-1.09-1.96c-0.17-0.48-0.17-1.14-0.11-1.77c-0.06,0.02-0.12,0.04-0.18,0.05c-0.01,0-0.01,0-0.02,0
		c-0.06,0.01-0.11,0.02-0.17,0.03c-0.01,0-0.01,0-0.02,0c-0.27,0.02-0.54-0.01-0.84-0.05c-0.02,0-0.05-0.01-0.07-0.01
		c-0.04,0-0.07-0.01-0.11-0.01c-0.07-0.01-0.14-0.01-0.21-0.02c-1.01-0.07-1.52,0.29-2.17-0.51c-0.27-0.33-0.67-0.53-1.04-0.66l0,0
		c-0.05,0.1-0.1,0.19-0.17,0.29c0,0,0,0,0,0.01c-0.06,0.09-0.14,0.18-0.22,0.26c-0.01,0.01-0.02,0.02-0.03,0.04
		c-0.08,0.08-0.17,0.16-0.26,0.24c-0.01,0.01-0.03,0.02-0.04,0.03c-0.1,0.08-0.2,0.15-0.32,0.23c-1.16,0.72-0.65,1.09-0.87,3.55
		s-3.11,2.46-5.36,2.46s-3.11,2.97-3.18,4.05c-0.07,1.09,0.07,2.39,0.65,3.26s0.22,1.38,0.36,2.68c0.14,1.3-0.51,1.66-1.38,1.81
		c-0.87,0.14-1.38,0.43-2.24,1.3c-0.87,0.87-1.67,0.94-2.82,0.29s-2.53-0.51-4.99-0.29s-1.38-0.65-3.98-1.88
		c-2.61-1.23-2.53,0.65-2.97,1.59c-0.43,0.94-0.87,2.03-1.3,3.76s0.07,1.74-1.23,2.61c-1.3,0.87-1.95,4.78-2.1,5.57
		c-0.14,0.8,0.22,1.52,1.38,1.52s1.09,0.8,0.72,1.74c-0.36,0.94-1.81,0.94-3.91,0.8c-2.1-0.15-1.81-1.3-3.18-1.59
		c-1.38-0.29-3.04,0.29-3.62,1.3s-4.42,0-4.56,0.65c-0.14,0.65-2.82-0.14-3.04,1.01c-0.22,1.16,0.22,1.74,1.74,1.74
		s0.94,2.9,1.01,4.92c0.07,2.03-0.07,2.46,1.23,3.4c1.3,0.94,0.8,2.24,1.38,4.2c0.58,1.95-0.51,1.74-0.87,2.82
		c-0.36,1.09-2.39,1.01-2.97,2.1c-0.04,0.07-0.07,0.15-0.1,0.24c-0.01,0.03-0.02,0.06-0.02,0.09c-0.02,0.06-0.03,0.13-0.05,0.2
		c-0.01,0.04-0.01,0.08-0.02,0.12c-0.01,0.07-0.02,0.14-0.02,0.21c0,0.04-0.01,0.09-0.01,0.13c0,0.07-0.01,0.15-0.01,0.23
		c0,0.05,0,0.09,0,0.14c0,0.08,0,0.17,0,0.25c0,0.04,0,0.08,0,0.13c0,0.09,0.01,0.19,0.01,0.29c0,0.04,0,0.07,0.01,0.11
		c0.01,0.11,0.02,0.23,0.02,0.34c0,0.02,0,0.03,0,0.05c0.01,0.14,0.02,0.27,0.04,0.41c0.03,0.28,0.05,0.56,0.08,0.83
		c0,0.01,0,0.02,0,0.03c0.01,0.09,0.02,0.18,0.02,0.27c0,0.04,0.01,0.08,0.01,0.11c0.01,0.07,0.01,0.14,0.02,0.21
		c0,0.04,0.01,0.08,0.01,0.12c0,0.06,0.01,0.12,0.01,0.18c0,0.04,0,0.08,0,0.12c0,0.06,0,0.12,0,0.17c0,0.03,0,0.06,0,0.09
		c0,0.08-0.01,0.16-0.02,0.23c-0.14,1.16-0.87,0.87-2.53,1.09s-2.75,1.01-4.78,1.88s-5.43,2.68-5.65,4.27s-6.01,1.09-7.74,1.09
		s-3.26,0.29-3.69,3.04s-2.17,2.39-3.33,2.32c-1.16-0.07-1.3,0.87-2.53,0.87s-1.09-0.29-1.16-1.3s-1.74-1.01-2.24-0.29
		c-0.51,0.72-1.23,0.72-2.17,0.72s-1.67,0.22-2.24,1.3c-0.57,1.08-1.88,1.52-4.05,1.88c-0.08,0.01-0.15,0.04-0.23,0.06
		c-0.02,0.01-0.04,0.02-0.06,0.03c-0.05,0.02-0.1,0.05-0.15,0.07c-0.02,0.01-0.04,0.02-0.06,0.04c-0.05,0.03-0.1,0.07-0.14,0.11
		c-0.01,0.01-0.03,0.02-0.04,0.03c-0.06,0.05-0.11,0.11-0.17,0.17c-0.01,0.01-0.02,0.03-0.03,0.04c-0.04,0.05-0.08,0.11-0.12,0.16
		c-0.02,0.02-0.03,0.05-0.04,0.07c-0.03,0.06-0.07,0.11-0.1,0.17c-0.01,0.02-0.02,0.05-0.04,0.07c-0.08,0.17-0.15,0.35-0.22,0.55
		c-0.01,0.02-0.01,0.05-0.02,0.07c-0.02,0.08-0.05,0.16-0.07,0.24c-0.01,0.03-0.01,0.05-0.02,0.08c-0.02,0.09-0.04,0.19-0.06,0.28
		c0,0.01-0.01,0.03-0.01,0.04c-0.02,0.12-0.05,0.23-0.06,0.35v0.01c-0.02,0.11-0.03,0.22-0.05,0.34c0,0.02-0.01,0.04-0.01,0.07
		c-0.01,0.1-0.02,0.21-0.04,0.32c0,0.02,0,0.03,0,0.05c-0.02,0.25-0.04,0.5-0.06,0.76v0.01c-0.01,0.12-0.01,0.24-0.02,0.36
		c0,0.01,0,0.02,0,0.03c-0.03,0.9-0.01,1.74,0,2.35c0,0.05,0,0.09,0,0.14c0,0.03,0,0.06,0,0.09c0,0.08,0,0.15,0,0.22
		c0,1.67,0.51,2.61,0.8,3.76c0.29,1.16,1.52,0.65,2.1,0.07c0.58-0.58,2.39,0.36,3.04,0.58s1.23,2.39,1.23,3.4
		c0,1.01,0,2.17,0.58,3.26c0.07,0.13,0.13,0.27,0.18,0.4c0.03,0.07,0.05,0.14,0.07,0.21c0.02,0.07,0.05,0.13,0.06,0.2
		c0.03,0.1,0.04,0.2,0.06,0.3c0.01,0.04,0.02,0.08,0.02,0.12c0.02,0.12,0.02,0.24,0.02,0.36c0,0.02,0,0.04,0,0.06
		c0,0.13-0.01,0.27-0.02,0.4c0,0.01,0,0.02,0,0.03c-0.02,0.14-0.04,0.29-0.07,0.43l0,0c-0.07,0.3-0.17,0.6-0.31,0.9l0,0
		c-0.03,0.07-0.05,0.13-0.09,0.2c-0.65,1.3-0.8,1.38-1.52,1.81c-0.05,0.03-0.11,0.06-0.16,0.09c0.37,0.34,1.03,0.78,1.97,0.78
		c1.52,0,2.03-0.14,2.46,0.43s0.51,0.94,1.74,1.23c1.23,0.29,1.01,0.87,1.67,1.16s1.38,1.09,1.38,2.03s-1.23,2.03-0.43,2.68
		c0.8,0.65,0.94,1.52,2.03,1.52s2.03-0.43,2.82,0.65s0.36,1.3,2.1,1.52c1.74,0.22,1.16,0.65,2.46,0.72c0.08,0,0.17,0.01,0.26,0.02
		c0.02,0,0.04,0,0.06,0.01c1.27,0.11,3.47,0.41,4.52-0.17c0.05-0.03,0.1-0.06,0.14-0.09c0.05-0.04,0.11-0.08,0.16-0.13
		c0.94-0.94,0.87-1.59,2.03-1.67c1.16-0.07,1.16-0.94,2.32-1.3s1.3-0.72,2.68-0.72s2.9-0.14,3.84,1.09s1.59,2.1,2.9,2.39
		c1.3,0.29,2.68,0.36,2.32,1.38s-1.01,1.16-0.87,2.17s0.14,2.82-0.72,3.04c-0.87,0.22-1.3,0.51-1.88,1.38
		c-0.58,0.87-1.23,3.04-2.61,3.4c-1.38,0.36-2.1,1.09-2.61,0.36c-0.51-0.72-1.23-1.38-2.03-0.87s-1.52,1.23-0.65,2.17
		s1.3,1.09,0.94,2.1s0.51,2.53,1.45,2.9c0.94,0.36,1.67,1.16,1.74,1.59s0,0.72,0.58,1.3s1.23,1.3,0.58,1.95
		c-0.65,0.65-1.59,0.58-2.1,1.3s-1.81,1.81-2.17,1.16c-0.36-0.65-0.51-1.88-0.87-2.1s-1.09,0.36-1.74,0.36s-1.01,0.87-0.36,1.88
		s0.94,0.72,1.3,2.03c0.36,1.3,0,5.36,1.23,5.36s2.17-0.94,2.82,0.51s0.87,2.32,2.17,2.32s1.74-0.8,2.53,0.51s0.65,1.88,2.03,2.39
		s1.95,0.58,2.68,1.16c0.72,0.58,1.16,0.14,1.59,1.09c0.04,0.08,0.07,0.15,0.09,0.22c0,0.01,0.01,0.02,0.01,0.03
		c0.03,0.07,0.05,0.13,0.07,0.19c0,0,0,0,0,0.01c0.35,0.04,0.75,0.14,1.14,0.34c1.03,0.54,1.14,0.65,1.63,0.71
		c0.49,0.05,0.22-1.63,0.92-1.74s0.6-0.98,1.3-1.03c0.7-0.05,0.92,0.98,1.68,0.98c0.76,0,2.01,0.16,2.28,0.81s0.6,1.74,1.47,1.9
		s1.9,0.11,2.33,0.71s2.5,2.71,3.8,3.09s1.3,0.87,2.17,1.68c0.87,0.81,1.19,1.95,2.77,1.95c1.57,0,2.77-0.38,3.58,0.54
		s1.09,1.68,2.23,1.9s2.39,0.11,2.82,1.03s1.14,1.25,1.95,1.3s3.26,0.54,3.69,0.98c0.43,0.43,0.6,1.41,1.52,1.47
		c0.92,0.05,2.23,1.14,2.55,0.49c0.33-0.65,1.03-1.41,1.74-0.65s2.17,1.63,2.55,1.68c0.38,0.05,1.63-0.11,2.33-0.11
		s0.71-1.03,1.95-1.03c0.53,0,1.14,0.06,1.71,0.09l0,0c0.19,0.01,0.37,0.02,0.54,0.02c0.01,0,0.02,0,0.03,0c0.07,0,0.14,0,0.21,0
		c0.02,0,0.04,0,0.07,0c0.05,0,0.11-0.01,0.16-0.01c0.03,0,0.05-0.01,0.07-0.01c0.05-0.01,0.09-0.01,0.13-0.02
		c0.02,0,0.05-0.01,0.07-0.01c0.04-0.01,0.08-0.02,0.11-0.03c0.02-0.01,0.04-0.01,0.06-0.02c0.04-0.01,0.07-0.03,0.1-0.05
		c0.02-0.01,0.03-0.02,0.05-0.02c0.04-0.03,0.08-0.05,0.12-0.09c0.49-0.49,1.09-0.81,1.9-0.76s0.98,0.6,0.98,1.19
		c0,0.6-0.38,2.28-0.11,2.77c0.02,0.04,0.05,0.08,0.08,0.12c0.01,0.01,0.02,0.02,0.03,0.03c0.03,0.04,0.07,0.08,0.11,0.12l0,0
		c0.4,0.39,1.11,0.64,1.46-0.33c0.43-1.19,0.71-1.74,1.25-1.95c0.54-0.22,0.71-1.47,1.52-1.74s1.85-1.85,2.39-1.95
		c0.54-0.11,1.09-0.98,1.57-0.49c0.49,0.49,1.03,0.65,1.85,0.87c0.81,0.22,1.09,1.85,2.01,1.79c0.92-0.05,1.79-0.33,2.55-0.22
		c0.76,0.11,2.12-0.81,3.04-0.27s0.6,0.87,1.41,1.19c0.75,0.3,0.45,1.46,0.98,1.53l0,0c0.05,0.01,0.1,0.01,0.16-0.01
		c0.76-0.16,1.47-0.76,2.39-0.6s3.09,0.54,3.42-0.54c0.33-1.09,0.16-2.39,1.36-2.61c1.19-0.22,1.95-0.92,2.33-1.85
		c0.38-0.92,0.49-1.52,1.68-1.47s2.61,0.05,3.48-1.14c0.87-1.19,1.09-1.9,2.33-2.61s2.28-2.23,3.04-1.41
		c0.76,0.81,0.87,1.85,1.9,1.85s1.3-0.38,1.85,0.05s1.19,0.65,1.57,0.16s1.3-2.17,2.01-2.17s0.87-1.14,1.52-1.19s0.6,0.81,1.14,0.92
		c0.54,0.11,1.14,0.6,1.19,1.03s-0.49,1.36,0.27,1.74c0.76,0.38,1.14,0.87,1.19,1.41s0.11,1.09-0.49,1.47s-1.79,1.68-0.43,1.85
		c1.36,0.16,2.71-0.6,3.2,0c0.49,0.6,0.6,0.98,1.14,0.81c0.54-0.16,1.19-0.43,1.85-0.16c0.07,0.03,0.13,0.04,0.2,0.05
		c0.06,0,0.11,0,0.17-0.01c0.46-0.07,0.8-0.64,1.1-1.07c0.38-0.54,1.63-1.09,2.17,0.05c0.54,1.14,0.87,1.14,1.63,1.63
		s0.87,1.63,0.87,2.5s0.33,2.01,1.25,2.17s2.66,0.38,2.66,1.3s-0.38,2.23-0.05,2.99s0.54,1.52,0.38,2.33s-0.65,0.81-0.65,2.01
		s0.33,3.15-0.22,3.8c-0.54,0.65-0.6,1.68-1.74,2.23c-1.14,0.54-1.3,0.76-2.12,1.85s-1.79,1.52-2.23,2.44
		c-0.43,0.92-0.38,1.74-1.03,2.23c-0.65,0.49-0.49,0.71-0.11,1.47s0.38,0.76,0.54,1.74s-0.43,2.77-0.05,2.93s1.14,0.11,1.52-0.43
		c0.38-0.54,1.19-0.81,1.74-0.81s1.19-0.81,1.74-0.43c0.54,0.38,2.01,0.33,2.77-0.27c0.76-0.6,0.27,1.25-0.05,2.28
		s-0.16,1.19,0.22,2.23c0.38,1.04,0.6,0.87,0.76,2.01c0.16,1.14,0.33,1.57,1.36,1.63s3.04-0.38,2.71,0.98
		c-0.33,1.36-1.41,1.3-1.19,2.17c0.22,0.87-0.11,1.52-0.54,1.74s-1.25,1.14-0.6,1.47s0.49,0.87,1.41,0.81
		c0.92-0.05,3.09-0.22,3.47,0.6c0.38,0.81,0.65,1.19,1.09,2.12c0.43,0.92,0.11,1.79,1.41,1.79s2.71,0.27,3.04-0.38
		c0.33-0.65,0.76-0.38,1.3-0.98s0.87-0.92,1.36-1.09c0.03-0.01,0.06-0.01,0.09-0.01l0,0c0.27-0.02,0.46,0.27,0.57,0.64
		c0,0.01,0.01,0.02,0.01,0.04c0.02,0.06,0.03,0.12,0.04,0.18c0.01,0.03,0.01,0.07,0.02,0.1c0.01,0.04,0.01,0.08,0.02,0.13
		c0.01,0.08,0.01,0.15,0.01,0.22c0,0.6-0.05,0.76,0.22,1.36c0.27,0.6,0.05,1.63,1.03,1.57s2.66,0.54,2.71-0.27
		c0.05-0.81-0.05-1.47,0.22-1.95c0.27-0.49,0.49-1.63-0.05-2.06s-0.76-0.38-0.87-0.98s-1.03-0.6-1.03-1.19
		c0-0.6,0.11-1.41,0.49-1.52s0.05-1.57,0.98-1.57s0.92,0,1.9,0.27c0.15,0.04,0.28,0.07,0.4,0.1c0.03,0.01,0.05,0.01,0.08,0.02
		c0.1,0.02,0.19,0.03,0.27,0.04c0.02,0,0.04,0,0.06,0c0.21,0.01,0.38-0.02,0.53-0.1c0.07-0.04,0.14-0.08,0.21-0.14
		c0.03-0.02,0.05-0.04,0.08-0.07c0.02-0.02,0.04-0.04,0.07-0.06c0.05-0.05,0.1-0.11,0.15-0.17c0.49-0.6,1.63-1.68,2.17-1.47
		c0.54,0.22,1.57,0.54,1.74,1.19s0.65,0.76,1.25,0.76s0.87-0.65,1.14-0.98s0.81-1.25,1.25-0.98c0.43,0.27,1.3,0.76,1.57,0.27
		c0.27-0.49,0.87-0.54,1.09-0.16s0.43,0.81,1.03,0.76c0.6-0.05,0.65-0.98,1.57-1.14s0.98-0.43,2.01-0.43s2.28,1.03,2.99-0.65
		c0.71-1.68,0.16-2.28,0.98-2.39c0.81-0.11,0.98-1.09,1.74-1.09s1.3,0.49,1.68,1.25s0.98,1.19,1.79,1.41s1.3,0.6,2.44,0.65
		s3.26-0.38,3.8,0.27s1.03,1.85,0.49,2.17c-0.54,0.33-0.92,0.49-0.92,1.19s0.05,2.06,0.54,2.5c0.49,0.43,2.12,2.17,2.5,2.55
		s1.52,1.14,2.77,1.14s2.01-0.6,2.77-0.16c0.52,0.3,0.96,0.3,1.5,0.52c0.22-0.17,0.47-0.35,0.8-0.57c1.87-1.24,0,0,2.18,0.15
		c2.18,0.16,0.93-0.47,2.18-0.47c1.24,0,1.56,1.09,2.49,1.56s1.56-0.47,2.18-1.24c0.62-0.78,1.71,0.31,2.65,0.47
		c0.93,0.16,0.31,1.24-0.78,2.18c-1.09,0.93-0.93,2.18-0.31,2.33s1.24,2.33,2.49,3.42s1.87,0.93,2.65-0.47s-0.62-1.71-1.09-2.49
		c-0.47-0.78,0-1.09,0.47-2.33s2.03-1.24,3.11-1.71c1.09-0.47,3.27-0.78,5.14-0.78s2.65-0.31,3.58-1.4s2.34-0.47,4.04,0.15
		c1.71,0.62,2.65-2.64,3.73-3.73c0.81-0.81,1.88-0.42,3.08,0.16c0.1-0.18,0.21-0.36,0.23-0.54c0.05-0.42,0.74-0.69,1.38-1.24
		c0.34-0.29,0.7-0.29,0.95-0.22c0.22-0.19,0.75-0.34,1.67-0.34c2.34,0,1.25-0.31,2.8-0.31c1.56,0,2.18-0.31,2.65-1.25
		s2.02,0,2.96,0.16c0.93,0.16,1.09-0.31,2.34-1.09s2.96,0,4.36-0.16c1.4-0.15,0.78-2.65,1.71-3.11c0.93-0.47,1.09-1.4,2.34-1.87
		s1.71-0.62,3.42-1.87c1.71-1.25,1.56-1.4,2.96-2.18s0.47-1.09,0.47-2.65s0.62-0.78,2.49-0.47s1.87-0.16,2.02-1.87
		c0.16-1.71,2.34-0.78,3.11-2.65c0.78-1.87,0.31-1.87,2.02-2.33c1.71-0.47,1.87-1.87,1.56-2.96s-0.78-2.02-0.16-2.49
		c0.62-0.47,1.4-1.4,0.31-2.65s1.09-1.71,2.34-1.71s1.4-2.02,1.87-2.02s1.4-0.31,1.4-2.02s1.71-1.4,1.71-2.65
		c0-1.24,0.31-2.8,1.4-2.96s1.56-1.09,3.74-2.33c2.18-1.25-0.16-2.02,0.93-2.65c1.09-0.62,0.31-1.4-0.16-2.96s0.62-1.09,2.34-1.09
		c1.71,0,0.93-1.4-0.78-2.33s0.62-0.93,1.09-2.18c0.47-1.25-1.4-1.09-2.65-1.4s-0.93-1.4-2.34-1.87c-1.41-0.47-1.87,1.56-4.36,1.4
		s-2.34-1.87,0.78-1.87s2.49-1.09,3.11-2.33s1.4-0.93,3.27-1.71s1.4-2.02,0.62-2.8s-2.18-1.87-3.73-2.65
		c-1.56-0.78-0.16-2.49,1.71-1.56s2.18,1.87,3.11,0.16s-1.56-2.33-2.49-3.58s-1.87-2.02-3.27-2.33s-0.93-2.65-1.25-4.51
		c-0.31-1.87-2.02-2.33-2.02-3.27c0-0.93-1.09-2.18-1.71-4.83s-2.18-2.18-4.36-2.8s-2.8-1.56-3.11-3.11
		c-0.31-1.56,1.09-1.24,1.87-3.58c0.78-2.33,2.8-1.56,3.89-4.2s1.56-1.71,3.27-1.87c1.71-0.15,1.56-1.09,2.03-2.65
		c0.47-1.56,1.56-1.87,3.42-2.02c1.87-0.16,2.18-0.31,3.27-1.09s1.25-1.09,2.96,0.16s1.71-1.4,2.18-2.49s0.16-1.56-0.93-2.33
		c-1.09-0.78-3.11-0.62-4.36-0.16c-1.24,0.47-3.27-0.31-4.2-1.56c-0.93-1.24-3.43-1.87-4.52-1.4s-3.42,2.49-4.67,4.2
		s-4.67,1.09-5.6,0.31s-0.31-2.18,0.16-3.42c0.47-1.25-0.31-1.71-0.93-2.65c-0.62-0.93-2.02-1.24-2.34,0
		c-0.31,1.24-2.18-1.4-3.73-1.09c-1.56,0.31-2.02-0.93-3.42-2.65c-1.4-1.71,0.78-3.27,0.93-4.83c0.16-1.56,5.45-0.31,8.25-0.78
		s2.34-2.18,3.27-4.2c0.93-2.02,3.42-2.33,5.29-3.11s1.87-1.87,3.27-2.96s2.49-2.02,3.73-3.42c1.25-1.4,2.8,1.4,3.11,0.16
		s1.24-0.31,2.02,0.16s1.87,1.24,1.71,2.96c-0.16,1.71-1.56,3.42-4.05,4.83c-2.49,1.4-1.09,2.96-0.31,2.96s1.25,0.93,0.31,2.49
		c-0.93,1.56-2.8,0.93-3.58,2.33s0.93,1.87,2.65,0.47c1.71-1.4,3.74-1.87,4.52-2.8s3.58-1.71,5.6-3.27s4.04-2.18,5.6-2.33
		c0.79-0.08,1.83-0.08,3.26,0.21C690.628,334.578,691.228,333.708,692.118,332.928z M619.278,469.708
		c-0.65,0.55-1.34,0.83-1.38,1.24c-0.02,0.18-0.13,0.36-0.23,0.54c0.41,0.2,0.84,0.42,1.28,0.62c1.71,0.78,2.34,0.31,4.05-0.47
		c1.71-0.78-0.47-0.93-1.87-1.25c-0.85-0.19-1.24-0.61-0.89-0.91C619.968,469.418,619.618,469.408,619.278,469.708z"
          pointer-events="auto"
        ></path>

        <path
          id="BD"
          title="Bangladesh"
          capital="Dacca"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M470.528,477.998c0.22-0.43,1.74,0.07,2.46,0.14s0.43-1.01-0.29-1.74c-0.72-0.72,0-1.09-0.22-1.59c-0.02-0.05-0.03-0.11-0.04-0.17
		c0-0.01,0-0.03,0-0.04c0-0.06,0-0.13,0-0.21c0-0.02,0-0.04,0.01-0.06c0-0.06,0.01-0.13,0.02-0.2c0.01-0.07,0.02-0.15,0.03-0.22
		c0-0.01,0-0.02,0-0.03c0.05-0.35,0.11-0.74,0.11-1.07c0-0.01,0-0.01,0-0.02c0-0.1-0.01-0.19-0.02-0.27l0,0
		c-0.01-0.07-0.03-0.13-0.05-0.18c-0.01-0.01-0.01-0.02-0.02-0.04c-0.03-0.06-0.06-0.11-0.1-0.15c-0.51-0.51-0.14-1.67-0.07-3.11
		c0.07-1.44-1.01-1.74-1.09-2.39s-0.36-1.3-0.72-1.67c-0.36-0.37,0-2.68,0-3.62s-1.09-0.65-2.32-0.36s-0.36,1.38-0.87,1.52
		s-0.22,1.59-0.72,2.97c-0.51,1.38-1.74-0.36-2.46-0.36s-0.94-0.43-0.94-1.38c0-0.94-0.65-2.68-0.87-3.4s0.94-0.94,0.94-1.52
		s1.74-0.72,1.81-1.16s0.94-0.36,1.52-0.43s1.45-0.51,1.67-1.3c0.22-0.8,1.3-1.23,1.3-1.88s0.14-0.94,1.16-1.23
		c1.01-0.29,0.43-1.16,0.14-1.52c-0.29-0.36-0.29-1.09-1.16-1.23s-1.52-0.29-3.55,0.29c-2.03,0.58-1.23-0.29-2.68-0.43
		c-1.45-0.14-4.42,0.29-5.43,0.22s-2.39-0.22-2.97-0.72c-0.58-0.51-1.23-0.51-2.03-0.94s0.65-1.88,0-2.53s0.43-1.59,0.43-2.46
		s0-1.38-0.94-1.16s-1.45,1.38-2.68,1.16c-1.23-0.22-1.59-0.87-2.03-1.81s-0.72-0.8-2.1-0.8c-0.08,0-0.16,0-0.23-0.01
		c-0.02,0-0.04-0.01-0.07-0.01c-0.05,0-0.09-0.01-0.14-0.02c-0.02,0-0.05-0.01-0.07-0.01c-0.04-0.01-0.07-0.01-0.11-0.02
		c-0.02-0.01-0.05-0.01-0.07-0.02c-0.03-0.01-0.06-0.02-0.1-0.03c-0.02-0.01-0.04-0.02-0.06-0.02c-0.03-0.01-0.06-0.03-0.09-0.04
		c-0.02-0.01-0.03-0.02-0.05-0.02c-0.03-0.02-0.06-0.04-0.09-0.05c-0.01-0.01-0.02-0.01-0.03-0.02c-0.04-0.03-0.07-0.05-0.11-0.08
		c0,0,0,0-0.01,0c-0.29-0.24-0.53-0.58-0.98-0.96l0,0c-0.05,0.27-0.07,0.56,0,0.75c0.16,0.43,0.54,1.36-0.38,1.52
		s-0.43,0.92-1.09,1.03s-0.81,0.81-0.81,1.3s0.49,0.43,0.87,0.6s0.81,0.27,1.03,0.81s0.76,1.57,1.36,1.68
		c0.6,0.11,2.61,0.33,2.71,0.49c0.11,0.16-0.11,0.92-0.43,1.63c-0.33,0.71-0.92,0.65-1.95,0.65s-1.41,0.71-1.41,1.03
		s-1.25,0.43-1.36,1.03s-0.27,1.3,0.33,1.9c0.6,0.6,0.92,1.19,1.52,1.52c0.6,0.33,1.9,1.57,1.52,2.66s-0.38,3.48,0,4.51
		s0.6,1.41,1.19,1.9c0.6,0.49,0.54,1.25,0.54,2.06s-0.33,2.99-0.11,3.8s0.71,0.92,0.76,1.57c0.05,0.57,0.18,3.15,0.14,4.51
		c0.76,0.47,1.16-0.03,1.44-1.29c0.31-1.4,2.02-0.31,2.96-1.09c0.93-0.78,2.8-0.62,4.05-0.31s2.49,0.15,2.96-1.25
		s1.25-0.16,2.33-0.78c1.09-0.62,0.93-1.4,0.31-2.96s1.24-0.93,2.49-0.93s1.4,1.4,2.02,2.65s0.78,2.02,0.93,4.36
		c0.16,2.33,0.93,2.8,1.4,4.67c0.06,0.24,0.18,0.52,0.35,0.82C470.638,479.808,470.348,478.368,470.528,477.998z"
          pointer-events="auto"
        ></path>

        <path
          id="BT"
          title="Bhoutan"
          capital="Thimphou"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M446.288,433.738c0.07,1.11-0.52,1.84,0.22,2.14c0.74,0.3,0.44,1.7,1.48,1.7s2.58,0.3,2.8,0.66c0.22,0.36,1.55,0.66,2.29,0.59
		s1.99,0.15,2.29-0.74c0.3-0.89,0.44-0.96,1.25-0.96s1.33,0.22,1.4,0.81s1.4,0.89,1.77,0.22s2.21-0.66,3.25-0.66
		c1.03,0,2.07-0.07,3.39-0.07c1.33,0,3.91,0.37,3.24-0.3c-0.66-0.66-1.33-1.03-1.4-1.48c-0.07-0.44,0.15-2.36-0.89-2.51
		c-1.03-0.15-1.92-0.74-2.07-1.03c-0.15-0.3-0.15-0.81,0.22-1.03c0.23-0.14,0.69,0.04,1.06-0.18c-0.53-0.07-0.23-1.23-0.98-1.53
		c-0.81-0.33-0.49-0.65-1.41-1.19s-2.28,0.38-3.04,0.27s-1.63,0.16-2.55,0.22c-0.92,0.05-1.19-1.57-2.01-1.79
		c-0.81-0.22-1.36-0.38-1.85-0.87c-0.49-0.49-1.03,0.38-1.57,0.49s-1.57,1.68-2.39,1.95c-0.81,0.27-0.98,1.52-1.52,1.74
		c-0.54,0.22-0.81,0.76-1.25,1.95c-0.35,0.97-1.06,0.72-1.46,0.33C446.398,432.848,446.258,433.298,446.288,433.738z"
          pointer-events="auto"
        ></path>

        <path
          id="NP"
          title="Népal"
          capital="Katmandou"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M441.198,436.398c-0.52-0.22-0.3-2.65-0.37-3.17s0.07-1.03,0.66-1.11c0.59-0.07,0.44-0.89,0.44-1.48c0-0.27-0.03-0.85-0.06-1.4
		c-0.56-0.03-1.18-0.09-1.71-0.09c-1.25,0-1.25,1.03-1.95,1.03s-1.95,0.16-2.33,0.11c-0.38-0.05-1.85-0.92-2.55-1.68
		c-0.71-0.76-1.41,0-1.74,0.65s-1.63-0.43-2.55-0.49c-0.92-0.05-1.09-1.03-1.52-1.47c-0.43-0.43-2.88-0.92-3.69-0.98
		c-0.81-0.05-1.52-0.38-1.95-1.3c-0.43-0.92-1.68-0.81-2.82-1.03s-1.41-0.98-2.23-1.9c-0.81-0.92-2.01-0.54-3.58-0.54
		c-1.57,0-1.9-1.14-2.77-1.95s-0.87-1.3-2.17-1.68c-1.3-0.38-3.37-2.5-3.8-3.09c-0.43-0.6-1.47-0.54-2.33-0.71s-1.19-1.25-1.47-1.9
		c-0.27-0.65-1.52-0.81-2.28-0.81s-0.98-1.03-1.68-0.98c-0.71,0.05-0.6,0.92-1.3,1.03s-0.43,1.79-0.92,1.74
		c-0.49-0.05-0.6-0.16-1.63-0.71c-0.39-0.21-0.8-0.3-1.14-0.34c0.2,0.67-0.01,1.12-0.97,1.72c-1.16,0.72-1.67,0.8-1.95,1.81
		c-0.29,1.01-0.8,1.38-0.72,2.32c0.07,0.94,0,1.23-0.58,1.74c-0.58,0.51-1.38,0.51-1.01,1.95c0.36,1.45,0.22,2.32,1.45,2.53
		c1.23,0.22,2.39,0.07,2.82,0.8c0.43,0.72,1.16,0.87,2.03,1.01s1.88,0.22,2.46,1.45s1.74,1.52,2.39,2.24
		c0.65,0.72,1.23,0.58,1.67,0.22c0.43-0.36,0.94-1.01,1.52,0s1.66,1.52,2.68,1.45c1.01-0.07,1.95,0,2.39,0.94
		c0.43,0.94,0.72,1.59,1.74,1.3s1.67-0.87,2.53-0.51c0.87,0.36,1.95,1.01,2.68,0.51s0.8-0.94,1.81-0.87s1.74-0.14,2.32,0.94
		s1.23,1.16,1.45,2.03s1.01,1.23,2.17,1.45s0.87,1.01,2.61,0.8s1.95-0.43,2.39,0.29c0.43,0.72,0.58,0.87,2.39,1.01
		c1.81,0.14,3.26,0.94,4.42,0.94s1.3-0.87,2.61-0.22c1.3,0.65,1.52,0.58,2.68,0.43c1.16-0.14,2.24,0.72,2.9-0.07
		c0.49-0.6,1.07-1.41,1.86-1.39c-0.2-0.59-0.63-0.87-0.63-1.32C441.788,437.058,441.718,436.618,441.198,436.398z"
          pointer-events="auto"
        ></path>

        <path
          id="MN"
          title="Mongolie"
          capital="Oulan-Bator"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M439.188,259.058c0.43,1.19,0.87,1.19,1.09,1.96c0.22,0.76,0.43,1.3,1.95,1.85s1.85,0.76,2.06,1.41c0.22,0.65,2.06,2.5,3.48,2.61
		c1.41,0.11,3.37-0.65,3.8,0.11s1.09,0.65,1.95,0.87c0.87,0.22,1.85,1.19,2.17,1.74c0.33,0.54,0.98,1.09,1.09,2.17
		c0.11,1.09,0.33,2.17,1.74,3.26s1.09,2.17,2.39,2.71c1.3,0.54,1.95,1.74,0.43,2.39c-1.52,0.65-1.74,2.28-0.98,2.82
		c0.76,0.54,1.74,0.76,0.33,1.74s-2.06,2.06-2.17,3.04c-0.11,0.98-1.09,1.41,0.65,2.28s0.87,1.85,2.82,2.06
		c1.95,0.22,4.45-0.22,5.86,0.22c1.41,0.43,1.85,0.76,3.26,0.76s2.28-0.22,3.48-0.11c1.19,0.11,4.13-0.43,4.99,0.98
		c0.87,1.41,1.41,1.74,2.5,2.06c1.09,0.33,1.95,0.33,2.61,1.09c0.65,0.76,2.06,2.06,3.69,2.28s2.82,0.33,3.37,1.41
		s-0.22,1.41,0.98,2.17c1.19,0.76,1.19,1.63,1.3,2.82s0.33,2.06,1.52,3.04s1.41,0.33,2.28,2.17c0.87,1.85,1.52,1.3,3.04,1.19
		c1.52-0.11,4.45-0.76,6.62,0.43c2.17,1.19,4.67,0.76,6.08,0.76s4.56,1.19,6.52,0.33c1.96-0.87,2.93-0.98,4.23-0.87
		c1.3,0.11,2.5,0.11,4.02,0.76c1.52,0.65,4.78-0.65,7.49,1.41s2.61,2.61,4.13,2.61s1.74,0.76,3.58,0.76s1.74,0.43,2.93,1.19
		c1.2,0.76,1.63,0.87,3.15,0.65s3.26-1.3,4.67,0.22s3.15,2.39,4.34,1.09s1.19-1.85,2.61-1.85c1.41,0,0.98-1.52,2.93-1.63
		c1.96-0.11,3.8-1.74,5.43-2.06s4.67-1.19,6.19-1.19s2.39-0.33,5.43,0s4.34,0.33,5.76-0.11c1.41-0.43,3.26-1.74,4.99-1.74
		s2.82-0.65,4.45-2.06s1.3-3.26,4.45-4.13s3.37-1.85,4.23-2.39c0.87-0.54,1.41-1.85-0.43-2.82c-1.85-0.98-3.15-2.71-2.93-3.37
		c0.22-0.65,0.22-1.3,1.09-1.85c0.87-0.55,0.43-3.26,2.17-3.58s2.17-1.63,3.8-0.33c1.63,1.3,1.63,1.85,3.26,2.06
		c1.63,0.22,3.04,1.09,4.78,0.54s2.61-1.52,3.91-1.85s2.06-3.8,3.8-3.8s4.99,1.52,7.06,0.11c2.06-1.41,3.15-1.74,4.02-3.91
		s1.3-4.13,4.23-4.45c2.93-0.33,3.69-1.85,5.86-1.74s2.93-1.74,4.56-1.74s3.26-0.43,4.23,0.22c0.98,0.65,0.98,1.96,2.71,1.3
		c1.74-0.65,3.04-0.22,3.58-0.87c0.54-0.65,0.54-2.71-1.09-3.91s-4.78-3.58-5.54-4.89c-0.76-1.3-3.26-2.17-4.02-2.5
		s-3.37-0.65-4.23,0.22c-0.87,0.87-1.63,2.5-2.93,2.39s-1.41-1.95-2.93-1.52s-4.89-0.43-5.32,0.22s-0.76,2.39-2.5,0.87
		c-1.74-1.52-3.04-3.48-1.41-3.8c1.63-0.33,1.96-0.11,1.96-2.06c0-1.96-0.43-2.28,0.87-3.48c1.3-1.19,0.54-2.93,1.84-4.02
		c1.07-0.89,1.85-3.7,3.06-5.51l0,0c-0.06-0.11-0.13-0.22-0.2-0.33l-0.01-0.01c-0.07-0.11-0.15-0.22-0.25-0.33
		c-0.8-0.94-1.96-0.72-3.26-0.94s-1.59,0.43-2.24,1.09c-0.65,0.65-2.82,0.43-4.13-0.36c-1.3-0.8-0.36-1.52-2.32-2.24
		s-2.53-0.8-4.13-0.72c-1.59,0.07-1.3,0.14-2.39,0.87c-1.09,0.72-0.51,0.8-1.96,1.09s-1.38,1.01-2.75,1.59
		c-1.38,0.58-1.09,1.09-1.3,2.24c-0.22,1.16-2.1,1.01-3.62,1.01s-1.74,0.58-3.18,0.8c-1.45,0.22-2.9,0.58-5.21,0.58
		s-1.96,1.74-3.69,1.81c-1.74,0.07-4.99-0.07-6.88-0.07s-1.88-0.51-3.84-1.45s-2.82,0-4.49,0.14c-1.66,0.15-1.66-1.16-2.9-1.66
		c-1.23-0.51-1.74-1.23-1.88-2.97s-2.82-1.23-4.56-1.23s-2.1-1.88-3.4-3.04c-1.3-1.16-2.24,0.29-3.55,0.36
		c-1.3,0.07-1.01-0.65-1.81-0.87s-1.38,0-2.46-0.65s-1.52-0.51-3.26-0.51s-1.38,0.8-2.61,0.87c-1.23,0.07-1.16,0-2.68,0.8
		s-1.52,1.45-2.68,1.67s-2.53,0.29-3.69-0.51s-1.81-0.72-4.05-1.3s-2.24-1.16-3.33-1.81s-0.8-1.01-0.94-2.17
		c-0.15-1.16-0.22-2.1-0.8-2.68c-0.58-0.58-0.58-1.45-0.94-2.53c-0.36-1.09-1.81-0.94-3.47-0.87s-2.17-1.01-3.18-1.09
		s-1.88-0.65-2.75-1.38c-0.87-0.72-1.66-0.36-3.33-0.29c-1.66,0.07-2.32,0.07-2.82-0.72c-0.51-0.8-0.87-0.8-2.03-1.01
		c-1.16-0.22-1.66-0.07-2.97-1.3c-1.3-1.23-1.23,0-1.45,0.94c-0.22,0.94-0.65,1.01-2.24,1.59c-1.59,0.58-1.95,2.68-2.97,2.97
		s-0.65,1.38-1.23,2.24c-0.58,0.87-0.51,1.23-0.43,2.24s0.94,1.23,1.23,2.24c0.29,1.01,0.58,1.23,1.74,1.95s1.16,1.38,0.29,1.88
		c-0.87,0.51-0.58,0.8-1.09,1.81s-1.01,1.01-2.32,1.23c-1.3,0.22-1.3,0.65-1.74,1.3s-1.3,0.22-1.59,0.72
		c-0.29,0.51-0.36,0.87-0.87,0.22s-1.09-0.07-1.52-1.01c-0.43-0.94-2.53-0.22-4.05-0.22s-0.94-0.8-2.17-1.16s-2.24,0-2.97,0.8
		c-0.72,0.8-1.59,0.22-2.46-0.29s-1.67,0.07-2.39-0.94s-1.88-0.22-3.11-0.65c-1.23-0.43-1.45-2.32-1.09-3.55
		c0.36-1.23-3.55-0.72-5.79-0.72s-2.75,0.07-3.11-0.65s-0.65-0.43-1.38-0.14c-0.72,0.29-1.59-0.07-1.67-0.8s-0.8-0.65-2.17-0.58
		c-1.38,0.07-1.16,1.16-3.04,1.09s-0.72,1.74-2.24,1.66c-1.52-0.07-2.53,0.22-3.55,1.16c-1.01,0.94-2.39,1.52-4.34,1.59
		c-1.95,0.07-2.39,1.81-3.84,1.81s-0.94,1.23-1.23,2.1c-0.29,0.87-0.72,1.88-1.81,1.59s-1.67,0-2.97,0.87s-2.24,0.07-3.55,0.07
		c-1.3,0-2.39,1.59-3.4,2.9c-0.09,0.11-0.17,0.21-0.25,0.3c-0.02,0.02-0.04,0.04-0.06,0.06c-0.08,0.08-0.16,0.16-0.24,0.22
		l-0.01,0.01c-0.08,0.06-0.15,0.11-0.23,0.16c-0.01,0.01-0.02,0.01-0.03,0.02c-0.08,0.04-0.16,0.08-0.23,0.11
		C439.018,257.918,439.018,258.588,439.188,259.058z"
          pointer-events="auto"
        ></path>

        <path
          id="RU"
          title="Russie"
          capital="Moscou"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M1135.798,92.278c3.27,0-1.63-2.1,0.47-2.1s4.67,0,4.9-1.17s1.63-1.63,3.97-2.8s-0.47-2.1-2.33-2.8c-1.87-0.7-2.1-2.57-4.44-3.27
		c-2.33-0.7-3.27-2.34-4.9-4.2s-5.84-1.87-7.7-0.47c-1.87,1.4-3.97-0.47-4.2-1.63c-0.23-1.17-2.57-0.47-5.6-0.23
		c-3.04,0.23-0.7,2.57,0.7,3.97c1.4,1.4,0.23,2.34,0,3.27s-2.33,1.4-3.97-0.23c-1.63-1.63-0.23-3.5-1.17-5.14
		c-0.93-1.63,0-0.93,0.93-3.03s-2.57-1.63-3.04-3.27c-0.47-1.63-6.3-4.9-8.64-6.07s-1.87-1.87-8.64-3.97
		c-6.77-2.1-4.67-0.47-7.24-4.43c-2.57-3.97-4.9-0.93-5.37-2.34s-3.97-0.23-5.84-2.57s-4.67-1.17-6.07-3.27
		c-1.4-2.1-7.24-1.87-11.67-2.8c-4.44-0.93-4.2-0.93-5.84-3.27s-12.61-0.23-15.17-0.7c-2.57-0.47-3.97-0.23-5.14,0.7
		s-4.44-2.1-8.87-1.87c-4.43,0.23-4.43,0.23-7.24-1.63c-2.8-1.87-3.27,0.23-3.27,1.63s0,2.1-1.4,3.03s-0.23,2.1,1.17,2.34
		c1.4,0.23,2.34,2.57,3.5,4.67c1.17,2.1,0.93,2.8-1.4,2.8c-2.34,0-2.34,2.57-5.14,2.34s-4.43-1.63-5.37-3.27
		c-0.93-1.63-3.73-2.34-5.84-2.34c-2.1,0-1.87-2.1-2.57-3.97c-0.7-1.87,2.57-2.1,4.9-0.7c2.34,1.4,3.03,0.7,3.5-1.4
		c0.47-2.1-2.57-2.8-5.84-3.5s-4.2,1.4-5.6,3.03c-1.4,1.63-0.7,3.03-4.43,3.03s-5.84,0-7-0.7c-1.17-0.7-3.5-0.47-6.54,0
		c-3.03,0.47-3.97,0-5.37-1.17s-4.67-0.93-6.77-0.7c-2.1,0.23-5.6,0.23-7.47,0.23s-4.43,0.7-4.9,1.87c-0.47,1.17-2.8,0.93-3.5-0.47
		s-5.37-1.4-7.94-2.57s0.23-3.27,1.4-5.14c1.17-1.87-4.67-7.94-10.27-10.04c-5.6-2.1-14.71-1.17-18.44-1.17s-8.87,1.17-12.61,2.1
		s-6.3,1.87-9.34,1.87c-3.03,0-5.84-0.93-4.9-2.8c0.93-1.87-1.63-1.63-3.27-3.74s-3.74-1.17-4.67-0.23s-1.87,1.63-3.5-0.23
		c-1.63-1.87-0.23-2.57-3.27-2.8c-3.03-0.23-1.4-0.93-3.03-0.93s-3.03-1.4-2.1-1.87c0.93-0.47,2.34,0,5.14-0.47
		s-1.87-4.44-4.43-5.37c-2.57-0.93-11.21-1.4-14.47-1.4c-3.27,0-4.43,0-6.54,0c-2.1,0-1.63,2.33-0.7,3.27
		c0.93,0.93-0.93,0.93-0.93,3.03s-1.87,0.47-2.57,1.63c-0.7,1.17-2.57,1.63-4.2,0.47c-1.63-1.17,0.23-2.33,2.57-2.8
		s2.57-2.1,2.57-3.03s0.47-2.1-1.87-2.33c-2.34-0.23-1.87,0.93-4.2,0.93s-0.47-1.4,1.17-2.8c1.63-1.4,0.47-1.87-1.87-1.87
		s-8.4-1.4-14.71-1.4s-0.93-1.4-5.6-0.47s-4.2-1.4-7-2.1s0.23,2.1,0.23,3.74c0,1.63-1.4,1.63-3.74,1.63s-3.5-0.23-6.3,0
		s-2.57,3.03-2.1,4.2c0.47,1.17,2.8,0,4.9-0.47c2.1-0.47,0.93,2.33-0.47,2.8c-1.4,0.47-0.7,2.8,1.17,4.9s0.23,2.57-1.87,2.57
		s-3.5,0-3.97-1.17s-1.63-0.7-3.5-1.4s-3.27,1.17-5.84,0.7s-2.8,0.23-2.1,1.4s2.34,0.7,4.43,1.63c2.1,0.93-0.23,2.1-2.34,1.63
		s-3.03-0.23-4.2-2.33s-3.73-0.47-5.6-2.57s-3.97,0.7-6.77,0.7s-2.34,1.17-4.43,2.57c-2.1,1.4-5.14,0.23-7.71-1.17
		s-2.8-0.93-3.5-3.97s-2.57-1.63-3.97-0.93c-1.4,0.7-1.63,3.27-2.8,5.6s-1.63,3.5-2.57,6.07c-0.93,2.57-3.73,2.8-5.14,0.7
		c-1.4-2.1-3.27-0.7-5.6-2.33c-2.34-1.63-8.17-7.94-9.1-9.81s-4.67-2.33-4.67-4.9s5.37,1.87,7.47,0.93c2.1-0.93,2.1-4.44-0.7-4.67
		s-3.27-1.4-2.34-2.1s4.2-1.63,1.17-2.8s0.47-2.57,0-4.2s-3.03-0.93-5.6-3.74s-8.17-1.63-11.67-0.23s-7.47-0.23-11.91-3.27
		s-4.67-0.7-7.47,0s-2.34,3.5-0.47,5.37c1.87,1.87-0.7,1.63-2.1,3.74c-1.4,2.1-1.4,0.93-4.67,0.7c-3.27-0.23-3.97,0.93-9.57,0.93
		s-6.3-1.63-7-2.57c-0.7-0.93-3.03-0.7-5.14,0.23c-2.1,0.93-3.74,0.47-6.77-2.1s1.87-2.8,1.63-3.97c-0.23-1.17-6.07-0.7-10.74-0.93
		s-4.9-1.17-7.94-1.4s-5.6-0.93-7.24,0.93c-1.63,1.87-5.14,0.7-7.94,0.7s-1.87,1.17-0.23,3.03s-0.93,1.87-3.03,0
		c-2.1-1.87,0-2.33-0.93-6.07s-2.8-0.23-3.5,1.17s-3.03,0-6.77-0.7c-3.73-0.7-1.63-0.7-2.34-2.1c-0.7-1.4,0,0-3.97-0.23
		s-1.63,0-5.14-0.7s-3.73,2.33-3.5,4.2s3.27-0.23,5.14-0.23s-0.7,1.4-2.34,2.33c-1.63,0.93-2.1,0.7-3.97,0.23s-3.03,2.57-4.67,2.57
		s-4.43,0-6.77,1.63s-4.9,1.4-7.7,0.47s-3.97,1.63-4.67,3.74c-0.7,2.1-2.34,2.1-5.37,1.87c-3.03-0.23,2.8-1.87,3.5-3.97
		s1.63-2.8,3.5-3.27s3.5-0.47,5.37-3.03s5.37-1.87,7.7-2.57c2.34-0.7,2.8-0.47,4.67-2.8c1.87-2.34,3.03-1.63,5.14-2.34
		s0.93-0.7,1.87-3.27c0.93-2.57,2.57-1.17,4.67-2.1s3.5-1.63,5.84-2.33s3.73-0.23,4.9-2.33s2.1-1.17,4.43-1.87s4.2-2.8,5.84-5.37
		c1.63-2.57-0.47-2.1-0.7-2.8c-0.23-0.7-1.87-0.23-3.03,0c-1.17,0.23-0.47-1.63-1.87-2.8s-1.63-1.4,0.47-0.93s1.4,0.47,3.5,1.87
		s3.27,0.47,3.97-0.7s0.93-2.33-0.7-2.33s-1.87-0.47-1.87-2.1s0.23-2.33-0.47-2.33s-2.34,1.87-3.5,1.87s-2.1-1.17-2.1-3.04
		s-1.4-1.87-1.4-1.87s-2.8,0.7-2.34-0.23c0.47-0.93-0.23-2.33-0.93-2.33s-1.63,0.47-4.2-1.4s-4.9-1.87-7-0.93
		c-2.1,0.93-2.57,1.4-5.84,0.23s-4.9,0.47-7.24,0c-2.34-0.47-3.5,0.23-4.2,2.57s-4.2,0.47-7,0.47s-1.63-0.23-0.93-2.33
		s3.03-1.17,3.97-3.27s-3.27-1.4-7.47-2.1c-4.2-0.7-3.27,0.47-9.1,0.47s-2.8-1.17-0.47-2.1s3.27,0,3.74-1.17s3.73-1.4,3.73-2.33
		s-0.93-2.1-3.5-2.1s-3.03-0.23-5.14-1.63c-2.1-1.4-3.97-1.63-5.84-0.47s-2.57,0.7-5.37,0.23c-2.8-0.47-2.8,2.33-4.9,3.04
		c-2.1,0.7-2.1,2.57-4.2,3.5c-2.1,0.93-2.57,1.87-4.2,3.27s-1.4,2.57-0.7,2.57s0.47,1.17,1.17,3.27s1.63,1.17,5.37,2.1
		s-0.23,1.63-1.87,0.93c-1.63-0.7-3.27-0.93-6.77-1.17s-4.9,0.7-7.7-0.23s-4.43-1.4-3.5,0.47s0.47,2.33,3.27,3.04
		c2.8,0.7,2.34,2.1,1.17,2.33c-1.17,0.23-2.8,0-5.37-1.17s-4.67-0.47-4.9,0.47c-0.23,0.93-1.17,0-3.04,1.87s-3.5,0-4.43,0.7
		s-3.04,0.47-3.04,0.47s-4.43,0.7-6.77,0.47s2.57-1.4,2.8-2.8c0.23-1.4,2.57-0.7,2.8-2.1c0.23-1.4-3.27-0.7-4.67-0.7
		c-1.4,0-2.8,0.47-2.8,1.17s-0.93,1.4-2.33-0.47s-2.57-0.7-2.57,1.17s-1.17,1.17-2.8,1.17s-4.43,0-6.3,0s-2.8,0-3.97,0.7
		s0.7,1.17,2.33,1.17s2.8,0.23,1.63,1.87s-2.57,0.93-4.67,0.7s-4.9,0-7,1.4s-4.67,0.23-5.6,0.23s-4.43,1.17-6.07,2.1
		c-1.63,0.93-3.27,0.7-4.9,0.7s-4.51,0.93-5.76,3.27c-1.24,2.33-4.98,1.25-7.63,0.62c-2.65-0.62-1.4,2.33-2.49,2.33
		s-2.96,1.4-1.87,1.87s1.87,1.71,1.56,2.49s-2.02-0.16-3.27-0.93c-1.25-0.78-3.27,0.62-3.27,1.56c0,0.93,2.96,0.78,4.83,1.71
		s3.11,2.49,2.64,3.11c-0.47,0.62-2.33-1.25-4.82-2.02c-2.49-0.78-1.71,2.33-1.25,2.33s2.96,0.47,4.2,1.56
		c1.24,1.09,2.96,1.25,3.11,2.65c0.16,1.4-2.96,1.09-5.92,1.09c-2.96,0-4.05,0.78-4.82,2.02c-0.78,1.24-3.42,0.62-5.14-0.16
		c-1.71-0.78-3.74,0.16-4.52,0.62c-0.78,0.47-6.69-0.31-10.89,0s-4.36,1.09-8.09,1.09s-4.36,0.93-5.6,1.56
		c-1.24,0.62-1.71,3.27-1.71,4.2s2.02,2.18,3.11,3.27s0.16,1.87-0.78,2.65c-0.93,0.78,0.78,3.11,1.4,4.36s4.51,1.56,7.78,1.56
		s0.47,2.49,2.64,4.05s1.4,2.8-0.16,3.27s-1.87,0.47-5.76-0.16c-3.89-0.62-3.89-1.87-4.67-3.42s-2.8-2.18-5.14-2.65
		s-2.65-0.47-4.05-1.87s-3.73-0.78-5.76-0.78c-2.02,0-3.42-1.71-4.67-2.65c-1.24-0.93-3.89-0.47-4.36,0.78s-2.02,1.24-3.42,2.02
		c-1.4,0.78,0.16,1.4,2.18,1.25c2.02-0.16,1.09,1.09,0.78,2.18s2.02,0.78,3.27,0.16c1.24-0.62,2.65,0.93,2.96,2.33
		s-2.49,1.24-4.05,1.24s-3.27-0.78-4.2-2.02c-0.93-1.25-2.96-0.78-2.96,0.31s-3.43-0.62-4.36,0.78s0.93,2.02,1.25,3.42
		c0.31,1.4,2.49,2.33,3.74,2.18c1.25-0.16,3.73,1.25,4.36,1.71c0.62,0.47,2.18,1.25,2.65,0.47s1.4,0.16,2.8,0.31
		c1.4,0.16,0.31,2.02,1.09,2.49s1.25,2.65,0.31,2.65s-2.33-0.47-3.27-2.02c-0.93-1.56-2.8-1.4-4.82-1.87
		c-2.02-0.47-5.45-0.47-7.78-0.31s-3.11-1.25-4.67-1.4c-1.56-0.15-0.16-2.33,0.78-3.58c0.93-1.25-0.93-0.93-1.4-2.18
		c-0.47-1.24-0.62-2.8,0.31-2.8s1.56-2.02,2.33-3.42c0.78-1.4-0.62-3.42-0.62-4.36s-0.62-2.8-1.25-2.96
		c-0.62-0.16-0.78-1.09-2.49-0.93s-0.93,2.65-0.31,2.96c0.62,0.31,0.47,3.42,0.47,4.82s-0.78,2.96-2.49,3.11s-3.58,1.56-5.29,1.71
		c-1.71,0.16-2.65,0.93-2.65,2.02s-0.62,2.18-2.33,3.74s0.16,2.33,1.71,3.11s2.18,2.65,2.64,4.36c0.47,1.71,2.65,1.56,3.27,3.42
		c0.62,1.86-1.56,3.58-3.27,4.51s-0.47,2.34-1.71,4.2c-1.24,1.87,0.47,4.2,1.24,4.82c0.78,0.62,1.4,1.25,0.31,2.49
		c-1.09,1.25-0.15,1.71,0.16,2.34c0.31,0.62,3.11,0.16,4.67,0.31c1.56,0.16,4.2-0.47,6.23-1.56c2.02-1.09-0.62,0.93,4.51,1.09
		s5.76,1.25,7.47,1.71c1.71,0.47,3.89,1.09,4.05,2.65s2.02,3.89,2.49,4.51s-0.15,2.34-2.18,2.34c-2.02,0-1.4,2.18-1.4,4.2
		c0,2.02,2.64,2.02,4.36,2.02s2.49,2.02,0.78,1.87c-1.71-0.16-3.58,0.16-4.36-1.25c-0.78-1.4-2.18-0.62-3.11-1.87s0-2.8,0.62-3.89
		s-0.78-1.71-0.31-2.8s0.31-2.02-1.09-2.65c-1.4-0.62-2.49-1.4-2.8-2.8c-0.31-1.4-0.78-2.18-3.11-2.18s-3.89-0.31-5.6,1.09
		s-1.71,0.47-4.51,0.47c-2.8,0-2.65,2.02-2.8,4.05c-0.15,2.02,0.78,2.49,1.87,3.74c1.09,1.24,1.56,2.18,1.09,4.2
		c-0.47,2.02-1.56,2.49-3.58,3.58c-2.02,1.09-1.4,2.8-2.18,5.29s-2.33,2.49-4.36,2.65c-2.02,0.16-1.56,2.02-3.89,2.02
		c-2.33,0-1.56,1.4-2.18,3.42s-2.18,1.87-3.27,2.02c-1.09,0.16-2.18,0.16-3.11-1.56c-0.93-1.71-3.74,0.16-6.23,0.47
		s-2.64,0.93-3.89-0.78s-2.49-0.16-2.96-1.4c-0.47-1.25-1.25-0.78-2.65-0.93c-1.4-0.16-1.56-0.62-1.56-2.02s2.64,0,3.11,0
		c0.47,0,2.18,0.16,4.05,0.93c1.87,0.78,3.58,1.25,4.67,1.71c1.09,0.47,2.18-1.4,3.58-1.4s1.87-0.62,1.71-1.56
		c-0.16-0.93,0.31-1.87,2.02-2.65s1.4-1.25,1.71-2.96s2.8-0.93,2.96-2.49s1.4-1.71,3.11-2.34c1.71-0.62,1.87-4.51,2.02-6.38
		c0.16-1.87,0.47-1.56,1.87-2.49s-0.78-2.34-2.33-3.11s-2.49-1.4-3.11-2.34c-0.62-0.93-1.4-1.56-1.4-3.27s0.78-3.27,0.93-4.36
		s0.15-3.43-0.31-4.36s-0.47-3.11-0.62-4.2s0.62-2.49,1.25-4.51c0.62-2.02,0.78-3.58,0.31-5.14s-1.71-3.11-2.64-3.27
		c-0.93-0.16-1.25-1.09-2.33-1.71s-0.93-2.34,0.47-2.96s1.4-1.24,1.71-2.49s0.78-2.18,2.18-3.89s1.09-5.6,0.93-7.16
		c-0.15-1.56-2.49-1.87-4.98-2.49c-2.49-0.62-6.54-1.24-9.8-1.24c-3.27,0-3.73,0.62-6.23-0.62c-2.49-1.25-2.96,0.78-4.51,2.49
		c-1.56,1.71-1.87,4.51-2.18,8.25s-2.65,3.58-4.2,6.07s-5.92,4.05-8.25,4.98s-2.65,3.89-2.49,5.14s0.31,1.56,2.8,0.62
		c2.49-0.93,0,0,1.24,2.33s-0.16,2.49-0.93,4.05s0.16,1.24,1.4,2.96c1.24,1.71-0.62,0.78-2.8,0.78s-1.24,4.51-0.47,5.76
		c0.78,1.24,1.71-1.4,2.33-1.4s3.73,1.4,4.98,1.56s1.87,2.96,2.65,4.67s1.87,2.8,3.42,2.96c1.56,0.16,1.87,3.27,0.62,4.05
		s-0.93,1.56-1.4,2.8c-0.47,1.24-2.8,0-4.05-1.4c-1.24-1.4-4.05-2.18-5.14-3.43s-1.87-2.02-3.11-2.02s-3.11-0.93-4.82-2.18
		c-1.71-1.25-4.98-1.87-6.69-1.87s-2.18-0.62-3.11-2.02s-5.14-1.87-7.47-2.34s-10.58-2.8-13.23-2.18c-2.65,0.62-1.71-0.93-3.89-0.93
		c-2.18,0-2.8,0.62-4.2,2.02s-1.71,0.62-1.87,2.02c-0.15,1.4,1.4,1.87,1.87,3.27s0,1.87,1.09,2.02s1.09,2.18,0.16,3.42
		s-2.49,1.4-4.67,1.56s-1.87,1.24-0.31,1.87s0.16,1.87-1.09,1.87c-1.24,0-2.49,0.47-4.2-0.47c-1.71-0.93-1.4-2.8-0.47-3.27
		s1.71-1.56,0.78-2.18c-0.93-0.62-2.02-1.87-3.11-1.56s-2.8,0.31-3.27,1.71c-0.47,1.4-1.56,1.25-3.27,1.25s-2.18,0.31-3.43,1.56
		s-2.18,1.25-4.05,0c-1.87-1.24-2.02-0.16-5.6-0.31c-3.58-0.16-1.71,1.09-4.05,1.24c-2.34,0.16-2.65,1.09-3.11,2.96
		c-0.47,1.87-1.09,1.71-3.11,0.31c-2.02-1.4-0.93,0.93-2.34,0.31c-1.4-0.62-2.34,1.25-4.98,0.93c-2.65-0.31-0.31-1.87,1.4-2.02
		c1.71-0.16,1.71-1.71,0.93-2.65c-0.78-0.93,0.62-2.18,0.78-3.11s1.24-2.49-1.56-2.02s-3.74,1.56-5.91,2.33
		c-2.18,0.78-1.56,1.71-1.56,3.27s-0.62,1.56-1.56,1.56s-0.62-0.31-0.93-1.87s-2.18,0.78-3.74,0.31c-1.56-0.47-3.89,0.78-6.07,1.71
		c-2.18,0.93-1.87,2.49-5.76,3.42s-3.27,2.02-5.14,2.65c-1.87,0.62-2.49,0.16-2.65,1.25c-0.15,1.09-1.4,0.62-3.74,0.47
		c-2.34-0.16-2.96,1.71-2.65,2.96s-1.24,2.8-1.71,4.82s-2.96,1.09-4.05,2.18c-1.09,1.09-3.58,0.62-5.45,0.62s-3.11-1.56-4.04-3.27
		s-2.34-2.02-3.58-2.49c-1.25-0.47-1.25-1.4-1.09-2.65s1.87-0.47,1.87-1.71s1.24-0.47,2.96-1.24c1.71-0.78,3.74-0.78,5.76-0.78
		s0.16-2.65-0.78-4.05c-0.93-1.4-2.18-0.78-2.49-2.02c-0.31-1.25-1.71-1.87-2.34-1.87c-0.62,0-1.87-0.62-2.49-1.4
		c-0.62-0.78-3.89,0.62-6.07,0.78c-2.18,0.16-3.58-0.16-5.29-1.4c-1.71-1.24-2.65,0.78-2.34,1.24c0.31,0.47,0.93,0.78,3.42,1.71
		s2.18,2.49,2.34,4.67c0.15,2.18-0.62,2.8-1.56,4.98c-0.93,2.18-1.71,2.33-1.71,3.89s2.65,1.87,4.2,2.18
		c1.56,0.31,0.47,1.56,0.62,4.51c0.15,2.96-0.16,2.18-1.56,3.11s-0.47,2.33-0.31,3.42c0.15,1.09,0,2.65-0.93,2.49
		s-0.78-1.56-1.09-2.65c-0.31-1.09-2.34,0.16-3.11,1.25c-0.78,1.09-1.87-0.47-0.16-1.56c1.71-1.09,1.4-2.02,0.31-2.33
		c-1.09-0.31-2.18-0.93-3.89-0.93c-1.71,0-2.18,0.31-3.27-0.78s-2.8,0.62-4.2,2.49c-1.4,1.87-3.42,2.8-6.38,3.11
		s-2.96,3.74-5.92,4.36s-2.18,1.87-2.49,2.96s2.02,2.96,3.74,3.74c1.71,0.78,1.56,2.8,1.56,4.83c0,2.02-2.02,0.93-3.11,0
		c-1.09-0.93-2.18-0.16-3.27,0.47c-1.09,0.62-2.18-0.47-3.11-1.71c-0.93-1.25-3.74-0.93-4.51-0.47c-0.78,0.47-1.71,0.31-2.02-0.78
		s-2.49-0.93-3.58-2.96c-1.09-2.02-1.56-1.09-3.74-1.09s-1.25,1.87-3.27,2.96s-0.78,2.65,0.31,2.96s2.02,1.56,3.58,3.11
		c1.56,1.55,1.56,0.78,3.58,0.31s2.33,1.24,2.49,3.11c0.15,1.87-1.87,2.02-2.64,2.8s-2.33,0.62-2.96-0.31
		c-0.62-0.93-1.56-0.62-3.11-0.62s-3.11-0.47-3.27-1.56s-1.25-1.24-2.33-2.18c-1.09-0.93-3.27-0.62-5.14-0.62s-2.33-1.4-2.18-2.33
		c0.16-0.93,0.16-2.65-0.62-4.51c-0.78-1.86-0.31-2.49-1.71-3.42s0.31-1.87,1.71-4.05s-0.62-2.49-0.78-4.05
		c-0.15-1.56-2.8-2.8-3.89-3.27s-2.49-0.93-4.05-0.93c-1.56,0-0.31-0.78-0.47-1.87s-1.4-0.93-2.02-0.16
		c-0.62,0.78-2.03-0.47-1.56-1.25c0.47-0.78,0.47-1.25-1.09-1.56s-0.93-0.93-2.8-2.8s-0.47-2.18,1.4-2.02
		c1.87,0.16,3.11,1.71,4.2,3.42s2.96,1.25,4.36,1.87c1.4,0.62,4.2,1.09,5.92,1.4s4.2,1.25,6.38,2.18s6.38,1.56,9.18,1.56
		s4.2,1.87,8.56,2.65s10.43-1.71,12.76-3.27s5.29-3.58,7.63-5.29c2.33-1.71,2.03-6.23,0.93-6.85c-1.09-0.62-1.71-1.71-1.87-3.58
		s-1.09-1.4-2.8-1.4s-1.87-0.62-2.49-1.87c-0.62-1.24-1.56-2.34-3.11-2.34s-2.33-1.09-4.51-1.71s-1.09-1.09-3.11-1.09
		c-2.02,0-3.11-0.47-5.76-2.8c-2.64-2.34-4.98-2.8-8.4-4.82s-3.89-0.78-5.92-2.34s-3.42-1.4-4.82-0.47s-3.11-0.31-3.74-1.24
		c-0.62-0.93-3.89-0.47-4.98-0.47s-1.56,0-1.71,2.65c-0.16,2.65-2.33,2.49-3.58,1.87c-1.24-0.62,2.02-2.02,2.49-3.89
		c0.47-1.87-1.25-2.18-1.87-1.25s-2.49,1.09-2.49,0.62s-0.78-1.56-2.8-2.18s-1.25-2.02,0.16-1.56c1.4,0.47,3.58,0.47,3.74-0.31
		c0.16-0.78-0.47-1.71-2.33-1.71s-1.4-0.93-2.96-1.71s-3.11-0.16-1.87,1.09s-1.71,0.31-2.8,2.18s-3.27,1.09-3.27,0
		c0-0.71-0.33-1.09-1.08-1.31c-0.25,0.84-0.13,1.81,0.02,2.5c0.22,0.98-0.71,1.03-1.74,0.98c-1.03-0.05-0.98-0.6-2.12-1.03
		c-1.14-0.44-1.96,0.98-2.39,2.17s-1.51,1.1-2.59,1.15c-1.09,0.05-1.52,1.41-2.28,2.77c-0.61,1.09-1.74,1.41-2.44,0.99
		c-0.31,0.39-0.75,0.83-1.23,0.91c-0.9,0.14-2.06,0.04-2.17,0.43s-0.15,0.83,0.62,0.87c0.76,0.04,1.38,0.43,1.27,1.01
		c-0.11,0.58-0.54,1.16-1.12,1.52s-0.54,1.09-0.54,1.92s-0.24,1.48,0.81,2.17s1.38,2.06,2.75,2.1c1.38,0.04,1.7,0.07,3.08,1.85
		s2.39,2.28,3.04,2.71s0.94,1.63-0.18,2.71c-1.12,1.09-2.68,3.04-3.4,3.91s-2.32,1.99-2.1,2.82s-0.69,2.03,0.69,2.61
		c1.38,0.58,1.3,1.01,1.99,1.81c0.69,0.8,1.45,3.66,2.06,4.42c0.62,0.76,1.3,1.74,1.52,2.32s1.48,3.26,0.33,3.51
		c-1.16,0.25-1.96,0.83-1.96,1.52s-0.83,1.45-0.65,2.13c0.18,0.69,1.41,1.7,0.87,2.24s-0.98,1.12-0.98,2.03s0.29,2.13,1.01,2.17
		c0.72,0.04,1.67-0.04,2.46,0.72c0.8,0.76,0.33,1.3-0.51,1.56c-0.83,0.25-0.69,1.45,0.07,1.99s0.76,1.12,1.67,1.3
		c0.9,0.18,1.92,0.72,1.92,1.41s0.04,1.77-1.05,2.13s-4.45,2.32-1.81,3.62s5.39,3.62,6.44,4.34s1.23,1.16,1.74,1.74
		c0.51,0.58,2.32,1.88,0.25,4.13s-9.39,10.1-11.07,11.67s-6.73,5.81-8.09,6.41s-2.77,2.01-3.31,2.44c-0.38,0.3-1.26,1.16-1.96,2.15
		c2.19,0.04,4.58,0.31,6,1.86c1.71,1.87,0.93,0.93,5.14,1.4c4.2,0.47,4.2,1.87,4.04,2.8s-2.49-0.16-4.67-0.62
		c-2.18-0.47-2.02,0.16-2.65,1.4c-0.62,1.25-1.87,1.25-4.05,0.93c-1.67-0.24-2.15,0.81-2.57,1.94c0.94,0.56,0.44,1.58,0.81,2.04
		c0.43,0.54,0.43,1.25-0.6,1.52s-0.87,1.74-2.28,2.61s-2.06,2.28-1.74,3.69s-0.06,2.12-0.22,3.31s1.79,3.04,2.39,4.34
		s-0.87,0.6-2.01,1.09s-0.65,1.03-0.65,2.17s1.3,1.47,2.39,2.28s0.27,1.19-0.22,2.06s-0.43,1.96,0.16,2.55
		c0.6,0.6,0.33,1.52,1.52,2.88s0.87,2.5,1.03,3.96s2.55,1.25,3.85,1.41c1.3,0.16,1.52,0.33,2.99,0.33s1.19,0.92,1.47,2.12
		c0.27,1.19,1.25,1.25,2.5,0.6s1.09-1.36,2.55-1.25c1.47,0.11,2.93,1.3,4.94,2.39s-0.16,3.09,0.6,3.91s0.98,1.58,0.22,2.99
		s0.33,1.74,1.09,3.04c0.76,1.3,2.82,4.67,4.02,4.83c1.19,0.16,1.36,1.19,1.52,2.33s1.63,0.54,2.66,0.54s1.3,1.47,2.5,2.55
		c1.19,1.09,0.49,1.19,0.27,1.79c-0.22,0.6-1.09,1.47-2.28,1.52s-1.3,1.19-2.12,1.25c-0.81,0.05-1.25-0.65-2.77-1.36
		s-1.41,0.43-1.9,1.41c-0.49,0.98-0.11,1.19,0.65,1.57c0.76,0.38,0.49,1.36,1.09,2.39s-0.71,0.76-0.27,1.9s1.14,1.96,2.01,2.88
		s2.5,0.27,2.93-0.65c0.44-0.92,1.03-0.98,1.68-1.36s1.14,0.65,2.61,0.92s1.85-1.14,2.66-1.9s2.01,1.14,2.5,0.49
		s0.71,0.27,1.85,0.54c1.14,0.27,1.41,2.61,2.55,3.64s0.27,1.25-0.38,1.36s-0.92,0.81-0.6,1.25c0.33,0.43,0.6,1.47,0.71,2.82
		s1.36,0.71,2.61,1.41s2.06-0.49,3.04-0.33s1.63,2.06,2.28,3.37c0.65,1.3,0.76,3.75,1.25,4.23c0.49,0.49,1.63,0.54,1.96,0
		s0.98-0.16,1.9-0.05s1.63,1.36,2.61,1.63s1.96-0.16,2.28-0.98c0.33-0.81,2.17-0.43,2.82-1.03s2.5,2.06,4.02,3.58
		s2.01,0.33,3.04,0.22s2.5,0.54,3.37,1.68s2.06,0.11,2.88-0.33c0.81-0.43,1.96,0.87,3.2,2.12s1.74,0.6,2.66,0.43
		c0.92-0.16,1.3,0.71,1.25,1.57s-0.06,1.25-1.25,2.39s-0.22,1.63,0.22,2.34c0.43,0.71-0.49,0.81-1.52,0.98
		c-1.03,0.16-0.65,1.47-0.16,2.01s0.54,0.92,1.09,1.79c0.54,0.87,0.87,0.92,0.6,1.79c-0.27,0.87-0.87,0.81-1.14,2.01
		c-0.27,1.19-3.58,0.49-5.59,0.49s-2.12,1.47-3.31,1.9s-1.36,1.25-2.28,1.85c-0.69,0.44-0.59,1.96-0.5,2.72
		c0.71-0.23,1.4-0.5,2.32-0.65c1.87-0.31,2.65-1.4,4.67-0.47s-0.31,1.4-0.93,1.4s-0.62,1.4-1.56,1.56
		c-0.93,0.16-2.65,0.31-2.96,1.71s-2.8,0-3.58,1.4s0.93,1.56,3.27,2.65c2.33,1.09,0,1.25-1.71,1.25s-0.78,1.56-2.33,2.49
		c-1.56,0.93-1.09,0.93-1.09,2.8s-2.96,0.78-4.51,0.78s-2.02,1.87-2.02,2.8s2.33,0.31,3.74,1.24c1.4,0.93,2.49,2.96,4.36,2.8
		s2.18,2.18,6.23,3.11c3.52,0.81,5.16,4.46,7.89,6.41c0.54-0.1,1.13-0.21,1.44-0.29c0.65-0.16,1.9-0.33,2.55,0s1.85-0.05,2.88,0.33
		c1.03,0.38,2.71,1.74,4.18,2.33s2.23,0.65,4.02,0.49c1.79-0.16,5.7-0.54,6.41,0.33s1.47,1.68,2.71,1.9
		c1.25,0.22,2.01,0.49,2.17,1.14s1.09,1.85,1.85,1.85s1.79-0.43,2.23-0.98c0.43-0.54,1.09-0.76,2.55-0.76
		c1.47,0,3.26-0.49,3.58,0.33c0.33,0.81,0.43,1.3,1.57,1.3c1.14,0,2.71,0.54,2.71,1.79s-1.74,1.19,0.16,2.17
		c0.12,0.06,0.24,0.11,0.35,0.16c0.02,0.01,0.05,0.02,0.07,0.02c0.09,0.04,0.18,0.07,0.27,0.1c0.02,0.01,0.03,0.01,0.05,0.01
		c0.1,0.03,0.19,0.05,0.28,0.08h0.01c1.38,0.3,2.46-0.17,3.34,0.58l0,0c0.01,0.01,0.02,0.01,0.03,0.02
		c1.09,0.98,2.23,0.92,3.31,2.44s2.82,3.42,3.8,3.64s2.44,0.6,2.99-0.27s0.87-1.41,2.12-1.57s1.25-0.87,1.85-1.68
		c0.34-0.46,0.76-1.37,1.07-2.08l0,0c-0.07-0.08-0.16-0.15-0.25-0.2c-0.76-0.43-1.52-0.87-2.06-2.28s-2.61-3.37-4.02-4.78
		s-0.87-5.1-0.87-6.62s0-1.95-0.54-2.71c-0.54-0.76-0.98-1.41-1.74-2.5c-0.76-1.09-1.74-1.41-2.93-2.06
		c-1.19-0.65-0.76-2.28,0.76-2.93c1.52-0.65,0.43-1.3,1.74-2.17c1.3-0.87,1.09-1.74,1.85-2.71c0.76-0.98,0.76-1.19,0.76-2.28
		s2.71-0.87,2.71-0.87c1.19-0.11,1.52,0.87,2.17-0.22s0.76-0.76,1.74-1.19c0.98-0.43,0.98,0.11,1.63,0.65
		c0.65,0.54,1.19-1.52,1.3-2.17s0.43-0.98,1.52-1.09c0.08-0.01,0.16-0.02,0.23-0.03c0.02,0,0.04-0.01,0.06-0.01
		c0.05-0.01,0.11-0.02,0.15-0.03c0.01,0,0.03-0.01,0.04-0.01c0.06-0.02,0.11-0.03,0.16-0.05c0.01,0,0.01-0.01,0.02-0.01
		c0.04-0.02,0.07-0.03,0.11-0.05c0.01-0.01,0.02-0.01,0.03-0.02c0.04-0.02,0.07-0.05,0.1-0.07l0,0c0.03-0.03,0.06-0.06,0.08-0.09
		c0.01-0.01,0.01-0.02,0.02-0.02c0.02-0.03,0.04-0.06,0.05-0.09c0-0.01,0.01-0.01,0.01-0.02c0.02-0.04,0.04-0.08,0.05-0.13
		c-1.46-1.43-2.62-1.61-4.2-2.32c-1.96-0.87,1.38-2.17,1.45-2.9c0.07-0.72-1.09-2.32-1.88-2.9c-0.8-0.58-1.81-2.53-2.61-3.84
		c-0.8-1.3-3.11-1.3-4.13-1.59c-1.01-0.29-1.59,0.51-2.75,0.51s-1.45-2.46-1.52-4.05s-2.17-1.09-3.48-2.24
		c-1.3-1.16,0.15-1.38,0.36-2.39c0.22-1.01,1.3-1.95,2.53-2.97c1.23-1.01-0.15-2.1-0.8-2.9c-0.65-0.8-0.29-2.24,0.15-3.11
		c0.43-0.87,1.01-1.96,2.39-2.68c1.38-0.72,0.51-2.17,1.45-3.18s1.74-0.43,2.39,0.36c0.65,0.8,1.81,1.3,3.11,3.4
		c1.3,2.1,2.68,0.8,3.84-0.07c1.16-0.87,0.72-1.38-0.43-2.03c-1.16-0.65-0.22-2.1-0.22-3.48s0.29-0.94,2.46-1.3
		c2.17-0.36,2.97-2.24,3.4-3.33s1.81-1.3,3.55-1.45c1.74-0.14,3.04-1.3,3.69-2.61c0.65-1.3,1.09-2.1,2.68-2.03s1.3,1.38,2.97,2.17
		c1.66,0.8,2.75-0.22,2.82-1.38s1.3-0.72,2.46-0.72s1.88,0.51,2.24,1.74c0.36,1.23,3.76,0.51,5.72,0.43
		c1.96-0.07,1.81,2.39,3.11,2.68s1.81,1.66,4.05,2.97c2.24,1.3,0.15,3.11,1.45,3.26c1.3,0.14,0.94-2.46,0.94-3.33
		s1.09-0.72,3.11,0.94c2.03,1.66,2.93,1.95,4.13,1.85c1.19-0.11,1.99-1.38,2.82-2.39s1.12-1.63,2.17-1.38s1.99-0.69,3-0.76
		c1.01-0.07,2.68,1.38,3.65,1.92c0.98,0.54,2.03-1.16,2.13-1.81c0.11-0.65,2.9-0.29,3.87-0.33c0.98-0.04,2.03,2.03,2.68,2.86
		s1.59,1.05,2.86,1.05s1.77,0.4,2.71,1.74c0.94,1.34,2.35-0.94,3.26-2.42s1.66-0.51,2.21,0.29c0.54,0.8,2.82,0.72,3.91,0.72
		s1.59-1.01,2.43-1.01c0.83,0,1.16-1.66,2.32-2.5s1.09-1.74,0.65-2.1s-1.67-1.67-2.9-1.92s-1.23-0.72-1.74-1.16
		c-0.51-0.43-1.41-0.29-2.5-0.33s-1.19-1.77-2.53-2.28s-1.01-1.52-0.54-1.59c0.47-0.07,1.23-0.43,2.21-0.72s1.52-0.54,2.75-1.34
		s0.8-2.32-0.29-3.04c-1.09-0.72-0.94-1.63-0.18-1.92c0.76-0.29,0.62-1.34,1.19-1.92c0.58-0.58,3.91,0.33,5.43,0.65
		c1.52,0.33,1.77-1.41,1.59-1.88s-1.67-0.36-3.18-0.58c-1.52-0.22-1.34-0.8-1.3-1.77c0.04-0.98-0.98-0.87-1.66-1.16
		c-0.69-0.29-0.8-0.76-0.58-1.27c0.22-0.51,0.15-1.52-0.33-2.57c-0.47-1.05,1.34-0.83,1.81-1.12s1.63-0.4,2.39,0.14
		s2.06,0.65,3.04-0.33s2.28,0.43,4.23,0.11c1.95-0.33,2.82-0.33,4.23-1.3c1.41-0.98,3.69-0.33,4.45-1.19
		c0.76-0.87,2.82-0.65,4.89-0.65s3.91-0.11,4.78-1.3s2.5-1.3,4.23-1.52c1.74-0.22,9.34-2.06,11.62-2.28s3.8,0,4.56-2.17
		s2.77-0.81,3.96-1.68s2.44-1.52,4.78-0.76c2.33,0.76,2.33,1.3,3.91,2.06c1.58,0.76,1.9-0.27,2.12-0.98
		c0.22-0.71,1.19-0.54,2.01-0.22c0.81,0.33,1.3,1.14,1.57,2.66c0.27,1.52,0.43,2.01,1.03,3.04s0.71,0.65,0.76,1.63
		c0.05,0.98-0.38,1.68-1.19,2.12s2.28,0.98,3.53,1.74c1.25,0.76,1.36-1.19,1.9-1.19s1.3-0.05,2.28-1.03s0.81,0.81,1.3,1.19
		s1.09,1.03,1.41,2.17c0.33,1.14,1.25-0.11,1.68-1.09s1.09,0.49,1.85,1.19s2.01,0.76,2.66-0.16s1.3-1.14,1.95-0.43
		c0.65,0.71-0.49,1.09-0.87,1.85c-0.38,0.76-0.49,0.98-1.68,1.41s-0.49,1.63,0.65,2.61c1.14,0.98,2.01-1.57,2.5-1.74
		c0.49-0.16,1.63-0.38,2.28-0.05s1.41,0.54,2.39,1.3c0.98,0.76,0.65-1.25,0.76-1.85c0.11-0.6,0.98-0.98,1.25-1.47
		s1.3-0.16,2.71-0.27s1.68-1.14,1.68-1.96s0.92-0.65,2.23-0.65c1.3,0,1.43-0.11,2.01-0.83c0.58-0.72,2.03-1.3,3.55-1.52
		s2.82-0.87,2.82,0.29s-1.67,1.52-2.53,2.82c-0.87,1.3,2.17,2.46,5.14,4.27s5.94,6.73,8.54,9.34c2.61,2.61,7.82,13.68,9.7,16.29
		c1.88,2.61,3.84-1.09,3.91-2.17s1.38-2.32,2.24-2.1c0.87,0.22,2.24,0.8,2.24,2.75c0,1.96,1.45,0.15,1.95,1.01
		c0.51,0.87,1.01,1.09,1.59,1.01s1.81-0.14,2.82,0.65c1.01,0.8,1.81,1.01,2.39,0.15c0.58-0.87,2.1-0.58,3.55-1.52
		c1.45-0.94,3.18-1.01,4.85-0.58s3.11,3.04,3.84,4.99c0.72,1.96,2.17,1.81,4.42,2.03c2.24,0.22,1.23,1.74,2.03,3.33
		s1.88,1.67,3.62,2.39s4.56,0,5.65-1.38s2.17,0,2.17,1.01s0.43,1.3,1.52,1.88c0.07,0.04,0.14,0.08,0.2,0.13
		c0.02,0.01,0.03,0.03,0.05,0.04c0.04,0.03,0.07,0.06,0.11,0.1c0.02,0.02,0.03,0.04,0.05,0.05c0.03,0.03,0.05,0.07,0.07,0.1
		c0.01,0.02,0.02,0.04,0.03,0.06c0.02,0.04,0.03,0.07,0.05,0.11c0.01,0.02,0.01,0.04,0.02,0.06c0.01,0.04,0.02,0.08,0.02,0.12
		c0,0.01,0,0.03,0.01,0.05c0,0.05,0,0.1,0,0.16c0,0.01,0,0.01,0,0.02c-0.02,0.13-0.05,0.25-0.11,0.38l0,0
		c0.38,0.13,0.78,0.33,1.04,0.66c0.65,0.8,1.16,0.43,2.17,0.51c0.07,0.01,0.14,0.01,0.21,0.02c0.04,0,0.08,0.01,0.11,0.01
		c0.02,0,0.05,0.01,0.07,0.01c0.3,0.04,0.57,0.07,0.84,0.05c0.01,0,0.01,0,0.02,0c0.06-0.01,0.11-0.01,0.17-0.03
		c0.01,0,0.01,0,0.02,0c0.06-0.01,0.12-0.03,0.18-0.05c0.08-0.03,0.15-0.07,0.23-0.11c0.01-0.01,0.02-0.01,0.03-0.02
		c0.08-0.04,0.15-0.1,0.23-0.16l0.01-0.01c0.08-0.06,0.16-0.14,0.24-0.22c0.02-0.02,0.04-0.04,0.06-0.06
		c0.08-0.09,0.17-0.18,0.25-0.3c1.01-1.3,2.1-2.9,3.4-2.9s2.24,0.8,3.55-0.07c1.3-0.87,1.88-1.16,2.97-0.87
		c1.09,0.29,1.52-0.72,1.81-1.59s-0.22-2.1,1.23-2.1s1.88-1.74,3.84-1.81c1.95-0.07,3.33-0.65,4.34-1.59s2.03-1.23,3.55-1.16
		s0.36-1.74,2.24-1.67s1.67-1.01,3.04-1.09s2.1-0.14,2.17,0.58s0.94,1.09,1.67,0.8c0.72-0.29,1.01-0.58,1.38,0.15
		c0.36,0.72,0.87,0.65,3.11,0.65s6.15-0.51,5.79,0.72s-0.14,3.11,1.09,3.55s2.39-0.36,3.11,0.65c0.72,1.01,1.52,0.43,2.39,0.94
		c0.87,0.51,1.74,1.09,2.46,0.29s1.74-1.16,2.97-0.8s0.65,1.16,2.17,1.16c1.52,0,3.62-0.72,4.05,0.22s1.01,0.36,1.52,1.01
		c0.51,0.65,0.58,0.29,0.87-0.22s1.16-0.07,1.59-0.72s0.43-1.09,1.74-1.3s1.81-0.22,2.32-1.23s0.22-1.3,1.09-1.81
		s0.87-1.16-0.29-1.88s-1.45-0.94-1.74-1.95s-1.16-1.23-1.23-2.24c-0.07-1.01-0.14-1.38,0.43-2.24s0.22-1.95,1.23-2.24
		c1.01-0.29,1.38-2.39,2.97-2.97c1.59-0.58,2.03-0.65,2.24-1.59c0.22-0.94,0.15-2.17,1.45-0.94s1.81,1.09,2.97,1.3
		c1.16,0.22,1.52,0.22,2.03,1.01c0.51,0.8,1.16,0.8,2.82,0.72c1.66-0.07,2.46-0.43,3.33,0.29c0.87,0.72,1.74,1.3,2.75,1.38
		c1.01,0.07,1.52,1.16,3.18,1.09s3.11-0.22,3.47,0.87s0.36,1.95,0.94,2.53s0.65,1.52,0.8,2.68c0.15,1.16-0.15,1.52,0.94,2.17
		s1.09,1.23,3.33,1.81s2.9,0.51,4.05,1.3c1.16,0.8,2.53,0.72,3.69,0.51c1.16-0.22,1.16-0.87,2.68-1.67s1.45-0.72,2.68-0.8
		c1.23-0.07,0.87-0.87,2.61-0.87s2.17-0.14,3.26,0.51s1.66,0.43,2.46,0.65s0.51,0.94,1.81,0.87s2.24-1.52,3.55-0.36
		c1.3,1.16,1.66,3.04,3.4,3.04s4.42-0.51,4.56,1.23c0.15,1.74,0.65,2.46,1.88,2.97s1.23,1.81,2.9,1.66
		c1.66-0.14,2.53-1.09,4.49-0.14c1.96,0.94,1.95,1.45,3.84,1.45c1.88,0,5.14,0.15,6.88,0.07s1.38-1.81,3.69-1.81
		c2.32,0,3.77-0.36,5.21-0.58c1.45-0.22,1.66-0.8,3.18-0.8s3.4,0.14,3.62-1.01c0.22-1.16-0.07-1.67,1.3-2.24
		c1.38-0.58,1.3-1.3,2.75-1.59s0.87-0.36,1.96-1.09s0.8-0.8,2.39-0.87c1.59-0.07,2.17,0,4.13,0.72s1.01,1.45,2.32,2.24
		c1.3,0.8,3.47,1.01,4.13,0.36s0.94-1.3,2.24-1.09c1.3,0.22,2.46,0,3.26,0.94c0.09,0.11,0.17,0.22,0.25,0.33l0.01,0.01
		c0.07,0.11,0.14,0.22,0.2,0.33l0,0l0,0c0.04,0.06,0.07,0.13,0.11,0.19c0,0,0,0,0,0.01c0.03,0.05,0.06,0.1,0.09,0.15
		c0,0.01,0.01,0.01,0.01,0.01c0.03,0.05,0.07,0.1,0.1,0.15c0,0.01,0.01,0.01,0.01,0.02c0.03,0.04,0.06,0.08,0.09,0.12
		c0.01,0.01,0.02,0.03,0.03,0.04c0.03,0.04,0.06,0.07,0.09,0.11c0.01,0.01,0.02,0.02,0.03,0.03c0.04,0.04,0.09,0.09,0.14,0.13
		c0.01,0.01,0.02,0.01,0.02,0.02c0.04,0.03,0.09,0.07,0.14,0.1c0.02,0.01,0.03,0.02,0.05,0.03c0.04,0.03,0.09,0.05,0.14,0.08
		c0.02,0.01,0.03,0.02,0.05,0.03c0.07,0.03,0.14,0.07,0.22,0.1c0.01,0,0.02,0.01,0.02,0.01c0.07,0.03,0.15,0.05,0.22,0.08
		c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0.02,0.14,0.04,0.21,0.05c0.03,0.01,0.06,0.01,0.09,0.02c0.1,0.02,0.22,0.04,0.33,0.06
		c2.1,0.29,2.32,0.51,3.55,0.94s1.52,1.3,3.11,0.29c1.59-1.01,1.81-2.32,3.33-2.75c1.52-0.44,1.66-1.23,2.97-1.23
		c1.3,0,2.61-0.51,2.9-1.45s-0.22-1.45-0.87-1.96c-0.65-0.51-0.43-1.59,0.65-2.39c1.09-0.8,1.59-1.59,2.17-2.9
		c0.58-1.3,1.38-1.88,1.81-3.55c0.43-1.66,1.01-3.4,2.46-4.2s3.04-1.45,3.47-2.39s0.43-1.23-0.07-1.66c-0.51-0.43-0.8-1.01-0.8-1.67
		c0-0.65-0.07-1.95-1.38-1.88c-1.3,0.07-2.68,0.07-2.68-0.72s-0.15-1.09,1.16-1.45c1.3-0.36,1.52-2.17,3.26-3.04
		s1.59-1.16,2.68-1.16s1.3-0.87,2.75-0.94s2.46,0.07,3.91-0.22c1.45-0.29,0.87-0.87,3.04-0.87s2.75,0.58,4.27,0.14
		c1.52-0.43,2.32-1.01,3.33-0.72s2.24,1.23,3.47,1.45s1.88,0.8,2.32,1.3c0.43,0.51,0.94,0.8,2.39,0.72
		c1.45-0.07,4.49-0.43,5.14,0.65s1.16,2.03,2.32,2.39c1.16,0.36,1.59,1.23,1.88,2.24c0.29,1.01,1.95,1.16,2.1,2.68s0,3.33,1.09,4.56
		s1.23,1.59,1.45,2.32s0.87,1.52,0.94,2.9c0.07,1.38-0.07,1.67,0.8,2.53c0.87,0.87,1.23,1.16,1.88,2.32s0.51,1.59,0.87,2.9
		c0.36,1.3,1.66,1.01,1.23,2.53s-0.36,2.9,0.51,3.69c0.87,0.8,1.88,1.81,2.82,1.95c0.94,0.15,1.59,1.16,2.32,0.15
		s1.45-1.09,2.24-0.43s2.1,2.61,3.69,1.74s2.17-0.22,3.26,0.8c1.09,1.01,1.45,2.82,2.97,3.33c1.52,0.51,3.4,0.07,3.4,1.09
		s-0.22,1.66,0.65,2.24s0.51,2.1,0.51,2.97s-1.23,1.88,0.22,2.61c1.45,0.72,1.45,1.52,1.74,1.81s1.45,0.51,2.53-0.07
		c1.09-0.58,1.23,0.36,2.9,0.51c1.66,0.15,2.82,0.36,3.91-0.72c1.09-1.08,2.03-2.82,3.4-2.9c1.38-0.07,2.97,0,3.91-0.58
		s0.87-0.8,2.46-1.09s1.16-1.45,2.46-1.45s3.4-0.07,3.55,0.72c0.15,0.79,0.65,1.52-0.15,1.88c-0.8,0.36-1.74,1.09-0.72,1.81
		c1.01,0.72,1.3,1.66,0.8,2.82c-0.51,1.16-1.16,1.38-2.03,1.45c-0.87,0.07-1.59,1.16-1.59,2.68s-1.66,2.75-1.59,4.13
		s0.15,3.26-0.72,4.05c-0.87,0.8-1.38,0.94-1.81,2.39s-0.65,2.97-1.74,3.33c-1.09,0.36-1.38,0.65-1.45,2.17s-0.43,2.24-2.17,1.95
		c-1.74-0.29-3.4-1.01-4.49-1.59c-1.09-0.58-2.17-1.09-2.82-0.07c-0.65,1.01-1.09,1.88-2.32,2.1s-2.97,0.22-3.11,1.09
		c-0.15,0.87,0.36,1.74,0.94,2.24c0.58,0.51,0,1.16,0.58,1.67s0.72,1.59,0.65,2.68c-0.07,1.09-0.36,3.33,0,4.2s0.15,2.03,0,3.04
		s-0.58,0.65-1.16,1.74c-0.58,1.09-0.94,1.52-2.1,1.59s-1.23-0.36-1.45,0.58c-0.03,0.12-0.07,0.23-0.11,0.34
		c-0.01,0.02-0.02,0.03-0.03,0.05c-0.04,0.09-0.09,0.17-0.14,0.24c0,0.01-0.01,0.01-0.02,0.02c-0.13,0.17-0.28,0.3-0.44,0.4l0,0
		c0.04,0.3,0.13,0.57,0.3,0.76c0.35,0.39,0.59,0.7,0.78,1c1.29-2.14,4.96-3.82,7.57-4.43c2.65-0.62,4.2,0.93,7.47,1.56
		c3.27,0.62,5.29-0.15,7.94-1.24s6.69-4.51,8.56-6.85s6.54-7,10.12-11.05s3.42-3.89,7-6.69s4.82-7.16,5.91-10.12
		c1.09-2.96,3.27-3.11,5.6-6.85c2.34-3.74,3.11-4.67,4.67-5.91s2.03-3.89,2.18-6.07c0.16-2.18,0.31-2.02,1.4-4.2
		s0.31-5.76-0.31-7.94c-0.62-2.18,0-2.8,0.93-4.51c0.93-1.71,1.4-3.97,2.18-6.07c0.78-2.1,2.03-2.18,2.65-3.66
		c0.62-1.48-0.08-2.33-0.85-3.11c-0.78-0.78-0.08-1.71,0.23-2.26c0.31-0.54,0.31-1.95-1.09-3.5s0.7-0.93,1.87-1.79
		s-0.23-1.79-1.79-2.65c-1.56-0.85-1.63-1.01-3.35-1.87c-1.71-0.86-2.18-3.04-3.03-4.75c-0.86-1.71-2.02-1.63-2.96-1.24
		s-3.03,0.23-4.67-0.47c-1.63-0.7-2.18-0.93-2.41,0.39c-0.23,1.32-0.54,2.88-1.79,2.88s-2.41,1.01-3.19,2.65
		c-0.78,1.63-2.57,1.09-3.66,0.31c-1.09-0.78,0.93-1.56,1.95-2.65c1.01-1.09-1.17-0.62-1.79-1.56c-0.62-0.93,1.71-1.95,2.41-3.42
		s-1.79-0.7-2.1-0.16s-1.32,1.09-2.49,1.71s-0.08,1.87-0.15,3.04c-0.08,1.17-0.78,1.79-2.18,1.79c-1.4,0-0.93-3.42-0.93-4.9
		s1.56-2.57,1.17-3.58s-3.35-0.62-4.36,0s-2.26,0.54-3.58-0.31c-1.32-0.86-2.88-0.47-3.27-1.56s1.56-2.88,3.58-3.5
		c2.03-0.62,2.72-1.09,3.81-2.8s1.48-1.4,4.36-2.65c2.88-1.24,5.06-4.75,7.7-6.07c2.65-1.32,0.47-2.1,1.56-3.19s2.49-2.88,4.51-4.36
		c2.03-1.48,3.19-1.56,4.9-3.11c1.71-1.56,2.41-2.8,4.51-3.43c2.1-0.62,1.63-1.48,2.8-4.2s2.18-2.26,4.59-3.11
		c2.41-0.86,1.79-3.04,3.66-5.14s3.66-1.4,6.46-2.41c2.8-1.01,3.19-0.54,6.15-0.93s4.28-0.31,6.77-0.31s4.36-0.08,6.15-0.15
		c1.79-0.08,1.48,1.94,3.03,2.41s1.4-1.17,2.41-2.41c1.01-1.24,2.88,0.62,3.89,0.39s1.94-0.08,3.27,0.86
		c1.32,0.93,2.88-0.7,3.66-1.56c0.78-0.86,1.32-0.15,2.26,0.86c0.93,1.01,1.79,0.62,2.8,0.54s0.78-0.86,0.78-1.94
		s1.4-0.39,1.79-1.25c0.39-0.86,2.18-1.63,3.97-1.56c1.79,0.08,3.74,0.93,5.6,2.02c1.87,1.09,1.79,1.4,3.03,1.01
		c1.24-0.39,1.79-1.48,3.81-0.47s1.09,1.25,3.03,2.57c1.95,1.32-1.25,0.78-3.11,0.7c-1.87-0.08-2.49,2.02-1.17,3.19s2.1,0.78,3.35,0
		s1.87-0.54,2.57-1.09c0.7-0.54,2.26,0.23,3.42,0.86c1.17,0.62,1.48,0.08,2.26-0.54s2.26-1.4,2.8-2.72s3.5,0.62,4.36,1.32
		c0.85,0.7,1.56,0,1.79-0.7s2.03-0.47,3.5-0.16c1.48,0.31,1.71-0.47,2.88-0.78s0.31-1.32-0.31-1.94s-1.48-0.16-3.03-1.01
		c-1.56-0.86-1.4,1.01-2.18,1.01s-0.86-1.56-0.93-2.8c-0.08-1.25,0-1.87,0.7-1.87s1.4-1.56,1.87-2.49c0.47-0.93,2.1-1.94,3.97-3.11
		s3.03-3.11,5.45-4.9s5.37-5.53,7.55-7.55s4.59-2.1,6.15-1.63s2.02,0.54,3.03-0.54s3.74,0.39,4.05-0.39s1.01-0.7,1.87-0.62
		c0.85,0.08,1.25,1.09,1.95,2.18s2.57-0.08,3.11-1.17s1.56-1.25,2.41-0.31c0.85,0.93-0.39,0.93-0.7,2.02s-0.7,1.09-2.02,2.26
		s-1.25,0.62-0.85,1.95s0.93,0.47,0.93,1.94s0.47,1.4,1.24,0.86c0.78-0.54,1.17,0.16,1.25,1.09s-1.01,0.7-1.4,2.41
		s2.34,0.23,3.73-0.62c1.4-0.86,3.66-2.41,5.6-4.75c1.95-2.33,4.51-3.27,6.69-4.67s2.18,0.08,3.5,0.31s1.48-1.32,1.09-2.49
		c-0.39-1.17,0-1.17,0-2.33s-0.08-1.71,0.31-2.26c0.39-0.54,1.48-2.1,2.03-3.35c0.54-1.25,2.8,0,3.27-0.93s2.1-0.23,3.81-0.31
		c1.71-0.08,2.65,0.7,2.65,1.79c0,1.09-2.57,0-3.58,0.47s-1.32,2.18-1.48,3.74s0.31,2.65-1.01,4.05c-1.32,1.4-1.09,1.25-1.25,2.02
		c-0.16,0.78,1.25,0.86,1.48,1.79s0.08,0.78-1.32,1.48s-1.32,1.79-1.4,3.27c-0.08,1.48-1.95,1.01-3.97,1.48
		c-2.02,0.47-3.03,1.94-4.51,2.49s-2.88,0.47-3.27,2.18s-0.55,1.63-2.26,2.88s-2.72,2.8-4.2,3.66c-1.48,0.86-3.19,2.72-5.06,3.81
		s-1.63,1.48-3.03,3.81s-2.26,2.49-4.28,4.28s-2.26,2.02-4.59,3.74c-2.34,1.71-2.99,1.72-4.21,1.52c-1.22-0.2-1.4,0.18-2.28,1.34
		c-0.88,1.17-1.23,0.88-2.74,0.7c-1.52-0.18-1.14,0.06-2.19,0.44s-0.06,1.25,0.55,3.06s-1.43,3.36-2.25,3.97s-2.74,2.07-3.97,4.46
		s-2.31,5.52-2.31,6.57s-0.7,5.05-0.79,7.59c-0.09,2.54,2.39,12.87,2.92,13.77s-0.26,3.71,0.29,6.54c0.55,2.83,1.28,3.04,2.01,4.35
		c0.73,1.31,0.03,5.63,0.76,6.71s0.15,3.65,0.7,5.02s0.9,1.14,0.9,2.48s3.65-1.46,4.26-2.16s1.87-1.55,4.09-3.47
		c2.22-1.93,2.89-2.28,3.85-3.97c0.96-1.69,1.05-3.53,1.28-5.46s1.23-1.87,2.04-2.8c0.82-0.93,2.54-0.99,3.27-1.58
		c0.73-0.58,2.07-0.93,3.24-0.12c1.17,0.82,1.25,0.5,1.72,0.06s-0.41-1.2-0.61-1.6s0.09-1.49-0.5-2.51s-0.41-1.49-0.32-2.45
		c0.09-0.96,0.5-2.19,1.34-2.54c0.85-0.35,1.9-1.93,2.77-2.92s4.32-1.55,6.16-1.02s3.01-0.64,4.38-2.07
		c1.37-1.43,0.29-2.54-0.79-3.5c-1.08-0.96-0.88-1.75-1.2-3.47s0.23-2.6,1.17-4.32c0.93-1.72,2.74-2.13,3.33-3.73
		c0.58-1.6,1.66-0.38,2.74,1.11s1.63,0.7,2.74,0.06s1.28-2.04,1.14-3.44c-0.15-1.4-1.31-1.93-2.6-2.74
		c-1.28-0.82-1.05-0.55-1.28-1.55s0.03-1.98,0.23-3.36c0.2-1.37,0.32-1.75,1.08-2.25s1.66-1.34,1.96-2.39
		c0.29-1.05-1.25-1.02-1.81-1.05c-0.55-0.03-0.73-0.5-0.88-1.17s-1.98,0.06-2.19,0.76c-0.2,0.7-0.17-0.76-1.58,0.41
		c-1.4,1.17-2.1,1.87-2.8-2.1s4.2-6.77,6.54-8.64s2.8-3.27,2.8-4.67s1.17-3.74,2.34-6.07c1.17-2.33,3.97,0,4.67-0.7s2.1,0,3.03,1.17
		c0.93,1.17,2.8-0.47,3.5-1.87s3.27-1.4,4.2-2.33s2.34-2.57,2.8-1.17c0.47,1.4-0.7,2.57-0.7,4.9c0,2.33,3.5,0.47,4.9-1.87
		c1.4-2.33,2.57-2.33,4.2-2.8s3.03-2.1,5.6-2.1s5.84,0.47,6.77,0.47s2.57,2.1,3.5,2.33s1.87,2.33,3.03,4.2s2.34-3.74,3.5-5.6
		c1.17-1.87,4.2-0.93,6.3-2.33s3.5-1.63,3.97-3.04s2.57-2.1,4.2-3.74c1.63-1.63,2.33,0.23,3.74-2.33c1.4-2.57,3.04,0,4.2-1.63
		c1.17-1.63,3.5-0.23,4.44-1.87c0.93-1.63,2.57-0.23,2.8-1.17c0.23-0.93,3.5-2.1,5.6-3.04c2.1-0.93,3.73-0.23,4.44-1.63
		c0.7-1.4,1.87-1.4,3.5-1.63c1.63-0.23,0.7-3.04,0.7-3.04s1.4,0,2.33,1.63c0.93,1.63,1.87,1.87,3.27,1.87s3.04,0.23,4.9,1.17
		s4.2,0.23,4.67-0.7c0.47-0.93,2.57-1.17,2.8-3.04c0.23-1.87-2.1-1.4-2.1-2.8s-0.7-2.33-1.4-2.33c-0.7,0-1.87-0.93-2.33-3.03
		c-0.47-2.1,0.23-3.5-0.47-4.2s-0.93-1.87-1.63-3.5s-2.1-1.17-3.04-0.47c-0.93,0.7-2.1-0.23-3.04-0.7c-0.93-0.47-1.4-1.4-1.87-2.8
		s-1.4-2.1-1.4-2.1s-2.1,1.87-3.5,1.87s-2.33,0-2.33-1.87s1.63-2.1,2.8-1.17s2.8-0.23,3.27-0.93s1.87-0.23,2.57,0.7
		s2.8,2.57,5.6,2.57c2.8,0,4.9-0.23,6.3-1.63c1.4-1.4,3.27-1.87,5.84-3.03c2.57-1.17,1.63-1.87,1.63-3.03c0-1.16,0.93-1.4,1.17-3.27
		c0.23-1.87-1.87-2.33-2.57-4.2c-0.7-1.87,2.33-0.7,3.04-2.57c0.7-1.87,1.17-0.47,2.8-0.93c1.63-0.47,4.44,1.17,1.4,3.03
		s1.17,3.5,0.93,4.9c-0.23,1.4,1.87,1.4,4.2,1.4s4.67,0,6.77-0.7s4.67,0.23,6.54,0.47c1.87,0.23,2.57,4.9,3.5,6.54
		s3.27,3.03,6.77,2.57c3.5-0.47,2.8,0.23,3.73,2.33c0.93,2.1,1.63,0.47,3.74,0.47c2.1,0,3.04,1.63,4.2,1.17
		c1.17-0.47,3.97-0.7,3.97-1.63s-0.7-0.93-2.8-1.17c-2.1-0.24-1.4-3.27,0.93-3.27s0.7-1.4,3.04-2.33c2.33-0.93,0.7-2.57-0.7-4.44
		C1127.868,90.638,1132.538,92.278,1135.798,92.278z M590.768,197.928c-1.3,0.87-0.98,3.37-1.09,4.34
		c-0.11,0.98-0.22,2.17-1.09,2.82s0,2.93-0.11,5.43s-1.19,1.41-1.52,2.71s-0.98,0.76-2.06,0c-1.09-0.76-1.52,0.98-2.5,1.96
		c-0.98,0.98-0.98,1.19,1.3,0.98c2.28-0.22,1.19,0.87,0.98,1.3c-0.22,0.43-0.65,1.09-2.28,1.19s-2.71,2.17-3.58,3.48
		c-0.87,1.3-1.19,1.3-2.71,1.19s-1.3,0.76-2.93,1.41c-1.63,0.65-2.39,0.65-2.82,1.52c-0.43,0.87-1.74,0.87-3.04,0.76
		s-1.19,0.43-1.3,1.85s-0.76,1.19-1.74,2.93c-0.98,1.74-1.3,1.41-3.04,1.41s-0.87,1.09-2.06,1.09s-2.06,0.43-3.15,1.3
		c-1.09,0.87-2.82,0-3.37-0.54s-0.54,0-2.06-0.43s0.43-1.19,1.3-1.95s3.15-0.54,3.15-0.54c2.06,0,2.93-0.22,3.91-0.76
		s3.26-1.85,4.02-3.58c0.76-1.74,1.63-2.28,3.8-2.93s2.82-2.5,4.23-3.91s2.28,0.65,3.04-0.54s1.74-0.65,2.39-1.41
		c0.65-0.76-0.22-1.85-1.09-1.19s-0.33,0.98-2.06,1.09c-1.74,0.11,1.96-2.17,3.26-3.15s2.82-3.15,3.91-4.78s1.52-2.28,2.5-3.8
		s2.39-4.89,2.82-5.76s0.54-2.71,0.98-4.13c0.43-1.41,1.52-2.61,2.71-2.71s2.06,0.43,2.06,1.19
		C591.528,196.518,592.068,197.058,590.768,197.928z"
          pointer-events="auto"
        ></path>

        <path
          id="KR"
          title="Corée-du-Nord"
          capital="Pyongyang"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M734.008,312.918L734.008,312.918c-0.16,0.09-0.33,0.14-0.5,0.15c-0.02,0-0.04,0-0.06,0c-0.07,0-0.13,0-0.19-0.02
		c-0.09-0.02-0.17-0.05-0.25-0.09c-0.65-0.36-0.58-1.23-0.65-1.81s-1.3-1.59-2.32-1.3s0.22,1.38-0.65,1.81s-0.94,0.65-0.94,1.59
		s-1.16,1.59-2.03,1.59c-0.87,0-1.38,1.16-1.81,2.03s-3.33,1.3-5.28,1.16c-1.96-0.14-2.82,0.58-2.03,1.3
		c0.8,0.72,0.15,1.81,0.65,2.17c0.5,0.36,0.72,1.3,0.07,1.45c-0.65,0.14-1.59-0.14-2.03,0.43c-0.43,0.58-2.39,0.51-2.97-0.22
		c-0.58-0.72-2.1-1.52-2.53-1.52c-0.43,0,0.15-1.74-1.45-1.74c-1.59,0-1.74,0.65-1.96,1.81c-0.22,1.16-1.16,2.75-2.24,3.69
		c-1.09,0.94-2.1,1.74-3.04,1.95c-0.94,0.22-1.16,0.94-1.88,1.59c-0.72,0.65-2.1,0.51-2.97,1.23s-3.77,1.81-4.85,2.75
		c-0.89,0.77-1.48,1.65-1.75,2.89c1.37,0.28,3.09,0.82,5.3,1.81c4.52,2.02,2.49,0.62,2.96,2.33c0.47,1.71-0.62,2.65-0.62,3.89
		c0,1.24-0.47,1.56-2.49,3.42c-2.02,1.87-0.31,2.18,1.09,4.51c1.4,2.33,2.49,1.71,3.27,0.16c0.78-1.56,4.2-0.31,6.07,0.31
		c0.7,0.23,1.07,0.62,1.3,1.17c0.25-0.56,0.6-1.26,1.02-1.98c0.97-1.66,2.63-3.6,4.7-3.32s4.15,1.38,4.98,0
		c0.59-0.99,1.05-1.77,1.72-2.28c-1.06-1.76-3.21-2.55-5.48-4.02c-2.65-1.71-0.31-4.2,1.71-5.76s2.8-1.87,3.89-3.11
		s4.2-2.65,6.07-3.74s2.96-2.18,2.96-4.05s-0.31-3.11,0.62-5.14c0.93-2.02,4.36-3.11,5.6-5.29c0.02-0.03,0.04-0.05,0.05-0.08
		c-0.19-0.3-0.43-0.61-0.78-1C734.138,313.488,734.058,313.208,734.008,312.918z"
          pointer-events="auto"
        ></path>

        <path
          id="AM"
          title="Arménie"
          capital="Erevan"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M154.188,341.858c-0.22-0.54-0.43-1.25-0.43-2.71c0-1.47-1.68-0.81-2.12-1.52c-0.43-0.71-1.57-1.52-2.77-2.17
		c-1.19-0.65,0.54-1.95,0.49-2.61s-1.74-1.79-2.28-2.06s-0.76-1.57,0.05-1.85c0.81-0.27,0.65-1.41,0.05-1.74
		c-0.6-0.33-1.25-0.81-2.23-1.19c-0.51-0.2-0.73-1.12-0.81-1.95c-0.03,0.04-0.07,0.08-0.11,0.12c-0.03,0.03-0.05,0.05-0.08,0.08
		c-0.04,0.03-0.08,0.07-0.12,0.1s-0.08,0.06-0.13,0.08c-0.04,0.02-0.08,0.05-0.12,0.07c-0.09,0.05-0.18,0.09-0.28,0.12
		c-0.03,0.01-0.06,0.02-0.1,0.03c-0.08,0.03-0.17,0.05-0.26,0.07c-0.04,0.01-0.09,0.02-0.13,0.02c-0.09,0.01-0.19,0.02-0.29,0.03
		c-0.04,0-0.09,0.01-0.13,0.01c-0.15,0-0.3,0-0.47-0.02c-1.47-0.16-2.28,0.54-3.64,0.05c-1.36-0.49-1.57,0.76-2.99,0.54
		c-0.85-0.13-1.72,0.05-2.28,0.22c0.21,0.29,0.4,0.64,0.59,1.08c0.6,1.36,1.79,1.41,1.68,2.71s0.05,4.34,0.76,4.89
		c0.71,0.54,2.06,1.79,2.99,2.06c0.61,0.18,1.08,0.25,1.39,0.57c0.12-0.04,0.21-0.08,0.3-0.1c0.09-0.02,0.18-0.03,0.28-0.03
		c0.02,0,0.05,0.01,0.07,0.01c0.07,0.01,0.15,0.01,0.22,0.03c0.1,0.03,0.21,0.06,0.31,0.12c0.318,0.157,0.575,0.277,0.776,0.426
		c3.662-0.981,8.262,3.508,10.276,7.014c0.026-0.003,0.055-0.006,0.079-0.009c0.01,0,0.01,0,0.02,0c0.09-0.01,0.18-0.03,0.26-0.05
		c0.01,0,0.01,0,0.02-0.01c0.09-0.02,0.18-0.04,0.26-0.07c0.01,0,0.01,0,0.02-0.01c0.09-0.03,0.18-0.06,0.27-0.09
		c0.01,0,0.01-0.01,0.02-0.01c0.09-0.03,0.18-0.07,0.27-0.11c0.01,0,0.01-0.01,0.02-0.01c0.09-0.04,0.18-0.09,0.27-0.14l0.01-0.01
		c0.09-0.05,0.18-0.11,0.28-0.17C154.398,342.968,154.308,342.148,154.188,341.858z"
          pointer-events="auto"
        ></path>

        <path
          id="TM"
          title="Turkménistan"
          capital="Achgabat"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M258.438,371.008c0.72,1.09,1.3,0.87,1.88,0.87s2.24,0.14,2.46,1.01s0.87,1.67,1.23,1.01c0.36-0.66,1.01-1.67,2.24-1.23
		c1.23,0.43,1.88,0,2.39-0.72s1.81-1.09,1.3-1.88c-0.51-0.8-0.58-1.52,0.51-1.74s1.88-0.72,3.55-0.94c1.67-0.22,1.81-1.59,3.18-1.59
		s2.17-1.23,2.24-2.75c0.07-1.52,0.87-2.17,1.23-3.4c0.36-1.23,0.94-2.17,2.32-2.17s3.04-0.43,3.4-1.67
		c0.36-1.24,1.45-1.52,2.24-0.8c0.8,0.72,1.23,1.45,2.82,1.23c0.12-0.02,0.25-0.03,0.38-0.05c0.33-0.05,0.7-0.1,1.08-0.14h0.01
		c0.03-1.24,0.27-2.37,0.45-3.5c0.22-1.41-0.65-0.98-1.52-1.85s-3.37-1.41-5.76-1.74s-5.32-2.28-7.27-4.56
		c-1.95-2.28-4.67-1.52-5.65-3.04c-0.98-1.52-4.78-4.02-6.73-5.21s-2.06-1.09-2.39-3.15c-0.33-2.06-1.41-1.3-2.17-3.15
		s-0.87-2.71-1.41-4.45c-0.54-1.74-3.15-1.63-4.24-0.11c-1.09,1.52-3.8-0.65-5.97-0.54s-2.39-1.95-1.96-3.8
		c0.43-1.85-0.65-1.95-0.65-3.04s-1.3-2.06-3.69-2.06s-3.26-1.19-4.24-2.61c-0.98-1.41-2.61-1.41-3.26-0.22s-0.43,1.63-2.06,1.74
		s-1.52,0.11-1.63,1.63c-0.11,1.52-1.52,0.76-3.48,0.65c-1.96-0.11-2.17,1.95-2.93,3.26c-0.76,1.3,0.33,2.06,0.76,3.15
		s-3.37,1.09-4.78,0.76c-0.16-0.04-0.31-0.06-0.46-0.08c-0.05-0.01-0.09-0.01-0.14-0.02c-0.11-0.01-0.22-0.02-0.32-0.02
		c-0.04,0-0.09,0-0.13-0.01c-0.14,0-0.28,0-0.41,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0.01-0.22,0.02-0.34,0.04
		c-0.05,0.01-0.1,0.02-0.16,0.03c-0.09,0.02-0.17,0.03-0.26,0.05c-0.05,0.01-0.11,0.02-0.16,0.04c-0.11,0.03-0.22,0.05-0.33,0.08
		c-0.01,0-0.03,0.01-0.04,0.01c-0.32,0.09-0.64,0.19-0.97,0.3c-1.3,0.43-3.26,0-4.24-1.3c-0.98-1.3-2.06-3.69-2.5-5.1
		c-0.43-1.41-2.5-0.65-3.58-1.95c-1.09-1.3-1.96-0.98-4.34-0.76c-2.39,0.22-1.85,1.52-3.8,1.52s-2.71,1.09-3.69,2.5
		c-0.51,0.73-1.28,1.26-1.9,1.6c0.17,0.15,0.37,0.29,0.58,0.4c0,0,0.01,0,0.01,0.01c0.1,0.05,0.2,0.1,0.3,0.15
		c0.01,0.01,0.03,0.01,0.05,0.02c0.1,0.04,0.2,0.08,0.31,0.11c1.09,0.33,0.65,1.74,1.96,2.39c1.3,0.65,0.76,1.63,0.76,2.82
		s-0.11,2.06-1.19,2.39c-1.09,0.33-0.54,3.26-0.22,4.02c0.33,0.76,0.76,1.52,0.98,2.71s1.09,1.3,0.98,0.65s0.54-1.95,1.41-1.95
		s1.74,0.22,1.63,1.52s-1.52,1.63-2.28,2.06s-0.11,1.74-1.09,3.15s0.11,2.28,0.98,3.15s0.33-1.63,0.87-2.17s0.22-1.52,0.65-2.39
		s1.74,1.09,3.37,1.95c1.63,0.86,1.52,2.17,0.54,3.37c-0.98,1.2-0.33,4.78-0.87,6.3s0.76,3.04,0.76,4.56c0,0.03,0,0.06,0,0.09
		c0.36,0.05,0.79,0.11,1.26,0.16c1.38,0.14,1.45-0.87,2.75-0.8s1.81-0.29,1.81-1.09s0.58-1.81,1.74-2.46s1.96-0.65,2.17-1.23
		c0.22-0.58,1.45-0.87,3.26-0.58s3.48,0.43,4.13-0.43s2.03-1.59,3.04-1.23s1.23,0.8,2.1,0.8s2.46,1.52,2.82,2.03
		s2.32,0.65,3.33,1.01c1.01,0.36,1.74,1.3,3.33,1.3s4.78,0.07,5.43,1.23s0.87,2.82,2.75,2.82s1.96,0.36,2.61,1.45
		s2.03,2.68,3.26,2.68s4.78-0.72,4.78,0.22s-0.29,1.38,0.22,2.03c0.51,0.65,0.8,1.09,0.58,1.74s-0.51,1.81-0.43,2.17
		c0.01,0.03,0.01,0.06,0.02,0.11c0.06,0.23,0.18,0.71,0.27,1.26c0,0.01,0,0.01,0,0.02c0.02,0.1,0.03,0.2,0.05,0.31
		c0,0.01,0,0.02,0,0.03c0.01,0.11,0.03,0.22,0.04,0.34C257.048,370.288,258.018,370.388,258.438,371.008z"
          pointer-events="auto"
        ></path>

        <path
          id="UZ"
          title="Ouzbékistan"
          capital="Tachkent"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M219.888,324.248c0.05-0.01,0.11-0.02,0.16-0.04c0.09-0.02,0.17-0.03,0.26-0.05c0.05-0.01,0.1-0.02,0.16-0.03
		c0.11-0.02,0.22-0.03,0.34-0.04c0.03,0,0.05-0.01,0.08-0.01c0.14-0.01,0.27-0.01,0.41-0.01c0.04,0,0.09,0,0.13,0.01
		c0.11,0,0.21,0.01,0.32,0.02c0.04,0.01,0.09,0.01,0.14,0.02c0.15,0.02,0.3,0.05,0.46,0.08c1.41,0.33,5.21,0.33,4.78-0.76
		c-0.43-1.09-1.52-1.85-0.76-3.15c0.76-1.3,0.98-3.37,2.93-3.26c1.96,0.11,3.37,0.87,3.48-0.65s0-1.52,1.63-1.63
		s1.41-0.54,2.06-1.74c0.65-1.2,2.28-1.19,3.26,0.22s1.85,2.61,4.24,2.61s3.69,0.98,3.69,2.06s1.09,1.19,0.65,3.04
		c-0.43,1.85-0.22,3.91,1.96,3.8c2.17-0.11,4.89,2.06,5.97,0.54c1.09-1.52,3.69-1.63,4.24,0.11s0.65,2.61,1.41,4.45
		s1.85,1.09,2.17,3.15c0.33,2.06,0.43,1.95,2.39,3.15c1.95,1.19,5.76,3.69,6.73,5.21c0.98,1.52,3.69,0.76,5.65,3.04
		c1.95,2.28,4.89,4.23,7.27,4.56c2.39,0.33,4.89,0.87,5.76,1.74s1.74,0.43,1.52,1.85c-0.17,1.13-0.41,2.26-0.45,3.5
		c0.02,0,0.04,0,0.05-0.01c0.13-0.02,0.26-0.03,0.39-0.04c0.04,0,0.09-0.01,0.13-0.01c0.06-0.01,0.12-0.01,0.18-0.02
		c0.05-0.01,0.11-0.01,0.16-0.01c0.05,0,0.11-0.01,0.16-0.01c0.1-0.01,0.2-0.01,0.3-0.02c0.03,0,0.07,0,0.1,0
		c0.07,0,0.15-0.01,0.22-0.01c0.04,0,0.08,0,0.12,0c0.07,0,0.14,0,0.21,0c0.03,0,0.06,0,0.09,0c0.09,0,0.18,0.01,0.27,0.02
		c0.02,0,0.04,0,0.07,0.01c0.07,0.01,0.13,0.01,0.19,0.02c0.03,0,0.05,0.01,0.08,0.01c0.06,0.01,0.13,0.02,0.18,0.04
		c0.01,0,0.03,0.01,0.04,0.01c0.07,0.02,0.13,0.04,0.18,0.07c0.02,0.01,0.03,0.01,0.04,0.02c0.04,0.02,0.08,0.04,0.11,0.07
		c0.01,0.01,0.02,0.02,0.04,0.03c0.04,0.03,0.07,0.07,0.1,0.11c0.43,0.72,1.09,1.23,2.39,1.45c0.09,0.02,0.18,0.02,0.26,0.03
		c0.03,0,0.06,0,0.09,0c0.06,0,0.11,0,0.17,0c0.04,0,0.08,0,0.11-0.01c0.05,0,0.1-0.01,0.15-0.01c0.04,0,0.08-0.01,0.13-0.01
		c0.05-0.01,0.09-0.01,0.13-0.02c0.05-0.01,0.1-0.01,0.14-0.02c0.03,0,0.06-0.01,0.09-0.01c0.3-0.05,0.58-0.1,0.82-0.12
		c-0.02-0.77,0.09-1.95,0.93-2.44c1.3-0.76,0.87-2.5,2.17-2.71c1.3-0.22,1.52-0.87,1.41-2.17c-0.11-1.3-0.65-1.63-1.3-2.06
		s-1.09-0.87-0.98-2.06s1.74-3.15-0.11-3.04s-2.71,0.43-3.58-0.87s-1.74-1.63-1.52-2.5s1.41-0.98,2.06-1.19s1.63-1.63,2.61-0.43
		s2.39,0.87,3.91,0.76s0.22-2.17,1.41-2.82s0-1.63-0.22-2.06s0.11-1.19,1.63-1.19c1.52,0,2.61-0.76,2.61-2.39s1.74-2.5,2.28-1.85
		s2.17,1.3,3.15-0.22c0.98-1.52,1.52-1.41,2.17-1.52c0.65-0.11,1.95,0.43,2.28,0.98c0.33,0.54,0.11,2.61-0.65,2.93
		c-0.76,0.33-1.95,1.63-0.87,2.06c0.08,0.03,0.17,0.05,0.26,0.07c0.03,0.01,0.05,0.01,0.08,0.01c0.07,0.01,0.14,0.02,0.22,0.02
		c0.03,0,0.05,0,0.08,0c0.1,0,0.2,0,0.3-0.01c0.12-0.01,0.24-0.02,0.36-0.04c0.42-0.05,0.86-0.15,1.29-0.23
		c0.05-0.01,0.1-0.02,0.15-0.03s0.11-0.02,0.16-0.03c0.08-0.01,0.16-0.02,0.24-0.04c0.03,0,0.05-0.01,0.08-0.01
		c0.1-0.01,0.2-0.02,0.3-0.02c0.03,0,0.06,0,0.08,0c0.07,0,0.14,0,0.2,0.01c0.03,0,0.05,0,0.08,0.01c0.09,0.01,0.17,0.03,0.24,0.05
		c0.98,0.33,2.61,0.87,3.58,0.87s2.39,0.65,2.5-0.76s0.87-1.85,2.71-1.85s2.5-0.54,3.04-1.09s2.5-0.98-0.65-2.17
		s-3.04-0.22-4.13-1.85s-3.04-4.23-3.69-2.5s-1.85,3.48-4.02,2.39c-2.17-1.09-1.95-2.39-3.69-2.39s-2.28-1.3-0.98-2.06
		s3.48-1.74,4.34-2.71c0.73-0.83,1.31-2.28,0.15-3.89c-0.01,0.03-0.03,0.06-0.04,0.09c-0.87,1.95-1.41,2.71-2.82,2.71
		s-2.17,1.19-3.37,2.39c-1.19,1.19-1.95,0.98-3.15,1.63c-1.19,0.65-1.41,1.19-3.48,2.06c-2.06,0.87-2.28,3.04-3.69,3.69
		s-0.22,1.41-0.11,2.82c0.11,1.41-1.63,0-2.82-0.33s-0.54-1.19-1.09-3.26s-4.56-0.54-6.62-0.54s-2.17-0.65-2.5-2.28
		s-0.87-3.58-2.06-5.1s-2.17-2.5-2.06-4.23c0.11-1.73,0.65-3.26-1.09-3.37s-1.95-1.41-4.23-4.67s-4.02-1.95-5.43-0.87
		c-1.41,1.09-5.21,0.11-8.36-0.43s-4.99,0.76-7.49,1.09s-2.61-0.76-3.8-2.5s-4.152-3.853-5.97-5.76
		c-1.683-1.765-5.478-2.668-6.008-3.058c-0.83,0.81-0.74,1.88-0.96,2.7c-0.214,0.785-0.418,0.587-0.917,0.769
		c-0.386,0.141-0.532,0.336-0.763,0.491c-0.548,0.366-1.022-1.264-2.332-1.628c-0.277-0.077,1.619-2.894,1.348-5.533
		c-0.09-0.882-0.349-3.092-1.348-3.68c-0.68-0.4-1.312-0.263-1.432-0.309c-0.837-0.319-2.135,2.87-2.247,2.809
		c-0.12-0.065,0.048,6.643-0.393,6.32c-1.304-0.956-2.132-1.53-2.377-2.741c-0.021-0.106-0.265-1.262,0.392-2.088
		c0.223-0.281,0.515-0.36,0.808-0.702c0.37-0.44,0.67-1.49,0.96-2.5c-1.98-0.63-5.86,1.03-7.85,1.43c-2.17,0.43-3.48,1.19-5.32,1.95
		c-1.85,0.76-5.43,1.41-5.43,1.41l0.1,30.97c0.01,0,0.03-0.01,0.04-0.01C219.678,324.298,219.778,324.268,219.888,324.248z"
          pointer-events="auto"
        ></path>

        <path
          id="KG"
          title="Kirghizistan"
          capital="Bichkek"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M383.918,314.978c-1.09-0.22-1.95-0.65-2.71-1.74c-0.76-1.09-1.19-0.87-2.71-1.3s-3.15-0.87-5.97-1.09s-3.91-0.22-5.65-0.65
		s-3.8,0.65-5.76,0.43c-1.95-0.22-2.93-0.11-5.32,0.87s-3.48-0.98-5.54-0.98s-2.28-1.63-4.23-2.71c-1.95-1.09-2.71,0.98-3.69,1.41
		s-1.19,1.74-1.19,3.26s-1.3,1.41-2.61,1.19c-1.3-0.22-3.15-0.22-4.45-1.09s-3.91-0.98-5.65-0.98c-0.11,0-0.22,0-0.33,0.01
		c-0.05,0-0.1,0.01-0.14,0.02c-0.07,0.01-0.13,0.01-0.2,0.02c-0.07,0.01-0.14,0.02-0.2,0.04c-0.05,0.01-0.1,0.02-0.14,0.03
		c-0.08,0.02-0.17,0.04-0.26,0.06c-0.03,0.01-0.06,0.02-0.1,0.02c-0.1,0.03-0.2,0.06-0.3,0.09c-0.02,0.01-0.04,0.01-0.06,0.02
		c-0.11,0.04-0.22,0.08-0.33,0.12c-0.01,0-0.02,0.01-0.02,0.01c-1.18,0.48-2.31,1.33-2.87,2.52c1.15,1.61,0.58,3.06-0.15,3.89
		c-0.87,0.98-3.04,1.95-4.34,2.71s-0.76,2.06,0.98,2.06s1.52,1.3,3.69,2.39s3.37-0.65,4.02-2.39c0.65-1.74,2.61,0.87,3.69,2.5
		s0.98,0.65,4.13,1.85c3.15,1.19,1.19,1.63,0.65,2.17s-1.19,1.09-3.04,1.09s-2.61,0.43-2.71,1.85c-0.11,1.41-1.52,0.76-2.5,0.76
		s-2.61-0.54-3.58-0.87c-0.08-0.02-0.16-0.04-0.24-0.05c-0.03,0-0.05-0.01-0.08-0.01c-0.07-0.01-0.13-0.01-0.2-0.01
		c-0.03,0-0.06,0-0.08,0c-0.1,0-0.19,0.01-0.3,0.02c-0.02,0-0.05,0.01-0.08,0.01c-0.08,0.01-0.16,0.02-0.24,0.04
		c-0.05,0.01-0.11,0.02-0.16,0.03c-0.05,0.01-0.1,0.02-0.15,0.03c-0.42,0.08-0.87,0.17-1.29,0.23c-0.04,0.75-0.31,1.56-1.3,1.56
		c-1.85,0-2.71-1.19-4.13-1.19c-1.41,0-3.04,1.09-3.37,2.06s-1.85,3.58,0.76,3.58s2.61-0.87,4.34-0.43c1.74,0.43,3.15,0.43,3.8,0.98
		c0.65,0.54,1.95,0.87,2.61-0.11s2.93-1.52,4.13-0.22c1.19,1.3,3.26,2.28,3.8,1.41c0.54-0.87,2.5-1.63,3.58-0.65
		s1.63,1.09,2.61,0.76c0.98-0.33,1.09-1.19,2.5-1.19c0.83,0,1.4-0.11,2.31-0.09c0-0.01,0-0.01,0-0.02c0-0.12,0.01-0.24,0.02-0.36
		v-0.01c0.01-0.25,0.03-0.51,0.06-0.76c0-0.02,0-0.03,0-0.05c0.01-0.11,0.02-0.21,0.04-0.32c0-0.02,0.01-0.04,0.01-0.07
		c0.01-0.11,0.03-0.23,0.05-0.34v-0.01c0.02-0.12,0.04-0.24,0.06-0.35c0-0.01,0.01-0.03,0.01-0.04c0.02-0.1,0.04-0.19,0.06-0.28
		c0.01-0.03,0.01-0.05,0.02-0.08c0.02-0.08,0.04-0.16,0.07-0.24c0.01-0.02,0.01-0.05,0.02-0.07c0.06-0.2,0.14-0.38,0.22-0.55
		c0.01-0.02,0.02-0.05,0.04-0.07c0.03-0.06,0.06-0.12,0.1-0.17c0.01-0.02,0.03-0.05,0.04-0.07c0.04-0.06,0.08-0.11,0.12-0.16
		c0.01-0.01,0.02-0.03,0.03-0.04c0.05-0.06,0.11-0.12,0.17-0.17c0.01-0.01,0.03-0.02,0.04-0.03c0.05-0.04,0.09-0.08,0.14-0.11
		c0.02-0.01,0.04-0.03,0.06-0.04c0.05-0.03,0.1-0.05,0.15-0.07c0.02-0.01,0.04-0.02,0.06-0.03c0.07-0.03,0.15-0.05,0.23-0.06
		c2.17-0.36,3.48-0.8,4.05-1.88c0.58-1.09,1.3-1.3,2.24-1.3s1.67,0,2.17-0.72c0.51-0.72,2.17-0.72,2.24,0.29
		c0.07,1.01-0.07,1.3,1.16,1.3s1.38-0.94,2.53-0.87c1.16,0.07,2.9,0.43,3.33-2.32s1.95-3.04,3.69-3.04s7.53,0.51,7.74-1.09
		s3.62-3.4,5.65-4.27s3.11-1.67,4.78-1.88c1.67-0.22,2.39,0.07,2.53-1.09c0.01-0.07,0.01-0.15,0.02-0.23c0-0.03,0-0.06,0-0.09
		c0-0.06,0-0.11,0-0.17c0-0.04,0-0.08,0-0.12c0-0.06,0-0.12-0.01-0.18c0-0.04-0.01-0.08-0.01-0.12c0-0.07-0.01-0.14-0.02-0.21
		c0-0.04-0.01-0.08-0.01-0.11c-0.01-0.09-0.02-0.18-0.02-0.27c0-0.01,0-0.02,0-0.03c-0.03-0.27-0.05-0.55-0.08-0.83
		C385.798,315.328,384.588,315.118,383.918,314.978z"
          pointer-events="auto"
        ></path>

        <path
          id="AZ"
          title="Azerbaïdjan"
          capital="Bakou"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M152.418,320.138c-1.09,1.09-0.11,1.25,0.16,1.95s0.76,1.03,1.57,1.19c0.81,0.16,0.92,0.71,0.81,1.52s-1.85,0.92-3.04,0.71
		c-1.19-0.22-1.9-0.16-2.34-0.92c-0.43-0.76-1.52-0.54-2.5-1.63s-2.39,0-2.66,0.65c-0.06,0.15-0.15,0.3-0.27,0.44l0,0
		c0.09,0.83,0.3,1.75,0.81,1.95c0.98,0.38,1.63,0.87,2.23,1.19c0.6,0.33,0.76,1.47-0.05,1.74s-0.6,1.57-0.05,1.85
		c0.54,0.27,2.23,1.41,2.28,2.06s-1.68,1.95-0.49,2.61c1.19,0.65,2.34,1.47,2.77,2.17c0.43,0.71,2.12,0.05,2.12,1.52
		s0.22,2.17,0.43,2.71c0.12,0.29,0.2,1.11,0.26,1.81c0.08-0.05,0.16-0.11,0.23-0.17c1.59-1.23,2.1-1.81,3.4-2.9
		c1.3-1.09,1.59-1.81,2.82-1.81s1.23-1.09,2.68-1.23s2.32-0.07,2.32,1.16s-0.8,1.52-0.22,2.17c0.58,0.65,0.94,1.95-0.15,2.32
		c-1.09,0.36-1.3,1.3-0.94,1.88c0.36,0.58,1.16,0.65,1.74,1.16s1.16,0.51,1.74,1.23c0.38,0.48,1.57,0.48,2.82,0.33
		c-0.22-0.59-0.5-1.05-0.68-1.78c-0.33-1.3,0.98-2.06,0.87-2.93s0.11-1.09,1.85-2.28s1.19-2.28,1.19-4.02s0.98-1.63,2.5-3.37
		s1.09-2.93-0.11-3.58s-1.85-2.17-2.61-2.71c-0.76-0.54-1.41-1.52-1.74-3.26s-1.09-2.5-2.06-2.93c-0.12-0.05-0.22-0.12-0.31-0.19
		c-0.03-0.02-0.06-0.05-0.09-0.08c-0.06-0.05-0.11-0.11-0.16-0.16c-0.03-0.04-0.05-0.07-0.08-0.11c-0.04-0.06-0.08-0.12-0.12-0.18
		c-0.02-0.04-0.05-0.08-0.07-0.13c-0.04-0.07-0.07-0.13-0.1-0.2c-0.04-0.07-0.07-0.14-0.1-0.21c-0.03-0.06-0.06-0.12-0.09-0.18
		c-0.03-0.07-0.07-0.13-0.11-0.19c-0.02-0.03-0.04-0.07-0.06-0.1c-0.06-0.09-0.12-0.17-0.19-0.25c-0.31,0.71-0.73,1.62-1.07,2.08
		c-0.6,0.81-0.6,1.52-1.85,1.68s-1.57,0.71-2.12,1.57c-0.54,0.87-2.01,0.49-2.99,0.27c-0.98-0.22-2.71-2.12-3.8-3.64
		s-2.23-1.47-3.31-2.44c-0.01-0.01-0.02-0.01-0.03-0.02C153.288,318.978,152.958,319.598,152.418,320.138z"
          pointer-events="auto"
        ></path>

        <path
          id="GE"
          title="Géorgie"
          capital="Tbilissi"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M135.318,325.348c1.41,0.22,1.63-1.03,2.99-0.54s2.17-0.22,3.64-0.05c0.17,0.02,0.32,0.02,0.47,0.02c0.05,0,0.09,0,0.13-0.01
		c0.1-0.01,0.2-0.01,0.29-0.03c0.04-0.01,0.09-0.01,0.13-0.02c0.09-0.02,0.18-0.04,0.26-0.07c0.03-0.01,0.07-0.02,0.1-0.03
		c0.1-0.04,0.19-0.08,0.28-0.12c0.04-0.02,0.08-0.05,0.12-0.07c0.04-0.03,0.09-0.05,0.13-0.08c0.04-0.03,0.08-0.06,0.12-0.1
		c0.03-0.03,0.05-0.05,0.08-0.08c0.04-0.04,0.08-0.08,0.11-0.12l0,0c0.12-0.14,0.21-0.29,0.27-0.44c0.27-0.65,1.68-1.74,2.66-0.65
		c0.98,1.09,2.06,0.87,2.5,1.63s1.14,0.71,2.34,0.92c1.19,0.22,2.93,0.11,3.04-0.71c0.11-0.81,0-1.36-0.81-1.52s-1.3-0.49-1.57-1.19
		c-0.27-0.71-1.25-0.87-0.16-1.95c0.54-0.54,0.87-1.16,1.06-1.65l0,0c-0.88-0.75-1.96-0.28-3.34-0.58h-0.01
		c-0.09-0.02-0.19-0.05-0.28-0.08c-0.02,0-0.03-0.01-0.05-0.01c-0.09-0.03-0.18-0.06-0.27-0.1c-0.02-0.01-0.05-0.02-0.07-0.02
		c-0.12-0.05-0.23-0.1-0.35-0.16c-1.9-0.98-0.16-0.92-0.16-2.17s-1.57-1.79-2.71-1.79s-1.25-0.49-1.57-1.3
		c-0.33-0.81-2.12-0.33-3.58-0.33s-2.12,0.22-2.55,0.76c-0.43,0.54-1.47,0.98-2.23,0.98s-1.68-1.19-1.85-1.85
		c-0.17-0.66-0.92-0.92-2.17-1.14s-2.01-1.03-2.71-1.9c-0.7-0.87-4.61-0.49-6.41-0.33c-1.79,0.16-2.55,0.11-4.02-0.49
		c-1.47-0.6-3.15-1.95-4.18-2.33s-2.23,0-2.88-0.33c-0.65-0.33-1.9-0.16-2.55,0c-0.31,0.08-0.9,0.19-1.44,0.29
		c0.4,0.29,0.83,0.54,1.29,0.75c3.58,1.56,3.43,2.8,7.16,4.51c3.74,1.71,2.64,2.8,2.64,4.83s2.18,3.74,1.71,4.67
		c-0.23,0.45-0.9,1.02-1.72,1.62c0.4,0.39,0.97,0.76,1.69,0.76c1.47,0,4.61,0.38,5.27-0.22c0.66-0.6,3.09-0.98,3.47-0.11
		c0.38,0.87,0.71,1.9,1.79,2.12c0.73,0.15,1.21,0.45,1.63,1.04C133.598,325.408,134.468,325.218,135.318,325.348z"
          pointer-events="auto"
        ></path>

        <path
          id="QA"
          title="Qatar"
          capital="Doha"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M183.638,452.228c0.11,0.43,0.65,1.57,1.14,1.74c0.49,0.16,0.76,0.43,1.63,0.11c0.73-0.27,1.01-0.58,1.62-0.68
		c0.28-1.12,0.45-3,0.89-4.33c0.47-1.4-0.31-2.33-0.47-4.36c-0.16-2.02-0.47-2.02-1.71-2.02c-1.25,0-1.87,0.47-1.71,2.33
		c0.16,1.87-1.4,1.24-1.4,3.42c0,1.4-0.58,2.15-1.12,2.06c0,0.32,0.05,0.67,0.2,0.8
		C183.038,451.578,183.528,451.798,183.638,452.228z"
          pointer-events="auto"
        ></path>

        <path
          id="TJ"
          title="Tadjikistan"
          capital="Douchanbé"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M340.808,339.408c-1.41,0-1.52,0.87-2.5,1.19c-0.98,0.33-1.52,0.22-2.61-0.76s-3.04-0.22-3.58,0.65c-0.54,0.87-2.61-0.11-3.8-1.41
		s-3.48-0.76-4.13,0.22s-1.95,0.65-2.61,0.11c-0.66-0.54-2.06-0.54-3.8-0.98c-1.74-0.43-1.74,0.43-4.34,0.43s-1.09-2.61-0.76-3.58
		s1.95-2.06,3.37-2.06s2.28,1.19,4.13,1.19c0.99,0,1.26-0.81,1.3-1.56c-0.12,0.02-0.24,0.03-0.36,0.04l0,0
		c-0.1,0.01-0.2,0.01-0.3,0.01c-0.03,0-0.05,0-0.08,0c-0.08,0-0.15-0.01-0.22-0.02c-0.03,0-0.05-0.01-0.08-0.01
		c-0.09-0.02-0.18-0.04-0.26-0.07c-1.09-0.43,0.11-1.74,0.87-2.06c0.76-0.33,0.98-2.39,0.65-2.93s-1.63-1.09-2.28-0.98
		s-1.19,0-2.17,1.52s-2.61,0.87-3.15,0.22s-2.28,0.22-2.28,1.85s-1.09,2.39-2.61,2.39s-1.85,0.76-1.63,1.19s1.41,1.41,0.22,2.06
		s0.11,2.71-1.41,2.82s-2.93,0.43-3.91-0.76s-1.95,0.22-2.61,0.43c-0.65,0.22-1.85,0.33-2.06,1.19c-0.22,0.87,0.65,1.19,1.52,2.5
		c0.87,1.3,1.74,0.98,3.58,0.87c1.85-0.11,0.22,1.85,0.11,3.04c-0.11,1.19,0.33,1.63,0.98,2.06c0.65,0.43,1.19,0.76,1.3,2.06
		s-0.11,1.95-1.41,2.17c-1.3,0.22-0.87,1.95-2.17,2.71c-0.85,0.49-0.96,1.67-0.93,2.44c0.08-0.01,0.16-0.01,0.23,0
		c0.03,0,0.06,0,0.08,0.01c0.02,0,0.05,0.01,0.07,0.01c0.03,0.01,0.05,0.01,0.08,0.02c0.02,0.01,0.04,0.02,0.06,0.02
		c0.02,0.01,0.05,0.02,0.07,0.03c0.02,0.01,0.03,0.02,0.05,0.03c0.02,0.02,0.04,0.03,0.06,0.05c0.01,0.01,0.03,0.03,0.04,0.04
		c0.02,0.02,0.03,0.05,0.05,0.08c0.01,0.02,0.02,0.03,0.03,0.05c0.02,0.05,0.04,0.11,0.05,0.17c0.22,1.09,0.07,1.81,1.45,1.67
		c1.38-0.14,1.23-1.88,3.33-1.88c2.1,0,1.74-2.03,3.18-1.09s1.81,1.74,2.61,1.74s0.51-1.23,0.43-2.39c-0.07-1.16,0.51-1.88,2.1-1.59
		s3.11,1.3,3.26-0.87c0.14-2.17,0.51-2.68,1.74-3.33c1.23-0.65,2.1-3.18,3.18-3.18c1.09,0,2.24,0.72,2.46,2.24s-0.51,2.03,0.94,2.68
		s1.16,1.38,0.8,2.1c-0.36,0.72-0.36,1.88-0.22,2.61s-1.38,2.9-0.07,3.98s3.33,1.01,4.27,0.14s2.53-0.94,3.4-1.81
		s2.9-2.82,4.56-3.04c1.67-0.22,2.82-0.07,3.26,0.43c0.43,0.51,1.67,0.65,2.32,0.22s1.52-1.01,2.53-0.8
		c0.86,0.18,2.48,0.16,3.71,0.67l0,0c0.14-0.31,0.24-0.61,0.31-0.9l0,0c0.03-0.15,0.06-0.29,0.07-0.43c0-0.01,0-0.02,0-0.03
		c0.01-0.14,0.02-0.27,0.02-0.4c0-0.02,0-0.04,0-0.06c0-0.12-0.01-0.24-0.02-0.36c-0.01-0.04-0.01-0.08-0.02-0.12
		c-0.02-0.1-0.03-0.2-0.06-0.3c-0.02-0.07-0.04-0.13-0.06-0.2s-0.04-0.14-0.07-0.21c-0.05-0.14-0.11-0.27-0.18-0.4
		c-0.58-1.09-0.58-2.24-0.58-3.26s-0.58-3.18-1.23-3.4c-0.65-0.22-2.46-1.16-3.04-0.58c-0.58,0.58-1.81,1.09-2.1-0.07
		s-0.8-2.1-0.8-3.76c0-0.07,0-0.14,0-0.22c0-0.03,0-0.06,0-0.09c0-0.04,0-0.09,0-0.14c-0.01-0.61-0.03-1.46,0-2.35
		c0-0.01,0-0.01,0-0.02C342.208,339.298,341.638,339.408,340.808,339.408z"
          pointer-events="auto"
        ></path>
      </g>
    </svg>
  </div>
</template>

<style scoped>
.svg-container {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
}

svg {
  width: 100vw;
  height: 80vh;
}

@media screen and (max-width: 850px) {
  svg {
    width: 100vw;
    height: inherit;
  }
}
</style>