<template>
  <span id="time"><h2>{{ time }}</h2></span>
</template>

<script>
export default {
  data() {
    return {
      time: null,
    };
  },
  methods: {
    timer() {
        this.time = "00:00"
        let second = 0;
        let minute = 0;

        setInterval(()=> {
            second++;
            if(second > 59) {
                second = 0;
                minute++;
            }
            this.time = (minute > 9 ? minute : "0" + minute) + ":" + (second > 9 ? second : "0" + second);

        }, 1000)
    },

  },
  mounted() {
    this.timer();
    setInterval(() => {
       this.$emit('timer', this.time)
    }, 1000)
  },

};
</script>

<style scoped>

h2 {
  font-weight: 500;
  color: var(--dark-grey)
}
</style>