<template>
  <div class="isPortrait">
    <InformationDevice />
  </div>

  <div class="play-container">
    <NavDesktop
      v-if="!isMobile"
      @timer="getTimeDesktop"
      :continentName="this.continentName"
      :code="code"
      :guessCountry="this.guessCountry"
      :correctAnswer="this.correctAnswer"
      :countryPassed="this.countryPassed"
      :total="this.total"
      :info="this.info"
    />

    <NavMobile
      v-if="isMobile"
      @timer="getTimeMobile"
      :info="this.info"
      :code="this.code"
      :guessCountry="this.guessCountry"
      :correctAnswer="this.correctAnswer"
      :displayTriesSentence="this.displayTriesSentence"
    />

    <div class="continent">
      <component :is="continent"></component>
      <div>
        <p class="instruction"></p>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from "@/components/Timer.vue";
import Button from "@/components/Button.vue";
import NavDesktop from "@/components/nav/NavDesktop.vue";
import NavMobile from "@/components/nav/NavMobile.vue";
import InformationDevice from "@/components/InformationDevice.vue";

import africa from "@/components/maps/Africa.vue";
import asia from "@/components/maps/Asia.vue";
import europe from "@/components/maps/Europe.vue";
import northAmerica from "@/components/maps/NorthAmerica.vue";
import southAmerica from "@/components/maps/SouthAmerica.vue";
import oceania from "@/components/maps/Oceania.vue";

import Swal from "sweetalert2";

export default {
  components: {
    Timer,
    Button,
    NavDesktop,
    NavMobile,
    InformationDevice,
    africa,
    asia,
    europe,
    northAmerica,
    southAmerica,
    oceania,
  },
  data() {
    return {
      continent: this.$route.params.continent,
      info: false,
      countries: [],
      country: "",
      capital: "",
      code: "",
      time: "",
      guessCountry: "",
      nbOfTry: 3,
      try: false,
      total: 0,
      correctAnswer: 0,
      countryPassed: 0,
      continentName: "",
      replay: false,
      isMobile: true,
      displayTriesSentence: false,
    };
  },
  methods: {
    startGame() {
      let image = require("@/assets/illustrations/nanaba-world.svg");
      Swal.fire({
        html: `<p>Prêt à tester tes connaissances sur l'<strong>${this.continentName}</strong> ? </p>`,
        imageUrl: image,
        showCloseButton: true,
        imageHeight: 140,
        imageWidth: 140,
        confirmButtonText: "Jouer",
      }).then((result) => {
        if (result.isConfirmed === true) this.play();
        if (result.isDismissed === true) this.$router.go(-1);
      });
    },

    getAllCountries() {
      const path = document.querySelectorAll("path");

      for (let i = 0; i < path.length; ++i) {
        this.countries.push({
          name: path[i].attributes.title.value,
          capital: path[i].attributes.capital.value,
          code: path[i].attributes.id.value,
        });
      }
      return this.countries;
    },

    getTimeDesktop(value) {
      this.time = value;
    },

    getTimeMobile(value) {
      this.time = value;
    },

    getRandomCountry() {
      const randomCountry = Math.floor(Math.random() * this.countries.length);
      this.code = this.countries[randomCountry].code;
      this.capital = this.countries[randomCountry].capital;
      return this.countries[randomCountry];
    },

    displayTries() {
      this.displayTriesSentence = true;
      let text = document.querySelector(".info-tries");
      switch (this.nbOfTry) {
        case 2:
          text.innerHTML = "";
          break;
        case 1:
          text.innerHTML = `Il ne te reste plus que ${
            this.nbOfTry + 1
          } chances`;
          break;
        case 0:
          text.innerHTML = `Il ne te reste plus qu'${this.nbOfTry + 1} chance`;
          break;
        case -1:
          text.innerHTML = "Il ne te reste plus de chance...";
          break;
      }
    },

    displayInstruction(guessCountry) {
      if (this.isMobile === false) {
        let svg = document.querySelector(".continent");
        let instruction = document.querySelector(".instruction");
        instruction.innerHTML = `Cliquez sur ${guessCountry}`;
        let nav = document.querySelector(".header");

        nav.addEventListener("mouseover", () => {
          instruction.style.display = "none";
        });

        svg.onmousemove = (event) => {
          let left = event.pageX + 20;
          let top = event.pageY;
          instruction.style.display = "block";
          instruction.style.left = left + "px";
          instruction.style.top = top + "px";
        };
      }
    },

    getScore(nbOfTry) {
      switch (nbOfTry) {
        case -1:
          this.correctAnswer += 0;
          break;
        case 0:
          this.correctAnswer += 1;
          break;
        default:
          this.correctAnswer += nbOfTry;
      }
    },

    play() {
      this.getAllCountries();
      this.total = this.countries.length;
      this.info = true;
      this.guessCountry = this.getRandomCountry().name;
      this.displayInstruction(this.guessCountry);

      let path = document.querySelectorAll("path");
      path.forEach((element) => {
        let country = element.attributes.title.value;
        element.classList.add("hover-path");

        element.addEventListener("click", () => {
          this.country = country;

          // Trouve le pays
          if (this.country == this.guessCountry) {
            let index = this.countries.findIndex((country) => {
              return country.name === this.country;
            });
            this.countries.splice(index, 1);

            if (this.countries.length === 0) {
              setTimeout(this.endGame, 200);
            } else {
              this.guessCountry = this.getRandomCountry().name;
              document.querySelector(".info-tries").innerHTML = "";
              this.displayInstruction(this.guessCountry);
            }

            switch (this.nbOfTry) {
              case 3:
                element.classList.add("correct-3");
                break;
              case 2:
              case 1:
                element.classList.add("correct-2");
                break;
              default:
                element.classList.add("correct-1");
            }
            element.classList.remove("second-hint");

            this.getScore(this.nbOfTry);
            this.nbOfTry = 3;
            this.countryPassed++;
            element.classList.remove("hover-path");
          }

          // Trouve pas le pays
          else {
            if (this.nbOfTry <= 2) this.try = true;

            let guessCountry = document.querySelector(
              `[title = ${this.guessCountry}]`
            );

            let wrongCountry = document.querySelector(
              `[title = ${this.country}]`
            );

            if (
              !wrongCountry.classList.contains("correct-3") &&
              !wrongCountry.classList.contains("correct-2") &&
              !wrongCountry.classList.contains("correct-1") &&
              this.nbOfTry >= 0
            ) {
              this.nbOfTry--;
              wrongCountry.classList.add("incorrect");
              setTimeout(() => {
                wrongCountry.classList.remove("incorrect");
              }, 1000);
            }

            this.displayTries();
            if (this.nbOfTry === -1) {
              guessCountry.classList.add("second-hint");
            }
          }
        });
      });
    },

    endGame() {
      this.replay = true;
      let image = require("@/assets/illustrations/win.webm");
      this.info = false;
      let intruction = document.querySelector(".instruction");
      intruction.remove("instruction");
      this.replay = true;
      Swal.fire({
        title: "Bravo à toi !",
        html: ` <div style="margin-top:-20px">
            <video autoplay loop muted width="350">
                <source src="${image}" type="video/webm">
                Tu as gagné!
            </video>
          </div>
          <div>
          <p>Tu as un score de ${this.correctAnswer} en ${this.time}</p>
          </div>`,
        confirmButtonText: "Retour",
        footer: "Partage ton score avec tes amis",
      });
    },

    onResize() {
      this.isMobile = window.innerWidth < 900;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    switch (this.continent) {
      case "europe":
        this.continentName = "Europe";
        break;
      case "asia":
        this.continentName = "Asie";
        break;
      case "oceania":
        this.continentName = "Océanie";
        break;
      case "southAmerica":
        this.continentName = "Amérique du Sud";
        break;
      case "northAmerica":
        this.continentName = "Amérique du Nord";
        break;
      case "africa":
        this.continentName = "Afrique";
        break;
    }

    setTimeout(this.startGame(), 200);
  },
};
</script>

<style>
@media screen and (orientation: portrait) {
  .play-container {
    display: none;
  }
}

@media screen and (orientation: landscape) {
  .isPortrait {
    display: none;
  }
  * {
    margin: 0;
    padding: 0;
  }

  .play {
    text-align: center;
    background: var(--light-orange);
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .score {
    text-align: center;
  }

  .continent {
    fill: var(--light-grey);
    stroke: var(--dark-grey);
    stroke-width: 0.6;
    text-align: -webkit-center;
    padding-top: 12px;
    background: var(--light-orange);
  }

  .tries {
    display: flex;
    justify-content: center;
    height: 12px;
  }

  .tries p {
    font-size: 22px;
  }

  .correct-3 {
    fill: var(--yellow);
    stroke: var(--dark-grey);
    animation-name: correct-3;
    cursor: default;
    animation-duration: 2.2s;
  }

  @keyframes correct-3 {
    from {
      fill: var(--light-grey);
    }
    to {
      fill: var(--yellow);
    }
  }

  .correct-2 {
    fill: var(--orange);
    stroke: var(--dark-grey);
    animation-name: correct-2;
    cursor: default;
    animation-duration: 2.2s;
  }

  @keyframes correct-2 {
    from {
      fill: var(--light-grey);
    }
    to {
      fill: var(--orange);
    }
  }

  .correct-1 {
    fill: var(--red);
    stroke: var(--dark-grey);
    animation-name: correct-1;
    cursor: default;
    animation-duration: 2.2s;
  }

  @keyframes correct-1 {
    from {
      fill: var(--light-grey);
    }
    to {
      fill: var(--red);
    }
  }

  .hover-path {
    cursor: pointer;
  }

  .hover-path:hover {
    fill: var(--white-hover);
  }

  path {
    cursor: pointer;
  }

  .second-hint {
    animation-name: second-hint;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    stroke: var(--dark-grey);
  }

  @keyframes second-hint {
    0% {
      fill: var(--orange);
    }
    20% {
      fill: var(--light-grey);
    }
    40% {
      fill: var(--orange);
    }
    60% {
      fill: var(--light-grey);
    }
    80% {
      fill: var(--orange);
    }
    100% {
      fill: var(--light-grey);
    }
  }

  .incorrect {
    fill: var(--light-grey);
    animation-name: incorrect;
    animation-duration: 1.4s;
  }

  @keyframes incorrect {
    from {
      fill: var(--red);
    }
    to {
      fill: var(--light-grey);
    }
  }

  .swal2-popup {
    font-family: "Fredoka";
  }

  button.swal2-confirm.swal2-styled {
    background: var(--orange);
    border: none;
    border-bottom: 5px solid #b15301;
    border-radius: 10px;
    width: 250px;
    height: 50px;
    color: white;
    font-size: 22px;
    cursor: pointer;
  }

  button.swal2-confirm.swal2-styled:focus {
    outline: none;
  }
  .instruction {
    position: absolute;
    z-index: 200;
    background: var(--orange-instruction);
    padding: 4px 12px;
    border-radius: 3px;
    color: black;
    font-weight: 400;
    display: none;
  }
}

@media screen and (max-width: 800px) and (orientation: landscape) {
  .continent {
    padding-top: -120px;
  }
}
</style>