<template>
  <div class="information-device-container">
    <div class="nav">
      <Logo/>
    </div>
    <div class="informations">

        <h1>Hop là !</h1>
        <p>Retournes ton smartphone pour commencer à jouer</p>
    </div>
    
      <img src="@/assets/illustrations/nanaba-ecran-landscape.svg" alt="Nanaba qui retourne son smartphone" class="illustration-nanaba-phone"/>

  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import Button from "@/components/Button.vue"
export default {
    components: {
        Logo,
        Button
    },

    methods: {
    }
}
</script>

<style scoped>
.information-device-container {
  background: var(--light-orange);
  text-align: center;
  position: fixed;
  height: 100vh;
  z-index: 1000;
  width: 100vw;
  top: 0;
  left: 0;
}

.nav {
  text-align: left;
  padding: 22px
}

.informations {
  position: fixed;
  top: 30%;
  left:0;
  color: var(--brown);

}

p {
  padding:0 24px;
  font-size: 18px;
  text-align: inherit;
  color: var(--dark-grey);
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 30px;
}

h1 {
  font-size: 36px;
}

.illustration-nanaba-phone {
  height: 350px;
  position: fixed;
  bottom:-20px;
  left:50%;
  transform: translateX(-50%);
}

</style>