<template>
  <div class="header">
    <div class="row">
      <div class="col title">
        <button v-on:click="$router.go(-1)" class="go-back">
          <span class="material-symbols-outlined icon">arrow_back_ios_new</span>
          Retour
        </button>
        <div class="time" v-if="info">
          <Timer @timer="getTime"/>
        </div>
      </div>
      <div class="col title">
        <h2>
          <span class="material-symbols-outlined">pin_drop</span
          >{{ continentName }}
        </h2>
        <div class="info-country" v-if="info">
          <img
            :src="require(`@/assets/flags/${code}.png`)"
            alt="flag"
            class="flag"
          />
          <h1>{{ guessCountry }}</h1>
        </div>
        <div class="tries">
          <p class="info-tries"></p>
        </div>
      </div>
      <div class="col">
        <h2>Score : {{ correctAnswer }}</h2>
        <p>{{ countryPassed }}/{{ total }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from "../Timer.vue";
export default {
  components: {
      Timer
  }, 
  data() {
    return{
      time: ""
    }
  },
  props: [
    "continentName",
    "code",
    "guessCountry",
    "correctAnswer",
    "countryPassed",
    "total",
    "info",
    "time"
  ],

  methods: {
    getTime(value) {
      this.time = value
    }
  },

  mounted() {
    setInterval(() => {
      this.$emit('timer', this.time)
    }, 1000)
  }

};
</script>

<style scoped>
.header {
  background: #ffecdc;
  padding: 15px 100px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.title {
  text-align: center;
}

span {
  padding: 0 12px;
}

button {
  background: none;
  font-weight: bold;
  border: none;
  color: #7a7a7a;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.go-back .icon {
  color: #7a7a7a;
  font-size: 12px;
}

.info-country {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag {
  height: 40px;
  margin-right: 12px;
}

h2 {
  font-weight: 500;
  color: #7a7a7a;
  margin-bottom: 16px;
}

h1 {
  font-weight: 600;
  font-size: 24px;
}

p {
  font-weight: 400;
  color: #7a7a7a;
}
</style>