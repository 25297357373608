<template>
  <div class="isPortrait">
    <InformationDevice />
  </div>

  <div class="container isLandsacape">
    <div class="logo">
      <Logo />
      <div class="start-container">
        <div v-if="!this.button" class="start-sentence">
          <p>Clique sur un continent pour commencer</p>
        </div>
      </div>
    </div>

    <div id="map">
      <WorldMap />
    </div>

    <div class="illustration">
      <img src="@/assets/illustrations/nanaba-plane.svg" alt="Nanaba dans un avion">
    </div>
    <div class="btn-start" v-if="this.button">

    <Button message="C'est parti !" v-on:click="play()" />
    </div>

  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import InformationDevice from "@/components/InformationDevice.vue";
import WorldMap from "@/components/maps/WorldMap.vue";
import Button from "@/components/Button.vue";
export default {
  name: "Home",
  components: {
    Logo,
    InformationDevice,
    WorldMap,
    Button,
  },
  data() {
    return {
      isLandscape: Boolean,
      areaSelected: [],
      button: false,
      buttonPlay: false,
      name: "",
      continents: [
        {
          name: "Europe",
          url: "europe",
          image: "europe.svg",
        },
        {
          name: "Amérique du Nord",
          url: "northAmerica",
          image: "northAmerica.svg",
        },
        {
          name: "Amérique du Sud",
          url: "southAmerica",
          image: "southAmerica.svg",
        },
        {
          name: "Asie",
          url: "asia",
          image: "asia.svg",
        },
        {
          name: "Océanie",
          url: "oceania",
          image: "oceania.svg",
        },
        {
          name: "Afrique",
          url: "africa",
          image: "africa.svg",
        },
      ],
    };
  },

  methods: {
  

    // State 1: Select continents to play and display continent name
    selectContinent(continent) {
      let continents = document.querySelectorAll("g");

      continents.forEach((c) => {
        c.style.fill = "";
        c.style.stroke = "var(--dark-grey)";
      });

      continent.style.fill = "var(--orange)";
      continent.style.stroke = "var(--white)";
    },

    displayContinentName(name) {
      let nameContinent = document.querySelectorAll("p");

      nameContinent.forEach((p) => {
        p.style.display = "none";
      });

      let title = "";

      switch (name) {
        case "europe":
          this.name = "Europe";
          title = "[title = France]";
          break;
        case "asia":
          this.name = "Asie";
          title = "[title = Mongolie]";
          break;
        case "africa":
          this.name = "Afrique";
          title = "[title = Niger]";
          break;
        case "northAmerica":
          this.name = "Amérique du Nord";
          title = "[title = Mexique]";
          break;
        case "southAmerica":
          this.name = "Amérique du Sud";
          title = `[title = Argentina]`;
          break;
        case "oceania":
          this.name = "Océanie";
          title = `[title = Australie]`;
          break;
      }

      let continentName = document.querySelector(`${title}`);
      let positionSVG = continentName.getBoundingClientRect();
      let top = positionSVG.top;
      let left = positionSVG.left;

      let newTag = document.createElement("p");
      let content = document.createTextNode(this.name);
      newTag.appendChild(content);

      let div = document.getElementById("map").appendChild(newTag);
      div.style.position = "absolute";
      div.style.top = `${top}px`;
      div.style.left = `${left}px`;
      div.style.fontFamily = "Fredoka One";
      div.style.color = "#331800";
      switch (this.name) {
        case "Asie":
          div.style.marginTop = "-2.375rem";
          break;
        case "Amérique du Nord":
          div.style.marginBottom = "21.875rem";
          div.style.marginTop = "-1.875rem";
          div.style.marginLeft = "-1.875rem";
          break;
        case "Amérique du Sud":
          div.style.marginLeft = "-3.125rem";
          break;
        case "Océanie":
          div.style.marginTop = "1.875rem";
          div.style.marginLeft = "3.75rem";
          break;
      }
    },

    // State 2: Go to the Play View to play
    play() {
      let area = this.areaSelected.toString();
      this.$router.push({
        name: "Play",
        params: { continent: area },
      });
    },
  },

  mounted() {
   
    this.button = false;
    let map = document.querySelector("svg");
    let continents = map.querySelectorAll("g");

    continents.forEach((continent) => {
      continent.addEventListener("click", () => {
        this.selectContinent(continent);
        this.button = true;
        let name = continent.getAttribute("data-name");
        this.displayContinentName(name);

        this.areaSelected.pop();
        this.areaSelected.push(name);
      });
    });
  },
};
</script>

<style scoped>


@media screen and (orientation: landscape) {
  .isPortrait {
    display: none;
  }

  .container {
  background: var(--light-orange);
  height: 100vh;
  margin: 0;
  padding: 0;
}

.logo {
  padding-top: 18px;
  padding-left: 18px;
  display: flex;
  align-items: center;
}

#map {
  width: 100vw;
  text-align: center;
  background: var(--light-orange);
}

.card {
  display: none;
}

.start-sentence {
  text-align: center;
  background: var(--light-orange);
}

.start-sentence p {
  font-family: "Fredoka";
  font-weight: 500;
  justify-content: center;
  font-size: 20px;
  color: var(--brown);
}

.illustration {
  position: absolute;
  width: 15.75rem;
  height: 15.75rem;
  margin-top: -17rem;
  margin-left: 1.563rem;
  transform: rotate(-15deg);
}

.start-container {
  width: 100%;
}

.btn-start{
  text-align: center;
  background: var(--light-orange);
  margin-bottom: 100px;
  z-index: 100;
}
}

@media screen and (max-width: 800px) {
  .illustration {
    display: none;
  }
}


</style>