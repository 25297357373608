<template>
  <div class="svg-container">
    <svg
      version="1.1"
      id="svgpoint"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 900 700"
      enable-background="new 0 0 900 700"
      xml:space="preserve"
	  class="svg-content"
    >
      <g id="northAmerica">

        <path
          id="DO"
          title="République-Dominicaine"
          capital="Saint-Domingue"
          stroke-width="0.5"
          d="M733.483,526.044c-0.74-0.2-1.08-2.56-2.36-2.63
		s-0.81-1.55-2.49-1.55c-1.69,0-3.03,0.61-4.11-0.07s-1.49-1.21-0.61-1.35s1.35-0.27,1.15-1.15c-0.2-0.88-1.01-0.4-1.62-0.07
		c-0.61,0.34-0.95,1.42-1.89,1.42s-0.27-0.88-1.82-1.69s-1.35-1.55-2.49-2.36c-1.15-0.81-1.96-1.82-3.37-1.89s-1.75-0.81-2.77-0.81
		c-1.01,0-1.62-0.94-2.9-0.88c-1.28,0.07-1.62,0.2-2.29-0.61s-1.35-1.22-2.02-1.01s-0.81,1.62-1.96,1.48s-2.3-1.15-3.37-1.15
		c-1.08,0-1.68,0.47-1.96,1.01c-0.27,0.54-0.47,1.75-1.15,1.96c-0.03,0.01-0.06,0.02-0.09,0.03c0.35,0.7,0.64,1.48,0.59,2.14
		c-0.12,1.61-0.99,2.35-0.25,3.34s1.98,2.1,0.99,2.23c-0.99,0.12-1.73-0.62-1.98,0.25s-0.37,1.48,0.62,1.73s1.49,1.11,0.87,1.49
		c-0.62,0.37-0.62,0.99-1.73,1.24s-2.6,1.23-1.49,1.86c1.11,0.62,2.35,0.74,2.72,1.49s0.99,1.61,0.5,2.47
		c-0.37,0.65-0.96,1.02-1.17,2.01c0.06,0.03,0.09,0.07,0.15,0.1c1.22,0.61,1.62,1.08,1.62,1.69s0.07,1.69,1.15,1.69
		s0.34,1.48,1.35,1.48c1.01,0-0.27-1.08,1.08-1.28c1.35-0.2,2.16-0.68,2.16-2.02s0.61-1.15,0.74-2.7s-0.54-2.43,0.88-2.56
		c1.42-0.14,3.1,1.08,3.37-0.2s0.41-1.55,1.48-1.48c1.08,0.07,0.2,2.23,1.21,2.43s0.95,0.88,2.23,0.07
		c1.28-0.81,1.21-1.28,2.16-1.28s0.94-0.47,1.28-1.01c0.34-0.54,0.88-1.01,2.43-1.01s4.52,0,5.73,0c1.21,0,1.55,0.34,1.89,0.94
		c0.34,0.61,1.01-0.13,1.08-0.61c0.07-0.47,1.21-0.61,1.75,0.4s1.55,0.81,1.76,1.69c0.2,0.88,0.87,1.42,1.35,1.35
		c0.47-0.07,0.2-0.4,0.07-1.28s-0.47-1.55,0.34-2.02c0.81-0.47,2.09-0.74,2.09-1.55
		C734.363,526.994,734.223,526.244,733.483,526.044z"
        />

        <path
          id="HT"
          title="Haïti"
          capital="Port-au-Prince"
          stroke-width="0.5"
          d="M695.693,530.504c-0.37-0.74-1.61-0.87-2.72-1.49
		s0.37-1.61,1.49-1.86c1.11-0.25,1.11-0.87,1.73-1.24s0.12-1.24-0.87-1.49s-0.87-0.87-0.62-1.73s0.99-0.12,1.98-0.25
		c0.99-0.12-0.25-1.24-0.99-2.23s0.12-1.73,0.25-3.34c0.05-0.66-0.24-1.44-0.59-2.14c-0.67,0.24-1.65,0.94-2.74,0.03
		c-1.15-0.94-1.42-1.42-2.56-1.35c-1.15,0.07-2.5,0.88-3.51,0s-1.42-0.27-1.55-1.42s-0.47-1.21-1.08-1.21c-0.61,0-1.62,0-1.82,0.54
		s0.74,1.48-1.35,1.42c-2.09-0.07-1.89-0.67-2.43,0.14s-1.68,0.27-1.68,1.55s0.81,0.88,1.01,1.48c0.2,0.61,1.41,0.47,2.02,0.47
		c0.61,0,1.82-0.54,2.29,0.07s2.56,1.35,1.82,3.24c0,0-0.81,2.77,0.54,3.91c1.35,1.15,0.94,1.48,2.09,2.23
		c1.15,0.74,1.08,0.74,2.02,0.81c0.95,0.07,1.08,0.74,1.01,1.08s-0.27,0.88-1.48,0.88c-1.21,0-2.09,0-2.09,0.4s0.07,1.42-1.41,1.21
		c-1.49-0.2-2.36-0.95-3.84-0.54c-1.49,0.4-3.71,1.35-4.38,0.54c-0.68-0.81-1.35-1.62-2.7-1.28c-1.35,0.34-3.31,0.41-4.18-0.47
		s-0.81-1.08-1.89-1.08s-0.27,1.42-1.76,1.42c-1.48,0-1.28,1.35-1.28,1.89c0,0.54,0.94,0.47,1.35,1.21s1.22,0.81,1.89,0.81
		c0.67,0,1.41,0.4,1.75,1.08c0.34,0.67,0.74,1.96,1.55,1.96s1.41,0.13,1.41-0.67c0-0.81,0.14-1.42,0.95-1.42s1.55,0.61,1.82-0.27
		s1.08-0.94,1.82-0.94s1.55-0.34,1.96,0.54s1.08,0.74,2.43,0.74s3.3,0.34,3.98-0.14c0.68-0.47,1.82-0.94,3.51-0.94
		c1.68,0,4.65-0.07,4.79,0.4c0.13,0.45,0.34,1.31,1.4,1.92c0.21-0.98,0.79-1.36,1.17-2.01
		C696.683,532.104,696.063,531.244,695.693,530.504z"
          pointer-events="auto"
          
        ></path>

        <path
          id="JM"
          title="Jamaïque"
          capital="Kingston"
          stroke-width="0.5"
          d="M633.893,531.104c0,0-1.69,0.6-2.48-0.04s-1.47-1.31-2.63-1.39
		c-1.16-0.08-3.19,0.34-3.6,0.6s-1.31,0.75-2.55,0.75s-1.65-0.04-1.84,0.45s-1.24,0.71-1.24,1.39s0.11,0.98,1.28,0.9
		c1.17-0.08,2.85-0.38,3.3,0.79c0.45,1.16,1.31,1.09,2.1,2.07s0.68,1.69,1.58,1.69s3.08-1.2,3.94-0.15s2.07,2.78,2.82,1.2
		s0.94-1.73,1.99-1.73s3.87-0.79,4.09-0.15c0.22,0.64,1.13,1.39,1.69,0.64s0-1.05,1.24-1.31s1.77-0.83,0.79-1.13
		c-0.98-0.3-1.01-1.24-2.52-1.65s-2.74-0.41-3.57-1.28s-1.46-1.95-2.37-1.95C635.013,530.804,634.833,530.914,633.893,531.104z"
          pointer-events="auto"
          
        ></path>

        <path
          id="CU"
          title="Cuba"
          capital="La Havane"
          stroke-width="0.5"
          d="M555.933,479.364c0,0-1.47,0.9-1.8,1.65
		c-0.34,0.75-0.71,0.9-1.28,1.2c-0.56,0.3-1.88,1.2-1.43,1.73s1.69,0.38,1.61,1.39s-0.04,1.58-1.24,1.43s-2.78-0.53-3.57,0.08
		c-0.79,0.6-1.99,0.71-2.03,1.58c-0.04,0.86,0.19,1.01,1.05,0.6s0.79-0.9,1.8-1.05s2.22-0.19,1.88,0.64
		c-0.34,0.83-0.41,0.98,0.41,0.98s1.2-0.11,1.69-0.9s0.38-0.86,1.54-0.83c1.16,0.04,1.91,1.05,2.37-0.41
		c0.45-1.46-0.38-2.07,1.54-2.14c1.91-0.08,5.03-0.04,6.05-1.58c1.01-1.54,2.07-1.61,2.82-2.48c0.75-0.86,1.88-1.73,2.82-1.84
		s2.37-0.34,3.64-0.15c1.28,0.19,2.37,0.15,3.12,0.08c0.75-0.08,1.35-0.3,2.67,0c1.31,0.3,2.37,0.45,2.4,1.31
		c0.04,0.86,0.53,1.92-1.58,1.95c-2.1,0.04-2.89-0.3-2.63,0.38s1.39,0.75,1.91,1.54s1.46,0.79,2.25,0.83
		c0.79,0.04,2.89-0.34,3.38,0.19s1.28,1.28,2.07,1.09s0.6-1.28,1.43-1.24s0.26,1.5,0.94,1.69s1.13,0.68,2.52,0.6
		s3.68-1.77,4.88-0.11s2.63,2.22,3.72,2.89s1.09,1.13,2.82,1.31c1.73,0.19,2.59-0.08,3.3,0.49c0.71,0.56,1.54,0.94,2.82,0.98
		s3.12-1.05,4.77-0.83s2.37,1.77,2.7,3.04c0.34,1.28,0.98,2.44,1.39,3.19c0.41,0.75,1.31,2.21,2.48,3.19s2.14,2.03,4.09,1.95
		s2.78-0.19,3.94-0.26s2.07,0.19,2.97,1.09s1.54,1.77,1.54,2.29c0,0.53-0.08,1.58-1.09,1.99s-1.65,1.65-2.67,2.1
		c-1.01,0.45-1.99,0.71-2.1,1.73c-0.11,1.01-0.19,1.58,1.58,1.54c1.77-0.04,1.2-1.46,3.42-1.54c2.22-0.08,4.17,0.11,6.46,0.15
		c2.29,0.04,2.14,0.23,3-0.15s1.61-0.9,2.7-0.68c1.09,0.22,1.8,1.2,2.93,1.13c1.13-0.08,2.89-0.34,3.83-0.15s0.86,1.01,2.03,0.86
		c1.16-0.15,1.73-1.13,2.78-1.13c1.05,0,1.88,0.15,2.4-0.26s1.88-0.19,2.18-0.86c0.3-0.68,1.01-1.16,2.14-1.2s3.79,0.75,4.17-0.23
		s0.11-1.84-1.05-1.8s-1.99,0.26-2.7-0.71c-0.71-0.98-1.31-1.16-2.63-2.22c-1.31-1.05-2.4-2.03-3.53-2.07s-6.27,1.13-6.91-0.79
		c-0.64-1.92-0.41-4.17-1.77-4.02s-1.95,0.75-3-0.11s-1.65-1.69-3.34-1.92s-2.18,0.56-2.85-0.26s-1.31-1.16-1.69-0.9
		s-0.45,1.24-1.39,1.13s-0.3-1.8-1.2-2.37c-0.9-0.56-2.33-3.27-3.79-3.38c-1.46-0.11-2.55,0.53-3.12-0.23
		c-0.56-0.75-0.26-1.28-1.43-1.24s-2.14,0.26-2.29-0.53s0.94-0.38,1.39-0.79s1.39-0.79,0.38-1.16s0.22-0.64-0.68-1.28
		c-0.9-0.64-1.05-0.49-2.07-1.13s-1.09-1.16-1.54-0.94s-0.15,0.86,0.34,1.09c0.49,0.23,0.38,0.83,0.86,1.09
		c0.49,0.26,1.09,0.3,1.05,0.68c-0.04,0.38,0.41,0.94-0.45,0.98s-0.79-0.3-1.65-0.79c-0.86-0.49-1.13-0.98-1.91-1.09
		c-0.79-0.11-1.47-0.04-1.88-0.9c-0.41-0.86-0.71-0.79,0.15-1.24c0.86-0.45,1.2-0.68,1.01-1.31c-0.19-0.64-0.6-1.35-1.24-0.86
		c-0.64,0.49-0.71,1.58-1.28,1.99s-0.49,0.53-1.65,0.53s-1.2-1.24-2.22-1.13c-1.01,0.11,0.11,1.05-1.31,1.2s-1.73,0.23-2.78-0.83
		s-4.69-5.97-6.72-6.05c-2.03-0.08-1.39-1.01-2.7-0.83s-0.79,0.9-1.88,0.83c-1.09-0.08-1.31-0.26-2.18-1.01
		c-0.86-0.75-1.54-1.5-3.12-1.43s-2.67,0.83-3.42,0.41s-0.26-1.8-1.35-1.8s-0.19,1.77-1.43,1.77s-2.25,0.53-2.78-0.04
		c-0.53-0.56-1.09-1.43-2.18-1.5s-1.77-0.45-2.59-0.34c-0.83,0.11-0.94,0.23-1.5,0.23s-1.43-0.38-1.95-0.23
		c-0.53,0.15-0.64,0.08-0.86,0.56c-0.22,0.49,0.15,1.05-0.9,1.05s-2.22-0.15-3.34-0.23c-1.13-0.08-2.25,0.08-2.48,0.34
		c-0.22,0.26-0.75,0.71-1.58,0.71s-2.44,0.04-2.89,0.41c-0.45,0.38-0.41,0.83-1.46,0.83c-1.05,0-2.07-0.26-2.25,0.41
		s-0.11,1.01-1.16,1.01C557.513,478.004,556.913,478.644,555.933,479.364z"
          pointer-events="auto"
          
        ></path>

        <path
          id="PA"
          title="Panama"
          capital="Panama"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M612.223,640.324
		c-0.37-0.73-0.6-1.97-1.56-2.11c-0.97-0.14-2.39,0.64-3.17-0.05c-0.78-0.69-1.24-1.51-2.25-0.96s-2.07,2.43-2.98,2.94
		c-0.92,0.51-1.97,2.29-4.68,2.62s-3.63,0.41-5.97,2.39s-4.22,2.57-5.88,2.71c-1.65,0.14-3.63-0.05-4.5-0.97s-1.1-1.47-1.88-2.16
		s-1.28-1.33-1.74-1.33s0.09,1.75-0.92,1.84s-2.8,0.55-3.08-0.97c-0.28-1.52,0.05-2.21-1.19-3.12s-1.88-2.8-3.67-3.62
		c-0.1-0.05-0.16-0.08-0.25-0.12c-0.34,0.38-0.81,0.69-1.45,0.74c-1.82,0.15-2.55,1.02-2.18,1.97c0.36,0.95-0.36,2.11,0.51,3.06
		s1.89,0.88,1.24,2.18s-1.89,1.53-1.53,2.48s0.51,1.6,0.22,2.33c-0.24,0.6,0.23,2.01,0.25,3.74c1.35-0.08,1.16-1.04,2.1-1.04
		c1,0,1.8-0.35,2.7,0.2s1,1,1.8,0.55s1.35-0.2,1.65,0.4s0.55,1.05,1.55,1.05s3.66-0.4,4.26,1.25c0.6,1.65,1.35,1.31,1.35,2.14
		s-0.09,1.28,0.87,1.65c0.97,0.37,0.87,1.2,1.74,1.52s1.15,0.09,1.61,0.97c0.46,0.87,0.87,0.41,0.83-0.28
		c-0.05-0.69,0.18-1.19-0.18-2.07s-0.87-1.93,0.05-2.07s1.79,0.83,1.7,1.61c-0.09,0.78-0.23,1.01,0.32,1.52s0.87,0.69,0.87,1.7
		s-0.55,3.17,0.87,3.53c1.42,0.37,2.71-0.14,3.58-0.14s1.15-0.28,1.56-0.64s0.83,0.14,1.19-0.46s0.51-0.92,1.84-1.06
		s2.85-0.37,2.85-1.29s-0.97-0.83-2.39-2.53c-1.42-1.7-3.45-2.71-3.58-4.04s-0.14-2.98,1.52-2.85c1.65,0.14,2.43,0.1,3.12-0.82
		s3.22-1.93,3.4-3.4s0.46-2.71,1.28-2.85c0.83-0.14,1.06-0.92,1.88-0.92s1.38,0.23,1.65-0.46s0.92-0.96,1.84-0.83
		c0.92,0.14,2.53,1.15,2.43-0.18c-0.09-1.33-0.23-1.93,0.23-2.25C612.503,641.514,612.593,641.055,612.223,640.324z"
          pointer-events="auto"
          
        ></path>

        <path
          id="CR"
          title="Costa-Rica"
          capital="San José"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M565.283,650.154
		c0.29-0.73,0.15-1.38-0.22-2.33s0.87-1.17,1.53-2.48c0.66-1.31-0.36-1.24-1.24-2.18s-0.15-2.11-0.51-3.06s0.36-1.82,2.18-1.97
		c0.65-0.05,1.11-0.37,1.45-0.74c-1.55-0.72-2.02-1.05-2.46-2.17c-0.46-1.19-2.02-2.8-3.03-3.31s-1.33-1.24-1.88-2.48
		s-1.24-1.38-1.79-3.03s-1.1-1.51-1.79-3.03s0.14-2.34-1.1-3.12c-0.25-0.16-0.45-0.31-0.64-0.47c-0.35,0.8-0.01,1.82-0.12,2.43
		c-0.15,0.8-0.65,0.6-1.7,0.95c-1.05,0.35-1.75,0.4-2.8-0.1s-2.05-2-2.75-2.75s-2.66-1.05-3.56-1.15c-0.42-0.05-0.9-0.15-1.37-0.32
		c-0.12,0.84-0.66,0.65-0.47,1.68c0.22,1.2-0.79,0.75-1.13,0.75s-1.16-0.68-1.09-1.58s-0.08-2.22-0.6-2.1s-0.15,0.49-0.49,1.58
		c-0.34,1.09-0.49,1.09-1.54,0.08s-1.73-0.98-2.97-1.58c-0.66-0.32-1.09-0.75-1.41-1.22c-0.47,0.29-0.9,0.68-1.26,1.07
		c-0.16,0.17-0.38,0.31-0.61,0.43c0.25,0.34,0.38,0.63,0.23,0.81c-0.34,0.41-1.05,0.53-1.05,1.35s-0.11,1.01,0.38,1.43
		c0.49,0.41,0.79,1.05,0.79,1.39s0.41,0.6-0.11,1.01s0.28,1.24-0.58,1.69c-0.85,0.45-0.8,1-0.75,1.8s-1.05,1.45-0.1,2.55
		s1.65,1.5,2.5,2.6s2.55,0.25,3.3,1.5s1,1.45,2.1,1.45s2.05,0.1,2.5-0.35c0.45-0.45,1-0.4,0.5-1.2s-0.55-1-1.15-1.25
		c-0.6-0.25-1.65-0.75-1.3-1.4c0.35-0.65,1.05-0.7,1.4-0.3s0.6,0.95,1.2,0.95s2.05,0.45,2.4,1.4c0.35,0.95,0.9,1.8,0.8,2.5
		c-0.1,0.7,0.35,1.35,1.3,1.6s2.6,0.35,3.4,1.35c0.8,1,2.3,0.9,2.8,1.65s1.3,1.4,1.85,1.8c0.55,0.4,1.65,0.3,1.75,1.4
		s0.3,2.9,0.1,3.5s-1.95,1.7-1.1,2.35s2,0.75,2.45,1.45s1.25,1.05,2.1,1.15s1.2-0.4,1.1-0.9c-0.1-0.5-0.6-1.65,0.35-1.65
		s0.8,0.45,1.55,0.95s0.95,3.3,2.6,3.35c0.1,0,0.17-0.01,0.25-0.01C565.513,652.164,565.043,650.755,565.283,650.154z"
          pointer-events="auto"
          
        ></path>

        <path
          id="SV"
          title="Salvador"
          capital="San Salvador"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M508.253,588.674
		c-0.05-0.95,0.7-2,1.45-2.5s0.65-1.6-0.35-2.35s-1,0.15-2.1,0.25s-1.45-2.05-2.25-2.05s-0.95,0.45-1.65,1.55
		c-0.7,1.1-2.25,0.15-2.5-0.85s-0.9-0.85-2.3-1.45c-1.4-0.6-1.7-1.3-2.4-2.3s-1.75-1.45-3-1.8c-0.66-0.18-1.3-0.89-1.77-1.51
		c-0.45,0.18-0.89,0.31-1.24,0.41c-1.1,0.3-1.4,0.6-1.85,1.45s-1.15,1.95-1.65,2.65s-1.85,1.25-3.61,1.8
		c-0.98,0.31-1.79,1.06-2.32,1.67c1.42,1.23,2.75,1.77,3.91,2.66c1.17,0.9,1.45-0.27,3.07,0.64s1.71,1.17,2.89,1.17
		s2.7,0.81,3.16,1.35s1.17,2.16,2.17,1.98c0.99-0.18,1.44-0.81,2.8-0.81s1.8,1.26,3.43,1.44c1.62,0.18,0.63,0.81,2.34,0.72
		s2.17,0.09,3.16-0.72c0.57-0.47,0.85-0.87,1.31-1.14C508.793,590.024,508.293,589.404,508.253,588.674z"
          pointer-events="auto"
          
        ></path>

        <path
          id="NI"
          title="Nicaragua"
          capital="Managua"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M533.793,616.444
		c-0.28-0.41-0.46-0.85-0.59-1.26c-0.26-0.86-0.53-1.24-1.46-1.77c-0.94-0.53-0.49-1.54-0.68-2.74s-1.39-1.01-1.35-2.55
		c0.04-1.54,2.37-2.22,3.42-1.91c1.05,0.3,1.09,1.13,2.07,1.39s1.2,1.05,1.2,1.05c0.22,0.75,0.49,1.13,1.28,1.88
		c0.79,0.75,0.6,0.9,1.84,1.54c1.24,0.64,1.43,2.03,2.33,2.93s1.58,1.8,1.65,3.3c0.01,0.22,0,0.39-0.02,0.54
		c0.47,0.18,0.95,0.28,1.37,0.32c0.9,0.1,2.85,0.4,3.56,1.15c0.7,0.75,1.7,2.25,2.75,2.75s1.75,0.45,2.8,0.1
		c1.05-0.35,1.55-0.15,1.7-0.95c0.11-0.61-0.23-1.62,0.12-2.43c-0.74-0.6-1.04-1.21-1.16-2.2c-0.14-1.24-0.32-1.74,0.55-2.71
		c0.87-0.96,1.47-0.55,1.52-2.16c0.05-1.61,0.23-3.08-0.28-3.72c-0.51-0.64-0.6-1.52,0-2.48c0.6-0.97,0-1.75,1.1-2.57
		s1.52-1.56,1.52-2.25s-0.92-1.06-0.78-2.43c0.14-1.38,0.87-2.25,0.92-4.13s-1.01-6.38,0.46-8.27c1.47-1.88,1.74-3.26,2.71-4.96
		c0.96-1.7,1.7-2.39,1.01-3.9c-0.69-1.52-1.65-2.66-1.42-3.99c0.11-0.62,0.28-1.39,0.38-2.09c-1.28-0.07-1.19-0.3-2.05-0.3
		c-1.01,0-1.13,0-1.95,0.69s-0.76,1.2-2.08,1.83s-3.15,0-3.97-0.13c-0.82-0.13-1.39,0.32-1.89,1.32s-1.51,1.45-3.46,0.88
		s-1.64-2.52-2.71-3.09s-1.45,0.44-1.83,1.32s-0.63,1.39-1.39,1.76c-0.76,0.38-0.76,1.51-0.88,2.2c-0.13,0.69-0.5,1.13-1.01,1.13
		c-0.5,0-1.2,0.88-2.65,1.58c-1.45,0.69-1.01,1.7-2.27,1.95s-0.25,2.27-1.13,2.46s-1.38-0.19-1.7-1.26s-1.39-1.01-2.14-1.64
		c-0.76-0.63-1.13,0-1.64,1.2c-0.5,1.2-2.46,1.45-4.22,1.32s-2.02,0.57-2.33,1.89c-0.32,1.32,0.06,1.7,0.69,2.2s0.76,1.26-0.19,2.02
		s-1.51-0.44-2.71,0.19s0.19,1.51,0.06,2.58s-1.32,0.94-2.65,0.94s-1.39,0.19-1.7-0.63c-0.1-0.26-0.27-0.41-0.46-0.52
		c-0.03,0.07-0.09,0.14-0.18,0.21c-0.45,0.36-1.08,0.72-2.08,0.09s-1.44-0.36-1.35,0.27s-0.18,0.81,0.72,1.53
		c0.9,0.72,1.17,0.9,1.62,2.07s2.52,2.43,3.16,3.24c0.63,0.81,4.42,3.16,4.51,4.42s2.33,3.61,3.25,4.51
		c0.31,0.3,0.46,0.64,1.43,1.42c0.98,0.79,1.54,1.95,2.44,2.52c0.9,0.56,2.07,1.46,3,2.4c0.51,0.51,1.01,0.99,1.31,1.4
		c0.23-0.11,0.44-0.25,0.61-0.43C532.903,617.125,533.323,616.734,533.793,616.444z"
          pointer-events="auto"
          
        ></path>

        <path
          id="HN"
          title="Honduras"
          capital="Tegucigalpa"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M514.553,594.365
		c0.31,0.82,0.38,0.63,1.7,0.63s2.52,0.13,2.65-0.94s-1.26-1.95-0.06-2.58s1.76,0.57,2.71-0.19s0.82-1.51,0.19-2.02
		c-0.63-0.5-1.01-0.88-0.69-2.2s0.57-2.01,2.33-1.89c1.76,0.13,3.72-0.13,4.22-1.32c0.5-1.2,0.88-1.82,1.64-1.2
		c0.76,0.63,1.83,0.57,2.14,1.64c0.32,1.07,0.82,1.45,1.7,1.26s-0.13-2.2,1.13-2.46c1.26-0.25,0.82-1.26,2.27-1.95
		s2.14-1.58,2.65-1.58c0.5,0,0.88-0.44,1.01-1.13s0.13-1.83,0.88-2.2c0.76-0.38,1.01-0.88,1.39-1.76s0.76-1.89,1.83-1.32
		s0.76,2.52,2.71,3.09s2.96,0.12,3.46-0.88s1.07-1.45,1.89-1.32s2.65,0.76,3.97,0.13s1.26-1.13,2.08-1.83s0.94-0.69,1.95-0.69
		c0.86,0,0.77,0.23,2.05,0.3c0.11-0.8,0.12-1.52-0.2-1.82c-0.6-0.55-0.78-1.24-1.7-1.33s-2.35-2.39-3.35-2.39s-1.3-1.4-2.7-2
		s-1.8-3.41-3.21-3.41s-1.6-0.2-3-0.9s-2.7-0.9-3.91-0.9s-2.2-0.3-2.8,0.4s-1.7,0.9-4.01,0.5s-6.01-1-7.31-0.7s-1.2,2.2-3.8,2.4
		s-4.01-0.6-5.81-0.6s-1.8-1.6-3.71-1.5c-1.9,0.1-2.5,0.9-4.01,0.1c-1.51-0.8-2.5-1.2-3.41-1c-0.9,0.2-1,0.5-1.95,1.2
		c-0.92,0.68-1.66,0.97-2.61,0.76c-0.61,1.16-1.15,1.26-2.09,2.49c-1.15,1.5-2.2,1.85-3.51,2.4c-1.3,0.55-1.7,1.95-4.11,2.35
		c-2.4,0.4-1.55,3.25-1.65,5.21c-0.07,1.35-1.11,2.04-2.12,2.45c0.46,0.62,1.11,1.32,1.77,1.51c1.25,0.35,2.3,0.8,3,1.8
		s1,1.7,2.4,2.3s2.05,0.45,2.3,1.45s1.8,1.95,2.5,0.85c0.7-1.1,0.85-1.55,1.65-1.55s1.15,2.15,2.25,2.05c1.1-0.1,1.1-1,2.1-0.25
		s1.1,1.85,0.35,2.35s-1.5,1.55-1.45,2.5c0.04,0.73,0.54,1.35,0.66,2.26c0.33-0.19,0.76-0.31,1.48-0.31c1.71,0,2.16,0.18,2.16,0.54
		s-0.9,1.08,0.36,1.71c0.99,0.49,1.3,0.72,1.17,0.96C514.283,593.954,514.453,594.104,514.553,594.365z"
          pointer-events="auto"
          
        ></path>

        <path
          id="BZ"
          title="Belize"
          capital="Belmopan"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M495.093,543.734
		c-0.35,1.69-0.47,3.04-0.47,3.79s-0.06,2.63-0.23,4.09s-0.17,2.04-0.47,3.03c-0.29,0.99-0.7,1.75-0.64,2.92s0.58,1.46,1.69,1.58
		c0.99,0.1,2.02,0.48,3.23-0.6c-0.11-0.05-0.22-0.09-0.36-0.11c-1.35-0.2-0.75-1.4,0.75-2.15s3.45-1.85,4.36-3.26
		c0.9-1.4,2.05-1.7,2.3-3.25s0.05-2.35,0.6-2.95c0.55-0.6,1.45-1.45,0.7-1.75s-1.3-1.4-1.2-2.9c0.1-1.5,0.95-1.3,1.45-2.35
		s-0.1-1.2-0.1-2.5s-0.25-1.6,0.6-2.35s0-2.55,1.75-2.6c0.88-0.03,1.59-0.18,2.09-0.58c-0.52-0.59-0.77-1.25-1.55-1.78
		c-1.16-0.79-1.24-1.24-2.07-2.33c-0.83-1.09-1.83-0.81-2.83-0.81s-1.55,1.75-2.4,2.5s-1.1,1.5-1.65,3.15s-2.75,0.75-3.86,0.7
		c-1.1-0.05-0.75,0.25-1.3,1.25c-0.07,0.12-0.18,0.22-0.34,0.3c0.28,0.48,0.56,1.2,0.58,2.28
		C495.793,539.304,495.443,542.044,495.093,543.734z"
          pointer-events="auto"
          
        ></path>

        <path
          id="GT"
          title="Guatemala"
          capital="Guatemala"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M483.023,581.964
		c1.75-0.55,3.1-1.1,3.61-1.8c0.5-0.7,1.2-1.8,1.65-2.65c0.45-0.85,0.75-1.15,1.85-1.45s3.25-0.9,3.35-2.85s-0.75-4.81,1.65-5.21
		s2.8-1.8,4.11-2.35c1.3-0.55,2.35-0.9,3.51-2.4c0.95-1.23,1.49-1.34,2.09-2.49c-0.03-0.01-0.06,0-0.09-0.01
		c-1-0.25-1.45-2.1-2.55-2.1c-1.1,0-0.65,1.95-2,1.75c-1.21-0.18-1.07-1.43-1.99-1.84c-1.2,1.08-2.24,0.7-3.23,0.6
		c-1.11-0.12-1.63-0.41-1.69-1.58c-0.06-1.17,0.35-1.93,0.64-2.92s0.29-1.58,0.47-3.03c0.17-1.46,0.23-3.33,0.23-4.08
		s0.12-2.1,0.47-3.79s0.7-4.43,0.64-6.71c-0.03-1.08-0.3-1.8-0.58-2.28c-1.1,0.54-4.16,0.01-6.27,0.05c-2.4,0.05-4.66,0-5.66-0.2
		s-1.05-1.1-2.25-1s-3.3-0.25-5.06,0s-2.05,2.7-1.6,5.26c0.45,2.55-1,1.6-3.2,1.6s-1.8,0.95-0.5,1.7s2.1,2.8,3.61,3.45
		c1.5,0.65,1.7,1.4,2.85,2.55c1.15,1.15,2.15,1.35,3.11,3.15c0.95,1.8-0.5,2.43-1.03,3.48s-1.13,0.83-2.25,0.75
		c-1.13-0.08-2.85-0.22-4.28-0.3s-2.78-0.38-4.58-0.75c-1.8-0.38-1.73,0.38-2.78,1.13s-2.85,2.63-3.45,3.68s-2.33,3-3,4.28
		s1.05,1.8,1.05,2.63s0.38,1.35-0.68,1.88s-0.53,1.95-0.53,3.3c0,0.62-0.26,1.16-0.53,1.58c0.09,0.07,0.17,0.17,0.26,0.24
		c1.62,1.26,1.8,2.43,3.25,3.88c1.44,1.44-0.09,2.71,2.89,3.7s3.16,1.17,5.5,0.72c2.35-0.45,2.98-1.26,3.88-0.27
		s1.26,0.99,3.07,0.99c1.8,0,2.25,0.09,3.7,1.35c0.02,0.02,0.04,0.03,0.06,0.04C481.233,583.024,482.043,582.274,483.023,581.964z"
          pointer-events="auto"
          
        ></path>

        <path
          id="MX"
          title="Mexique"
          capital="Mexico"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M459.163,568.094
		c1.05-0.53,0.68-1.05,0.68-1.88s-1.73-1.35-1.05-2.63c0.68-1.28,2.4-3.23,3-4.28c0.6-1.05,2.4-2.93,3.45-3.68
		c1.05-0.75,0.98-1.5,2.78-1.13c1.8,0.38,3.15,0.68,4.58,0.75c1.43,0.08,3.15,0.22,4.28,0.3s1.73,0.3,2.25-0.75
		c0.53-1.05,1.98-1.68,1.03-3.48s-1.95-2-3.11-3.15c-1.15-1.15-1.35-1.9-2.85-2.55c-1.5-0.65-2.3-2.7-3.61-3.45
		c-1.3-0.75-1.7-1.7,0.5-1.7s3.65,0.95,3.2-1.6c-0.45-2.55-0.15-5.01,1.6-5.26s3.86,0.1,5.06,0s1.25,0.8,2.25,1s3.26,0.25,5.66,0.2
		s6.06,0.65,6.61-0.35s0.2-1.3,1.3-1.25c1.1,0.05,3.3,0.95,3.86-0.7c0.55-1.65,0.8-2.4,1.65-3.15s1.4-2.5,2.4-2.5s2-0.28,2.83,0.81
		s0.9,1.54,2.07,2.33c0.79,0.53,1.03,1.2,1.55,1.78c0.5-0.41,0.79-1.07,0.81-2.12c0.05-2.1,0.85-3.2,1.3-4.36
		c0.45-1.15,0.75-1.15,0.85-2.65s1.4-0.85,1.4-2.5s-1.1-0.85-1.15-2.2c-0.05-1.35,0.7-0.75,0.9-1.65s0.15-1.45-0.6-1.8
		s-0.3-1.55,0.85-2.2c1.15-0.65,1.06-0.69,1.06-2.04s-0.68-2.07,0.22-3.15s2.52-1.77,2.97-3c0.45-1.24,0.75-1.8,1.77-2.63
		c1.01-0.83,2.22-1.58,2.74-2.37c0.53-0.79,0.04-2.4,0.04-3.72c0-1.31,0.22-1.24-0.45-1.8c-0.68-0.56-0.56-0.86-0.94-1.84
		s-1.09-1.39-1.65-1.09c-0.56,0.3-1.13,1.35-2.52,1.35s-1.91,0.19-2.85-0.45s-1.39-0.94-2.48-0.9c-1.09,0.04-1.24,0.23-1.95-0.26
		c-0.71-0.49-1.01-1.05-2.63-1.05s-3.83-0.19-4.66,0.71c-0.83,0.9-1.28,0.38-1.92,1.09s-1.43,0.9-2.74,0.98
		c-1.31,0.08-3.39-0.2-5.39-0.2s-2.3,0-2.8,0.35s-1.1,1.15-2.4,1.3s-2.35,0.45-3.05,0.65c-0.7,0.2-1.55,1.05-1.7,1.9
		c-0.15,0.85,0.2,1.85-0.5,2.4s-1,1.15-1,1.75s0.4,2.2-0.05,3.2s-1.05,1.2-0.75,1.95s1.1,1.35,0.6,2.1s-0.75,1.85-1.9,2.45
		s-1.35,1.2-1.35,1.8c0,0.6,0,3-0.7,3.55c-0.7,0.55-3.51,2.95-5.36,3.76c-1.85,0.81-2.35,0.7-2.7,1.45s-0.25,0.85,0.3,1
		s1.4-0.05,1.3,1.05s0.15,1.95-0.75,1.95s-0.5,1.05-1.75,1.05s-3.6,0.6-4.26,0s-0.55-1.55,0.55-1.6s0.65-0.45,1.7-0.8
		c1.05-0.35,1.15-0.8,1.15-1.25s-0.5-0.5-1.15-0.1s-1.45,1-2.2,1s-1.05,0-1.6-0.5s-0.05-0.55-2.2-0.7c-2.15-0.15-3.2-0.05-3.96,0.6
		s-1.25,2.05-3.1,2.05s-2.8,0.2-4.61,0c-1.8-0.2-4.21-0.65-4.76-0.2c-0.55,0.45-0.4,1.6-2,1.6s-1.3,1.15-3.2,1.15
		c-1.9,0-5.21,0.3-5.76-0.8s-0.85-3.4-2.45-3.9s-1.7-2.6-4.21-2.75c-2.51-0.15-2.95-0.75-4.05-0.95c-1.1-0.2-1.95-0.05-2.4,0.45
		c-0.45,0.5-0.35,1.75-1.4,2.15s-1-1.2-0.65-1.9s1.75-0.6,1.15-1.95c-0.6-1.35-0.85-1.35-1.55-2.25c-0.7-0.9-0.25-2.85-1.4-3.3
		s-1.55-1.2-1.55-2.9s0.85-3.3-0.5-4.66c-1.35-1.35-2.1-3.05-3-4.71c-0.9-1.65-3.1-3.76-3.45-5.11c-0.35-1.35-0.9-2.7-1.65-4.11
		s-1.1-4.61-0.55-5.21c0.55-0.6,0.85-1.75,0.45-2.75s-2.2,0.05-2.25-1.95s0.2-2-0.3-2.95s-1.2-2.3-1.2-4.01
		c0-1.7-0.4-4.05,0.55-5.26s1.65-1.8,1.7-3.45c0.05-1.65,0.25-4.8,0.45-7.11c0.2-2.3,0.75-2.45,1.25-5.26
		c0.5-2.8,0.65-3.51,1.3-5.96c0.65-2.45,1.8-6.51,3.41-8.16c1.6-1.65,2.25-2.86,2.95-3.86s2.15-3.15,1.6-4.06
		c-0.06-0.1-0.14-0.23-0.21-0.37c-1.14,0.47-1.88,0.42-2.91,0.48c-1.26,0.07-1.96-0.91-3.01-2.03s-2.17-1.05-3.78-1.05
		s-2.24-0.7-3.29-1.47c-1.05-0.77-4.06-2.24-6.16-3.36s-1.96-2.87-1.96-4.41s-0.28-2.52-1.19-3.5s-0.7-1.68-0.42-2.73
		c0.28-1.05,0.14-1.54,0.14-2.94s-0.98-2.03-1.89-2.8s-0.63-1.68-1.26-3.43s-2.24-3.78-3.22-4.62s-0.91-1.96-0.7-3.29
		c0.21-1.33-0.49-2.03-1.12-2.94s-0.91-2.24-1.19-4.06s-1.75-3.85-2.8-4.69s-2.1-2.66-2.87-3.99s-1.89-1.26-3.29-1.26
		s-1.89-1.05-3.64-1.05s-3.85,0.07-5.18,0.14s-2.17,0.91-3.15,3.29c-0.98,2.38-2.59,1.89-3.85,4.27s-3.29,0-4.83-1.33
		s-3.29-2.66-5.46-4.83c-2.17-2.17-3.29-3.85-3.29-5.67s-0.28-4.69-0.42-6.58s-2.03-4.76-3.43-6.23s-3.22-4.41-5.74-7.14
		s-2.73-3.57-2.87-5.25s-2.73-1.96-3.92-2.03s-2.24-0.42-3.5-0.63s-3.64-0.77-5.18-0.84c-1.54-0.07-3.15-0.21-4.41-0.77
		s-1.82,0.35-2.24,3.5c-0.42,3.15-4.97,1.05-7.28,0.42s-5.11-1.33-7.35-1.61c-2.24-0.28-5.74-1.05-9.66-1.89
		c-3.92-0.84-4.41-0.98-8.96-4.41s-7.49-4.48-12.46-7.98s-10.43-8.4-13.23-9.8s-1.33-1.47-0.07-2.73s-3.71-1.4-5.53-1.47
		c-1.82-0.07-2.24,0-5.39-0.49s-7.42-1.05-10.43-1.4c-1.04-0.12-1.77-0.22-2.29-0.29c-0.34,0.98-0.5,2.22-0.29,2.74
		c0.25,0.6,1.1,0.95,1.2,2.8c0.1,1.85,0,1.85,0.55,2.55c0.55,0.7,0.95,0.6,0.75,1.55c-0.2,0.95-0.2,1.6-0.85,1.9
		c-0.65,0.3-0.75,1.2-0.7,2.1s1.2,0.75,1.3,2.15s-0.5,3.46-0.25,4.76c0.25,1.3,1.85,1.35,1.55,2.85s-0.65,4.11-0.25,4.96
		s1.05,1.45,1.05,2.1s-0.6,1.3-0.65,2.55s0.55,2.3,0.5,3.3s0.25,2.65,1.4,3.95c1.15,1.3,2.41,1.15,4,3.22s1.99,2.39,2.31,4.69
		c0.32,2.31,0.32,2.63,1.19,3.66c0.88,1.03,0.48,1.99,1.11,2.46c0.64,0.48,0.64,1.11,0.8,2.23c0.16,1.11,0.4,2.47-0.24,2.86
		c-0.64,0.4-1.83,1.03-1.83,2.07s1.12,1.19,1.51,2.07c0.4,0.88,0.71,1.27,0.48,1.83c-0.24,0.56-1.11,1.99-1.51,1.51
		c-0.4-0.48,1.11-2.31-0.48-2.31s-1.75,0.24-2.7-0.95c-0.96-1.19-2.07,0.71-3.5-0.48s-2.31-1.75-2.94-1.75
		c-0.64,0-0.4,0.72-0.32,1.59c0.08,0.87,1.75,1.11,1.91,2.62s1.59,1.75,1.59,3.18s0.16,1.91,1.11,2.7s5.73,3.66,5.73,5.33
		c0,1.67,0.32,2.47,1.35,2.47s0.87-1.91,1.59-1.11c0.72,0.8,1.27,1.19,1.75,0.48c0.48-0.72,0.72-1.67,1.43-1.43
		c0.72,0.24,0.16,2.62,0,3.5s-0.16,1.75,1.59,3.42s4.06,2.46,3.98,3.66c-0.08,1.19,0.16,1.35,0.56,2.15s0.87,2.15,0.79,3.26
		c-0.08,1.11,0.56,2.86,0.08,4.13s-1.11,0.95-1.35,2.07c-0.24,1.11-0.24,1.83-0.88,2.46s-0.63,2.23-0.4,3.5
		c0.24,1.27,1.35,2.07,2.47,3.1c1.11,1.03,2.31,0.24,2.31,1.91s0.87,1.91,1.67,3.18c0.8,1.27,2.86,1.59,3.26,3.26
		c0.4,1.67,1.83,2.7,2.78,3.98s3.18,2.94,3.42,4.93c0.24,1.99,0.08,5.33,0.48,5.89c0.4,0.56,1.11,0.8,1.27,1.19
		c0.16,0.4,1.75,1.35,2.07,0.08s1.35-1.03,2.31-1.59c0.95-0.56,2.47-1.19,2.47-2.63c0-1.43,0-2.7-0.72-3.26s-0.56-1.91-1.11-2.39
		s-1.04-0.87-0.95-1.99c0.08-1.11,0.64-1.91-0.32-2.31c-0.95-0.4-2.3-3.02-3.26-2.94c-0.95,0.08-2.23,1.51-3.18,0.16
		c-0.95-1.35-1.19-0.56-1.19-1.67s-1.51-0.87-1.43-1.99s1.04-0.95,1.19-1.99c0.16-1.03,0.24-1.19,1.03-1.91s0.4-2.07-0.24-2.46
		c-0.64-0.4,0-2.15-0.87-2.86c-0.88-0.72-0.64-1.11-0.72-2.62s0.32-2.31-0.72-3.02s-0.87-3.74-0.95-6.28
		c-0.08-2.55,0.24-5.41-0.32-6.52s-0.8-2.7-1.35-3.02c-0.56-0.32-0.4,1.43-1.19,1.27c-0.8-0.16-0.48-1.11-1.27-1.99
		c-0.8-0.88-0.16-1.35,0.48-2.31c0.64-0.95,0.08-1.67-0.32-1.83c-0.4-0.16-2.07-1.43-1.51-3.02s0.08-1.75-0.79-2.86
		c-0.88-1.11-2.39-1.43-2.46-2.7c-0.08-1.27-0.4-1.99,0.08-3.18c0.48-1.19,1.27-1.59,1.19-3.02s-0.87-1.2-1.03-2.63
		s-0.96-0.71-1.19-2.54c-0.24-1.83-0.24-2.23-1.19-2.94s-1.43-2.31-1.75-3.66s0.16-3.1-0.8-4.37c-0.95-1.27-2.15-2.47-2.39-3.82
		s-0.88-2.15-1.83-3.02s-1.35-1.19-1.35-3.26s-0.4-4.13,0.16-5.01s0.8-1.35,0.8-3.58c0-2.23,0.08-4.38,0.24-6.36
		c0.16-1.99,0.24-1.83,1.03-2.94c0.8-1.11,1.04-0.8,1.04-2.39s-0.48-3.98,1.67-0.32s2.94,4.06,4.53,3.98
		c1.59-0.08,2.54-0.8,3.34,0.87c0.8,1.67,0.8,0.56,0.88,2.23c0.08,1.67,1.27,1.91,1.99,1.91s0.87-0.95,1.35,0.08
		s2.23,1.51,1.27,2.31c-0.95,0.79-1.51,0.95-1.51,1.91s-0.71,0.64-0.87,1.83c-0.16,1.19,0.71,1.43,0.87,1.99s0.24,1.19,0.24,3.26
		s-0.55,5.57-0.08,7.23c0.48,1.67,1.19,2.23,1.35,3.5s-0.72,2.23,0.16,2.86c0.88,0.64,1.03,1.99,0.08,2.07s-1.35,0.87-1.75,1.59
		s-1.11,1.27-0.8,2.07c0.32,0.79,1.67,1.51,1.75,0.64c0.08-0.88,0.56-0.95,1.43-0.95c0.88,0,1.43,0.95,1.43,1.59
		c0,0.64,0.72,0.71,1.75,3.34s1.83,2.94,2.31,4.45s0.55,3.26,2.94,4.53s3.26,1.35,3.34,3.9s-0.16,3.89,0.64,4.93
		c0.8,1.04,1.11,2.39,2.31,3.34c1.19,0.95,2.86-0.08,2.86,1.75s0.08,4.69,1.83,4.85s1.67,0.24,2.31,1.51
		c0.64,1.27,0.4,1.91,1.03,2.31s1.99,1.59,0.24,1.91s-1.91,0.8-2.15,1.75s-1.03-0.24-0.87,1.35s-0.08,4.21,0.79,4.61
		c0.88,0.4,0.87,2.55,2.78,2.78c1.91,0.24,1.99,0.08,2.39,1.27c0.4,1.19,1.59,1.91,3.42,2.86c1.83,0.95,2.79,1.83,2.55,3.02
		c-0.24,1.19-0.56,1.43-0.56,2.39c0,0.95,0.63,3.5,2.86,5.72c2.23,2.23,4.14,3.66,4.93,5.41s2.47,3.18,3.18,5.81
		c0.72,2.62,3.02,6.12,3.98,7.55c0.95,1.43,4.45,7.24,4.53,8.83s0.56,2.55-0.24,3.58s-0.16,1.75,0.16,2.7s0.32,1.83,1.27,2.78
		s0,1.67,0.95,2.23s1.75,0.95,1.19,1.91c-0.56,0.95-0.72,1.03-0.8,2.15s0,2.63-1.51,2.86c-1.51,0.24,0.08,0.64-1.19,0.87
		c-1.27,0.24-1.35,1.51-0.72,1.51s1.99,0.4,2.07,1.27s-0.08,1.75-1.59,1.75s-2.79-0.16-3.18,0.56c-0.4,0.72-0.64,1.43-0.24,2.7
		c0.4,1.27,1.19,2.31,1.83,4.14c0.64,1.83,3.34,9.15,5.09,9.23c1.75,0.08,8.35,3.89,9.7,6.52c1.35,2.62,4.14,6.68,5.65,7.64
		c1.51,0.96,4.37,1.67,5.8,2.7s4.3,2.15,5.41,2.15c1.11,0,4.45-0.16,4.61,0.95c0.16,1.11,0.48,2.94,3.26,4.77s4.93,4.14,5.57,4.93
		c0.64,0.8,1.35,1.19,3.58,1.91c2.23,0.72,5.01,1.99,6.84,3.34c1.83,1.35,3.74,3.34,5.65,3.34s2.31,1.75,4.61,1.99
		c2.31,0.24,3.1-0.08,3.74,1.27c0.64,1.35,1.99,3.02,3.66,3.26s2.31,0.56,3.66,1.91s3.26,2.78,4.69,2.63
		c1.43-0.16,3.82,0.16,5.25,1.43s3.25,1.83,4.21,2.46c0.95,0.64,1.67,1.03,2.94,1.19s2.7,0.56,3.74-0.16s1.99-1.11,3.02-1.11
		s1.59-1.19,3.1-1.19s2.78,0.16,3.42-1.19s1.75-1.43,3.1-1.91s1.19-2.54,2.54-2.54s1.11,1.83,2.23,1.91
		c1.11,0.08,0.95,1.51,2.47,1.43s2.23-0.64,3.18,0.16s2.23-0.48,3.66,1.91c1.43,2.39,3.58,3.98,5.41,5.65
		c1.83,1.67,5.24,4.92,7.59,7.98c2.22,2.9,3.63,4.96,5.15,6.25c0.27-0.42,0.53-0.96,0.53-1.58
		C458.633,570.044,458.113,568.625,459.163,568.094z"
          pointer-events="auto"
          
        ></path>

        <path
          id="US"
          title="Etats-Unis"
          capital="Washington"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M721.953,210.894
		c-1.46-0.35-1.52-0.87-2.17-1.63c-0.77-0.9-0.83-0.49-2.43-1.32s-0.56-1.88-1.18-2.99s-1.67-1.88-2.71-2.78
		c-1.04-0.9-0.77-4.8-0.77-5.63s0-1.88-0.49-3.27s-0.63-2.23-0.28-4.17c0.35-1.95-1.39-2.23-2.36-3.34s-1.32-1.53-2.85-1.88
		c-1.53-0.35-1.6,0-2.43,1.39s-0.84,1.39-2.22,1.25c-1.39-0.14-1.04-0.9-2.09-2.99s-1.81-0.55-2.29,0.63
		c-0.49,1.18-1.11,1.25-1.39,2.57s-0.7,2.43-2.36,4.1c-1.67,1.67-0.49,3.06-1.46,4.94s-1.67,1.18-1.74,4.03s-1.39,3.41-1.53,4.87
		c-0.14,1.46-1.95,1.46-2.09,2.78c-0.14,1.32-0.77,1.32-2.36,1.32c-1.6,0-1.81,0.7-2.99,2.64c-1.18,1.95-3.61,1.53-8.34,1.53
		s-14.95,0.49-20.65,0.77s-7.85,4.31-9.11,5.49c-1.25,1.18-1.95,1.81-2.22,3.96c-0.16,1.25-0.98,1.61-1.8,1.85
		c0,0.01,0.01,0.02,0.01,0.03c0.41,0.71-0.04,2.1,0.45,3s-0.15,1.13-0.15,2.14s-1.35,1.09-2.4,1.31c-1.05,0.23-0.94,0.45-2.37,1.8
		s-4.54,0.23-6.01,0.23c-1.46,0-3.04-0.6-4.24-1.09s-4.84-0.53-6.68-0.34c-1.84,0.19-2.37,1.65-3.68,2.22
		c-0.18,0.08-0.35,0.1-0.53,0.14c0.23,0.78,0.71,1.54,1.13,2.54c0.25,0.6,0.24,1.03,0.07,1.4c0.16,0.07,0.3,0.16,0.45,0.27
		c0.6,0.46,0.07,0.93-0.4,2.13c-0.47,1.2-2.46,1.86-4.38,2.92s-2.19,1.79-4.38,2.59s-2.26,1.06-3.85,1.46
		c-1.59,0.4-1.46,1.2-3.59,1.66s-2.32,1.06-4.38,1.59s-3.25,1.66-4.58,2.79c-1.33,1.13-2.46,0.6-4.45,0.6s-2.46,0.46-4.05,0.46
		c-1.59,0-3.12-0.6-4.38-1.46s-1.2-1.33-2.59-1.53s-0.66-1.93,0.4-2.39s0.33-1.13,1.13-1.73c0.05-0.04,0.12-0.03,0.18-0.06
		c-0.61-2.27,2.52-4.67,3.83-5.49c1.32-0.83,2.64-1.32,2.99-2.23c0.3-0.79,0.55-2.41,0.7-4.05c-1.92-0.17-0.76-4.2-0.86-4.77
		c-0.1-0.6-0.65-0.7-0.5-2.4s-0.5-2.85-1.1-3.05s-0.7-0.65-0.8-1.55c-0.1-0.9-1.25-0.45-2.35-0.4s-0.8,1.15-1.8,1.6
		c-1,0.45-1.35,1.6-2.25,2.85s-3.05,0.75-3.55-0.4s0.25-1.6,0.85-2.55s1.7-1.25,2.7-2.05s1.1-2.3,1.7-3s1.1-1.6,1.15-2.6
		s0.25-1.3-0.15-1.75s-0.05-1.5-0.2-2.5s0.4-1.05,0.85-1.6c0.45-0.55-0.15-0.85-0.65-1s-0.75-0.55-1.05-1.8s-1.25-0.75-1.9-1.3
		s-0.25-0.6-2.3-0.65c-2.05-0.05-1.65-2.25-2.7-2.25s-0.9,0-2.15-0.7s-1.95-0.7-3.35-0.4c-1.4,0.3-1.05,1.25-1.05,2.55
		s-0.45,1.1-1.7,1.4c-1.25,0.3-0.45,0.5-1.75,1.3s-0.25,1.75,0.1,2.55s0.2,1.85-0.75,2.25s-1.2-1.45-1.15-2.15s0.7-1.5-0.05-1.7
		s-0.8,0.1-1,0.95c-0.2,0.85-0.45,0.45-1.7,0.25s-0.65,1.2-1.8,1.3c-1.15,0.1-0.4,1.85-1.2,2.5s-0.45,0.75-0.45,1.9
		s-1.45,0.7-1.45,1.9s-0.2,1.6-1,2.2c-0.8,0.6-0.35,1.85-0.25,3.15c0.1,1.3-0.15,1.4-0.7,2.05c-0.55,0.65-0.5,1.7-0.55,3.05
		s0.8,1.95,1.35,2.75s0.2,1.25,0.2,2.8s0.05,3.81-0.05,5.86s-2.45,3.9-2.9,4.81c-0.45,0.9-1.35,2.35-2.7,3.05s-2.3,1.75-3.41,2.3
		c-1.1,0.55-2.25,0.05-3.25-0.8s0-1.35-1.1-2.3s-0.1-2.5-0.9-3.05c-0.8-0.55-0.45-1.6-0.4-3.05s0.35-1.4,1-1.9s0.25-2.3,0.15-3.6
		s-0.4-1.65-1-2.4s0.05-2.6,0.55-2.65c0.5-0.05,0.45-0.95,0.4-1.5s-0.05-0.55,0.85-1.85c0.9-1.3,0.75-1.1,1.05-2.6
		s0.1-1.35,0.9-3.56c0.8-2.2,1.8-2.8,1.75-3.95s0.25-1.4,1-1.95s0.5-1.55,0.1-2s-0.8-0.45-1.85-0.45s-0.65,1.35-1.6,1.55
		c-0.95,0.2-2.2,0.05-2-1.05s2.05-1.55,2.4-2.15c0.35-0.6,0.3-0.3,1.8-1.3s1.1-1.2,1.65-2.2c0.55-1,1.65-1.5,2.3-2.8
		c0.65-1.3,0.55-2,1.4-1.95c0.85,0.05,1.6-0.1,1.8-0.6s0.8-0.95,2.25-0.85c1.45,0.1,0.85,1,2,1s0.45-0.45,0.85-0.6
		c0.4-0.15,0.7-0.75,0.9-1.25s3-0.5,4.76-0.5s1.1-0.2,1.2-0.7c0.1-0.5,1.05-0.7,1.75-0.8s1.85,1.55,2.5,1.55s0.75,1,1.15,1.35
		s1.2,0.1,1.85-0.4c0.65-0.5,1-0.65,1.7-0.65s0.95,0.45,1.6,0.7c0.65,0.25,1.1,0.45,1.85,0.45s1.25-0.1,1.25-0.85s1-0.3,1-0.6
		s-0.9-0.6-1.1-1.05c-0.2-0.45-0.35-0.6-0.75-0.7c-0.4-0.1-1.4-0.4-1.95-0.4s-0.5-0.6-0.6-1.15c-0.1-0.55-0.3-1.15-0.95-1.2
		s-1.15-0.5-1.6-0.6c-0.45-0.1,0,0-1.45-0.05s-0.6-1.75-0.65-2.6c-0.05-0.85-0.15-1.25-0.85-1.35c-0.7-0.1-0.85,0.25-1.2,0.45
		c-0.35,0.2-2.45,0.15-3.5,0.05c-1.05-0.1-2.28-0.1-3.71,0.05s-1.28,0.08-1.8,0.6c-0.53,0.53-0.83,0.53-1.43,0.45
		c-0.6-0.07-1.28,0.53-1.5,1.28s-1.95,0.08-2.25-0.6s-1.43-0.38-2.4-0.3s-1.58,0.15-2.33-0.9s-1.58-2.25-2.33-3.23
		s-3.98-0.6-5.26-0.68c-1.28-0.08-0.22-2.25-1.2-2.78c-0.98-0.53-0.68-1.95-1.58-2.03s-0.3,0.38-1.58,1.05
		c-1.28,0.68-1.05,0.68-1.73,1.58c-0.68,0.9-1.73,0.83-2.63,1.5c-0.9,0.68-3.23,0.6-4.58,0.68c-1.35,0.08-1.88,0.83-2.55,0.83
		c-0.68,0-1.05,0.45-1.2,0.9s-0.83,0.45-1.8,0.45s-1.58-0.68-1.43-1.2c0.15-0.53-0.75-0.45-1.13-0.68s0.08-1.35-0.53-1.58
		c-0.6-0.23-0.23-1.28-0.75-1.35s-1.2-0.07-1.95,1.2c-0.75,1.28-2.78,0.23-3.76,0.23s-0.98,1.5-2.18,1.28s-1.88-0.9-1.8-1.88
		c0.08-0.98,2.55-1.2,3.08-1.35c0.53-0.15,1.28-0.98,2.03-1.73s1.13-0.98,2.78-1.43s1.5-1.2,2.25-2.4s2.93-1.35,2.93-1.35
		c1.85-0.13,2.8-0.17,3.35-0.83s1-0.4,2.35-0.4s1.1-0.5,2.2-0.85s1.15-0.7,2.3-1.15c-1.35-2.1-2.75-1.4-4.16-1.4
		s-3.57-1.04-5.23-1.74c-1.65-0.7-2.79-0.61-3.57,0.52s-0.78,0.09-3.57,0.09c-2.79,0-2-1.39-2.96-2.79
		c-0.96-1.39-3.4,0.35-4.18-1.48s-2.96-1.57-3.57-3.04c-0.61-1.48-2.79-2-3.39,0.26c-0.61,2.26-2.96-0.43-3.48-1.22
		c-0.52-0.78-2.44-0.26-3.83-0.96c-1.39-0.7-0.61-1.31-2.79-1.92c-2.18-0.61-0.35-3.57,0.35-5.14c0.7-1.57,0-1.48-1.57-2.35
		s-1.92,0.78-2.26,2.87c-0.35,2.09-3.22,1.57-7.32,1.16c-108.15-13.82-199.08-47.8-201.91-49.43c-1.43-0.82-2.7-1.1-3.62-1.17
		c0.11,0.29,0.22,0.6,0.34,0.93c0.5,1.4,1.55,2.65,0.25,3.55s-1.5,1.6-2.05,2.3c-0.55,0.7-2.05,1.4-2.05,2.25s1.25,0.8,1.3,2.05
		s0.3,2.7-0.85,3.31s-1.95,1.85-2.6,2.65s-1.25,1-1.7,1.95s-0.25,1.55-1.55,1.85c-1.3,0.3-2.96,1.65-3.05,0.25
		c-0.1-1.4,1.75-1.3,1.55-2.75s-0.05-2.45,0.85-2.7s1.25,0.1,2.05-0.75s1.55-0.2,1.75-1.15c0.2-0.95,0.25-1.05,0.6-1.9
		s0.5-1.85-0.75-1.95s-2.35-0.3-3.05-1.45c-0.7-1.15-1.05-1.8-2.2-1.85c-1.15-0.05-1.3-0.2-2.6-1.75c-1.3-1.55-2.35-2.4-3.35-2.25
		s-1.6,2.05-1.85,3.65s-1,1.95-0.8,3.11c0.2,1.15,0.25,1.95-0.2,2.65s0,1.75-0.55,2.55s-0.5,0.65-0.5,2.25s0,2.45-0.35,2.75
		s-0.3,0.75-0.85,1.5c-0.55,0.75-0.8,0.45-0.3,1s1.1,1.3,0.65,1.9c-0.45,0.6-0.55,1-1.3,1.2s-1.2,0.3-1.1,1.1
		c0.1,0.8,0.05,1.5,1.55,1.8s2.1,0.35,2.25,1.15c0.15,0.8,0.65,2.7-1.2,1.7s-2.75-1.95-3.35-1.65s-1.25,2.7-2.6,4.06
		c-1.35,1.35-2.15,2.8-2.6,4.06s-0.55,2.05-1.25,2.75s-2.65,3.3-3.96,5.51c-1.3,2.2-2,3.65-2.9,4.91c-0.9,1.25-1.95,1.5-2.9,3.5
		s-1,3.96-2.65,5.16s-3.96,4.26-5.71,5.16s-1.75,2.5-1.7,3.26c0.05,0.75,0.3,3.05-0.5,4.16c-0.8,1.1-1.55,2.15-1.6,3.6
		c-0.05,1.45,0.05-0.85-0.75,2.9c-0.8,3.76,0.35,3.3-1,5.76s-2.15,4.36-4.06,5.31c-1.9,0.95-2.25,2.4-3.4,3.4s-1.2,2.55-1.2,3.46
		s1.5,1.5,1.5,2.9s0.2,5.71-0.3,6.46s-2,2-2,3.46s-0.4,2.9-0.8,3.55c-0.4,0.65-0.4,1.55-0.6,2.55c-0.2,1,1.4,1.55,1.4,3.15
		c0,1.6,1.2,1.7,1.15,3.96s-0.05,4.15,0.15,5.06c0.2,0.9,0.55,0.8,1.25,1.7s1.5,1.5,1.85,0.55s0.2-2.55,1.3-2.55
		s1.05,0.85,2.15,0.75c1.1-0.1,1.4-0.05,2.5,0.1s1.45,0.85,1.9,1.2c0.45,0.35,0.8,0.8,0.55,1.4c-0.25,0.6-1.75,0.25-2.4-0.45
		s-1.3-1.05-2.35-1.05s-2.4,0-2.6,1c-0.2,1,0.1,4.61-1.5,4.15c-1.6-0.45-0.85-2.85-2.1-2.5s-0.15,2.4-0.9,3.4s-0.35,3.96,0.1,4.76
		c0.45,0.8,0.75,1.55,1.6,2.3s1.3,0.8,1.3,1.75s-0.15,2-1.4,2.4s-1.6,1.2-1.8,2.15c-0.2,0.95-0.1,2.6,0.55,3.2
		c0.65,0.6,1.25,1.55,1.15,2.6c-0.1,1.05-0.5,2.3,0.15,3.15s1.05,1.35,0.95,2.45s0.75,1.9,0.75,3.1s0.15,1.6,0.7,2.2
		s0.75,0.65,0.75,2.1s-0.1,2.25-0.9,3.15c-0.8,0.9-0.35,1.6-0.55,3.15s-0.95,2.3,1.05,2.45s3.26,0.8,4.71,1.6
		c1.45,0.8,4.11,1.05,4.36,2.8s1.05,3.85,2.5,4.46c1.45,0.6,3.65,0.95,3.55,2.5c-0.1,1.55-0.05,2.3,0.9,2.6
		c0.95,0.3,2.05,0.75,2.55,1.95s1.55,2.45,2.05,3.76c0.5,1.3,1.65,2.7,1.65,3.9s-0.1,2.45-0.75,2.95s-0.95,1.05-0.8,1.95
		s1.35,1.55,0.9,2.55c-0.06,0.14-0.11,0.31-0.16,0.46c0.52,0.07,1.25,0.17,2.29,0.29c3.01,0.35,7.28,0.91,10.43,1.4
		s3.57,0.42,5.39,0.49c1.82,0.07,6.79,0.21,5.53,1.47s-2.73,1.33,0.07,2.73c2.8,1.4,8.26,6.3,13.23,9.8s7.91,4.55,12.46,7.98
		c4.55,3.43,5.04,3.57,8.96,4.41c3.92,0.84,7.42,1.61,9.66,1.89c2.24,0.28,5.04,0.98,7.35,1.61s6.86,2.73,7.28-0.42
		c0.42-3.15,0.98-4.06,2.24-3.5s2.87,0.7,4.41,0.77c1.54,0.07,3.92,0.63,5.18,0.84c1.26,0.21,2.31,0.56,3.5,0.63
		s3.78,0.35,3.92,2.03s0.35,2.52,2.87,5.25s4.34,5.67,5.74,7.14s3.29,4.34,3.43,6.23s0.42,4.76,0.42,6.58s1.12,3.5,3.29,5.67
		s3.92,3.5,5.46,4.83c1.54,1.33,3.57,3.71,4.83,1.33s2.87-1.89,3.85-4.27s1.82-3.22,3.15-3.29s3.43-0.14,5.18-0.14
		s2.24,1.05,3.64,1.05c1.4,0,2.52-0.07,3.29,1.26c0.77,1.33,1.82,3.15,2.87,3.99s2.52,2.87,2.8,4.69s0.56,3.15,1.19,4.06
		s1.33,1.61,1.12,2.94c-0.21,1.33-0.28,2.45,0.7,3.29s2.59,2.87,3.22,4.62s0.35,2.66,1.26,3.43s1.89,1.4,1.89,2.8
		s0.14,1.89-0.14,2.94c-0.28,1.05-0.49,1.75,0.42,2.73s1.19,1.96,1.19,3.5s-0.14,3.29,1.96,4.41s5.11,2.59,6.16,3.36
		c1.05,0.77,1.68,1.47,3.29,1.47c1.61,0,2.73-0.07,3.78,1.05s1.75,2.1,3.01,2.03c1.03-0.06,1.76-0.01,2.91-0.48
		c-0.6-1.1-1.55-3.23-1.24-4.34c0.35-1.25,1.35-1.4,0.75-2.75s-1.5-1.05-1.65-2.55s-0.7-1.6-0.05-2.95c0.65-1.35,1-1.8,0.35-2.9
		s-1.95-1.5-1.3-2.15c0.65-0.65,1.05,0.05,1.6,0.5c0.55,0.45,1.25,1.25,1.55-0.15s1.3-0.8,1.4-2.3s-0.25-3.86,1-3.76
		c1.25,0.1,0.75-2,1.15-2.6s0.75-0.4,1.05,0.4s0.15,1.4,0.85,1.15s0.1-1.65,0.65-2.2c0.55-0.55,1.15,0,1.15-1.7s0.2-2.3,0.55-2.3
		c0.35,0,0.5,1.1,0.5,1.85s-0.4,1.5,0.9,1.3s1.65-0.4,1.55-1.6s-1.3-2.1-0.45-2.9c0.85-0.8,1.3-0.8,2.55-0.65s1.55,0.55,1.55,1.35
		s-0.5,1.25,0.5,1.3s0.1-1.05,1.45-1s1.55,0.25,2-0.5s0.5-0.75,1.5-0.8s2.4-0.3,3.05-0.95c0.65-0.65,1.25-0.75,1.75-1.75
		s1-1.3,2-1.75s1.65-0.8,2.55-1.35c0.9-0.55,1.5-1,0.7-1.3s-1.3-0.6-1.3-1.65s-1.25-2.6-0.15-2.85s0.45,1.05,1.3,1.25
		c0.85,0.2,0.25,1.1,1.2,1.45s0.1,1.65,1,1.7c0.9,0.05,0.9-0.85,1.75-0.95s0.55-0.6,1.95-0.55c1.4,0.05,3.15,0.7,3.3-0.5
		s-0.05-1.95,0.85-2.1s0.15,0.95,1.2,0.95c1.05,0,1.2-0.2,2.8-0.05c1.6,0.15,2.6-0.65,3.7,0.05c1.1,0.7,2.1,1.15,2.9,1.85
		s1.41,0.73,2.24,1.48s1.43,0.15,2.14,0.64c0.71,0.49,0.45,0.94,1.2,0.94s0.11-0.83,1.35-0.83c1.24,0,1.39-0.11,1.31-1.2
		c-0.08-1.09,0.3-1.58,1.24-1.58s1.95,0,2.29,0.26s0.75,1.31,0.08,1.73s-1.69,0.41-1.69,0.98s0.56,0.6,1.05,0.6s0.53-0.56,1.09-0.94
		s1.05-1.31,1.54-0.6c0.49,0.71,0.68,1.61,1.35,1.61s1.39-0.34,1.46,0.9c0.08,1.24-0.26,1.73,0.71,2.07
		c0.98,0.34,1.43,0.68,1.92,1.2s0.98,0.49,1.54,0.49s0.26,0.86,1.13,0.98c0.86,0.11,1.46,0.11,1.73-0.41s0.86-0.64,1.05-1.01
		c0.19-0.38,0.45-0.53,1.88,0.41s1.2,1.09,2.1,0.98s0.94-0.45,0.45-1.61s0.26-0.98-0.38-1.99c-0.64-1.01-0.45-1.31,0.56-1.05
		s1.73,0.56,2.25,1.61s1.13,1.35,2.07,1.77c0.94,0.41,1.5,1.16,1.77,1.77s0.79,0.9,0.56,1.61s0.11,1.84,0.86,0.94
		s1.32-1.5,1.88-1.65s2.22-0.71,0.83-2.18c-1.39-1.46-2.67-1.43-3.9-2.25s-2.67-2.29-2.03-2.7s1.35-1.13,2.07-1.69
		c0.71-0.56,2.21-2.1,0.98-2.48c-1.24-0.38-2.97,1.2-3.49,0.04c-0.53-1.16-0.75-1.43-1.99-1.24s-3.27,1.13-3.61-0.34
		c-0.34-1.46-0.98-2.67-0.08-2.97c0.9-0.3,0.98-0.98,2.29-0.64s1.35,0.79,1.88,1.73s1.43,1.05,2.07,1.5s2.18,0.49,2.29-0.26
		c0.11-0.75-0.04-0.56,0.94-1.01s1.46-0.98,2.4-1.01c0.94-0.04,2.37-0.19,3.53-0.19s2.03-0.26,3.15-0.04
		c1.13,0.23,2.74,0.56,3.53,0.08c0.79-0.49,1.09-1.28,1.69-1.95s-0.19-1.5,0.53-1.65c0.71-0.15,0.22,1.2,0.75,1.54
		s0.86,1.01,0.71,2.1s-0.79,2.44,0.15,2.63s2.14,0.08,2.4-0.64c0.26-0.71,1.05-1.73,1.65-1.54c0.6,0.19-0.53,1.01,0.83,1.05
		s1.69-0.08,1.99-0.86s0.38-2.14,1.39-2.1s1.54,0.41,1.58,1.2c0.04,0.79-0.3,1.39,0.45,1.43c0.75,0.04,1.77,0.53,2.18-0.19
		c0.41-0.71,1.39-1.09,2.1-1.13c0.71-0.04,1.35,0.15,2.29,1.31s2.85,2.44,4.17,2.37s2.44,0.19,2.93,1.32s0.45,1.28,1.5,1.99
		s0.68,2.55,1.28,2.85c0.6,0.3,0.79,0.23,1.28,0.98s2.18,0.64,2.82,0.49c0.64-0.15,1.05-1.39,2.14-1.61
		c1.09-0.23,0.68-2.29,1.58-2.29s0.98,0.68,2.03,0s1.54-0.94,2.33-0.94s1.39,0.6,2.7,0.83c1.31,0.23,1.88-0.04,2.25,0.98
		c0.38,1.01,1.31,2.29,2.14,2.89s2.14,3.53,3.12,4.32s1.54,1.95,2.63,2.63s2.03,1.31,2.03,1.95s1.05,1.39,0.56,2.37
		c-0.49,0.98-0.45,1.54-0.6,2.44s0.22,2.63-0.3,3.49c-0.53,0.86-1.2,1.35-0.45,2.4s0.98,1.58,0.9,2.18s-0.68,0.45-1.05,0.11
		c-0.38-0.34-1.16-0.23-1.35,0.23s-0.64,0.49,0.38,1.01s1.95,1.13,2.44,0.49s0.64-0.9,0.98-0.6c0.34,0.3,1.69-0.08,0.04,1.58
		s-1.95,0.79-1.73,2.07s0.38,0.94,0.83,2.33c0.45,1.39,1.09,2.29,1.88,3.53s0.68,2.18,1.24,2.18s1.39,0.3,1.5,0.83
		s0.75,0.71,0.53,1.65s0.45,1.31,1.35,1.2c0.9-0.11,0.75,1.65,0.68,2.48c-0.08,0.83,0.08,1.61,0.22,2.29
		c0.15,0.68,1.09,0.19,1.13,1.16s0.11,1.39,0.86,1.5s1.46,0.26,2.48,1.16c1.01,0.9,2.29,2.25,2.33,3.19
		c0.04,0.94,1.05,1.69,0.68,2.33c-0.38,0.64-0.79,1.31-0.38,1.61s0.15,1.05,1.24,1.39s1.8,0.19,2.85,0s1.73-0.45,2.4-0.41
		s0.64,0,1.58-0.68s1.58-1.99,1.77-3.08s0.71-0.41,0.79-1.58c0.08-1.16,0.64-0.23,0.94-1.8c0.3-1.58,0.38-3.57,0.45-4.58
		c0.08-1.01,0.84,0.35,0.84-2.91c0-3.25,0.76-5.53-0.22-6.83s-1.09-1.73-1.3-3.14c-0.22-1.41-1.52-1.74-1.52-2.93
		s-0.65-0.65-0.76-2.71s-0.43-2.28-0.98-3.04s-0.32-1.08-0.76-2.28c-0.43-1.19-1.41-2.49-1.95-4.12c-0.54-1.63,0.65-1.08,1.19-0.22
		c0.54,0.87,1.19,1.19,1.3,0.54s0.76-0.54-0.43-1.74c-1.19-1.19-0.87-1.3-1.95-3.47s-1.95-2.28-2.49-4.45s-2.06-3.14-2.06-5.86
		c0-2.71-0.43-3.58-1.08-5.53s-0.65-2.28-0.65-4.23s-0.65-2.6-0.33-4.88s-1.09-3.9,0.97-4.66s2.06-0.22,2.39-2.39
		s-0.97-4.12,0.43-4.34s4.12-0.98,3.25-3.9s-0.54-1.95,1.52-1.95s2.93-1.19,4.01-1.3s0.54-1.3,2.6-1.73
		c2.06-0.43,0.87-1.63,2.06-1.84c1.19-0.22,2.39-0.33,3.9-2.28c1.52-1.95,1.84-1.41,2.28-3.25c0.43-1.84,2.17-2.39,2.6-3.47
		s1.19-3.25,4.23-3.25s1.84-0.98,4.34-0.98c2.49,0,2.6-0.98,2.71-1.84c0.11-0.87,1.13-2.31,1.95-2.82c0.79-0.49,0.6-0.71,1.8-1.05
		s1.58-0.79,1.8-1.24c0.22-0.45,0.3-0.53,1.99-0.94s1.13-1.58,2.25-1.58s2.18-0.23,2.48-0.23s0.49-0.64,1.16-0.64
		s0.08,0.75,0.56,0.9s0.83,0,0.9-0.6s0.04-0.75,0.49-0.83s0.68-0.41,0.75-1.13c0.08-0.71,0.04-1.09-1.01-0.94s-1.61,0.08-2.1,0.41
		c-0.49,0.34-0.9,0.45-1.65,0.34s-1.88-0.11-2.22-0.68c-0.34-0.56-1.47-1.5-0.6-1.69c0.86-0.19,0.94,1.05,1.65,1.05
		s1.05-0.26,1.8-0.04c0.75,0.23,0.83,0.26,1.24-0.3s1.58-0.9,1.13-1.39c-0.45-0.49-0.6-1.39-1.01-1.28
		c-0.41,0.11-0.83-1.01-1.65-0.98c-0.83,0.04-1.58,0.15-2.1-0.56c-0.53-0.71-1.39-1.01-0.49-1.09s0.94,0.45,1.58,0.79
		s1.28,0.68,1.77,0.11c0.49-0.56,0.9-1.09,1.43-0.86c0.53,0.23,0.68,1.16,1.31,1.5s1.35,0.34,1.99,0.15s2.07-0.19,2.4-1.24
		c0.34-1.05,1.01-1.05,0.98-1.92c-0.04-0.86-1.05-1.77-1.31-2.33s0.04-0.49-1.09-0.9s-0.53-0.15-1.5-0.83s-1.35-0.83-1.61-0.34
		s-0.64,0.79-1.39,0.79s-1.09,0.64-1.43-0.23c-0.34-0.86-0.56-1.5-1.05-1.58s-0.98-0.71-0.22-0.83c0.75-0.11,1.65,0.49,1.77,0.9
		c0.11,0.41,0.38,0.79,1.01,0.75c0.64-0.04,0.34-1.13,1.24-1.16c0.9-0.04,1.2-0.15,1.58-0.08c0.38,0.08,1.77-1.24,2.59-0.75
		s1.28,0.41,1.69,1.2s0.79,0.9,0.94,1.46s-0.86,0.9-0.75,1.54s0.15,1.2,0.83,0.79s0.41-1.01,0.79-1.5s0.75-0.94,0.34-1.88
		s-1.01-1.28-1.16-2.14c-0.15-0.86-1.77-0.75-1.77-2.1s-1.13-0.3-0.98-2.03s0.98-2.25-0.23-3.08c-1.2-0.83-1.65-1.73-2.03-1.5
		s-0.38,1.5-1.13,0.9s-1.13-1.65-1.73-1.88s-1.8-1.35-2.1-1.73s0.3-1.73,1.58-1.2s1.58,0.75,1.8-0.08s0.8-1.2,0.45-2.15
		c-0.35-0.95-0.65-1.25-0.15-2s0.6-0.9,0.4-2c-0.2-1.1,0.05-1.4-0.5-1.9s-1.6-0.75-1.5-1.6s1.05-0.35,0.95-1.2s-0.25-1.1-0.7-1.45
		s-0.65-1.25-0.6-2.05c0.05-0.8-0.25-1.6-0.05-3s-0.6-4.61,0.5-5.36s0.55-1.95,1.65-2.45c1.1-0.5,0.95-0.75,1.55-0.6
		s0.75,1.25,0.3,1.7s-0.6,1.1-1.15,1.35c-0.55,0.25-0.85,0.9-0.85,1.55s-0.25,2.05,0.15,2.5s0.85,0.4,0.95,1.2
		c0.1,0.8,0,2.25-0.7,2.85s-0.35,1.5,0.25,1.9s0.25,1.15,1.25,1.45c1,0.3,0.5,0.85,1.55,1.6s1.5,0.75,1.75,1.4s1.4,1.7,0.8,2.25
		c-0.6,0.55-1.9,1.6-2.2,2.15s-0.35,0.6-0.5,2.2s-0.9,2.7-0.2,3.26c0.7,0.55,1.1,0.45,1.5-0.9s0.25-1.85,1.05-2.4
		s1.3,0.15,1.55-1.65s0.05-2.15,0.7-2.85s1.05-1.05,1.35-2.4s0.85-2.2,1.55-2.65s1.1-0.85,1.25-1.6s1-0.2,0.9-1.6
		s-0.7-0.25-0.7-1.45s0.2-1.85-0.6-2.25s-1.45-2.35-2.4-2.95s-2.01-1.95-1-2.55c1-0.6,1.1-0.15,1.7,0.05
		c0.6,0.2,0.95,0.55,1.45,1.35s0.85,0.45,1.3,1.1s0.65,0.4,0.65,1.15s1.1,1.1,1.1,0.2s0.1-0.9,0.6-1.45s0.1-1.35,0.85-1.9
		s1.1-0.6,1.45-1.6c0.35-1,0.05-1.75,1.25-2.3s1.95-0.75,1.95-1.55s-0.57-1.22,0.19-2.35s0.59-1.51,0.59-2.27s-0.22-1.19,0.22-1.89
		s0.22-1.46-0.22-1.84s-2-0.92-1.95-1.89s-0.11-2.01,0.94-2.11c1.06-0.1,1-0.05,1.16-0.96c0.15-0.91,0.25-0.91,0.15-1.56
		s0.3-1.11-0.15-1.76s-0.75-0.5-0.7-1.41s0.45-1.36,0.8-0.55s0.91,1.41,0.91,2.17s0.3,1.01,0.3,1.76c0,0.76-1.26,1.51,0,1.56
		s0.6-1.86,1.71-2.17c1.11-0.3,1.61,0.2,2.11-0.81s1.06-1.11,1.81-1.16c0.76-0.05,1.61-0.05,2.07-0.65s1.46-1.06,2.27-1.06
		s2.07,0.35,2.53-0.25s0.25-0.91,1.21-0.91s1.91-0.3,3.17-0.2s3.17,0.3,3.88-0.3s0.86-1.11,0.76-1.96c-0.1-0.86,0-1.87,0.71-2.12
		s1.21,0.25,1.51,1.01s0.25,1.31,1.06,1.41c0.8,0.1,1.36,0.2,1.66-0.35s0.76-1.81,1.81-1.81c1.06,0-0.05,0.6,1.06,0.86
		c1.11,0.25,2.27,0.2,2.47-0.1c0.2-0.3,0.96-0.7,1.56-0.7s1.82,0.76,2.12-0.71c0.3-1.46,0.15-2.07-0.2-2.32
		c-0.35-0.25-0.3-1.51-0.91-1.86c-0.6-0.35-0.7-0.76-1.11-0.76s-1.76,0.5-0.66,0.76c1.11,0.25,0.15,1.16,1.01,1.51
		c0.86,0.35,0.76,0.7,0.71,1.26c-0.05,0.55,0.25,0.96-0.91,1.11s-2.97,0.1-3.38-1.11c-0.4-1.21-0.25-2.07-0.81-2.67
		c-0.55-0.6-0.8-1.56-1.66-1.91s-1.97,0.15-1.97-0.96s-0.41-1.86,0.5-1.91s1.51-0.61,1.31-1.31s-0.56-1.01-1.11-1.61
		s-1.06-1.56-0.35-2.01s1.11-0.25,1.11-1.21s-0.05-1.36,0.86-1.86c0.91-0.51,1.56-0.96,1.56-2.12c0-1.16-0.15-1.81,0.4-2.37
		c0.55-0.55,0.75-0.96,0.8-1.71c0.05-0.76,0.3-0.91,0.86-1.31c0.55-0.4,1.11-0.71,1.71,0.05c0.6,0.76,0.81,1.01,1.26,0.5
		c0.45-0.5,1.26-1.06,0.4-1.56c-0.85-0.5-1.11-1.41-0.4-1.41c0.7,0,1.56-0.3,1.96,0.35s0.91,1.26,1.06,0.45s0.4-1.26,1.31-1.11
		s1.27,0.1,1.82-0.96c0.55-1.06,1.01-1.61,0.76-2.27c-0.25-0.65-0.51-2.17,0.25-2.37s0.2,0.61,0.96,0.61s1.26-0.25,1.56,0.3
		s0.85,1.31,1.26,1.11s-0.6-1.97,0.51-2.07s2.17-0.65,2.77-0.35c0.6,0.3,1.26,0.6,1.91,0.5c0.65-0.1,0.91-0.3,1.01-0.86
		c0.1-0.55,0.35-0.7,1.06-0.76s0.2-1.01,1.26-1.16s1.31-0.76,2.97-0.96s2.32-1.26,2.32-1.76
		C722.783,212.044,721.933,211.874,721.953,210.894z"
          pointer-events="auto"
          
        ></path>

        <path
          id="CA"
          title="Canada"
          capital="Ottawa"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M796.373,115.514
		c0.38-1.05,0.45-1.88-0.22-1.95c-0.68-0.07-0.45,1.35-1.65,1.13s-1.95-0.83-2.4-1.13s-0.83-1.05,0.15-1.05s0.53-0.68,0.68-1.88
		c0.15-1.2,0.68-1.95-0.45-2.25s-2.03-0.07-1.43-1.13c0.6-1.05,1.35-0.98,1.2-2.55c-0.15-1.58-0.08-1.5-1.5-1.73
		s-2.1-2.25-3.38-2.85s-1.65-0.75-2.7-0.38c-1.05,0.38-2.25,0.9-2.55,1.8s-0.08,1.95-1.13,2.55s-0.45-0.07-1.43-0.53
		c-0.98-0.45-1.05-1.58,0.22-2.1c1.28-0.53,0.83-0.68,0.9-1.5c0.08-0.83,0.45-1.05-0.68-1.43s-0.75-0.68-1.43-1.5
		s-0.53-0.83-1.88-0.83s-2.25-0.22-2.63,0.6c-0.38,0.83-2.25,0.45-2.55,1.13s-1.2,2.03-2.18,1.88s-0.38,2.78-2.48,2.93
		s-0.83,1.28-2.4,1.8c-1.58,0.53-1.65,1.95-2.55,2.1c-0.9,0.15-0.68,2.1-1.8,2.25c-1.13,0.15-1.05-0.3-1.2-1.73s-1.2-0.9-2.78-1.58
		s-2.63-1.13-1.35-1.43s0.98,0.3,1.95,0.53c0.98,0.22,2.4,0.68,2.63-0.07s0.75-2.48,1.73-2.48s2.7,0.68,3.08-0.45
		s-0.53-2.25,0.68-2.25c1.2,0,1.95,0.45,2.33-0.45s0.98-1.58,2.1-1.73c1.13-0.15,1.2-1.65,2.1-1.8c0.9-0.15,1.2-1.43,2.4-1.43
		s0.68-1.05,2.18-1.05s1.5-0.53,1.2-0.75s-0.9-0.3-1.43-1.13c-0.53-0.83-1.28-0.98-2.1-0.98s-2.18,0.6-3.23,0s-1.43-1.5-2.03-1.43
		c-0.6,0.07,0.22,1.2-1.35,1.13c-1.58-0.08-1.88-0.08-2.4-0.68c-0.53-0.6-1.5-1.95-2.25-2.33s-0.22,1.05-1.35,0.75
		s-1.13,0-1.58,0.98s-0.53,1.8-1.43,1.73s-1.65-0.08-1.5-1.28s0.08-1.2,0.75-1.5c0.68-0.3,0.9-0.75,0.68-1.28s-0.6,0-1.35,0
		s-1.35,0.15-2.1,0.22s-0.98,0.53-1.13,1.05c-0.15,0.53,0.22,1.28-0.45,1.35c-0.68,0.08-1.58,1.05-1.5-0.6s0.15-2.33,0.68-2.63
		c0.53-0.3,1.8-0.75,1.05-1.35s-1.95-0.6-2.25-1.65s-0.68-1.65-1.58-1.65s-1.5,0.22-2.48-0.6c-0.98-0.83-1.28,0.53-3.3-1.58
		s-1.88-0.68-2.93-2.25s-0.98-1.65-2.03-1.8c-1.05-0.15-2.25-1.2-2.25-1.65c0-0.45-1.73-0.68-1.65-1.73c0.08-1.05,0.75-1.2,1.35-0.3
		c0.6,0.9,0.23,1.2,1.05,1.35s2.55,1.65,2.25,0.08s0.3-2.55,0.75-2.78c0.45-0.23,2.03-1.65,1.35-1.8c-0.67-0.15-1.13-1.95-2.18-1.95
		s-2.25,1.28-2.4-0.23s-0.38-1.65-1.13-1.58s-1.95,0.83-1.65-0.3s0.6-1.73,1.28-1.73c0.68,0,0.98-0.75,0.45-0.9
		c-0.53-0.15-1.39-0.49-1.95-1.5s-0.68-2.03-1.46-1.95c-0.79,0.08-0.11,1.91-0.86,1.8s-1.13-0.08-1.31-0.94
		c-0.19-0.86,0.79-1.65,0.08-1.76s-1.31-0.53-1.77,0.15c-0.45,0.68-0.38,0.49-1.05,1.16s-1.58,1.58-1.54,0.49
		c0.04-1.09,0.38-1.28,0.83-1.58s1.61-0.79,1.61-1.39s1.05-2.03-0.41-2.22c-1.46-0.19-1.8-0.19-2.22,0.11
		c-0.41,0.3-0.68,0.38-1.39,0.45s-0.6,0.9-1.2,0.94c-0.6,0.04-0.9-0.41-0.68-1.05c0.22-0.64,0.45-0.75,1.24-0.86
		s1.43-0.07,1.43-0.68s1.16-0.23,1.39-1.24c0.23-1.01,0.15-1.54-0.67-1.65c-0.83-0.11-0.98-1.31-2.03-1.54
		c-1.05-0.23-0.79-0.83-1.39-1.28c-0.6-0.45-1.13-0.49-1.31,0.34c-0.19,0.83,0.04,1.05-0.86,1.09c-0.9,0.04-2.74,0.41-2.33-0.26
		s2.52-0.26,2.52-1.13c0-0.87,0.56-1.31-0.26-2.07c-0.83-0.75-1.39-0.04-1.65-0.9s-0.3-1.29-1.75-1.64s-2-0.65-1.95-1.8
		c0.05-1.15,0.95-2.3-0.15-2.7c-1.1-0.4-2.05-0.15-2.2-1.25s0.25-1.05-0.4-1.45s-0.75-1.7-1.5-1.7s-0.35,1.2-1.4,1.45
		c-1.05,0.25-0.45,2.15-0.5,2.9s-0.2,1.65-0.6,2.15s-1.75,0.7-1.75,1.55s0.4,1.15,1.1,1.65c0.7,0.5,1.1,1.25,0.75,1.95
		s-0.75,1.2-1.2,1.6s-0.25,1.55,0.35,1.85s1.2,0,1.2,0.6s-0.35,0.8-1.05,0.8s-1.15,0.3-1.2,0.85s-0.15,0.5-0.1,1.25s-0.4,1,0.05,1.5
		s0.7,0.55,0.55,1.3s0.45,0.9-0.15,1.6c-0.6,0.7-1.1,0.45-1.1-0.35s0.1-1.35-0.3-1.9c-0.4-0.55,0.45-1.6-1.2-1.7
		c-1.65-0.1-2.05,0.35-2.05,1.6s-1.1,0.7-2.01,1.6c-0.9,0.9-1.35,0.85-2.2,1.9s-0.7,2.55-2.1,2.45c-1.4-0.1-1.85-0.7-1.75-1.45
		s0.05-0.65-0.8-1s-0.6-1.65-1.1-0.8s-0.35,2.3-0.8,2.75s-0.55,2.4-1.25,2.7s-0.3-0.4-0.1-1.75s0.65-1.85,0.65-2.75s0.1-1.5-0.7-2.5
		s-1-2.85-2.25-2.85s-1.25,0.55-2.55,0.4s-1.8-0.2-2.2,0.6s-0.55,1.4-1.25,1.5s-2.55,0.95-2.55-0.15s-0.5-1.5,0.7-1.7
		s2.16-0.05,2.16-1s-0.35-1.55,0-2.05s0.55-1.15,0.25-1.7s-1.35-0.4-2.35-0.4s-0.9-0.75-0.85-1.25c0.05-0.5,0.2-1.25,0.65-1.25
		s0.95-0.2,0.95-1.05s-0.3-1.15-1-1.45s-0.55-1-0.5-1.9s0.6-2.15,0-2.45s-1.45-1.55-1.35-2.65c0.1-1.1-0.05-2.65,0.6-3.06
		s0.95-0.6,0.95-1.45s-0.55-0.85-0.6-1.5s-1-1.3-1.3-0.4s-0.8,1.25-1.3,1.35s-0.3,1.85-0.95,0.8s-0.45-2-1-2.2s0.5-1.5-0.75-1.45
		s-1.2,1.65-1.75,1.65s-1.2-0.05-1.35-0.65c-0.15-0.6-0.1-0.85-0.75-0.85s-1.85-0.45-2.55,0s-1.8,1.05-2.15-0.35
		c-0.35-1.4-1-1.75-1.2-2.8s-0.35-1.95-1.4-2.25c-1.05-0.3-0.65-1-1.6-1.4c-0.95-0.4-0.3-1.25-1.45-1.25s-0.85,0.7-1.25,1.35
		s-1.55,0.5-1.2-0.4s0.7-1,0.45-2.1s-1.1,0.05-2.01-0.95c-0.9-1-0.95-2.1-1.75-2.25s-0.95-0.1-1.55-0.65s-0.95-0.85-1.8-0.8
		s-0.3,0.75-1.3,1s-0.25,1.2-1.55,1.35s-0.35,1.25-1.45,1.45c-1.1,0.2-1.5,0.3-1.9-0.6s-0.85-0.75-2.1-0.75s-1.9-0.6-2.4,0.1
		s-0.35,1.75-1.05,1.65s-1.2-0.25-1.3-1.15s-0.25-1.25-1.25-1.3s-1.66,0.25-1.9-0.6c-0.25-0.85,0.15-1.15-1.05-1.1
		s-2.3,0.15-3.3-0.45s-1.2-1.05-2-0.85s-0.25,1.35-1.15,1.5s-0.95,1.2-1.95,1.25s-0.8,1-0.8,1.75s-0.2,4.21,0.55,4.71
		s0.7,0.9,1.25,1.2c0.55,0.3,0.25-0.3,1.05,1.15c0.8,1.45,0.75,1.75,0.25,1.85s-0.85,0.4-0.85,0.95s0.7,3.15-0.3,3.15
		s-1,0.25-1,0.95s-1.3,1.1-1.05,1.7s1.05,0.5,1.55,0.55s0.35,0.4,0.35,1.4s0.75,0.4,0.95,1.6s0.15,3.9,0.3,4.66s0.6,1.25,1.2,1.2
		c0.6-0.05,1.4-0.65,1.4-0.05c0,0.6-0.05,1.45-0.7,1.7s0.5,1.2-1.2,1.2s-1.95,0.45-1.8,1.45s0.45,2.7-0.45,3.5s-1.05,2.1-2.75,2.81
		c-1.7,0.7-2.85,1.1-2.3,1.95c0.55,0.85,0.7,0.5,1.45,1.8s1.6,2.2,2.95,2.95c1.35,0.75,1.75,1.15,3.16,2.2
		c1.4,1.05,2.15,1.75,3.41,4.01s2.55,4.21,2.9,6.41c0.35,2.2,0.7,3.4,1.05,5.26s1.2,4.31-0.05,6.46s-0.85,4.36-3.6,5.71
		s-1.15,4.16-5.96,6.21s-5.11,2.5-6.51,2.95s-1.15,1.35-3.26,1.6c-2.1,0.25-1.8,0.45-3.4,0.8c-1.6,0.35-1.05,1.2-0.2,1.65
		s1.3,2.2,2.2,3.56s1.35,3.06,1.9,4.46c0.55,1.4,1.2,3.31,1,4.76s-0.75,3.15-0.1,4s0.85,1.95,0.8,2.65c-0.05,0.7,0.2,2.4,0.85,2.95
		c0.65,0.55,0.65,1.65,1.25,2.3s0.55,2.2-0.15,3.2s-1.35,2.3-2.1,2.85s-2.3,1.75-0.7,2.25s2.5,1.55,2,2.3s-2.05,4.71-2.3,2.95
		c-0.25-1.76-1-2.6-1.7-3.2c-0.7-0.6-1.25-2.3-2.6,0s-1.1,2.45-1.1,3.46s0.25,1.9-0.9,1.65s-1-1.75-2.15-2.45s-2-0.3-2.8,0.05
		s-1.85,0.25-1.55-1.1s0.5-1.8,0.15-3.15c-0.35-1.35-1.3-2.6-2.51-3.5c-1.2-0.9-2.55-2.6-3.85-2.6s-3.96,1.05-3.55-0.25
		c0.4-1.3,2.75-0.4,2.8-1.7s0.25-2.2-0.9-2.75s-4.51-3.21-4.56-4.96s0.05-2.45,1.1-3.36c1.05-0.9,0.55-2.1,0.55-3.46
		c0-1.35,0.3-3.61-0.4-4.61s-1.1-1.15-1.1-2.55s-0.2-2.85,0.45-3.56c0.65-0.7,0.95-0.9,0.95-3s0.55-4.1-1.45-4.06
		c-2,0.05-3.71,1.1-5.56-0.3s-3.1-2.3-4.71-2.3s-4.05,0.25-5.56,0.33c-1.5,0.08-4.81,1.2-5.03-0.38c-0.22-1.58-0.15-1.43-1.65-2.78
		s-2.7-2.7-4.43-3s-3.23-1.8-4.88-2.1s-3.76,0-4.88-2.33c-1.13-2.33-4.88-8.26-8.11-9.16s-4.36-0.6-5.48-1.73
		c-1.13-1.13-3.53-1.88-4.58-2.93s-2.55-1.43-4.66-1.43s-1.95,0.53-2.7,0.9c-0.75,0.38-0.83,0.9-2.55,0.9s-2.25,0.38-3.23,0.38
		s-1.13,0.38-2.25,0.75c-1.13,0.38-1.28-0.3-0.3-1.2c0.98-0.9,2.63-0.6,2.48-3.23s0.68-4.13-0.3-5.18s-1.13-1.65-1.13-3.08
		s1.28-7.21-0.38-7.81s-1.65-0.3-2.63-1.2s-1.88-0.3-2.63-0.3s-0.98,1.58-1.43,2.25s-0.3,2.78-0.98,3.15
		c-0.68,0.38-1.5,0.6-1.13-0.98c0.38-1.58,1.43-2.25,1.5-3.6s0.98-1.13-0.75-2.25c-1.73-1.12-2.7-1.05-2.1-2.63s3.23-1.13,2.7-4.43
		c-0.53-3.31-0.23-3.15,1.43-5.56c1.65-2.4,0.15-5.33,1.8-6.08s3-1.43,3.38-2.55c0.38-1.13,0.9-1.88,2.03-3.08s0.83-3.08,2.03-3.76
		s0.98-1.5,2.18-1.58s2.03,0,2.18-1.28c0.15-1.28,0.22-2.7,1.28-2.63s2.33,0.3,2.33-0.9s-0.75-1.35-0.68-2.1
		c0.08-0.75,0.98,0.45,1.2,0.68c0.22,0.23,1.35,0.6,1.73-0.15s0.9-1.8,1.65-1.88s1.95-0.23,1.73-1.5c-0.22-1.28-0.98-1.8,0.15-2.1
		s1.88-0.3,2.7,0.08c0.83,0.38,1.73,0.98,3.08,0.3c1.35-0.68,0.45-1.43,2.1-1.35c1.65,0.08,2.18,0.38,2.18-1.2s-0.3-3.9-1.5-4.13
		c-1.2-0.22-2.48-0.98-3.46-1.58c-0.98-0.6-1.8-0.53-2.55-0.08s-2.03,0.83-1.8-0.08c0.23-0.9,1.5-1.95,0.23-2.18
		c-1.28-0.22-2.03-1.8-3.53-1.58c-1.5,0.22-2.7-0.15-3.23-0.9c-0.53-0.75-1.13-1.73-0.08-2.48s1.58-1.43,1.88-0.53
		s1.5,2.33,2.18,2.63s1.95,0.3,2.55,0.98s0.75,1.35,2.1,1.5s3.38-0.15,4.13,0.83s1.28,1.95,2.48,1.95c1.2,0,3-0.08,3.98-0.08
		s1.88-0.83,1.88-1.8c0-0.98-0.38-1.8,0.3-2.63s1.28-1.65,1.8-0.75c0.53,0.9,0.3,2.18,1.35,2.18s1.95-1.2,2.93-0.75
		s4.96,0.6,5.26-0.38s2.4-0.08,2.7-2.1c0.3-2.03,1.13-2.55,2.25-3.83c1.13-1.28,2.63-2.25,3.16-3.08s0.68-1.43-0.15-1.95
		c-0.83-0.53-1.05-1.2-2.4-1.2s-2.85-0.75-3.91-0.53c-1.05,0.22-3.83,0.53-4.51-0.98c-0.68-1.5-1.13-1.95-1.8-2.63
		c-0.68-0.68-0.9-1.95-2.4-2.55s-2.78-0.45-2.78-1.2s2.4,0.53,3.15-0.6s2.78,0.53,3.76,2.03s3.23,1.73,3.76,3.08s1.73,1.5,2.85,1.58
		s3.76,2.03,4.36,0.75s0.45-1.2,2.25-2.25s2.18-3.23,3.3-3.68c1.13-0.45,1.88-0.3,2.18-1.58s-1.13-1.5-1.73-1.5s-1.5-0.22-1.5-1.2
		s-0.9-2.18,0.38-2.18s1.35,0.07,2.18,0.53c0.83,0.45,0.98,0.6,1.8,0.22s2.25,0,2.25,0.38s-0.9,1.28,0.53,1.8
		c1.43,0.53,1.28,0.75,2.18,1.43c0.9,0.68,1.2-1.05,2.48-0.68c1.28,0.38,0.15,1.13,1.58,1.05s1.73-0.6,1.43-1.28
		s-0.98-1.58-2.4-1.95s-1.28-1.5-2.4-2.33c-1.13-0.83-1.35-1.28-0.08-1.95s2.1-0.9,2.1,0.07s0.38,2.48,1.43,2.63s0,1.43,1.58,1.43
		s0.68,1.58,2.18,1.58s0.15-1.35,1.88-1.43c1.73-0.07,3-0.83,3.91-1.13c0.9-0.3,1.13-0.38,1.95-1.73c0.83-1.35,1.43-1.88,2.55-2.4
		c1.13-0.53,1.05-0.22,1.2-1.73s0.98-1.13,0.98-2.4s-0.22-1.73-0.98-2.4c-0.75-0.68-1.65-1.73-2.33-2.25s-1.28-0.68-0.98-1.8
		c0.3-1.13,1.28-1.5,0.53-1.88s-2.33,0.3-2.25-1.35s-0.3-1.65,1.05-1.65s1.58-0.07,1.73,0.6c0.15,0.68,1.35,0.68,1.35,0
		s-0.3-0.9,0.83-1.13c1.13-0.22,1.05-0.53,1.65-1.5c0.6-0.98,0.22-1.88-0.53-1.88s-2.4,1.2-2.55,0c-0.15-1.2,0-1.13,0.98-1.58
		s1.58,0.22,1.58-1.2s-0.38-0.53-1.73-1.5c-1.35-0.98-1.2-1.88-2.03-1.95s-2.1-0.38-1.73-1.2c0.38-0.83,0.53-0.98,0.15-1.35
		c-0.38-0.37-1.2-0.9-1.95-0.83s-1.2,0.75-2.7-0.22c-1.5-0.98-1.8-0.53-3.08-1.2s-1.95-0.68-3.3-0.68s-2.48,0.3-2.55,1.13
		c-0.08,0.83-0.08,1.65-0.6,2.1s-0.53,0.9,0.15,1.43s0,2.1,0.68,2.55s1.28,0,1.28,1.28s0.98,3.23-0.53,3.23s-3.08-0.9-3.23,0.22
		c-0.15,1.13-0.53,2.78-0.9,3.23c-0.38,0.45-1.35,1.58-1.43,2.4s-0.38,1.65-0.83,2.1s-0.6,0.68-0.75-0.53
		c-0.15-1.2-0.22-1.88-1.05-2.1s-0.9-1.5-1.95-1.35s-0.3,1.95-1.28,2.4c-0.98,0.45-0.75,1.5,0.53,2.33s2.03,1.65,2.03,2.4
		s-0.98,1.5-1.65,1.8c-0.68,0.3-1.05,0.3-1.43,1.28s-0.08,1.65-1.13,1.8s-1.35-0.07-1.2-1.13c0.15-1.05-0.98-0.53-1.28-2.33
		c-0.3-1.8-2.25-1.65-2.18-2.93s-0.83-4.43,0.53-4.88s2.25,0.38,2.63-1.88c0.38-2.25,1.05-4.58-0.45-5.33s-1.58-1.05-2.03-2.18
		s-1.2-2.18-2.03-2.18s-0.98,0.15-1.35,0.83c-0.38,0.68-2.18,1.95-2.4,3c-0.22,1.05,0.08,2.03-0.83,2.55s-1.2,0.38-1.28,1.35
		c-0.08,0.98,0.08,1.8-0.9,1.95s-1.95,0.15-1.5-1.8s-0.38-2.93,0.83-3.61c1.2-0.68,0.45-0.75-0.08-2.1s-0.83-1.65-0.53-3.31
		c0.3-1.65,0.3-0.98,1.5-0.9s1.2-0.53,0.6-0.83c-0.6-0.3-2.1-2.03-3.98-2.03s-3.91,0.3-4.06-0.83c-0.15-1.13-1.5-0.9-0.9-1.8
		s2.7,0.07,2.78-1.05s0.68-0.07,0.08-1.13c-0.6-1.05-1.28-1.58,0-1.73s2.4,1.13,2.63,0.07c0.22-1.05,0.6-1.43-0.15-1.88
		s-1.05-0.15-1.05-1.73s-1.13-0.3-1.58-1.8s-1.13-1.43-0.75-2.7c0.38-1.28,0-1.65,0.9-2.18s0.6-0.68,0.6-1.88s-0.3-1.58-0.75-1.88
		s-0.53-0.6-1.05-1.58c-0.53-0.98-0.22-2.18-0.98-2.14c-0.75,0.04-2.63,0.56-2.37-0.83s-0.08-1.69,0.94-1.8
		c1.01-0.11,1.43,0.3,1.65-1.01c0.22-1.31,0.53-1.31,1.43-1.54s1.13-0.53,1.35-1.13s1.46-1.01,0.98-1.88
		c-0.49-0.86-2.25-1.2-1.99-1.8s0.15-0.64,1.09-0.64s2.03-0.38,3.15,0.34c1.13,0.71,0.49,1.43,1.61,1.43s2.18,0.38,2.55-0.19
		c0.38-0.56,1.43-0.26,2.03-1.92c0.6-1.65,1.28-1.65,2.33-3.04s1.2-1.58,2.63-2.55c1.43-0.98,1.5-1.73,2.29-2.14
		s1.16-1.54,0.56-2.07s-1.13-1.35-2.29-1.35s-1.2-0.83-2.07-0.86c-0.86-0.04,0.22,0.86-1.54,0.68c-1.77-0.19-1.8-0.75-2.48-1.43
		s-1.2-1.73-2.78-1.84s-3.38-0.34-4.17,0.15s-1.28,1.05-2.07,1.24s-0.19,1.39-1.43,1.77s-1.8,1.2-2.03,1.88
		c-0.22,0.68-0.3,2.1-0.86,3.04s-1.88,1.91-1.84,3.3s0.04,3.04-0.26,4.88s-0.04,3.04-0.6,4.02c-0.56,0.98-0.11,4.13-2.44,4.73
		s-2.55,0.49-2.59,1.31c-0.04,0.83,0,1.16-1.13,1.16s-1.73,0.83-2.37,1.65c-0.64,0.83-1.16,1.5-1.24,2.14s-1.13,0.9-1.16,1.92
		c-0.03,1.02,0,1.28,0.49,1.54s1.58,0.11,1.16,1.05s-0.9,1.76-1.58,1.99s-2.07-0.15-2.22,1.01c-0.15,1.16-0.75,4.09,0.49,5.03
		s1.35,1.2,2.29,1.95s1.95,1.31,2.67,2.22c0.71,0.9,1.09,1.2,2.1,1.2c1.01,0,0.98-0.19,1.99-0.19s1.5-0.04,1.43,0.6
		s1.01,1.58,0.26,1.84s-0.56-0.83-1.65-0.9s-1.65-0.79-2.25,0.22s-1.13,3-1.61,3.64c-0.49,0.64-1.16,1.58-0.83,2.25
		c0.34,0.68,1.43,0.56,1.65-0.19s1.2-1.76,1.65-1.8s1.54,0.49,0.98,1.43s-0.9,1.09-1.01,1.95s0.19,1.65-1.09,1.65
		s-2.44,0.19-2.85,0.98s-1.46,2.63-2.74,2.89c-1.28,0.26-3.38-0.07-3.64,0.41s-0.41,1.16-1.05,1.46s-1.58,0.98-1.35,1.88
		c0.22,0.9,1.09,0.9,1.09,1.61s-0.71,0.6-0.94,1.8c-0.22,1.2,0.19,2.14-0.19,3.34s-0.26,1.61-1.46,1.5s-2.89,0.86-3.83-0.3
		s-1.58-1.76-0.6-1.76s3.87,1.13,3.94,0.04s0.11-1.8-0.68-2.14s-1.35-0.49-1.2-1.54c0.15-1.05-1.2-2.33,0.11-3.46
		s1.8-1.35,2.29-2.22c0.49-0.86,0.79-1.16,0.75-1.95s-0.22-0.94-1.24-0.98c-1.01-0.04-1.54-0.64-2.03-1.54
		c-0.49-0.9-0.38-0.79-1.43-1.54s-1.13-1.5-1.77-1.5s-0.53,1.61-1.91,1.61s-1.09,0.07-2.33-0.04s-1.39,0.19-1.5,1.05
		s-0.75,2.67,1.13,2.85s2.48-0.04,3.08,0.71c0.6,0.75,1.5,1.24,1.43,1.92s-0.26,1.65-1.46,1.73c-1.2,0.07-2.25,0.3-2.4-0.68
		s-0.08-2.7-1.24-2.7s-1.8-0.11-2.18,0.34s-0.56,0.56-1.65,0.56s-1.47-0.56-2.55-1.13c-1.09-0.56-1.28-1.88-2.93-1.88
		c-1.65,0-3.04,0.2-5.14,0.3s-2.55,0.9-3.36,0.05c-0.8-0.85-1-1.15-1.8-1.25s-1.25,0.25-1.75-0.8s-1.8-0.1-1.8-1.55
		s-0.25-2.1-1.15-2.2s-1.6,0.45-2.6,0.1s-1.4-0.05-1.9-1.45s0-1.6-1.2-2.15c-1.2-0.55-1.45-1-1.65-2.25
		c-0.2-1.25,0.05-1.8-0.85-1.75s-0.6,0.95-1.6,1.15s-1.25,1.95-2.35,2s-2.55-0.05-4.05-0.05s-4.46-0.4-5.16,0.25s-2,1.75-1.85,2.8
		s1.1,2.2,0.35,3s-0.75,1.75-0.75,2.6s-1.2,1.3-0.55,2.2c0.65,0.9,1.25,1.55-0.1,1.8s-2.7-0.05-2.9,0.25c-0.2,0.3,0.3,1.9-0.9,1.95
		c-1.2,0.05-2.2-0.1-2.05-0.95c0.15-0.85,1.75-1.15,1.5-2.3s0.2-2.05,1.15-2.05c0.95,0,1.35,0.5,1.2-1.5s0.45-4.26-0.65-4.66
		s-1.5-0.3-1.85-1.7c-0.35-1.4-0.95-0.8-1.6-2.35s-0.65-2.5-1.95-2.5c-1.3,0-3.01,0.75-4.56,0.75s-3.4,1.1-4.81-0.05
		c-1.4-1.15-1.95-1.4-2.95-1.4s-1.35,1.25-2.5-0.05s-1.3-1.65-2.35-1.75c-1.05-0.1-2-0.05-3.05-1.35c-1.05-1.3-1.05-1.1-2-1.8
		c-0.95-0.7-1.25-0.65-0.9-1.65s1.75,0.05,2.3-1.15s0.5-1.25,1.85-1.25s1.9-0.1,2.15,0.6s0.85,1.05,1.75,1s1,0.15,1.4-0.9
		s1.3-0.9,1.2-1.95c-0.1-1.05-0.8-0.4-0.8-2.35s-0.75-2.35-1.35-2.45s-1.2-1.9-2.4-2.7c-1.2-0.8-1.65-0.65-1.65,0s0.1,1.8-0.75,1.45
		s-0.35-1.9-2.1-2.2s-1.8-0.1-2.4-1.4s-2.3-1.5-2.6-3.25c-0.3-1.75-0.2-3.3-1.6-3.36c-1.4-0.05-2.15,0-2.55-0.9s0.15-1.85-0.6-2.25
		s-1.05-0.85-1.2-2.3c-0.15-1.45-0.05-1.8-1-2.6s-2.85-3.35-4.31-3.35s-2.1,0.65-3.15,1.55s-1.8,0.7-2.35,1.05
		c-0.55,0.35-0.25,0.85-1.45,0.9s-2.1-0.45-2.1-1.35s0.55-0.9,1.5-2.05s0.55-1.9,1.6-2.9c1.05-1,1.35-0.55,1.75-1.65
		s0.4-1.75-0.7-1.75s-2.05-0.45-2.65,0.95s-0.5,1.7-1.95,1.8s-2.6,0.65-2.75,1.45s0.9,1.95-1,1.95s-2.2,0.1-2.2-1.5
		s-1-3.85,0.7-5.06c1.7-1.2,1.35-2.65,2.35-3.55s1.4-1.35,1.45-2.35c0.05-1,0.35-4.3-1.2-4.15s-0.85,1.55-2,2.4s-0.9,1.9-2.25,1.9
		s-2.2,0.4-2.55,0.75c-0.35,0.35-0.26,0.8-1.2-0.1s-0.71-1.13-1.43-0.83c-0.71,0.3-0.86,0.19-0.83,0.86
		c0.04,0.68,0.83,1.77-0.38,1.99c-1.2,0.23-0.86-0.49-0.98-1.54s-0.15-1.5-1.16-1.35s-0.04-0.04-2.07-0.79s-2.1-0.45-2.93-0.08
		c-0.83,0.38,0.15,1.65-0.9,1.73c-1.05,0.07-2.18-0.08-2.63,0.6s-1.05,1.65-1.88,1.58c-0.83-0.07-0.15-1.28,0.15-1.8
		s2.25-0.68,1.05-1.43s-1.73-0.45-1.73-1.5s0.53-0.6,1.8-0.6c1.28,0,1.73-0.6,2.48-0.3s2.25,0.75,2.85,0.38
		c0.6-0.38,0.98-0.53,2.33-0.38c1.35,0.15,2.18,0.23,3.38,0.15c1.2-0.07,0.9-1.65,0.53-1.88c-0.38-0.23-0.9-0.6-2.1-1.35
		s-1.43-0.6-2.03,0.08s-1.35,1.13-2.25,0.15c-0.9-0.98-1.73-0.15-2.7,0.23c-0.98,0.38-2.03,0.23-2.7-0.3s-0.9-0.9-1.2-0.3
		c-0.3,0.6-1.05,0.83-1.5,0.23s-1.13-0.75-1.8-0.45c-0.68,0.3-0.15,1.28-1.65,0.9s-1.58-1.2-2.63-1.13
		c-1.05,0.07-1.58,0.07-1.95,0.75c-0.38,0.68-1.95,0.75-3.23,0.68c-1.28-0.08-1.5,0.38-1.95,1.43s0.53,2.25-0.6,2.78
		s-2.03-0.07-1.88-1.35s1.5-0.98,0.6-2.4c-0.9-1.43-1.73-1.43-2.03-0.3s-0.45,2.93-1.2,1.5s1.13-3.45-0.38-4.28
		c-1.5-0.83-2.4,0.83-2.25-1.28c0.15-2.1-0.9-0.6-0.98-3.15c-0.08-2.55-0.75-3.76,0.08-4.88c0.83-1.13,1.05-0.45,0.75-2.03
		s-0.3-1.13-0.6-2.7c-0.3-1.58-0.38-1.58-1.8-2.18c-0.96-0.4-1.6-0.67-1.92-1.25c-10.33,7.48-91.94,66.77-101.65,79.29
		c-1.05,1.2-0.65,2.85,1.2,3.61c1.85,0.75,2.1,0.95,3.45,1.3s4.06,0.75,2.25,2.25c-1.8,1.5-2.6,2.3-2.8,3.31c-0.2,1,0.5,1.75,0,3.36
		c-0.5,1.6-1.4,3.81-0.9,5.01s0.15,3.1-0.55,3.96c-0.7,0.85-1.45,1.9-0.75,2.7s1.7,0.55,3.2,0.5s2.45,0.65,3.61-0.3
		c1.15-0.95,2.2-2.75,3.81-3.1s2.45-1,3.9,0.05c1.45,1.05,2.65,2.35,1.4,3.9s-3.96,1.95-2.45,3.36c1.5,1.4,1.85,1.45,1.5,3.06
		c-0.35,1.6-2,1.7-1.5,3.56c0.5,1.85,1.55,1.35,0.8,3.31c-0.75,1.95-2.8,5.76-2.9,7.76s-0.65,4.56-1.3,5.86s-1.1,5.31-2.25,6.51
		s-1,2.55-1.1,3.6s-2.2,2.2-0.9,3.1c1.3,0.9,3.05,2.1,3.1,4.51c0.05,2.4-0.45,3,0.65,3.91s2.3,2.25,0.3,2.85s-2.85,2.91-3.55,4.01
		c-0.7,1.1,0.05,2.8-1.3,3.65c-1.35,0.85-2.15-0.55-3.25,1.05c-1.04,1.51-2.67,0.73-6.08,1.47c0.25,0.24,0.61,0.39,0.95,0.63
		c0.57,0.4,1.77,0.23,1.65,1.2c-0.12,0.97,0.06,1.65-1.88,1.94s-1.99,1.14-1.99,1.88s0.8,3.41-0.23,3.76
		c-1.02,0.34-1.2,0.63-1.31-0.63s-0.8-1.48-1.25-1.31s0.17,1.42-0.91,1.65c-1.08,0.23-1.76,0.68-1.31,1.2
		c0.46,0.51,1.59-0.06,1.42,0.8c-0.17,0.85-1.31,0.51-1.42,1.31s-0.63,0.74-1.42,0.74s-0.51,0.57-0.51,1.2s-0.74,3.47,0.06,4.04
		s0.4,0.68,0.97,1.14c0.57,0.45,0.51-0.28,0.8-0.8s1.25-0.57,1.71,0.06s1.25,0.45,1.71-0.11c0.46-0.57,2.9,0.17,3.13-0.8
		c0.23-0.97,0.17-1.88,1.42-2.05s1.54-0.11,2.62-0.68s1.94,0.34,0.97,0.74s-1.59,0.63-1.59,1.42c0,0.79-0.17,1.59-1.6,1.77
		c-1.42,0.17-1.71,1.82-3.02,1.93s-2.1,0.68-2.33,1.54c-0.23,0.86-0.46,1.88-1.31,2.05s-0.8,1.02-1.65,0.63
		c-0.85-0.4-1.31,0-1.37,0.97s-0.4,1.94,0.85,1.59c1.25-0.34,2.33-0.74,2.28-0.06c-0.06,0.68,0.11,1.31-0.91,1.59
		c-1.02,0.28-1.76,1.25-1.99,1.99c-0.23,0.74-0.29,1.25,0.51,0.91c0.8-0.34,0.8-0.91,1.54-0.68c0.74,0.23-0.06,1.37,0.68,1.42
		s2.11,0.34,2.22,0.97c0.11,0.63-0.12,1.14,1.25,1.14s0.74-0.46,2.05-0.4c1.31,0.06,1.82,0.06,1.82,0.45s-0.57,1.54-2.16,1.54
		s-1.77,0.85-3.53,0.85s-1.88-0.46-2.05-1.08c-0.17-0.63-0.4-1.14-0.85-1.14s0.23,1.25-0.68,1.59c-0.91,0.34-0.68,1.54-0.57,2.05
		s1.2,0.68,0.63,1.42c-0.57,0.74-0.51,1.08-1.54,1.08c-1.02,0-1.42,0.11-1.42,0.74s-0.23,1.48,0.63,1.48c0.85,0,1.59-0.68,1.48,0.4
		s0.06,1.42-0.8,1.99c-0.85,0.57-1.99,0.91-1.25,1.59s1.25,1.25,0.4,1.76s-0.97,1.31-0.17,1.54s0.97,1.99,2.05,1.94
		s1.68,0.05,2.52,0.43c0.85,0.38,2.07-0.19,2.07,0.66c0,0.85,0,1.6-1.04,1.6s-1.41,0.94-0.47,0.94s-0.09,0.66,1.51,1.32
		s2.73-0.66,2.73,0.38s0.66,1.22,0.66,2.07s-0.47,1.32,0.38,1.7s1.13-0.66,2.26-0.66s1.04-1.13,1.6-2.17
		c0.57-1.04,0.28-0.94,1.13-1.13s0.85,0.66,0.38,1.04s-1.04,0.94-1.23,1.6c-0.19,0.66-0.38,1.51-1.32,1.7
		c-0.94,0.19-0.85,1.22-0.47,1.79s1.6,1.04,0.75,1.98s-1.6,1.98-1.98,1.7s-0.47,1.04-0.57,2.54c-0.09,1.51,0.18,1.82,0.18,2.37
		s-0.55,2.05,0,2.15s0.9-0.1,1-1s0-1.5,0.9-1.45s1.85,0,2.25-0.55s0.2-1.9,1.45-2.3s1.45,0.2,0.85,0.5s-1.2,1.1-1.4,1.8
		c-0.2,0.7-0.8,1.75-1.5,1.9s-1.05,0.45-1.1,1.15c-0.05,0.7-0.25,2.65,1.25,2.9s2.25-0.2,2.95,0.2s1.35,1.35,0.75,2.15
		s-1.8,1.1-1.8,2c0,0.69,0.26,1.4,0.61,2.33c0.91,0.07,2.19,0.35,3.62,1.17c2.83,1.63,93.76,35.61,201.91,49.43
		c4.1,0.41,6.97,0.93,7.32-1.16s0.7-3.75,2.26-2.87c1.57,0.87,2.26,0.79,1.57,2.35c-0.7,1.57-2.52,4.53-0.35,5.14
		c2.17,0.61,1.39,1.22,2.79,1.92c1.39,0.7,3.31,0.17,3.83,0.96c0.52,0.78,2.87,3.48,3.48,1.22c0.61-2.26,2.79-1.74,3.39-0.26
		c0.61,1.48,2.79,1.22,3.57,3.04s3.22,0.09,4.18,1.48s0.17,2.79,2.96,2.79c2.79,0,2.79,1.04,3.57-0.09
		c0.78-1.13,1.92-1.22,3.57-0.52s3.82,1.74,5.23,1.74c1.4,0,2.8-0.7,4.16,1.4c1.15-0.45,1.1-1.85,2.4-2.5s1.05-2.3,2.05-2.45
		s1.2,0.75,1.5,1.25s0.7,0.15,1.4-0.5s1.25,0.05,1.45-0.85s1.3-1.1,1.8-1.25s-0.9-0.6-0.55-1.5c0.35-0.9,0.4,0.25,1.3,0.55
		c0.9,0.3,1.05,0.8,1.55-0.5s-1.8-2.8,0.25-3s1.85,0.4,2.2,0.9c0.35,0.5,1,0.55,2.05,0.6s1.3,0.95,2.25,1s1.35,1.35,1.95,1.35
		s0.9-0.3,1.35-0.95c0.45-0.65,1,0.05,1.7,0.75s1.05,0.3,2.3,0.3s0.85,1.55,1.4,2.05c0.55,0.5,0.8,1.25,1.2,3.7s0.8,1.35,1.95,2.7
		s3.35,1.5,5.71,1.3s1.9,0.55,1.95,1.55s-0.6,1.2-0.8,2.51c-0.2,1.3,0.9,1.35,1.65,1.85s0.25,1.6,0.15,2.7
		c-0.1,1.1-0.65,0.85-1.3,1.25c-0.65,0.4,0.4,1,1.25,1s0.6,0.7,1.2,0.85c0.6,0.15,0.3,1.4,0.55,3s-0.8,1.1,1.05,1.25
		s0.95,1.2,1.25,2s1,1,2,1.05s2.25,0.95,3.6,1.15s0.95,0.4,1.9,0.9s2.05,0.3,4.01,0.15c1.95-0.15,2.5,0.05,4.86,0.35
		c2.35,0.3,1.7,1.1,4.26,1.1c2.55,0,1.5,1.35,2.9,1.1s2.35,0.9,4.66,1.15s1.7,2.65,3.35,3.6s0.65,1.45,2.35,3.15
		c1.7,1.7,0.7,2.45,1.75,3.45s1.1,0.4,1.15,1.25s-0.3,1.05-0.95,1.45s-0.2,1.5-0.25,2.55s-0.5,0.45-2,0.25s-1.1-0.2-2.4-0.85
		s-1.1-0.15-2-0.95s-0.8-0.8-1.05-1.95s-0.45-0.9-1.7-1.3c-1.25-0.4-1.85-2.05-2.55-2.75s-1.15-0.95-2.55-0.95s-1.85,0.7-0.9,0.95
		s0.25,0.5,1.25,1.05s1.2,2.6,1.9,2.75s0.5,1.7,0.25,3.71c-0.25,2-1.8,2.25-2.7,3.35s-0.45,2.05-1.5,3.55s-0.15,2.35-0.35,5.26
		c-0.2,2.9-1.15,2.95-2.7,3.51c-1.55,0.55-0.5,1.5-2.96,1.85c-0.14,0.02-0.23-0.03-0.34-0.04c-0.15,1.64-0.39,3.26-0.7,4.05
		c-0.35,0.9-1.67,1.39-2.99,2.23c-1.31,0.83-4.45,3.22-3.83,5.49c0.8-0.39,1.95,0.22,2.01,0.59c0.07,0.4,0.73,0.46,1.79,0.46
		s1.2-0.4,1.33-0.86s1.2-0.73,1.2-0.73c1.33-0.6,1.39-0.26,2.72-0.73s1.4-0.86,2.59-0.93c1.2-0.07,1.53-0.99,3.05-3.05
		c1.53-2.06,3.52-1.73,5.58-1.73s1.39,0.2,2.19,0.93s1.06,0.6,2.99,0.66c1.93,0.07,1-0.2,1.06-1.79c0.07-1.59,4.32-1.86,6.31-1.73
		s3.39-0.27,4.32-0.47c0.7-0.15,1.26-0.15,1.74,0.06c0.17-0.37,0.18-0.81-0.07-1.4c-0.42-1-0.9-1.76-1.13-2.54
		c-1.11,0.26-2.2-0.06-3.75-0.06c-1.8,0-1.35-0.04-1.54-0.83c-0.19-0.79,0.86-2.18,1.47-3.08s2.29-1.4,2.44-1.43
		c1.28-0.26,1.65-1.2,2.29-1.54c0.64-0.34,1.61-0.34,3.57-0.34c1.95,0,1.73-0.07,2.93-0.83s2.82-0.6,4.02-0.98
		c1.2-0.38,2.52,0.56,3.42,1.24c0.9,0.68,2.29,0.34,3.19,0.34s1.73-0.83,1.77-1.65c0.04-0.83-1.61-0.11-2.52-0.98
		c-0.9-0.86,0.49-0.83,1.95-0.64c1.47,0.19,1.88-0.04,2.7-0.19s1.46-1.01,2.7-1.09c1.22-0.07,0.59,1.86,0.97,2.6
		c0.82-0.25,1.64-0.6,1.8-1.86c0.28-2.16,0.97-2.78,2.22-3.96c1.25-1.18,3.41-5.21,9.11-5.49s15.92-0.77,20.65-0.77
		c4.73,0,7.16,0.42,8.34-1.53c1.18-1.95,1.39-2.64,2.99-2.64s2.22,0,2.36-1.32s1.95-1.32,2.09-2.78c0.14-1.46,1.46-2.02,1.53-4.87
		c0.07-2.85,0.77-2.15,1.74-4.03s-0.21-3.27,1.46-4.94c1.67-1.67,2.08-2.78,2.36-4.1c0.28-1.32,0.9-1.39,1.39-2.57
		s1.25-2.71,2.29-0.63c1.04,2.09,0.7,2.85,2.09,2.99s1.39,0.14,2.22-1.25c0.83-1.39,0.9-1.74,2.43-1.39
		c1.53,0.35,1.88,0.76,2.85,1.88c0.97,1.11,2.71,1.39,2.36,3.34c-0.35,1.95-0.21,2.78,0.28,4.17c0.49,1.39,0.49,2.43,0.49,3.27
		c0,0.83-0.28,4.73,0.77,5.63c1.04,0.9,2.09,1.67,2.71,2.78c0.63,1.11-0.42,2.15,1.18,2.99c1.6,0.83,1.67,0.42,2.43,1.32
		c0.65,0.77,0.71,1.28,2.17,1.64c0-0.1-0.04-0.17-0.01-0.29c0.24-1.29,0.16-1.25,1.21-1.09c1.04,0.16,1.97,0.28,2.29-0.44
		s0.8-0.96,1.61-0.88c0.8,0.08,1.25,0.16,1.33-0.96c0.08-1.13-0.24-1.77,0.56-2.13s1.05-0.36,1.13,0.72s0,1.85,0.84,1.73
		c0.84-0.12,0.32-1.33,1.29-1.41s0.77-0.76,2.17-1.08c1.41-0.32,0.84-1.17,1.69-1.29c0.84-0.12,0.96-1.27,1.87-1.61
		s1.08-0.64,1.29-1.59c0.21-0.95,0.86-0.13,0.95-1.16s0.39-1.81,0.91-1.51c0.52,0.3-0.39,1.42,0.17,1.46
		c0.56,0.04,1.12-0.3,1.55,0.04s-0.17,0.99-0.65,0.99s-0.6,0.17-0.6,0.99s-0.34,1.59-1.03,1.81c-0.69,0.21-1.12,0.51-0.78,1.16
		s0.47,0.86,1.77,0.86s1.42-0.26,1.77,0.13c0.34,0.39,0.69,0.43,1.25,0.43s2.07-0.43,2.15,0.17s0.39,1.29-0.65,1.29
		c-1.03,0-1.59,0.13-2.5-0.35c-0.9-0.47-1.98-0.65-2.58-0.3c-0.6,0.35-1.68,0.82-2.28,1.29c-0.6,0.47-2.2,1.33-2.5,2.07
		c-0.3,0.73-2.06,2.93-3.23,3.23c-1.16,0.3-2.11,1.12-2.28,2.28c-0.17,1.16,0.3,1.76-0.3,2.41c-0.6,0.65-1.42,1.72-0.09,2.37
		s0.09,2.97,1.12,3.53c1.03,0.56,1.85,0.17,2.67,1.12s1.17,1.89,1.77,2.07c0.6,0.17,1.98,0.04,2.2-0.86c0.22-0.9,0.3-1.12,1.16-1.34
		c0.86-0.21,2.07-0.6,2.41-1.89c0.34-1.29,2.33-1.08,2.5-2.24s0.47-1.98,1.12-2.41c0.65-0.43,1.12-0.43,1.2-1.55
		c0.09-1.12,0.65-1.2,1.38-2.41s0.78-2.2,1.34-2.2s0.39,1.94,1.16,2.02c0.77,0.09,1.42-0.56,1.72-1.55s0.99-0.73,1.98-0.82
		c0.99-0.09,0.99-1.2,2.34-1.2c1.35,0,2.29-0.19,2.74-0.79c0.45-0.6,1.28,0,2.33-1.01s1.28-1.16,2.22-1.39
		c0.94-0.23,0.3-0.9,1.91-1.28s1.65-1.35,2.7-1.43c1.05-0.07,0.86-1.16,1.77-1.39c0.9-0.23,1.2-0.26,1.54-0.98
		c0.34-0.71-0.75-0.68-1.28-1.24c-0.53-0.56-0.64-0.19-0.64-1.2s-0.04-1.24-0.64-1.46c-0.6-0.23-1.24-0.34-1.61,0.23
		c-0.38,0.56-1.13,0.9-1.69,0.56s-1.05-0.34-1.01-1.16c0.04-0.83-0.04-1.46-0.98-1.28c-0.94,0.19-1.43,0.68-1.54,1.43
		s-0.41,1.09-1.77,1.09s-2.48,0.26-3.04-0.6s-0.86-1.43-2.29-1.31c-1.43,0.11-2.39,0.53-3.69,0.58s-4.06,0.85-4.46-0.5
		s-0.15-3.5-1.95-3.5s-4.35,1.6-4.55-0.05c-0.2-1.65-0.35-1.85-1.35-2.6s-0.85-1.85-1.65-2.55c-0.8-0.7-0.2-3.55-1.1-3.71
		c-0.9-0.15-3.81,0.8-3.76-0.25s1-0.5,1.45-1.9s2-0.1,1.65-2.3s0.45-3.05-0.75-3.96c-1.2-0.9-1.45-1.3-2.6-1.1
		c-1.15,0.2-0.75,0.65-1.25,1.65s-0.65,1.55-1.7,1.65c-1.05,0.1-0.4-1.85-1.55-2.3c-1.15-0.45-1.65-0.75-2.46-0.7
		c-0.8,0.05-1.25-0.85-2.75-0.7c-1.5,0.15-1.75,0.35-2.75,0.1s-0.25-0.9,0.6-0.95s0.6-0.95,2.15-0.8c1.55,0.15,2.35,0.4,2.85-0.1
		s1-0.8,1.3-0.3s0.8,1.05,1.85,0.95s0.25,0.85,1.4,0.8s0.8-0.6,1.8-0.7c1-0.1,1.2-0.1,1.75-1.05s0.95-1.65,1.85-1.95
		c0.9-0.3,1.05-0.1,1.5-1s0.5-0.95,1.2-1.15s0.9-0.3,1.05-0.85c0.15-0.55,1.7-0.2,1.6-1.05c-0.1-0.85-1.1-0.55-1.35-1.3
		s-1.65-0.55-1.4-1.3s1.45-0.6,1.4-1.1s-1.05,0.2-1.05-0.6s-0.1-0.8-1.1-0.85s-0.9,0-1.45-0.75s-1.05,0.1-2.25-0.8
		c-1.2-0.9-2-1.05-3.2-1.05s-2.91,0.02-4.61,0.81s-2.15,0.68-3.96,0.9c-1.81,0.23-2.72,0.91-4.19,1.13
		c-1.47,0.23-2.27,1.81-4.08,2.38s-3.28,1.7-4.98,2.72c-1.7,1.02-4.98,3.06-5.66,3.96s-4.07,2.38-4.98,3.96s-2.72,4.53-3.85,6.11
		s-2.15,2.38-2.72,3.62s-1.24,2.15-1.47,2.6c-0.23,0.45-0.79,1.02-2.04,1.59c-1.25,0.57-2.15,1.58-3.28,2.04s-2.72,1.02-0.91-1.02
		c1.81-2.04,3.17-1.93,3.96-3.85c0.79-1.92,1.61-3.48,3.22-4.95c1.61-1.46,2.4-1.73,2.78-2.82c0.38-1.09,0.11-2.48-0.71-2.85
		c-0.83-0.38-2.74-1.61-3.15-1.95c-0.41-0.34-0.98,1.35-2.52,0.19s-1.01-1.69,0.04-1.73s1.16,0.38,2.22,0.56
		c1.05,0.19,1.8,0.3,2.52,0.86c0.71,0.56,1.65,1.65,2.44,1.58c0.79-0.07,1.09-0.38,1.43-1.31c0.34-0.94,1.99-0.94,2.22-2.29
		c0.23-1.35-0.56-2.73,1.94-4.53s2.3-4.81,4.71-4.81s2.21-0.7,3.91-0.9c1.7-0.2,1.6-1.4,2.4-1.5c0.8-0.1,1.4,0.2,1.5-1.4
		s0.2-2.6,0.8-3.21c0.6-0.6,0.3-2.9,2.4-3.81c2.1-0.9,1.2-1.9,2.7-1.9s1.9,0.2,2.6-0.3s0.8-0.7,3.21-0.7s4.05,0.38,5.63,0.07
		s0.53-1.13,3.6-1.13c3.08,0,3.91-1.05,5.33-0.68c1.43,0.38,1.5,0.45,2.55,0.53c1.05,0.07,1.88,0.22,2.25-0.38
		c0.38-0.6,1.2-0.83,2.4-0.83s1.73-0.07,3.53,0.07c1.8,0.14,3.38-0.15,5.56-0.07c2.18,0.07,2.48,0.83,3.98,0.07
		c1.5-0.75,1.8-1.28,3.23-1.28s2.25,0.3,2.85-0.45s1.28-0.83,2.18-0.9c0.9-0.07,1.95,0.22,2.55-0.9c0.6-1.13,0.83-1.8,1.65-2.03
		s0.9-0.75,1.58-1.58s1.28-2.1,2.18-2.1c0.9,0,1.05-0.98,1.05-2.1c0-1.13-0.08-1.58,0.68-2.33c0.75-0.75,0.08-2.63,1.5-2.63
		s1.2,0.75,2.55,0.15s0.75-1.5,2.25-1.8s1.13-1.35,3.23-1.35s2.55,0.15,3.53-0.9s1.8-1.35,2.78-2.55c0.98-1.2,1.8-1.28,2.1-2.78
		s0.75-1.8,1.35-2.03c0.6-0.22,0.75-0.3,0.98-0.98C797.503,116.874,796.003,116.574,796.373,115.514z"
          pointer-events="auto"
          
        ></path>

        <!-- <path
          id="PR"
          title="Porto-Rico"
          capital="San Juan"
          stroke-width="0.5px"
          d="M753.493,526.484c0,0-1.35-0.49-1.73,0.08
		c-0.38,0.56-0.49,1.54-0.98,1.39c-0.49-0.15-1.01-0.98-1.43-1.39c-0.42-0.41-0.94-0.26-1.24,0.38s-0.3,1.43-0.64,1.43
		s-0.9-0.23-0.9,0.64c0,0.86,1.16,0.11,1.05,1.5s-0.53,3.34,1.43,3.38s2.55-0.68,3.46-0.38c0.9,0.3,1.28,0.41,2.37,0.23
		c1.09-0.19,1.05-0.45,2.63-0.45s4.02,0.79,4.58,0.08c0.56-0.71,0.9-0.83,1.35-1.28c0.45-0.45,0.34-0.34,0.94-1.01
		c0.6-0.68,0.64-0.75,1.24-1.01s1.46-0.41,1.5-1.05s-0.75-0.34-1.13-0.71c-0.38-0.38-0.53-0.83-1.73-0.83c-1.2,0-2.1,0.56-2.85,0.15
		s-1.35-1.01-2.1-1.09s-1.58-0.15-2.29,0C756.313,526.674,754.733,526.674,753.493,526.484z"
          pointer-events="none"
          
        ></path> -->
      </g>
    </svg>
  </div>
</template>

<style scoped>
.svg-container {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
}

svg {
	width: 100vw;
	height: 80vh;
} 

@media screen and (max-width: 850px) {
svg {
	width: 100vw;
	height: inherit;
} 
}

</style>