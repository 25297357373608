<template>
  <router-view/>
</template>

<style>
#app {
font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

:root {
    --light-orange: #fffaf5;
    --brown: #331800;
    --dark-grey: #7a7a7a;
    --light-grey: #dfdfdf;
    --white: #ffff;
    --orange: #fd7f12;
    --yellow: #ffd800;
    --red: #c90a4c;
    --white-hover: #f7f7f7;
    --orange-instruction: #fd8012a4;
    --black: #0000;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Fredoka', cursive;
}
button {
  font-family: 'Fredoka One', cursive;
}

.swal2-confirm {
  padding: 0!important;
  box-shadow:none!important;
}
 
</style>
