<template>
  <div class="svg-container">
    <svg
      version="1.1"
      id="svgpoint"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="60 100 1000 600"
      xml:space="preserve"
    >
      <path
        id="VE"
        title="Venezuela"
        capital="Caracas"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M604.875,163.939
		c-0.484-1.211-1.332-0.969-3.028-2.785c-1.695-1.817-0.364-2.422,0.605-3.633s0.242-1.817,0.242-2.664
		c0-0.848,0.969-0.969,2.422-1.211s0.606-0.969,2.18-0.848c1.575,0.121,1.211-0.606,1.575-1.817s-0.848-1.332-2.422-2.543
		c-1.575-1.211,1.211-2.543,2.059-3.27s1.211-1.332,2.906-1.695c1.695-0.364,0.606-1.453,0.484-2.301
		c-0.06-0.419-0.149-1.401-0.224-2.286c-0.11-0.02-0.221-0.04-0.337-0.057c-2.431-0.348-4.686-1.214-6.422,0
		s-2.778,2.081-3.819,0.867s1.562-1.039,1.562-2.429c0-1.389,1.217-1.215,1.389-2.603c0.173-1.389,1.908-1.215,1.214-2.603
		c-0.693-1.389-2.083-2.083-3.471-2.083s-1.563-0.523-4.339-1.216c-2.777-0.693-4.339,1.389-4.859-0.174
		c-0.521-1.562,0.521-1.908-0.694-2.255c-1.216-0.348-2.602,0.175-2.43-1.562c0.173-1.736,0.521-1.389,2.603-1.389
		s3.644-0.521,3.644-1.562c0-1.041-2.604-0.519-5.554-0.173s-3.991,0.866-6.074,0.866s-4.167-0.348-5.901,1.562
		c-1.735,1.91-6.248,2.949-8.852,2.082c-2.603-0.867-3.47-1.041-4.859-2.603c-1.389-1.562-5.207-1.387-6.943-1.214
		c-1.736,0.173-0.869,2.431-4.86,1.562c-3.991-0.869-3.124-0.346-5.033-3.297s-3.124-3.994-6.074-4.34
		c-2.951-0.346-4.687,0.695-5.381-1.908s-1.042-3.472-2.256-3.472s-2.257,1.214-1.562,2.603c0.694,1.389,2.258,3.82-0.693,3.992
		c-2.951,0.174-5.9,2.081-6.769,2.429c-0.869,0.348-2.43,2.951-1.562,4.513c0.868,1.562,3.297,3.818,3.297,5.9
		c0,2.083-0.348,3.818-2.082,4.166c-1.735,0.348-2.949,0.175-3.297-0.867c-0.348-1.041-2.431-1.214-2.431-3.645
		s2.603-3.472,2.603-5.554s1.562-2.776,0.175-4.686c-1.388-1.91-2.603-4.511,0-5.38c0.779-0.26,1.463-0.442,2.041-0.616
		c-0.663-0.15-1.542-0.285-2.19-0.152c-1.18,0.244-1.952,0.854-2.44,1.831c-0.488,0.977-2.36,3.214-3.295,4.108
		c-0.935,0.895-3.051,2.888-3.295,3.824c-0.244,0.935-0.732,1.099-0.732,2.685s0.427,2.825-0.702,4.196
		c-1.13,1.373-1.776,2.341-2.341,3.39c-0.566,1.049,0.242,0.888,1.373,0.888s3.956-0.646,4.037,0.404
		c0.08,1.05-1.049,1.05-0.969,2.099s0.404,1.453,1.373,2.018c0.969,0.566,2.18,1.937,1.857,3.23
		c-0.323,1.292-1.211,0.969-1.13,2.826c0.08,1.857-0.08,2.826,1.211,3.552c1.291,0.727,1.857,1.937,2.26,2.584
		c0.404,0.646,1.373,0.566,3.229,0.566s4.763,0.807,5.167-0.081c0.403-0.888,1.857-1.534,2.906-0.727
		c1.049,0.808,1.211,0.808,2.341,0.969c1.13,0.162,1.937,0.162,2.987,1.777c1.049,1.615,2.664,3.633,3.552,4.44
		c0.888,0.808,1.615,0.888,2.019,0.162c0.403-0.727,1.857-0.808,2.906-0.324s4.844,0.162,5.812-0.566
		c0.969-0.727,1.211-0.808,2.341-0.727c1.13,0.08,2.261,0.727,2.261,1.695c0,0.969-0.727,1.777-0.969,2.583
		c-0.242,0.807,0.242,2.098-0.969,2.744s-1.292,0.97-1.05,2.341c0.242,1.373-0.08,2.098-0.161,3.471
		c-0.08,1.373-0.404,3.634,0.888,5.005c1.292,1.373,0.242,2.26,1.615,2.744c1.373,0.484,2.664,1.453,1.049,2.987
		s-3.956,2.664-1.937,3.875s2.503,1.05,2.826,2.987c0.241,1.448,0.436,2.129-0.322,2.549c0.789,0.46,1.648,1.093,1.775,1.729
		c0.242,1.211-0.727,5.207,0.727,5.449s2.18,0.484,3.028,1.575s0.484,1.332,2.18,1.575c1.695,0.242,3.028,0.606,3.39-0.484
		c0.364-1.09,0.727-1.937,1.817-1.211s1.09,1.695,2.422,1.09c1.332-0.606,0.606-2.059,2.301-2.18
		c1.695-0.122,1.09-1.695,3.028-1.695c1.937,0,3.39,0.242,3.633-1.575s0.242-2.664,2.059-3.27s3.148-0.484,3.39-1.575
		s0.969-1.817-1.211-2.059s-2.906,0.122-2.906-1.332s-0.848-2.301-1.575-2.664c-0.727-0.364,0.727-3.512-0.727-5.086
		c-1.453-1.575-2.785-2.301-2.785-3.39s0-1.332,1.695-1.09s3.39-0.122,3.754,1.09c0.364,1.211,1.09,0.364,2.543,0.969
		s2.664-0.606,3.39,0.484c0.727,1.09,0.606,1.695,1.453,1.817s2.18,0.848,2.059-0.727s0.242-2.664,1.332-2.664
		c1.09,0,0.605-0.848,2.543-0.727c1.937,0.122,2.059-1.211,3.996-1.211c1.937,0,1.937-0.364,3.512-1.09s1.937-0.122,2.422-1.332
		c0.484-1.211,1.937-0.848,2.18-2.301c0.086-0.516,0.036-0.983,0.059-1.37C605.442,166.019,605.291,164.98,604.875,163.939z"
        pointer-events="auto"
      ></path>

      <path
        id="GY"
        title="Guyana"
        capital="Georgetown"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M635.995,188.036
		c-0.363-1.453-0.848-1.695-1.695-2.785c-0.848-1.09-1.211-1.817-1.211-3.633s-1.453-2.301-2.059-3.754
		c-0.605-1.453-2.301-1.695-3.028-2.422c-0.727-0.727-0.848-4.843,0-6.539s2.785-1.695,4.601-1.937
		c1.817-0.242,0.484-2.906,0.484-3.875s0.242-1.695,1.453-2.906c0.35-0.35,0.464-0.864,0.453-1.416
		c-3.062-0.976-4.622-2.704-5.608-3.972c-1.216-1.562-2.602-3.124-3.991-3.124c-1.389,0-1.562-2.081-1.562-3.99
		s-4.513-4.34-6.943-5.902c-2.312-1.486-3.055-2.498-5.216-2.892c0.075,0.885,0.165,1.868,0.225,2.286
		c0.122,0.848,1.211,1.937-0.484,2.301s-2.059,0.969-2.906,1.695c-0.848,0.727-3.633,2.059-2.059,3.27
		c1.575,1.211,2.786,1.332,2.422,2.543c-0.363,1.211,0,1.937-1.575,1.817c-1.575-0.122-0.727,0.606-2.18,0.848
		s-2.422,0.364-2.422,1.211c0,0.848,0.727,1.453-0.242,2.664s-2.301,1.817-0.605,3.633c1.695,1.817,2.543,1.575,3.028,2.785
		c0.417,1.04,0.568,2.08,1.754,2.505c0.041-0.703,0.324-1.13,2.121-1.052c2.785,0.122,3.148,1.211,2.906,2.906
		s-2.059,2.422-0.121,2.906c1.937,0.484,1.937,1.09,2.059,1.937c0.121,0.848,2.664,2.18,1.09,3.148
		c-1.574,0.969-2.18,1.453-1.695,2.785c0.484,1.332-0.242,2.543-0.727,3.39c-0.484,0.848-0.364,3.148-0.122,4.239
		c0.242,1.09,0.848,1.332,0.969,2.664s1.09,2.18,2.422,3.512s3.148,3.028,3.633,3.39s2.543,1.332,3.512-0.364
		c0.969-1.695,2.422-2.906,2.785-2.18c0.363,0.727,1.575,0.606,2.422-0.484s2.18-1.575,3.875-1.817s2.422-0.363,3.754-0.363
		c0.719,0,2.635,0.281,4.247,0.294c-0.222-0.577-0.468-1.188-0.978-1.626C637.933,189.005,636.358,189.489,635.995,188.036z"
        pointer-events="auto"
      ></path>

      <path
        id="SR"
        title="Suriname"
        capital="Paramaribo"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M656.459,184.646
		c1.332-1.09-0.122-2.785-0.122-4.481c0-1.695-0.363-3.996-0.848-5.692c-0.485-1.695-0.727-2.422-1.453-3.39
		c-0.727-0.969,0.121-2.301,0.121-3.754s2.301-2.301,3.39-3.39c0.64-0.64,0.653-2.321,0.555-3.602
		c-1.098-0.495-2.153-0.91-3.03-1.027c-2.603-0.348-4.166-0.867-5.207-0.174c-1.041,0.693-1.041,0.693-3.124,0.174
		c-2.083-0.521-3.297,0.173-4.166,0.693c-0.869,0.521-0.521,0-5.207-0.693c-0.879-0.13-1.668-0.316-2.376-0.541
		c0.011,0.552-0.103,1.065-0.453,1.416c-1.211,1.211-1.453,1.937-1.453,2.906s1.332,3.633-0.484,3.875
		c-1.817,0.242-3.754,0.242-4.601,1.937c-0.848,1.695-0.727,5.812,0,6.539s2.422,0.969,3.028,2.422s2.059,1.937,2.059,3.754
		s0.363,2.543,1.211,3.633s1.332,1.332,1.695,2.785c0.363,1.453,1.937,0.969,2.785,1.695c0.51,0.437,0.756,1.049,0.978,1.626
		c1.378,0.011,2.534-0.174,2.534-0.899c0-1.575,0-3.754,1.817-3.754s3.028,0.242,3.633-0.364c0.606-0.606,3.27-1.211,3.754-0.122
		c0.384,0.865,1.078,2.411,2.858,2.894c0.087-0.316,0.197-0.661,0.29-1.078C654.885,186.946,655.127,185.735,656.459,184.646z"
        pointer-events="auto"
      ></path>

      <path
        id="CO"
        title="Colombie"
        capital="Bogota"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M472.766,198.813
		c2.059,0.242,3.148,0.484,3.512,1.937c0.364,1.453,1.817,2.543,2.785,2.543c0.969,0,1.332,1.211,3.148,1.211
		c1.817,0,2.664,0.122,2.785-0.848c0.122-0.97,1.937-1.575,2.785-0.364s1.211,1.695,2.422,2.059
		c1.211,0.363,1.211,1.211,3.148,1.211s2.906-1.09,4.239,0.606c1.332,1.695,3.996,2.543,3.996,4.239c0,1.695,0,2.906,1.575,2.906
		s3.27,1.09,3.754,2.422c0.484,1.331,1.453,3.633,2.906,3.754c1.453,0.122,0.848,1.09,1.211,2.664s0.848,1.453,2.543,1.695
		c1.695,0.242,6.781,0.484,6.781-0.606s0.484-1.817,1.817-1.575c1.332,0.242,1.332,0.848,1.817,1.575
		c0.484,0.727,1.575,0.242,1.575-0.242c0-0.484,1.09-0.484,2.422-0.484s2.059,0.848,3.27,1.211c1.211,0.363,3.27,1.211,2.543,2.664
		s-0.605,2.785-2.059,3.754c-1.453,0.969-3.27,3.27-1.937,4.359c1.332,1.09,2.785-0.727,3.27,0.364
		c0.418,0.941,0.928,1.701,2.384,2.593c0.212-0.667,0.159-1.624,0.159-2.472c0-1.453,0.888-2.987,0.888-4.843
		c0-1.857,1.13-5.409,1.13-6.296c0-0.888,0.242-2.341,0.242-3.552s0.242-2.503,0.808-3.633c0.566-1.129,0.565-1.615,0.484-2.987
		s-0.242-1.615-0.727-2.906c-0.484-1.292-2.745-3.148-3.714-3.956s-0.565-3.955-0.323-5.65s4.117-1.05,5.812-1.13
		c1.695-0.081,0.484-0.727,0.646-2.018s-2.664-0.969-4.198-1.211s-0.727-1.695-1.13-4.198c-0.403-2.503,5.49-1.211,8.396-1.13
		s3.23,0,3.23-1.05s1.373-0.727,1.937,0.162c0.565,0.888,2.18,0.727,2.422-0.08s0.484-1.05,2.502-1.453
		c2.018-0.404,1.776-0.969,1.453-2.906s-0.807-1.777-2.826-2.987c-2.019-1.211,0.324-2.341,1.937-3.875s0.323-2.503-1.049-2.987
		c-1.373-0.484-0.323-1.373-1.615-2.744c-1.292-1.373-0.969-3.633-0.888-5.005c0.081-1.373,0.403-2.099,0.161-3.471
		c-0.242-1.373-0.161-1.695,1.05-2.341s0.727-1.937,0.969-2.744s0.969-1.615,0.969-2.584s-1.13-1.615-2.261-1.695
		c-1.13-0.081-1.373,0-2.341,0.727s-4.763,1.05-5.812,0.566s-2.503-0.404-2.906,0.324c-0.403,0.727-1.13,0.646-2.019-0.162
		c-0.888-0.808-2.502-2.826-3.552-4.44c-1.049-1.615-1.857-1.615-2.987-1.777c-1.13-0.162-1.291-0.162-2.341-0.969
		c-1.049-0.808-2.503-0.162-2.906,0.727c-0.404,0.888-3.31,0.081-5.167,0.081s-2.826,0.08-3.229-0.566
		c-0.404-0.646-0.969-1.857-2.26-2.584s-1.13-1.695-1.211-3.552c-0.08-1.857,0.807-1.533,1.13-2.826
		c0.323-1.292-0.888-2.664-1.857-3.23s-1.292-0.969-1.373-2.018c-0.08-1.049,1.049-1.05,0.969-2.099s-2.906-0.404-4.037-0.404
		c-1.13,0-1.937,0.162-1.373-0.888s1.211-2.019,2.341-3.39c1.13-1.373,0.702-2.61,0.702-4.196s0.488-1.75,0.732-2.685
		s2.359-2.929,3.295-3.824c0.935-0.895,2.807-3.133,3.295-4.108c0.488-0.977,1.261-1.586,2.44-1.831
		c0.648-0.133,1.528,0.002,2.19,0.152c1.355-0.407,2.124-0.771,2.124-1.988c0-1.736-2.082-3.298-3.471-3.298
		s-4.167,2.433-5.034,3.82c-0.868,1.387-5.553,3.124-6.596,3.818c-1.041,0.694-0.347,1.91-3.471,1.562s-4.34-0.696-5.034,0.693
		c-0.693,1.389-0.694,3.472-3.124,1.91s-2.603-1.041-4.687,0.521c-2.082,1.562-3.819,3.818-3.124,5.9
		c0.694,2.082,1.214,4.513-1.041,5.034c-2.255,0.521-3.123,2.085-4.339,3.472s-2.951,2.601-2.951,3.99s-1.736,4.339-3.124,1.562
		c-0.642-1.284-1.286-2.382-2.083-3.364c-0.153,0.418-0.287,0.912-0.266,1.319c0.047,0.894-0.565,0.848-0.188,1.741
		s-0.094,1.364,0.706,1.552s0.847,1.082,0.847,1.506s0.188,1.458-0.518,2.07s-1.835,1.882-2.211,1.129s-1.365-1.552-1.412-0.893
		s0.094,2.587-0.141,3.058c-0.09,0.179-0.208,0.318-0.376,0.423c0.139,0.215,0.295,0.457,0.479,0.745
		c1.172,1.823,1.953,2.342,2.474,3.71c0.521,1.367,0.585,3.124,0.585,3.839s-0.13,1.237,0.39,1.628s0.716,1.107,0.13,1.758
		c-0.585,0.651-1.562,0.977-1.236,2.342c0.326,1.367,0.456,2.538,1.107,3.905s0.521,1.497,0.39,3.58s-1.757,2.213-0.846,3.515
		c0.911,1.302,2.213,1.496,1.367,2.929c-0.846,1.431-1.497,2.147-2.213,3.775s-1.823,3.515-3.319,4.231
		c-1.497,0.716-4.361,1.107-4.946,2.083c-0.585,0.977,0.781,2.408-1.106,3.385s-2.474,1.367-2.148,2.408
		c0.132,0.424,0.36,1.075,0.467,1.714c0.815,0.069,1.857,0.293,2.458,0.961C469.255,196.996,470.708,198.57,472.766,198.813z"
        pointer-events="auto"
      ></path>

      <path
        id="EC"
        title="Equateur"
        capital="Quito"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M455.572,232.597
		c0.848,0.484,1.453,3.512,0,3.875s-2.18,0.727-1.695,1.817c0.484,1.09,0.727,4.117,1.211,2.785
		c0.484-1.331,1.332-1.817,2.543-1.575s2.543,0.364,3.27,1.575s0.727,2.664,1.695,3.028c0.969,0.363,2.422,1.937,2.906,0.242
		c0.484-1.695,0.242-2.059,1.332-2.301c1.09-0.242,1.211-0.364,1.575-1.817s-0.364-2.664,0.727-4.481
		c1.09-1.817,3.028-6.539,6.176-7.265s5.449-1.211,7.507-2.059c2.059-0.848,8.84-6.054,9.324-8.718s0.605-3.633,1.211-4.117
		c0.606-0.484,1.09-0.484,1.09-1.817c0-1.332,1.211-0.727-0.727-2.18c-1.386-1.039-2.027-2.266-2.28-3.544
		c-0.361-0.246-0.663-0.524-1.232-0.694c-1.211-0.364-1.575-0.848-2.422-2.059c-0.848-1.211-2.664-0.606-2.785,0.364
		c-0.122,0.97-0.969,0.848-2.785,0.848c-1.817,0-2.18-1.211-3.148-1.211c-0.969,0-2.422-1.09-2.785-2.543
		c-0.364-1.453-1.453-1.695-3.512-1.937s-3.512-1.817-4.601-3.028c-0.601-0.668-1.643-0.893-2.458-0.961
		c0.156,0.931,0.054,1.841-0.987,1.995c-1.757,0.261-2.603,0.326-3.189,0.977c-0.585,0.651-0.846,0.586-2.343,0.846
		c-1.497,0.26-2.474,0.781-2.474,2.408c0,1.628,1.562,4.036-0.13,4.882c-1.692,0.846-2.278,0.912-2.278,2.082
		c0,1.172-0.065,2.929-1.236,3.58c-1.172,0.651-3.385,0.846-3.124,2.799c0.261,1.952-0.065,3.905,0.977,4.556
		c1.041,0.651,1.562,1.823,0.521,2.343c-1.041,0.521-1.692,1.562-0.326,2.213s1.692,0.977,2.343,1.887
		c0.651,0.911,1.628,2.018,2.538,1.171c0.911-0.846,1.497-1.302,1.692-2.408c0.195-1.106,0.911-1.302,1.041-0.326
		c0.13,0.977,2.148,2.018,0.521,2.734s-2.343,1.172-1.562,1.692c0.781,0.521,1.823,1.692-0.13,2.278
		c-0.911,0.274-1.963,0.534-2.959,0.925C454.852,231.907,455.186,232.376,455.572,232.597z"
        pointer-events="auto"
      ></path>

      <path
        id="UY"
        title="Uruguay"
        capital="Montevideo"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M661.666,469.69
		c-0.242-1.817-0.242-4.723-0.848-6.539c-0.606-1.817-2.18-1.575-3.633-2.18c-1.453-0.606-1.817-1.09-2.543-2.785
		c-0.727-1.695-1.332-1.937-3.633-2.059c-2.3-0.122-2.906-1.09-3.996-2.906c-1.09-1.817-0.969-0.848-2.18,0.484
		s-2.059-0.969-2.422-3.028c-0.363-2.059-2.18-1.211-2.543-2.664c-0.363-1.453-0.969-1.453-3.028-2.18s-0.727,0-3.27,1.09
		c-1.276,0.547-2.337,0.301-3.079-0.079c-1.187,0.934-2.072,2.141-1.885,3.349c0.242,1.575,1.211,2.422,0.121,4.117
		s-0.242,4.601-1.332,5.812c-1.09,1.211-1.695,2.059-1.211,4.238c0.484,2.18,1.575,3.754,0.606,5.207s-0.848,2.543-0.727,3.996
		c0.121,1.453-0.242,2.906,0.727,3.996c0.913,1.027,2.576,3.02,2.665,5.373c1.543,0.41,2.638,0.416,4.094,0.416
		c1.736,0,2.256,0.173,3.819,1.041c1.562,0.869,2.777,1.387,5.553,1.563c2.778,0.175,3.125-0.348,5.381,0
		c2.255,0.348,3.124,1.216,6.248-0.346c3.124-1.562,6.074-4.861,8.678-7.464c0.094-0.094,0.196-0.19,0.295-0.285
		c-0.553-1.115-0.888-2.322-1.376-3.688C661.545,472.475,661.908,471.507,661.666,469.69z"
        pointer-events="auto"
      ></path>

      <path
        id="BO"
        title="Bolivie"
        capital="Sucre"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M593.977,374.837
		c0.646-0.727,0.646-0.97,0.727-3.068c0.081-2.099-0.161-5.005,1.373-6.296c1.533-1.291,1.453-4.521,3.229-5.328
		c1.777-0.807,1.534-1.291,3.714-1.373c2.18-0.08,5.005-0.323,5.974-1.05s1.777-1.373,4.279-1.13c2.502,0.242,6.539,0,7.427,0.888
		s3.39,1.777,4.601,3.23c0.863,1.035,1.807,1.087,2.834,1.119c-0.092-0.428-0.492-0.741-0.675-1.361
		c-0.303-1.029,0.121-2.483,0.969-3.451c0.848-0.969,1.029-1.332,0.969-2.24s0.424-2.603,0.424-3.814s1.272-1.514,1.453-2.543
		s-0.545-1.998-1.211-2.483c-0.666-0.484-0.424-0.848-0.424-2.119s-1.634-1.756-2.725-2.119c-1.09-0.364-1.998-0.666-2.18-1.15
		c-0.181-0.484-0.848-1.756-0.908-2.603c-0.061-0.848-0.181-1.575,0.545-2.301c0.727-0.727,0.122-2.785,0.122-2.785l-14.168,0.061
		c0,0,0.061-4.117,0.061-5.994c0.424-1.634-0.787-1.272-1.634-2.24c-0.848-0.969-0.424-1.453,0.605-2.059s0.666-1.695,0.181-2.543
		s0.364-1.211-0.484-2.119s-0.303-1.453-0.181-2.725c0.122-1.272-0.848-2.18-1.817-3.209c-0.969-1.029-3.148-0.666-5.025-0.666
		s-4.359-0.242-4.904-1.514s-3.27-2.24-4.723-2.361c-1.453-0.122-2.18-0.545-2.906-1.937c-0.727-1.392-2.845-0.787-3.875-1.937
		c-1.029-1.15-3.633-0.424-5.207-0.545c-1.575-0.122-2.664-0.303-3.028-1.211c-0.363-0.908-1.332-1.634-2.361-2.18
		c-1.029-0.545-1.877-1.756-2.301-2.845c-0.424-1.09-1.029-1.695-1.817-2.603c-0.787-0.908-0.484-1.937-0.363-3.028
		c0.121-1.09-0.061-1.695-0.545-2.785s0.242-1.09,0.181-1.695c-0.061-0.606,0.181-1.332,0.908-2.059s0.181-1.877-0.122-2.785
		c-0.303-0.908-1.15-1.332-1.695-0.727c-0.545,0.606-2.422,0.424-3.451,0.424s-2.543,0.122-3.148,0.848
		c-0.605,0.727-2.785,0.787-3.33,1.575c-0.545,0.787-1.211,1.392-2.603,1.695s-2.361,0.908-3.028,1.998s-2.119,0.787-3.39,1.15
		c-1.272,0.364-2.361,1.332-2.725,2.543c-0.363,1.211-2.483,1.029-2.967,0.242s0.181,0.061-3.148-0.545
		c-1.321-0.24-2.161-0.241-2.699-0.156c0.403,0.256,0.732,0.768,0.862,1.811c0.323,2.583,0.888,2.583,1.937,3.713
		s1.211,1.938,1.937,2.826c0.727,0.888,2.584,2.664,1.373,4.359s-1.615,1.615-1.695,3.39s-0.162,2.664-0.727,3.471
		c-0.565,0.808-0.323,2.987,0.324,3.794s1.533,1.211,0.646,2.664s-1.937,2.098-2.744,3.229c-0.807,1.13-1.695,2.18-0.807,2.745
		c0.887,0.566,1.291,2.181,0.403,3.23s-0.888,2.583-0.323,3.713s0.727,1.937,1.937,2.745c1.211,0.808,0.969,3.472-0.323,4.359
		c-1.292,0.888-3.633,2.906-3.39,3.875c0.242,0.969-0.242,2.18,0.888,2.906c1.13,0.727,1.211,2.907,2.261,3.23
		c1.049,0.323,1.373,1.857,1.13,3.552c-0.242,1.695,0.162,3.956,1.777,4.925c1.614,0.969,2.744,1.615,2.341,2.906
		c-0.403,1.291-1.373,2.098-0.807,2.986c0.565,0.888,0.242,1.938-0.404,2.341s-1.049,1.857-0.08,3.068s0.727,3.875,1.937,4.521
		s1.615,2.099,1.937,4.037c0.323,1.937,1.534,3.39,2.018,5.893s-0.242,4.439,1.695,4.843c1.349,0.281,3.244-0.53,4.19-0.288
		c0.712-1.279,1.064-1.578,2.027-3.022c1.211-1.817,2.906-1.817,4.239-4.359c1.332-2.542,2.301-0.484,3.875,0.484
		c1.575,0.969,3.996,0.848,5.934,0.605c1.937-0.242,3.028,0.606,3.512,1.453c0.484,0.848,1.575,2.301,2.059,5.086
		s1.453-1.817,1.453-2.906c0-1.089,1.332-3.148,2.422-3.996s4.601-0.484,7.387-0.242c0.919,0.08,1.531,0.4,1.991,0.832
		c0.195-0.666,0.427-1.501,0.551-2.125C592.766,376.37,593.331,375.563,593.977,374.837z"
        pointer-events="auto"
      ></path>

      <path
        id="PE"
        title="Pérou"
        capital="Lima"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M535.941,348.541
		c0.648-0.948,0.948-1.547,0.798-2.743c-0.15-1.197,0.199-1.945,0.748-2.294c0.296-0.188,0.624-0.318,0.985-0.437
		c-0.307-0.68-0.102-1.511-0.277-2.215c-0.242-0.969,2.099-2.987,3.39-3.875c1.292-0.888,1.534-3.552,0.323-4.359
		c-1.211-0.808-1.373-1.615-1.937-2.745c-0.565-1.13-0.565-2.664,0.323-3.713s0.484-2.664-0.403-3.23
		c-0.888-0.566,0-1.615,0.807-2.745s1.857-1.776,2.744-3.229s0-1.857-0.646-2.664s-0.888-2.987-0.324-3.794
		c0.565-0.808,0.646-1.695,0.727-3.471c0.08-1.776,0.484-1.695,1.695-3.39s-0.646-3.472-1.373-4.359
		c-0.727-0.888-0.888-1.695-1.937-2.826s-1.615-1.13-1.937-3.713c-0.323-2.583-1.857-1.937-2.745-2.099s-2.341-0.323-2.986,0.727
		c-0.646,1.049-1.937,0.242-2.906-0.323s-1.291-1.937-1.291-3.472c0-1.534,0.646-4.117,0.565-5.247
		c-0.08-1.13,1.049-2.019,0.969-3.23s-2.744,1.13-3.471,2.18s-1.534,1.05-3.633,2.019c-2.098,0.969-3.552,0.404-5.167,0.404
		s-1.211-1.937-1.533-3.31s-2.826-1.615-4.279-1.777c-1.453-0.162-1.776-1.13-1.533-2.261s-0.323-1.291-1.13-1.857
		c-0.808-0.566-0.807-1.534-1.857-2.664c-1.049-1.13-1.777-3.068-2.583-4.198s-0.484-1.13-1.857-2.26
		c-1.373-1.129,0.323-1.937,1.453-2.503c1.13-0.566,0.162-1.292-0.162-2.261c-0.324-0.969,1.13-1.937,1.937-2.906
		c0.808-0.969,1.695-0.807,2.744-1.291c1.049-0.484,0.565-1.534-0.162-2.583s0.323-1.776,1.13-2.744
		c0.808-0.969,1.211-3.068,0.888-3.956s0.727-1.534,1.776-2.019c1.049-0.484,3.229-1.776,4.843-3.471
		c1.615-1.695,3.39-1.13,5.408-1.211c2.018-0.08,3.068-0.888,4.117-1.777c1.049-0.888,1.695-0.646,2.664-0.242
		c0.969,0.404,2.341,0.969,3.633,0.888c0.537-0.033,0.821-0.363,0.972-0.837c-1.456-0.891-1.966-1.652-2.384-2.593
		c-0.484-1.09-1.937,0.727-3.27-0.364c-1.332-1.09,0.484-3.39,1.937-4.359s1.332-2.301,2.059-3.754
		c0.727-1.453-1.332-2.301-2.543-2.664c-1.211-0.364-1.937-1.211-3.27-1.211s-2.422,0-2.422,0.484s-1.09,0.969-1.575,0.242
		c-0.484-0.727-0.484-1.332-1.817-1.575c-1.332-0.242-1.817,0.484-1.817,1.575s-5.086,0.848-6.781,0.606s-2.18-0.122-2.543-1.695
		c-0.364-1.574,0.242-2.543-1.211-2.664c-1.453-0.122-2.422-2.422-2.906-3.754s-2.18-2.422-3.754-2.422s-1.575-1.211-1.575-2.906
		s-2.664-2.543-3.996-4.239c-1.332-1.695-2.301-0.606-4.239-0.606c-1.028,0-1.509-0.239-1.917-0.517
		c0.253,1.279,0.893,2.504,2.28,3.544c1.937,1.453,0.727,0.848,0.727,2.18c0,1.331-0.484,1.332-1.09,1.817
		c-0.606,0.484-0.727,1.453-1.211,4.117s-7.265,7.871-9.324,8.718c-2.059,0.848-4.359,1.332-7.507,2.059
		c-3.148,0.727-5.086,5.449-6.176,7.265c-1.09,1.817-0.364,3.028-0.727,4.481c-0.364,1.453-0.484,1.575-1.575,1.817
		c-1.09,0.242-0.848,0.606-1.332,2.301c-0.484,1.695-1.937,0.122-2.906-0.242s-0.969-1.817-1.695-3.028
		c-0.727-1.211-2.059-1.332-3.27-1.575c-1.211-0.242-2.059,0.242-2.543,1.575s-0.727-1.695-1.211-2.785s0.242-1.453,1.695-1.817
		s0.848-3.39,0-3.875c-0.385-0.221-0.72-0.69-0.971-1.137c-1.138,0.446-2.204,1.063-2.898,2.07c-1.302,1.887-1.302,2.798-2.864,3.97
		s-1.692,2.603-1.367,4.231c0.326,1.628,0.456,1.823,1.236,3.645c0.781,1.823,1.628,1.757,1.692,2.993
		c0.065,1.237,0.13,1.953-0.977,2.538c-1.107,0.585-1.823,1.692,0.586,2.474c2.408,0.781,2.994,0.977,4.686,2.342
		c1.692,1.367,4.751,4.426,5.988,6.834c1.236,2.408,2.733,3.84,4.1,5.597s1.367,1.823,2.603,5.662s3.385,5.142,4.1,8.005
		c0.716,2.864,1.887,5.011,3.449,6.898s2.213,2.864,2.278,4.687c0.065,1.822-1.041,3.515,1.172,5.011
		c2.213,1.497,2.538,1.236,4.556,3.839s1.823,3.58,2.929,4.751c1.106,1.172,2.669,2.603,1.757,3.71
		c-0.911,1.106-1.952,1.302-1.562,2.929c0.391,1.628,1.302,3.254,3.124,4.491c1.823,1.236,3.905,3.059,4.49,4.686
		c0.586,1.628,1.823,2.864,3.059,3.449s2.734,0.977,4.556,2.473c1.823,1.497,3.905,2.929,6.639,3.84s4.816,1.952,6.834,3.059
		c2.018,1.106,3.385,1.757,5.142,2.864c1.757,1.107,3.775,1.497,4.296,3.449s1.106,2.213,2.798,3.059
		c1.6,0.8,3.258,1.08,4.425,3.861c0.468,0.017,1.021,0.092,1.518,0.308C534.095,349.688,535.293,349.489,535.941,348.541z"
        pointer-events="auto"
      ></path>

      <path
        id="AR"
        title="Argentine"
        capital="Buenos-Aires"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M626.792,477.561
		c-0.969-1.09-0.606-2.543-0.727-3.996c-0.122-1.453-0.242-2.543,0.727-3.996s-0.122-3.028-0.606-5.207
		c-0.484-2.18,0.122-3.028,1.211-4.239c1.09-1.211,0.242-4.117,1.332-5.812c1.09-1.695,0.122-2.543-0.121-4.117
		c-0.242-1.574,1.332-3.148,3.028-4.117c1.695-0.969,1.211-2.422,3.148-3.27s1.817-2.059,3.148-4.117
		c1.332-2.059,2.785-2.18,3.996-3.996c1.211-1.817,1.937-2.18,3.512-4.481s3.028-2.422,4.723-3.754s3.148-3.27,7.629-4.239
		c4.481-0.969,2.664-2.785,2.543-4.481c-0.122-1.695,0.121-2.301,0.969-3.27s0.242-1.937-0.242-2.543s-0.969-1.695-0.969-2.785
		s-1.332-1.453-3.27-1.453c-1,0-2.095-0.065-2.923-0.126c-0.061,0.756-0.105,1.555-0.105,2.79c0,2.059,0.727,5.207-1.817,7.023
		c-2.543,1.817-5.328,1.817-5.449,3.633c-0.121,1.817-1.211,2.422-2.18,2.059c-0.969-0.364-2.059-1.332-3.39-0.122
		c-1.332,1.211-1.575,1.332-3.754,1.09c-2.18-0.242-3.875-0.122-5.57-0.606c-1.695-0.484-2.543-0.848-5.328-0.848
		s-5.086,0-2.422-2.422s2.543-3.028,2.664-5.692c0.121-2.664,0.363-2.18,1.695-3.148c1.332-0.969,1.211-0.969,1.817-2.664
		c0.605-1.695,0.727-2.18-1.09-3.875s-6.054-1.817-8.598-3.512c-2.543-1.695-6.297-6.176-9.203-6.054s-5.812-0.242-6.66-1.937
		c-0.848-1.695-1.937-2.543-3.512-3.148s-2.543-1.453-3.754-3.27s-1.817-2.906-3.148-4.117c-1.332-1.211-1.332-3.633-4.117-3.875
		c-2.784-0.242-6.297-0.606-7.387,0.242s-2.422,2.906-2.422,3.996s-0.969,5.692-1.453,2.906c-0.484-2.785-1.575-4.239-2.059-5.086
		c-0.484-0.848-1.575-1.695-3.512-1.453s-4.359,0.364-5.934-0.605c-1.575-0.969-2.543-3.028-3.875-0.484s-3.028,2.543-4.239,4.359
		c-0.963,1.444-1.315,1.743-2.027,3.022c0.413,0.106,0.647,0.409,0.574,1.095c-0.242,2.261-0.08,3.148-0.565,3.875
		c-0.484,0.727-0.646,1.534-0.646,2.503s-0.242,1.856-1.615,2.26s-3.633,1.292-4.763,2.341s-2.906,1.776-3.23,2.26
		c-0.323,0.484-1.291,1.211-0.161,2.503s0.646,2.261-0.081,2.987c-0.727,0.727-0.646,2.422-0.08,3.552
		c0.565,1.13,0.969,2.341,0.969,2.987s-1.453,0.969-1.453,2.422s0.484,1.857,1.291,2.422c0.808,0.565,1.615,1.373,0.969,2.422
		s-1.292,1.534-2.422,1.453s-2.422,1.211-2.502,2.26c-0.08,1.05-0.242,2.18-1.13,3.472s-1.695,2.26-2.744,3.068
		c-1.049,0.808-2.018,1.615-1.937,3.148c0.08,1.534,0.484,2.826-0.727,3.956s-1.937,1.695-1.695,3.068s1.453,1.453,1.049,2.906
		s-0.323,2.261,0.08,2.826c0.404,0.565,0.404,2.26-0.727,2.583c-1.13,0.323-1.777,0.323-2.099,2.019
		c-0.323,1.695-0.242,2.986-0.969,3.713c-0.727,0.727-1.615,1.937-1.695,2.987s-0.646,1.615,0.323,3.23s1.373,1.05,1.453,1.937
		c0.081,0.888,0.484,1.937,0.646,2.826c0.162,0.888-0.081,2.341,1.049,3.23s1.13,1.211,0.484,2.26
		c-0.646,1.05-0.807,2.26,0.566,2.906s2.422,0.323,1.695,1.937c-0.727,1.615,0,2.099-0.484,3.875
		c-0.484,1.777-1.13,2.099-1.857,3.472s-1.373,2.986-1.776,4.037c-0.404,1.05-1.373,2.18-1.373,3.39
		c0,1.211-0.404,2.745,0.484,3.068s0.727,1.695,0.727,2.664s-0.484,2.099-1.453,2.664s-1.13,1.776-2.583,2.26
		s-1.937,2.018-2.018,2.906c-0.08,0.888-0.404,1.696,0,2.584s-0.727,5.893,0.323,7.427s0.888,2.665,0.727,3.23
		c-0.162,0.565,1.937,2.098,0.888,3.471s-1.695,0.565-2.906,1.453s-1.776,2.744-1.291,4.117c0.484,1.373,0.08,2.503-1.05,3.068
		s-0.969,1.534-0.969,2.906s0.08,4.359-0.646,5.408c-0.727,1.05-0.808,2.664-0.808,3.23s0.162,1.777,0.08,3.39
		c-0.081,1.614-0.242,4.601,0.242,5.328s0.727,0.808,0.727,1.695s0.646,1.211-0.324,1.695c-0.969,0.484-2.26,0.969-2.26,1.695
		s-0.484,6.297,0.646,6.862s1.373,1.211,1.049,2.502c-0.323,1.292,1.211,1.938,0.888,3.31c-0.324,1.373,1.13,2.18,0.242,3.552
		c-0.888,1.373-0.969,3.068,0.565,3.23c1.533,0.161,4.037-0.081,3.955,1.291c-0.08,1.373-1.695,2.341-3.068,2.341
		s-3.714-1.13-3.714-0.404c0,0.727-0.08,1.453,1.05,1.695s2.341,0.324,2.986,1.615c0.646,1.291,2.744,1.776,1.049,2.986
		c-1.695,1.211-2.986,1.534-3.068,2.664s2.099,2.341,0.727,3.148c-1.373,0.808-2.664,1.615-0.808,2.745s0.727,1.695,0.646,3.148
		c-0.08,1.453-1.857,0.484-1.857,2.422s1.13,3.713-0.484,4.521c-1.615,0.808-2.261,0.566-2.422,1.857
		c-0.162,1.291-1.13,1.614-1.291,2.987s0.484,0.808,0.969,2.019c0.484,1.211,0.969,1.937,0.484,3.068
		c-0.484,1.13-2.341,0.97-2.18,2.341c0.162,1.373,0.081,2.664-1.291,3.713c-1.373,1.049-1.857,1.453-2.664,2.422
		c-0.808,0.969-2.745,0.404-3.068,1.777s-0.484,7.103,0.08,7.911c0.566,0.807,1.857,0.807,1.615,2.261
		c-0.242,1.453,0.242,2.826,1.13,3.23c0.888,0.403,1.695,0.646,2.18,0s0.727-1.049,2.18-0.727s2.18,0.646,2.18,1.13
		s1.533,1.13,0.646,2.099c-0.888,0.969-0.888,1.373-0.888,2.745s-1.13,3.391,0,4.44s2.584,1.129,2.986,2.502
		c0.404,1.373,2.18,1.373,3.39,1.373c1.211,0,4.843,0,7.75,0c2.906,0,6.054-0.081,7.023,0.565s3.148-0.162,4.359,0.484
		s2.341,0.727,3.552,1.534c0.708,0.472,1.272,1.049,1.623,1.452c0.6-0.563,0.511-2.336-0.573-3.528
		c-1.302-1.431-4.751-7.941-4.882-9.762c-0.13-1.823-0.456-5.662,0.977-6.898c1.431-1.237,2.864-1.887,3.839-2.799
		c0.977-0.912,5.597,0.456,5.857-3.32c0.261-3.775,1.172-9.177,3.385-10.349s4.556-3.71,6.769-5.077
		c2.213-1.367,4.816-2.734,4.686-3.775s-1.236-0.326-0.716-1.953c0.521-1.628,1.302-1.887,0.977-3.58
		c-0.326-1.692,0.521-2.993-1.887-3.125c-2.408-0.13-5.142,0.195-6.444-1.431c-1.302-1.628-1.367-2.408-2.929-3.189
		s-2.343-0.846-2.603-3.059c-0.261-2.213,0.195-4.556,1.562-5.597s2.474-4.231,4.816-4.946c2.343-0.716,3.319-2.668,4.946-2.408
		c1.628,0.261,3.385,1.106,3.515-0.716c0.13-1.823,0.13-2.734,1.367-3.515c1.236-0.781,1.757-2.083,1.757-3.97
		s-2.083-3.971,0.066-5.143c2.147-1.172,0.911-3.189,2.538-3.515c1.628-0.326,3.385-0.066,3.515-1.041
		c0.13-0.977-0.195-1.041-1.367-1.628c-1.172-0.585-2.408-1.041-2.213-1.823c0.195-0.781,1.041-0.39,1.432-1.302
		c0.39-0.911,2.343-0.13,2.734,0.586c0.39,0.716,0.456,2.343,2.669,2.148s2.994-0.13,3.059-1.628s0.13-2.603-0.261-3.515
		c-0.39-0.911-0.261-1.887-1.823-1.953c-1.562-0.065-2.278,0.651-2.278,1.563c0,0.911-0.456,2.213-1.628,1.041
		s-0.977-1.823-2.018-1.823s-3.124-0.26-3.254-2.018s1.432-3.515,0.781-4.361s-1.367-0.912-1.236-2.864
		c0.13-1.953-0.391-2.929,0.977-3.189c1.367-0.261,3.254-0.261,3.905,0.585s0.846,0.78,1.823,0.977s1.823,0.456,2.213,1.041
		s1.757,0.585,3.449,0.781c1.692,0.195,2.408-0.846,3.905-0.846s6.313-2.018,6.052-3.905s-1.887-2.538-1.887-3.644
		c0-1.107,0.911-2.083,1.692-2.994s1.106-2.929,1.172-3.775s1.172-1.172-0.39-2.018s-1.823-0.977-1.367-2.278
		c0.456-1.302,1.757-2.538,1.952-0.912c0.195,1.628,1.302,1.823,1.367,0.912c0.065-0.912,1.172-1.107,1.823-0.456
		s2.603,0.651,4.036,0.326c1.432-0.326,3.472-1.647,7.463-1.647s5.9-1.734,8.678-2.082c2.778-0.348,10.933-3.818,11.802-6.769
		s3.472-4.168,4.86-6.596c1.388-2.429,2.257-4.337,1.562-6.073c-0.694-1.736-3.991-0.867-4.687-3.644
		c-0.694-2.779-1.214-1.737,0.174-3.473c1.388-1.734,0.868-3.299-0.694-4.513s-3.298-3.295-5.207-3.47s-3.818,0.519-3.471-1.216
		c0.347-1.735,1.562-1.908,3.644-1.215c0.335,0.112,0.642,0.2,0.938,0.278C629.367,480.581,627.705,478.588,626.792,477.561z"
        pointer-events="auto"
      ></path>

      <path
        id="CL"
        title="Chili"
        capital="Santiago"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M552.887,386.057
		c-1.937-0.404-1.211-2.341-1.695-4.843c-0.484-2.503-1.695-3.955-2.018-5.893c-0.323-1.937-0.727-3.39-1.937-4.037
		c-1.211-0.646-0.969-3.31-1.937-4.521c-0.969-1.211-0.565-2.664,0.08-3.068c0.646-0.404,0.969-1.453,0.404-2.341
		s0.404-1.695,0.807-2.986c0.404-1.291-0.727-1.937-2.341-2.906c-1.615-0.969-2.019-3.23-1.777-4.925
		c0.242-1.695-0.08-3.23-1.13-3.552c-1.049-0.323-1.13-2.503-2.261-3.23c-0.31-0.199-0.495-0.434-0.611-0.691
		c-0.362,0.12-0.689,0.249-0.985,0.437c-0.549,0.349-0.898,1.097-0.748,2.294c0.15,1.197-0.15,1.795-0.798,2.743
		s-1.845,1.147-2.992,0.648c-0.497-0.216-1.05-0.291-1.518-0.308c0.067,0.16,0.133,0.324,0.196,0.5
		c1.172,3.254,0.651,5.727,1.887,7.55s2.082,5.662,1.562,6.899c-0.521,1.236-1.692,0.911-1.692,2.799
		c0,1.887,0.651,2.018,0.977,4.426c0.326,2.409,0,4.817-0.586,6.508c-0.586,1.692-0.456,3.189-0.456,4.751s-0.066,2.994-1.172,3.775
		s-2.603,2.082-1.431,3.32c1.172,1.236,2.213,1.822,1.431,2.864c-0.78,1.041-1.302,2.082-1.106,3.645
		c0.195,1.562,0,1.367,0.065,3.254s-0.065,4.165,0.521,4.686s-0.456,1.172-1.562,2.213c-1.107,1.041,0.195,2.148,0.456,4.166
		c0.261,2.018,0.195,3.189-0.781,5.467c-0.977,2.278-1.497,2.799-1.628,5.011c-0.13,2.213,0.195,2.603-0.781,3.971
		c-0.977,1.367-1.106,1.367-1.172,3.319s1.106,2.734-0.13,4.036s-2.278,2.213-2.213,3.514c0.066,1.302-0.195,2.408,0.911,3.515
		c1.107,1.107,1.041,2.278,0.585,3.645c-0.456,1.367-2.018,2.669-2.799,3.905s-1.236,2.408-0.716,4.946
		c0.521,2.538,0.39,3.514,1.107,4.295c0.716,0.781,0.651,0.651,0.585,2.474c-0.065,1.823-0.26,2.538,0.456,3.775
		c0.716,1.236,0.78,1.628,0.456,3.385c-0.326,1.757-0.651,2.408-1.497,2.994c-0.846,0.585-0.911,1.628-0.781,3.905
		c0.13,2.278,0,3.97-0.911,5.011s-0.195,1.041-1.041,3.385c-0.846,2.343-1.106,3.059-1.497,5.011
		c-0.39,1.952-1.302,0.912-1.953,3.645c-0.651,2.734-1.692,2.799-2.148,5.077c-0.456,2.278-0.651,4.621-2.018,6.247
		c-1.367,1.628-1.302,3.124-1.302,3.905s-0.911,1.757-1.692,1.692c-0.781-0.066-2.474,0.065-2.603,1.367
		c-0.13,1.302,0.781,5.012,1.692,6.314c0.911,1.302,0.977,1.171,0.911,3.254c-0.066,2.082,0,4.686,0.456,6.118
		c0.456,1.431,0.195,4.556-0.716,5.337c-0.911,0.781-2.538,2.278-2.538,3.905s0.13,4.036-0.781,5.272
		c-0.911,1.236-1.562,2.993-0.911,4.295s0.977,2.148,1.628,3.385c0.651,1.236,2.213,2.929,0.456,2.993
		c-1.757,0.066-3.254-0.846-3.385,1.041s1.562,2.668,0.326,3.319c-1.236,0.651-1.367,1.692-1.041,2.799
		c0.326,1.106,0.326,2.408-0.066,3.32c-0.39,0.911-1.172,2.342,0,3.319s4.231,1.563,4.296,0.521
		c0.065-1.041,0.326-2.148,1.106-2.603c0.781-0.456,1.431-1.497,0.586-2.343c-0.846-0.846-2.408-1.562-1.692-2.343
		s1.757-1.041,1.562-2.343c-0.195-1.302,1.172-0.846,1.041-2.082s-1.432-1.628,0.26-2.342c1.692-0.716,2.018-1.692,2.148-2.474
		c0.13-0.78,1.497-0.066,2.148-0.066s1.367,0.977,0.846,1.562s-1.628,1.431-0.651,2.213c0.977,0.781,3.124,1.431,1.302,2.018
		c-1.823,0.585-2.473-0.261-2.018,2.147c0.456,2.409,1.041,3.515-0.195,5.272c-1.236,1.757-2.798,3.905-2.082,4.751
		c0.716,0.846,1.367,2.018,0.651,3.385s-2.082,3.71-0.261,3.971c1.823,0.26,0.39,1.562-0.521,1.822
		c-0.911,0.261-1.497,1.107-1.172,2.603c0.326,1.497,0.195,3.189,0.195,4.231s0.716,2.278-0.846,2.734s-2.474,0.065-2.018,1.367
		s1.692,1.106,0.911,2.734c-0.781,1.628-0.977,3.71-1.497,2.669s-0.846-2.408-1.562-2.603s-2.343,0.195-2.343-1.497
		s0.13-2.408-0.977-2.864c-1.106-0.456-0.586-1.237-1.431,0.911c-0.846,2.148-0.977,2.734-2.408,3.189
		c-1.431,0.456-2.538,1.302-1.628,1.887s0.716,1.172-0.716,1.692c-1.431,0.521-1.692,1.106-2.734,2.278
		c-1.041,1.172-2.733,2.083-1.562,3.385c1.172,1.302,2.278,1.497,2.473,0.065c0.195-1.431,1.823-1.431,2.734-0.456
		c0.911,0.977,1.172,1.432,2.603,0.977c1.431-0.456,2.864-1.236,3.254-0.39c0.39,0.846,0.716,2.993-0.456,3.644
		s-0.846,2.734-1.302,3.645s0.066,1.953,1.432,2.213c1.367,0.261,2.733,0.781,3.515-0.065c0.781-0.846,2.343-0.39,2.603,0.521
		c0.26,0.912,1.757,2.864,0.39,2.994c-1.367,0.13-3.449-0.716-3.84-1.367c-0.39-0.651-1.823-1.302-2.864-0.585
		c-1.041,0.717-1.887,0.585-1.367,1.757c0.521,1.172,1.953,1.563,2.474,1.952c0.521,0.391,1.431,1.041-0.261,1.692
		s-3.905,0.26-3.905-0.781s-0.781-0.781-0.781-2.083s2.082-2.734,0.261-2.473c-1.823,0.26-2.082,1.692-2.994,0.78
		c-0.911-0.912-1.952-0.39-2.343,0.781c-0.39,1.172-1.302,3.775-0.781,4.946c0.521,1.172,1.432,0.911,2.343,0.13
		c0.911-0.78,2.213-1.562,2.213-0.13c0,1.431,0.781,1.953-1.172,2.864c-1.952,0.911-2.864,2.083-2.603,2.994
		c0.261,0.911,2.082,3.775,3.254,2.083s0.261-4.686,1.823-2.864c1.562,1.823,0.651,2.734,0,3.645s0.39,2.083-2.343,1.952
		c-2.734-0.13-4.166,0.39-1.953,1.172c2.213,0.781,3.775,1.562,4.166,0.781c0.39-0.781,2.603,1.562,2.734-0.391
		c0.13-1.952,0.261-3.644,1.952-3.775c1.692-0.13,1.041,1.041,0.911,2.473c-0.13,1.432,1.172,2.213-0.521,2.734
		c-1.692,0.521-3.905,1.431-2.213,2.603c1.692,1.171,3.644,1.952,1.823,2.342c-1.823,0.39-3.124,1.041-2.734,1.692
		c0.39,0.651-1.692,0.781-1.692,1.823s1.041,3.644,1.953,2.864c0.911-0.781,2.213-1.823,3.254-0.651s-0.39,3.775,0.911,3.124
		c1.301-0.651,2.994-1.041,3.124,0.13c0.13,1.172-1.302,0.977-1.367,1.628s-1.628,0.977-2.603,1.628
		c-0.977,0.651-3.059,1.041-1.628,1.952s2.864,1.953,2.864,1.106s-0.131-2.018,1.04-2.018c1.172,0,2.213-0.846,2.213,0.456
		s0.456,1.952,1.497,1.823c1.041-0.13,2.148,0.456,2.278-0.912c0.13-1.367,0.651-1.757,1.757-1.562s1.692,0.195,1.692,1.172
		s-0.586,1.496-1.431,1.757c-0.846,0.26-0.066,1.236-0.977,1.497c-0.911,0.26-0.977-0.456-2.343-0.456s-2.018,0.066-2.603,1.041
		c-0.585,0.977-1.952,2.213-0.716,2.799c1.236,0.585,0.781,1.757,2.278,1.628c1.497-0.13,0.911-0.716,1.236,0.39
		c0.326,1.107-2.082,1.887-1.106,2.669c0.977,0.781,1.887,1.562,2.798,1.302c0.911-0.26,1.236-0.521,1.367,0.391
		c0.13,0.911-0.521,1.822,0.651,2.342c1.172,0.521,1.823,1.172,2.343,0.456c0.521-0.716,1.367-1.172,2.278-1.757
		s2.148-2.018,3.839-2.929c1.692-0.911,2.474-1.367,1.172-1.757s-3.059-1.562-4.1-0.781s-3.319,1.692-4.231,1.302
		c-0.911-0.39-2.864-2.343-0.13-2.538c2.733-0.195,3.515-0.326,4.231-0.456s2.733-0.13,3.775,0.651s2.278,1.367,2.408,2.734
		s0.846,2.213-1.367,2.734s-3.124,1.236-3.97,1.562c-0.846,0.326-1.497,2.018-2.148,2.669s-1.431,1.171-3.124,0.651
		c-1.692-0.521-4.295-1.237-5.077-1.237c-0.781,0-2.082,0.261-2.082,1.302s-0.13,1.563,0.977,2.213
		c1.107,0.651,2.343,1.172,1.497,2.018c-0.846,0.846,0.195,1.106,1.628,1.172c1.431,0.066,3.515,0.26,3.84-0.651
		c0.326-0.911,2.864-0.26,2.473-1.628c-0.39-1.367,0.456-1.628,1.692-1.628s2.018,0.326,3.385,0.716s4.036,1.302,4.231-0.586
		c0.195-1.887,0.586-5.272,1.106-5.923c0.521-0.651,1.107-1.823,1.236-2.994c0.13-1.172,0.586-2.083,1.432-2.083
		s1.952,0.977,2.343,0.13c0.39-0.846,1.628-1.628,2.798-1.692c1.172-0.065,3.254-0.195,3.71-1.172s1.431-2.538,2.798-1.367
		c1.367,1.172,3.385,1.887,4.426,1.887c0.175,0,0.322-0.069,0.442-0.182c-0.351-0.403-0.915-0.979-1.623-1.452
		c-1.211-0.807-2.341-0.888-3.552-1.534s-3.39,0.162-4.359-0.484s-4.117-0.565-7.023-0.565s-6.539,0-7.75,0s-2.986,0-3.39-1.373
		s-1.857-1.453-2.986-2.502s0-3.068,0-4.44c0-1.373,0-1.777,0.888-2.745c0.888-0.969-0.646-1.615-0.646-2.099
		s-0.727-0.807-2.18-1.13s-1.695,0.08-2.18,0.727c-0.484,0.646-1.292,0.403-2.18,0s-1.373-1.777-1.13-3.23
		c0.242-1.453-1.049-1.453-1.615-2.261c-0.566-0.808-0.403-6.539-0.08-7.911c0.323-1.373,2.26-0.808,3.068-1.777
		c0.808-0.969,1.291-1.373,2.664-2.422c1.373-1.05,1.453-2.341,1.291-3.713c-0.162-1.373,1.695-1.211,2.18-2.341
		c0.484-1.13,0-1.857-0.484-3.068s-1.13-0.646-0.969-2.019c0.162-1.373,1.13-1.695,1.291-2.987c0.161-1.291,0.808-1.049,2.422-1.857
		s0.484-2.584,0.484-4.521s1.777-0.969,1.857-2.422s1.211-2.019-0.646-3.148s-0.565-1.937,0.808-2.745
		c1.373-0.807-0.808-2.018-0.727-3.148c0.08-1.13,1.373-1.453,3.068-2.664s-0.403-1.695-1.049-2.987s-1.857-1.373-2.986-1.615
		c-1.13-0.242-1.05-0.969-1.05-1.695s2.341,0.404,3.714,0.404s2.986-0.969,3.068-2.341c0.08-1.373-2.422-1.13-3.955-1.291
		s-1.453-1.857-0.565-3.23s-0.566-2.18-0.242-3.552c0.323-1.373-1.211-2.019-0.888-3.31c0.323-1.292,0.08-1.937-1.049-2.502
		c-1.13-0.565-0.646-6.136-0.646-6.862s1.291-1.211,2.26-1.695s0.324-0.808,0.324-1.695s-0.242-0.969-0.727-1.695
		c-0.484-0.727-0.323-3.714-0.242-5.328c0.08-1.614-0.08-2.826-0.08-3.39s0.08-2.18,0.808-3.23c0.727-1.05,0.646-4.036,0.646-5.408
		s-0.161-2.341,0.969-2.906c1.129-0.565,1.534-1.695,1.05-3.068s0.08-3.229,1.291-4.117s1.857-0.08,2.906-1.453
		s-1.049-2.906-0.888-3.471s0.323-1.695-0.727-3.23c-1.05-1.534,0.081-6.539-0.323-7.427s-0.08-1.695,0-2.583
		s0.565-2.422,2.018-2.906s1.615-1.695,2.583-2.26c0.969-0.565,1.453-1.695,1.453-2.664s0.162-2.341-0.727-2.664
		c-0.888-0.323-0.484-1.857-0.484-3.068s0.969-2.341,1.373-3.39c0.404-1.05,1.049-2.664,1.776-4.037s1.373-1.695,1.857-3.472
		c0.484-1.777-0.242-2.26,0.484-3.875c0.727-1.615-0.323-1.291-1.695-1.937c-1.373-0.646-1.211-1.857-0.566-2.906
		c0.646-1.05,0.646-1.373-0.484-2.26c-1.13-0.888-0.888-2.341-1.049-3.23c-0.162-0.888-0.565-1.937-0.646-2.826
		c-0.08-0.888-0.484-0.323-1.453-1.937c-0.969-1.615-0.404-2.18-0.323-3.23c0.08-1.049,0.969-2.261,1.695-2.987
		c0.727-0.727,0.646-2.018,0.969-3.713s0.969-1.695,2.099-2.019c1.13-0.323,1.13-2.019,0.727-2.584s-0.484-1.373-0.08-2.826
		c0.403-1.453-0.807-1.533-1.049-2.906c-0.242-1.373,0.484-1.937,1.695-3.068c1.211-1.13,0.807-2.422,0.727-3.956
		s0.888-2.341,1.937-3.148s1.857-1.776,2.744-3.068s1.05-2.422,1.13-3.472s1.373-2.341,2.502-2.26
		c1.13,0.08,1.777-0.404,2.422-1.453c0.646-1.049-0.161-1.857-0.969-2.422c-0.807-0.565-1.291-0.969-1.291-2.422
		s1.453-1.777,1.453-2.422c0-0.646-0.404-1.857-0.969-2.987s-0.646-2.826,0.08-3.552c0.727-0.727,1.211-1.695,0.081-2.987
		s-0.162-2.019,0.161-2.503c0.323-0.484,2.099-1.211,3.23-2.26s3.39-1.937,4.763-2.341s1.615-1.291,1.615-2.26
		c0-0.969,0.162-1.777,0.646-2.503c0.484-0.727,0.323-1.615,0.565-3.875C557.892,384.604,554.824,386.461,552.887,386.057z"
        pointer-events="auto"
      ></path>

      <path
        id="BR"
        title="Brésil"
        capital="Brasilia"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M801.386,259.454
		c-2.431-4.511-2.778-8.677-3.298-10.587s-2.778-4.861-5.207-4.513c-2.43,0.348-6.943,2.084-13.537-3.99
		c-6.596-6.075-17.531-13.54-21.522-13.713c-3.991-0.173-6.594,2.779-7.983,1.217c-1.389-1.562-1.735-1.041-3.124-1.562
		c-1.389-0.521-6.597-2.949-7.984-2.603c-1.388,0.346-4.167,0.691-4.86,0.867c-0.693,0.175-0.867,0.871-1.214,2.258
		c-0.348,1.387-2.604,1.212-2.431-0.695c0.174-1.908,3.992-2.256,1.736-4.859s-1.909-4.513-4.686-4.513
		c-2.778,0-5.729-2.429-7.984-2.429s-2.431-2.433-4.686-2.258c-2.256,0.175-8.158-1.737-9.372-1.562s-2.949,2.258-3.818,4.513
		c-0.869,2.256-4.686,4.168-5.901,5.555s-2.43-2.603-0.521-3.645c1.909-1.041,4.513-4.165,5.381-6.075s0.693-3.992-1.736-4.166
		c-2.43-0.173-4.513-0.173-4.86,0.869c-0.347,1.041-2.081-1.391-2.256-2.258c-0.174-0.867-0.869-2.256-2.257-2.777
		s-2.431-0.869-2.778,1.214c-0.347,2.082-2.95,2.082-2.95,4.166c0,2.082,0.174,5.034-1.388,5.034s-0.869-1.91-2.257-2.431
		s-2.431-3.645,0.347-4.859s4.86-1.736,6.422-5.035c1.562-3.297,2.083-4.337,2.778-6.073c0.693-1.737-0.347-3.473-2.083-3.992
		c-1.736-0.521-4.166,0.175-4.86-4.339c-0.694-4.513-2.602-9.547-2.95-11.282c-0.297-1.48,0.164-2.709-2.499-3.685
		c-1.327,1.485-1.67,3.736-2.909,5.165c-1.575,1.817-2.785,2.664-4.481,7.871c-1.695,5.207-6.297,3.754-8.113,3.633
		c-1.817-0.122-2.543,1.09-5.57,1.332s-3.996-1.937-4.481-3.028s-3.148-0.484-3.754,0.122c-0.605,0.606-1.817,0.364-3.633,0.364
		s-1.817,2.18-1.817,3.754c0,1.575-5.449,0.606-6.781,0.606s-2.059,0.122-3.754,0.363c-1.695,0.242-3.028,0.727-3.875,1.817
		s-2.059,1.211-2.422,0.484c-0.364-0.727-1.817,0.484-2.785,2.18c-0.969,1.695-3.028,0.727-3.512,0.364
		c-0.484-0.364-2.301-2.059-3.633-3.39c-1.332-1.332-2.301-2.18-2.422-3.512c-0.122-1.332-0.727-1.575-0.969-2.664
		s-0.363-3.39,0.122-4.239s1.211-2.059,0.727-3.39c-0.484-1.332,0.122-1.817,1.695-2.785c1.574-0.969-0.969-2.301-1.09-3.148
		c-0.122-0.848-0.122-1.453-2.059-1.937s-0.122-1.211,0.121-2.906s-0.121-2.785-2.906-2.906s-1.937,0.969-2.18,2.422
		c-0.242,1.453-1.695,1.09-2.18,2.301c-0.484,1.211-0.848,0.606-2.422,1.332c-1.574,0.727-1.575,1.09-3.512,1.09
		s-2.059,1.332-3.996,1.211c-1.937-0.122-1.453,0.727-2.543,0.727s-1.453,1.09-1.332,2.664c0.122,1.575-1.211,0.848-2.059,0.727
		c-0.848-0.122-0.727-0.727-1.453-1.817c-0.727-1.09-1.937,0.121-3.39-0.484s-2.18,0.242-2.543-0.969
		c-0.363-1.211-2.059-0.848-3.754-1.09c-1.695-0.242-1.695,0-1.695,1.09s1.332,1.817,2.786,3.39c1.453,1.575,0,4.723,0.727,5.086
		c0.727,0.364,1.575,1.211,1.575,2.664s0.727,1.09,2.906,1.332c2.18,0.242,1.453,0.969,1.211,2.059s-1.575,0.969-3.39,1.575
		c-1.817,0.606-1.817,1.453-2.059,3.27s-1.695,1.575-3.633,1.575c-1.937,0-1.332,1.575-3.028,1.695
		c-1.695,0.122-0.969,1.575-2.301,2.18c-1.332,0.606-1.332-0.364-2.422-1.09c-1.09-0.727-1.453,0.122-1.817,1.211
		c-0.363,1.09-1.695,0.727-3.39,0.484c-1.695-0.242-1.332-0.484-2.18-1.575c-0.848-1.09-1.575-1.332-3.028-1.575
		c-1.453-0.242-0.484-4.239-0.727-5.449c-0.127-0.635-0.986-1.269-1.775-1.729c-0.257,0.142-0.622,0.255-1.131,0.357
		c-2.018,0.404-2.26,0.646-2.502,1.453s-1.857,0.969-2.422,0.08c-0.566-0.888-1.937-1.211-1.937-0.162s-0.324,1.13-3.23,1.05
		s-8.799-1.373-8.396,1.13c0.404,2.503-0.404,3.956,1.13,4.198s4.359-0.08,4.198,1.211c-0.162,1.291,1.049,1.937-0.646,2.018
		c-1.695,0.081-5.57-0.565-5.812,1.13s-0.646,4.843,0.323,5.65c0.969,0.808,3.23,2.664,3.714,3.956
		c0.484,1.292,0.646,1.534,0.727,2.906c0.08,1.373,0.08,1.857-0.484,2.987c-0.565,1.13-0.808,2.422-0.808,3.633
		s-0.242,2.664-0.242,3.552s-1.13,4.441-1.13,6.296c0,1.857-0.888,3.39-0.888,4.843s0.161,3.229-1.13,3.31
		c-1.291,0.08-2.664-0.484-3.633-0.888s-1.615-0.646-2.664,0.242s-2.099,1.696-4.117,1.777s-3.794-0.484-5.408,1.211
		c-1.615,1.695-3.794,2.986-4.843,3.471s-2.099,1.13-1.776,2.019c0.323,0.888-0.081,2.987-0.888,3.956s-1.857,1.695-1.13,2.744
		s1.211,2.099,0.162,2.583c-1.049,0.484-1.937,0.323-2.744,1.291c-0.808,0.969-2.26,1.937-1.937,2.906s1.291,1.695,0.162,2.261
		c-1.13,0.566-2.826,1.373-1.453,2.503s1.05,1.129,1.857,2.26c0.807,1.13,1.534,3.068,2.583,4.198s1.049,2.099,1.857,2.664
		c0.808,0.565,1.373,0.727,1.13,1.857s0.08,2.099,1.533,2.261s3.956,0.404,4.279,1.777c0.323,1.373-0.081,3.31,1.533,3.31
		c1.615,0,3.068,0.566,5.167-0.404s2.906-0.969,3.633-2.019c0.727-1.05,3.39-3.39,3.471-2.18c0.08,1.211-1.049,2.099-0.969,3.23
		c0.08,1.13-0.565,3.713-0.565,5.247s0.323,2.907,1.291,3.472c0.969,0.565,2.26,1.372,2.906,0.323s2.099-0.888,2.986-0.727
		c0.529,0.096,1.287-0.089,1.883,0.288c0.538-0.085,1.378-0.084,2.699,0.156c3.33,0.606,2.664-0.242,3.148,0.545
		c0.484,0.787,2.603,0.969,2.967-0.242c0.364-1.211,1.453-2.18,2.725-2.543c1.272-0.364,2.725-0.061,3.39-1.15
		c0.666-1.089,1.634-1.695,3.028-1.998c1.392-0.303,2.059-0.908,2.603-1.695c0.545-0.787,2.725-0.848,3.33-1.575
		c0.605-0.727,2.119-0.848,3.148-0.848s2.906,0.181,3.451-0.424c0.545-0.606,1.392-0.181,1.695,0.727s0.848,2.059,0.122,2.785
		c-0.727,0.727-0.969,1.453-0.908,2.059s-0.666,0.606-0.181,1.695c0.484,1.089,0.666,1.695,0.545,2.785
		c-0.122,1.09-0.424,2.119,0.363,3.028c0.787,0.908,1.392,1.514,1.817,2.603c0.424,1.09,1.272,2.301,2.301,2.845
		c1.029,0.545,1.998,1.272,2.361,2.18c0.364,0.908,1.453,1.09,3.028,1.211s4.178-0.606,5.207,0.545
		c1.029,1.15,3.148,0.545,3.875,1.937s1.453,1.817,2.906,1.937c1.453,0.122,4.178,1.09,4.723,2.361s3.028,1.514,4.904,1.514
		c1.877,0,4.056-0.364,5.025,0.666c0.969,1.029,1.937,1.937,1.817,3.209c-0.121,1.272-0.666,1.817,0.181,2.725
		c0.848,0.908,0,1.272,0.484,2.119s0.848,1.937-0.181,2.543s-1.453,1.09-0.605,2.059s2.059,0.606,1.634,2.24
		c0,1.877-0.061,5.994-0.061,5.994l14.168-0.061c0,0,0.605,2.059-0.122,2.785c-0.727,0.727-0.605,1.453-0.545,2.301
		c0.061,0.848,0.727,2.119,0.908,2.603s1.09,0.787,2.18,1.15c1.09,0.364,2.725,0.848,2.725,2.119s-0.242,1.634,0.424,2.119
		c0.666,0.484,1.392,1.453,1.211,2.483s-1.453,1.332-1.453,2.543s-0.484,2.906-0.424,3.814c0.061,0.908-0.122,1.272-0.969,2.24
		c-0.848,0.969-1.272,2.422-0.969,3.451s1.211,1.211,0.303,2.483s-2.059,1.514-0.787,2.725c1.272,1.211,1.877,1.575,1.634,3.39
		c-0.242,1.817,0.121,3.028-0.364,4.239s-0.484,3.39-0.121,4.481c0.363,1.09-0.484,2.18,1.817,2.422s3.028,0.606,4.359,1.09
		c1.332,0.484,3.875,0.364,4.239-0.484s1.09-2.059,2.543-0.727s2.786,0.606,2.906,2.543c0.122,1.937,1.332,3.633,1.817,5.692
		c0.484,2.059,0.363,6.054,2.18,6.418c1.817,0.363,3.512,1.09,4.239-0.122c0.727-1.211,1.332-1.817,2.543-1.09
		c1.211,0.727,2.18,1.332,2.18,3.148c0,1.817,0.242,1.937-0.969,4.239c-1.211,2.301-1.817,3.633-1.937,5.086
		c-0.048,0.58-0.097,1.065-0.137,1.569c0.828,0.063,1.922,0.126,2.923,0.126c1.937,0,3.27,0.363,3.27,1.453s0.484,2.18,0.969,2.785
		c0.484,0.606,1.09,1.575,0.242,2.543s-1.09,1.575-0.969,3.27c0.121,1.695,1.937,3.512-2.543,4.481
		c-4.481,0.969-5.934,2.906-7.629,4.239c-1.695,1.332-3.148,1.453-4.723,3.754s-2.301,2.664-3.512,4.481s-2.664,1.937-3.996,3.996
		s-1.211,3.27-3.148,4.117c-1.937,0.848-1.453,2.301-3.148,3.27c-0.394,0.226-0.781,0.485-1.141,0.768
		c0.742,0.38,1.804,0.627,3.079,0.079c2.543-1.09,1.211-1.817,3.27-1.09s2.664,0.727,3.028,2.18s2.18,0.606,2.543,2.664
		c0.364,2.059,1.211,4.359,2.422,3.028c1.211-1.332,1.09-2.301,2.18-0.484c1.09,1.817,1.695,2.785,3.996,2.906
		c2.301,0.121,2.906,0.364,3.633,2.059c0.727,1.695,1.09,2.18,2.543,2.785c1.453,0.606,3.028,0.364,3.633,2.18
		c0.605,1.817,0.605,4.723,0.848,6.539c0.242,1.817-0.121,2.785,0.484,4.481c0.487,1.366,0.823,2.572,1.376,3.688
		c2.623-2.521,6.653-5.212,6.82-8.392c0.174-3.299,2.083-4.339,1.91-6.769c-0.174-2.431,0-4.166,1.388-5.728
		c1.388-1.563,5.38-2.777,6.074-5.728s0.348-3.297,2.257-3.645s2.951-0.348,2.778,1.214c-0.174,1.562-2.083,2.603-3.298,4.166
		c-1.216,1.562-1.042,2.951-3.298,4.165c-2.255,1.214-4.512,3.297-4.512,4.166s1.389-0.348,2.778-1.041
		c1.388-0.693,8.33-6.073,9.024-7.983s2.95-5.207,3.645-8.331c0.694-3.124,4.34-6.25,5.381-7.985
		c1.041-1.735,6.074-4.686,6.074-7.81s2.083-3.472,0.869-5.728c-1.215-2.256-2.257-2.949-1.736-6.421s-0.694-3.47,0.868-5.38
		c1.562-1.91,1.562-1.389,1.041-3.473c-0.521-2.082-0.348-1.908,1.562-1.734c1.91,0.173,2.082-0.867,3.298-3.645
		c1.215-2.779,5.033-2.258,6.074-3.992c1.041-1.735,0.348-1.041,3.298-2.082c2.95-1.041,4.167-1.389,4.86-2.431
		c0.693-1.041,2.603,0.346,4.166,0.521c1.562,0.175-0.694-2.774,1.562-3.47c2.255-0.695,3.818-0.693,4.859-2.082
		s3.992-3.647,3.819-2.258c-0.174,1.389,0.694,1.217,2.778,0.869c2.083-0.348,11.28,1.217,11.628-2.255
		c0.348-3.473,1.214-4.166,3.125-6.076c1.91-1.91-0.869-5.382,2.083-7.637c2.951-2.256,4.167-4.511,5.034-6.942
		c0.868-2.431,1.389-3.99,2.603-4.859c1.214-0.869,1.388-1.041,1.388-5.034c0-3.992,0.348-7.117,2.778-8.679
		c2.43-1.562,1.041-7.115,1.388-9.718s3.124-3.647,2.083-7.464s-1.563-6.073-1.216-8.504c0.348-2.431,1.389-3.126,0.869-5.034
		c-0.521-1.908-1.562-2.603-0.521-4.166c1.041-1.562,1.561-4.686,2.255-4.686s-1.389-2.082,0.521-1.562s4.512,0.695,5.728-2.429
		c1.215-3.124,2.951-7.81,4.513-8.852c1.562-1.041,0.868-3.992,3.991-4.513c3.124-0.521,4.165-4.165,7.29-7.29
		c3.124-3.124,6.422-7.985,6.769-11.803C800.518,265.53,803.816,263.966,801.386,259.454z"
        pointer-events="auto"
      ></path>

      <path
        id="PY"
        title="Paraguay"
        capital="Asunción"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M656.944,396.673
		c0-1.817-0.969-2.422-2.18-3.148c-1.211-0.727-1.817-0.122-2.543,1.09c-0.727,1.211-2.422,0.484-4.239,0.122
		c-1.817-0.364-1.695-4.359-2.18-6.418c-0.484-2.059-1.695-3.754-1.817-5.692c-0.121-1.937-1.453-1.211-2.906-2.543
		s-2.18-0.122-2.543,0.727c-0.364,0.848-2.906,0.969-4.239,0.484c-1.332-0.484-2.059-0.848-4.359-1.09s-1.453-1.332-1.817-2.422
		c-0.363-1.09-0.363-3.27,0.121-4.481c0.484-1.211,0.122-2.422,0.364-4.239c0.242-1.817-0.364-2.18-1.634-3.39
		c-1.272-1.211-0.122-1.453,0.787-2.725c0.362-0.506,0.432-0.838,0.372-1.122c-1.027-0.031-1.971-0.083-2.834-1.119
		c-1.211-1.453-3.714-2.341-4.601-3.23c-0.888-0.888-4.925-0.646-7.427-0.888s-3.31,0.404-4.279,1.13
		c-0.969,0.727-3.794,0.969-5.974,1.05s-1.937,0.566-3.714,1.373c-1.777,0.807-1.695,4.037-3.229,5.328
		c-1.533,1.291-1.291,4.198-1.373,6.297c-0.08,2.099-0.08,2.341-0.727,3.068c-0.646,0.727-1.211,1.533-1.453,2.744
		c-0.125,0.623-0.356,1.458-0.551,2.125c0.935,0.878,1.232,2.232,2.126,3.042c1.332,1.211,1.937,2.301,3.148,4.117
		c1.211,1.817,2.18,2.664,3.754,3.27c1.575,0.606,2.664,1.453,3.512,3.148s3.754,2.059,6.66,1.937
		c2.906-0.122,6.66,4.359,9.203,6.054c2.543,1.695,6.781,1.817,8.598,3.512s1.695,2.18,1.09,3.875
		c-0.606,1.695-0.484,1.695-1.817,2.664s-1.575,0.484-1.695,3.148c-0.122,2.664,0,3.27-2.664,5.692s-0.363,2.422,2.422,2.422
		c2.784,0,3.633,0.364,5.328,0.848c1.695,0.484,3.39,0.364,5.57,0.606c2.18,0.242,2.422,0.122,3.754-1.09
		c1.332-1.211,2.422-0.242,3.39,0.122c0.969,0.363,2.059-0.242,2.18-2.059c0.122-1.817,2.906-1.817,5.449-3.633
		c2.543-1.817,1.817-4.965,1.817-7.023c0-2.059,0.122-2.906,0.242-4.359c0.121-1.453,0.727-2.785,1.937-5.086
		C657.186,398.611,656.944,398.489,656.944,396.673z"
        pointer-events="auto"
      ></path>

      <path
        id="GF"
        title="Guyane-française"
        capital="Cayenne"
        stroke-width="0.5"
        stroke-miterlimit="10"
        d="M675.38,170.765
		c-4.513-1.041-3.644-3.99-6.248-5.38c-2.603-1.389-2.779-2.779-5.034-2.779c-1.496,0-3.831-1.295-5.995-2.271
		c0.098,1.28,0.085,2.962-0.555,3.602c-1.09,1.09-3.39,1.937-3.39,3.39s-0.848,2.785-0.121,3.754
		c0.727,0.969,0.969,1.695,1.453,3.39c0.484,1.695,0.848,3.996,0.848,5.692s1.453,3.39,0.122,4.481
		c-1.332,1.09-1.575,2.301-1.817,3.39c-0.093,0.417-0.202,0.762-0.29,1.078c0.464,0.126,0.996,0.183,1.622,0.133
		c3.028-0.242,3.754-1.453,5.57-1.332c1.817,0.122,6.418,1.575,8.113-3.633c1.695-5.207,2.906-6.054,4.481-7.871
		c1.238-1.429,1.581-3.679,2.909-5.165C676.589,171.078,676.042,170.918,675.38,170.765z"
        pointer-events="auto"
      ></path>
    </svg>
  </div>
</template>

<style scoped>
.svg-container {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
}

svg {
  width: 100vw;
  height: 80vh;
}

@media screen and (max-width: 850px) {
  svg {
    width: 100vw;
    height: inherit;
  }
}
</style>