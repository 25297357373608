<template>
  <div class="header">
    <nav>
      <div class="col nav-mobile">
        <button v-on:click="$router.go(-1)" class="go-back">
          <span class="material-symbols-outlined icon">arrow_back_ios_new</span>
        </button>

        <Timer @timer="getTime" v-if="info" />
        <div>
          <div class="info-country" v-if="this.info">
            <img
              :src="require(`@/assets/flags/${code}.png`)"
              alt="flag"
              class="flag"
            />
            <h1>{{ guessCountry }}</h1>
          </div>
        </div>

        <h2>Score : {{ correctAnswer }}</h2>
      </div>
      <div class="tries" v-if="displayTriesSentence">
        <p class="info-tries"></p>
      </div>
    </nav>
  </div>
</template>

<script>
import Timer from "../Timer.vue";
export default {
  components: {
    Timer,
  },
  data() {
    return {
      time: "",
    };
  },
  props: [
    "info",
    "code",
    "guessCountry",
    "correctAnswer",
    "displayTriesSentence",
  ],
  methods: {
    getTime(value) {
      this.time = value;
    },
  },
  mounted() {
    setInterval(() => {
      this.$emit("timer", this.time);
    }, 1000);
  },
};
</script>

<style scoped>
.header {
  background: var(--light-orange);
  background: transparent;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  justify-content: center;
}

nav {
  margin: 0 80px;
  background: var(--light-orange);
  border-radius: 10px;
  border: 1px solid var(--light-grey);
  padding: 0 24px;
  box-shadow: 4px 4px 10px 0 rgba(122, 122, 122, 0.1);
}

.nav-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.flag {
  height: 40px;
  margin-right: 12px;
}

button {
  background: none;
  font-weight: bold;
  border: none;
  color: #7a7a7a;
  font-size: 16px;
  cursor: pointer;
}

.go-back .icon {
  color: #7a7a7a;
  font-size: 24px;
}
.info-country {
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  font-weight: 500;
  color: #7a7a7a;
}

.tries {
  display: flex;
  justify-content: center;
  height: 12px;
  background: var(--light-orange);
  height: 30px;
}

.tries p {
  font-size: 22px;
}

h1 {
  font-weight: 600;
  font-size: 24px;
}

p {
  font-weight: 400;
  color: #7a7a7a;
}
</style>