<template>
  <button>{{message}}</button>
</template>

<script>
export default {
    props: {
        message: '',
    }
}
</script>

<style scoped>
button {
    background: var(--orange);
    border: none;
    border-bottom: 5px solid #B15301;
    border-radius: 10px;
    width: 250px;
    height: 50px;
    color: white;
    font-size: 22px;
    cursor: pointer;
}
</style>